import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Divider,
  Popover,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { nanoid } from '@reduxjs/toolkit';
import { useMemo, useState } from 'react';
import { utcTimestampToLocalizedClean } from '../../../models/dates';
import { staticService } from '../../../services/StaticService';
import { loadChanges } from '../../api/audit';
import { useHistoryChangeColumnSetting } from '../../container/History/HistoryChangeColumnSetting';
import { ChipPicker } from '../ChipPicker';
import { TableHeader } from '../InfiniteTable/TableHeader';
import { TableRow } from '../InfiniteTable/TableRow';
import { trapEvent } from '../hooks';
import {
  auditSubRowStyle,
  expandRowStyle,
  historyHeadbRowStyle,
  infoHeader,
} from './style';

interface EditNotesProps {
  data: any;
  readOnly?: boolean;
  idField: string;
  onCommit(x: any): any;
}
export function EditNotes({
  data,
  readOnly,
  idField,
  onCommit,
}: EditNotesProps) {
  const [tab, setTab] = useState(0);

  return (
    <Stack gap={1} sx={{ width: '320px' }} onClick={trapEvent}>
      <Tabs
        textColor="secondary"
        value={tab}
        onChange={(e, newTab) => setTab(newTab)}
      >
        <Tab label="Notes" />
        <Tab label="History" />
      </Tabs>

      {tab === 0 && (
        <NotesTab data={data} readOnly={readOnly} onCommit={onCommit} />
      )}
      {tab === 1 && <HistoryTab data={data} idField={idField} />}
    </Stack>
  );
}
interface NotesTabProps {
  data: any;
  readOnly?: boolean;
  onCommit(x: any): any;
}

function NotesTab({ data, readOnly, onCommit }: NotesTabProps) {
  const [editNotes, setEditNotes] = useState(data.comments || {});

  const noteOptions = useMemo(
    () => [
      { label: 'General', value: 'general' },
      ...(staticService as any).data.countries.all.map(
        (country: { code: string }) => ({
          label: country.code.toUpperCase(),
          value: country.code,
        }),
      ),
    ],
    [],
  );

  const toggleCountry = (_: any, country: string) => {
    if (readOnly) return;

    const newNotes = { ...editNotes };
    if (country in editNotes) {
      delete newNotes[country];
    } else {
      newNotes[country] = data.comments?.[country] || '';
    }
    setEditNotes(newNotes);
  };

  const onInput = (country: any, note: any) => {
    setEditNotes({
      ...editNotes,
      [country]: note,
    });
  };

  return (
    <Stack gap={1} padding={1}>
      {!readOnly && (
        <ChipPicker
          options={noteOptions}
          selected={Object.keys(editNotes)}
          onChange={toggleCountry}
        />
      )}

      <Stack gap={1}>
        {noteOptions
          .filter(option => option.value in editNotes)
          .map(({ value: country }) => (
            <Stack key={country} direction="row" alignItems="center" gap={1}>
              <Box sx={{ width: '32px', textAlign: 'center' }}>
                {country === 'general' ? 'Gen.' : country.toUpperCase()}
              </Box>
              <TextField
                multiline
                size="small"
                disabled={readOnly}
                value={editNotes[country]}
                // readOnly={readOnly}
                inputProps={{ style: { fontSize: '0.70rem', height: '48px' } }}
                sx={{ flex: 1 }}
                onInput={(e: any) => onInput(country, e.target.value)}
              />
            </Stack>
          ))}
      </Stack>

      {!readOnly && (
        <Stack direction="row" justifyContent="flex-end">
          <Button startIcon={<Check />} onClick={() => onCommit?.(editNotes)}>
            Done
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

interface HistoryTabProps {
  data: any;
  idField: string;
}

function HistoryTab({ data, idField }: HistoryTabProps) {
  const [anchorEl, setAnchorEl] = useState<any>();
  const [detailsData, setDetailsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const open = Boolean(anchorEl);

  const handleClick = async (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsLoading(true);
    const changes: any[] = await loadChanges(data.type, data._id);
    if (changes && Array.isArray(changes) && changes.length === 0) {
      setErrorMessage('No details to display');
    }
    setIsLoading(false);
    setDetailsData(changes);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getTime(time: any) {
    if (!time) return;

    return utcTimestampToLocalizedClean(time, true);
  }

  return (
    <Stack gap={1} padding={1} sx={{ fontSize: '0.70rem' }}>
      <div>
        <b>ID:</b> {data[idField]}
      </div>
      <div>
        <b>Created by:</b>
        {data.created_by?.substring(0, data.created_by.indexOf('@')) ||
          data.created_by}
      </div>
      <div>{getTime(data.create_datetime) || getTime(data.created_time)}</div>
      <div>
        <b>Updated by:</b>{' '}
        {data.updated_by?.substring(0, data.updated_by.indexOf('@')) ||
          data.updated_by}
      </div>
      <div>{getTime(data.update_datetime) || getTime(data.updated_time)}</div>
      <LoadingButton
        onClick={handleClick}
        loading={isLoading}
        color={errorMessage ? 'error' : 'primary'}
      >
        {!errorMessage ? 'Show Details' : errorMessage}
      </LoadingButton>
      {detailsData.length > 0 && (
        <Popover
          id="history"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <HistoryDetail data={detailsData} />
        </Popover>
      )}
    </Stack>
  );
}

function HistoryDetail({ data }: any) {
  const columSetting: any = useHistoryChangeColumnSetting();
  const tableHeader = columSetting;
  const tableColumns = columSetting;
  return (
    <Box
      sx={{
        ...expandRowStyle,
        flex: 1,
        maxHeight: 400,
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {data &&
        data.map(({ versionNumber, updatedAt, updatedBy, changes }: any) => (
          <Stack className="_subs">
            <Box sx={infoHeader}>
              {changes ? (
                <Typography
                  sx={{ fontWeight: 600, fontSize: 11 }}
                  variant="subtitle2"
                >
                  CHANGED by {updatedBy} at{' '}
                  {utcTimestampToLocalizedClean(updatedAt)}
                </Typography>
              ) : (
                <Typography
                  sx={{ fontWeight: 600, fontSize: 11 }}
                  variant="subtitle2"
                >
                  Registered for audit by {updatedBy} at{' '}
                  {utcTimestampToLocalizedClean(updatedAt)}
                </Typography>
              )}
              <Typography sx={{ fontWeight: 600, fontSize: 11 }}>
                Version: {versionNumber}
              </Typography>
            </Box>
            <Divider sx={{ color: '#b17e9b' }} />
            {changes && (
              <TableHeader
                key={nanoid()}
                columns={tableHeader}
                sub
                rowStyle={historyHeadbRowStyle}
                badgeName="Changes"
                minWidth="750"
              />
            )}
            {changes &&
              changes.map((change: any) => (
                <TableRow
                  key={nanoid()}
                  item={change}
                  readOnly
                  columns={tableColumns}
                  tableRowStyle={auditSubRowStyle}
                />
              ))}
          </Stack>
        ))}
    </Box>
  );
}
