import { Add } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { card, cardContent, table, tableHeader } from '../style';
import CreatedRightsRow from './CreatedRightsRow';
import { createNewId } from '../../../../../models/records';

interface LeagueRightsProps {
  disabled: boolean;
  league: any;
  onChange: any;
}

const LeagueRights = ({ disabled, league, onChange }: LeagueRightsProps) => {
  const createdRights = (league && league.rights) || [];

  const handleAdd = () => {
    const newRight = {
      id: createNewId(),
      country: '',
      type: '',
      rightStart: '',
      rightEnd: '',
      level: 4,
    };

    let currentRights: any = [];
    if (createdRights) {
      currentRights = [...createdRights];
    }
    currentRights.push(newRight);
    onChange(currentRights);
  };

  const handleDeleteRow = (id: any) => {
    const newList = createdRights.filter((right: any) => right.id !== id);
    onChange([...newList]);
  };

  const renderRow = () => {
    if (createdRights?.length > 0) {
      return (
        <CardContent sx={cardContent}>
          <Table size="small" sx={table}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeader}>Country</TableCell>
                <TableCell sx={tableHeader}>Importance!</TableCell>
                <TableCell sx={tableHeader}>Start Date</TableCell>
                <TableCell sx={tableHeader}>End Date</TableCell>
                <TableCell sx={tableHeader}>Rights Type</TableCell>
                <TableCell sx={tableHeader} />
              </TableRow>
            </TableHead>
            <TableBody>
              {createdRights.map((right: any) => (
                <CreatedRightsRow
                  key={right.id}
                  right={right}
                  handleDeleteRow={handleDeleteRow}
                  disabled={disabled}
                />
              ))}
            </TableBody>
          </Table>
        </CardContent>
      );
    }
  };

  return (
    <Card sx={card}>
      <CardHeader
        title="Rights"
        action={
          <Fab
            id="add-rights"
            color="secondary"
            size="small"
            onClick={handleAdd}
            disabled={disabled}
          >
            <Add sx={{ fontSize: 20 }} />
          </Fab>
        }
      />
      {renderRow()}
    </Card>
  );
};

export default LeagueRights;
