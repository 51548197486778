import { nanoid } from '@reduxjs/toolkit';
import { compact, groupBy } from 'lodash';
import { addToast } from '../reducers/appSlice';

export interface DisplaySet {
  [key: number | string]: DisplayValue;
}

export interface DisplayValue {
  value: any;
  label: any;
  style?: any;
}

export interface ErrorMessage {
  id: number;
  error: string;
}
export interface BatchResponse {
  hasErrors: boolean;
  savedIds: number[];
  errorMessages?: ErrorMessage[];
  savedTempIds?: number[];
}
export interface BatchCall {
  payloads: any[];
  apiCall: (payload: any) => any;
  getId?: (item: any) => number;
}

export const buildErrorMessage = (response: BatchResponse) => {
  const errorMessages: any = [];
  if (response?.errorMessages) {
    const groupedById = groupBy(response.errorMessages, 'id');
    for (const id in groupedById) {
      errorMessages.push({
        id,
        message: groupedById[id].map(({ error }: any) => error).join('\n'),
        severity: 'error',
      });
    }
  }
  return errorMessages;
};

export function toastSaveResult(
  postRes: BatchResponse,
  putRes: BatchResponse,
  delRes: BatchResponse,
  prefix: string,
  saveMessage: string,
  delMessage: string,
  dispatch: (arg0: { payload: any; type: string }) => void,
) {
  const upsertIds = [...postRes.savedIds, ...putRes.savedIds];
  const compactIds = compact(upsertIds);
  if (compactIds.length > 0 && delRes.savedIds.length === 0) {
    dispatch(
      addToast({
        id: `${prefix}-save`,
        message: saveMessage.replace('{ids}', compactIds.join(', ')),
        severity: 'success',
      }),
    );
  }
  if (delRes.savedIds.length > 0) {
    dispatch(
      addToast({
        id: `${prefix}-del`,
        message: delMessage.replace('{ids}', delRes.savedIds.join(', ')),
        severity: 'success',
      }),
    );
  }
}
