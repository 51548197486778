import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { AllowedActionOptions } from '../container/Account/Notification/Criterias/hooks';
import { NotificationBox } from '../container/Account/Notification/NotificationBox';
import {
  NotificationBase,
  ProtocolSendInfo,
} from '../container/Account/Notification/NotificationSetting';
import { DataType } from './dataSlice';

export type NotificationError = {
  name?: string;
  action?: string;
  filter?: string;
  protocol?: string;
};

interface NotificationType {
  [key: string]: any;
  items: any;
  errors: NotificationError;
  changes: NotificationBase;
  groups: NotificationGroup[];
}

export interface NotificationGroup {
  [key: string]: any;
  id: string;
  name: string;
}

const initialState: NotificationType = {
  groups: [],
  items: {},
  changes: {
    id: 0,
    name: '',
    type: DataType.Unknown,
    notificationBoxes: {},
    disabled: false,
  },
  errors: {},
};

export const EMPTY_VALUE = '';

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotifications: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        items: { ...action.payload },
      };
    },
    addUserGroups: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        groups: action.payload,
      };
    },
    updateNotifications: (state, action: PayloadAction<any>) => {
      const newChanges = { ...state.changes, ...action.payload };
      return {
        ...state,
        changes: newChanges,
        errors: validate(newChanges),
      };
    },
    clear: state => ({
      ...state,
      changes: { ...initialState.changes },
    }),
  },
});

export const { addNotifications, addUserGroups, updateNotifications, clear } =
  notificationSlice.actions;

export const { reducer } = notificationSlice;

export function validate(data: NotificationBase): NotificationError {
  const {
    name,
    notificationBoxes,
    productionLeader,
    productionCreator,
    all,
    filter,
    type,
  } = data;
  let newErrors: NotificationError = {};

  if (!name) {
    newErrors = {
      ...newErrors,
      name: '* Please enter name for subscription',
    };
  }

  if (Object.keys(notificationBoxes).length === 0) {
    newErrors = {
      ...newErrors,
      protocol: '* Select how to be notified',
    };
  } else {
    const types = Object.keys(notificationBoxes);
    for (const contactType of types) {
      if (
        hasEmptyValues(
          notificationBoxes[contactType as keyof typeof NotificationBox],
        )
      ) {
        newErrors = {
          ...newErrors,
          protocol: '* Please fill in notification information',
        };
      }
    }
  }

  if (
    type === DataType.Event &&
    !productionCreator &&
    !productionLeader &&
    !all &&
    Object.keys(filter).length === 0
  ) {
    newErrors = {
      ...newErrors,
      filter: '* Please select when to be notified',
    };
  }

  if (
    type !== DataType.Placeholder &&
    type !== DataType.Feed &&
    actionIsMissing(type, data)
  ) {
    newErrors = {
      ...newErrors,
      action: '* Please select action',
    };
  }

  if (
    type === DataType.Production &&
    !all &&
    Object.keys(filter).length === 0
  ) {
    newErrors = {
      ...newErrors,
      filter: '* Please select filter',
    };
  }

  if (
    type === DataType.Event &&
    !all &&
    Object.keys(filter).length > 0 &&
    Object.values(data?.filter).every(isEmpty)
  ) {
    newErrors = {
      ...newErrors,
      filter: '* Please select filter',
    };
  }

  return {
    ...newErrors,
  };
}

/**
 *
 * @param type DataType
 * Get allowed actions and check if any of the actions is not checked
 */
export function actionIsMissing(
  type: DataType,
  data: NotificationBase,
): boolean {
  const typeActions = AllowedActionOptions[type];
  let actionIsMissing = true;
  if (typeActions) {
    typeActions.forEach((action: keyof typeof data) => {
      if (data[action]) {
        actionIsMissing = false;
      }
    });
  }
  return actionIsMissing;
}

export function hasEmptyValues(protocol: ProtocolSendInfo): boolean {
  if (
    protocol?.sendFrequency === EMPTY_VALUE ||
    protocol.ahead?.unit === EMPTY_VALUE ||
    protocol.ahead?.time === EMPTY_VALUE
  ) {
    return true;
  }

  if (protocol?.targetGroup && protocol?.targetGroup.length === 0) {
    return true;
  }

  return false;
}
