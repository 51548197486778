import {
  aggregatedInfoCollector,
  aggregatedInfoMerger,
  mergerWithSubItems,
  reseterWithSubItems,
  transformWorkspaceDelta,
  validateWorkspace,
} from '../../models/workspace';
import { createDataSlice } from './dataSlice';

const workspaceSlice = createDataSlice({
  sliceName: 'workspace',
  validator: validateWorkspace,
  deltaTransformer: transformWorkspaceDelta,
  aggregateDetailCollector: aggregatedInfoCollector,
  aggregateDetailMerger: aggregatedInfoMerger,
  subItemsMerger: mergerWithSubItems,
  subItemsReseter: reseterWithSubItems,
});

export const { reducer } = workspaceSlice;
export const {
  applyFilter,
  setRequestedTo,
  addItems,
  setFinished,
  update,
  create,
  undoCreate,
  undoChange,
  rollback,
  commit,
  clearList,
  setLoading,

  opensubItems,
  closeSubItems,
  setSubItems,
  removeSubItems,
  clearSubItems,
  refreshItem,
  resetItemAction,
} = workspaceSlice.actions;

export const actions = workspaceSlice.actions;

export const getSlice = (state: any) => state.workspace;
