import Keycloak from 'keycloak-js';

class AuthService {
  keycloakInstance = new Keycloak(process.env.REACT_APP_KEYCLOAK_CONFIG);

  callbacks!: {
    onInit: (isLoggedIn: any) => Promise<void>;
    onLogout: () => void;
  };

  init(callbacks: {
    onInit: (isLoggedIn: any) => Promise<void>;
    onLogout: () => void;
  }) {
    this.callbacks = callbacks;
    this.initKeycloak();
  }

  initKeycloak = async () => {
    try {
      const auth = await this.keycloakInstance.init({
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: `${window.location.origin}/auth/silent-check-sso.html`,
        pkceMethod: 'S256',
      });

      if (!auth) {
        window.location.reload();
      }

      const isLoggedIn = this.isLoggedIn();
      await this.callbacks.onInit(isLoggedIn);
    } catch (e) {
      console.error(e);
    }
  };

  refreshToken = async () => {
    if (
      !this.keycloakInstance ||
      !this.keycloakInstance.authenticated ||
      !this.keycloakInstance.token
    ) {
      console.log('Not authenticated');
      this.doLogin();
    }
    await this.keycloakInstance.updateToken(5).then(refreshed => {
      if (refreshed) {
        console.log('Token refreshed');
      } else {
        console.log('Token not refreshed, valid for another 60 seconds');
      }
    });
  };

  doLogin = () => {
    this.keycloakInstance.login();
  };

  doLogout = () => {
    this.keycloakInstance.logout();
    this.callbacks.onLogout();
  };

  getToken = () => this.keycloakInstance.token;

  isLoggedIn = () => {
    return !!this.keycloakInstance.token;
  };

  isTokenExpired = () => this.keycloakInstance.isTokenExpired();

  getUsername = () => this.keycloakInstance.tokenParsed?.preferred_username;

  hasRole = (roles: string[]) =>
    roles.some(role => this.keycloakInstance.hasRealmRole(role));

  openUserManagement = () => {
    const adminUrl = `${this.keycloakInstance.authServerUrl}admin/${this.keycloakInstance.realm}/console`;
    window.open(adminUrl, '_blank')?.focus();
  };
}

export const authService = new AuthService();
