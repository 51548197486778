import { DataType } from '../../../../reducers/dataSlice';

export interface NotificationActionProps {
  id: string;
  label: string;
}

/**
 * Actions that can be included in an notificaiton
 */
export type NotificationActionTypes = {
  [x: string]: any;
  created: NotificationActionProps;
  notifyAllChanges: NotificationActionProps;
  deleted: NotificationActionProps;
  startTimeChange: NotificationActionProps;
  timeChange: NotificationActionProps;
  runTypeChange: NotificationActionProps;
};

export enum ActionField {
  CREATED = 'created',
  DELETED = 'deleted',
  TIME = 'timeChange',
  START_TIME = 'startTimeChange',
  ALL_CHANGES = 'notifyAllChanges',
  RUN_TYPE = 'runTypeChange',
}

export type AllowedOption = {
  [x: string]: any;
};

export const AllowedActionOptions: AllowedOption = {
  [DataType.Event]: [
    ActionField.CREATED,
    ActionField.DELETED,
    ActionField.START_TIME,
    ActionField.ALL_CHANGES,
  ],
  [DataType.Production]: [
    ActionField.CREATED,
    ActionField.DELETED,
    ActionField.TIME,
    ActionField.RUN_TYPE,
  ],
};

export default function useNotificationActions(
  type?: DataType,
): NotificationActionProps[] {
  if (!type) return [];
  const initialActions: NotificationActionTypes = {
    created: {
      id: ActionField.CREATED,
      label: 'Is Created',
    },
    notifyAllChanges: {
      id: ActionField.ALL_CHANGES,
      label: 'Any updates',
    },
    deleted: {
      id: ActionField.DELETED,
      label: 'Is Deleted',
    },
    startTimeChange: {
      id: ActionField.START_TIME,
      label: 'Start time change',
    },
    timeChange: {
      id: ActionField.TIME,
      label: 'Start/End time is changed',
    },
    runTypeChange: {
      id: ActionField.RUN_TYPE,
      label: 'Run Type is updated',
    },
  };

  const options: NotificationActionProps[] = [];

  AllowedActionOptions[type]?.forEach((option: string) => {
    options.push(initialActions[option]);
  });

  return options;
}
