import { Box, Typography } from '@mui/material';

export default function EmptyRowsBox() {
  return (
    <Box
      sx={{
        display: 'flex',
        margin: 10,
        justifyContent: 'center',
      }}
    >
      <Typography variant="h2" display="block" sx={{ fontSize: 14 }}>
        No result found for the given filter
      </Typography>
    </Box>
  );
}
