import { ColumnType } from '../../components/InfiniteTable';
import { columns } from './style';

export const useHistoryChangeColumnSetting = () => {
  return [
    {
      key: 'id',
      header: '',
      datatype: ColumnType.Text,
      style: columns.id,
      editable: false,
      textAlign: 'left',
    },
    {
      key: 'property',
      header: 'Property',
      datatype: ColumnType.Text,
      style: columns.property,
      editable: false,
      textAlign: 'left',
    },
    {
      key: 'type',
      header: 'Type',
      datatype: ColumnType.Text,
      style: columns.type,
      editable: false,
    },
    {
      key: 'oldValue',
      header: 'Old Value',
      datatype: ColumnType.Text,
      style: columns.old,
      editable: false,
      textAlign: 'left',
    },
    {
      key: 'newValue',
      header: 'New Value',
      datatype: ColumnType.Text,
      style: columns.new,
      editable: false,
      textAlign: 'left',
    },
  ];
};
