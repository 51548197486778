import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { authService } from './services/AuthService';
import { staticService } from './services/StaticService';

import './index.css';
import theme from './theme/theme';
import { store } from './app/reducers/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const renderApp = () => {
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>,
  );
};

authService.init({
  onInit: async isLoggedIn => {
    await staticService.init(isLoggedIn);
    renderApp();
  },
  onLogout: () => staticService.clearPrivate(),
});

reportWebVitals();
