import { Save } from '@mui/icons-material';
import { Button, Stack, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { getTypeByFilters } from '.';
import { authService } from '../../../services/AuthService';
import { FilterAction, save } from '../../api/user';

/** Decides what type the filter will be saved as */
export function getFilterType(typedPreset: { filter: any; type: string }) {
  const selectedCriteria = typedPreset.filter;
  const criteriasWithValues = Object.keys(selectedCriteria).filter(
    (key: any) => selectedCriteria[key].length > 0,
  );
  const type = getTypeByFilters(criteriasWithValues);

  return type;
}

export interface SaveFilterProps {
  typedPreset: any;
  onSave?: any;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  setCriteria?: any;
}

export function SaveFilter({
  typedPreset,
  onSave,
  name,
  setName,
  setCriteria,
}: SaveFilterProps) {
  const isLoggedIn = authService.isLoggedIn();

  const dispatch = useDispatch();

  const isNew = !typedPreset.id;
  const hasValues = Object.values(typedPreset.filter).some(
    values => (values as any).length > 0,
  );

  const onClick = async () => {
    const type = getFilterType(typedPreset);

    const filters = {
      [type]: [{ ...typedPreset, name, type }],
    };

    const action = isNew ? FilterAction.CREATE : FilterAction.UPDATE;

    await save({ action, dispatch, filters });
    if (isNew) setName('');

    if (setCriteria) setCriteria([]);

    onSave?.();
  };

  if (!isLoggedIn || !hasValues) return null;
  return (
    <Stack direction="row" gap={1} sx={{ flex: '1' }}>
      <TextField
        size="small"
        placeholder="Enter name to save filter"
        value={name}
        onChange={e => setName(e.target.value)}
        sx={{ flex: '1' }}
      />
      <Button startIcon={<Save />} disabled={!name} onClick={onClick}>
        Save
      </Button>
    </Stack>
  );
}
