import { batchCreate, batchDelete, batchUpdate } from '../../services/common';
import { addMissingFieldsToast } from '../reducers/appSlice';
import { buildErrorMessage, toastSaveResult, BatchCall } from './common';
import { getKeyFromId } from '../../models/records';
import { DataType } from '../reducers/dataSlice';

interface BatchProps {
  payload: any[];
  request(x: any): any;
  getResultId?: (x: any) => any;
}

interface SaveProps {
  postReq: BatchCall;
  putReq: BatchCall;
  delReq: BatchCall;
  type: DataType;
  dispatch: any;
}

/**
 *  Will trigger a batch of request to api to perform the actions (POST,PUT,DELETE)
 *  Receive a BatchCall object for each operation, every batchCall contains  a list of payload, the rest-api call that will be made.
 *  For each payload will be made an api call passing the payload accordingly.
 *
 *  Returns a BatchResponse with the follow:
 *  hasErrors, boolean to indicate any errors
 *  errorMessage, the messages if any.
 *  savedIds, id that has been updated or deleted successfullyt.
 *  saveTempIds. tempid for the items created successfully. Useed to map or clean the item on the UI.
 *
 */

export async function save({
  postReq,
  putReq,
  delReq,
  type,
  dispatch,
}: SaveProps) {
  const postRes = await batchCreate(postReq);

  const putRes = await batchUpdate(putReq);

  const delRes = await batchDelete(delReq);

  if (postRes.hasErrors) {
    dispatch(addMissingFieldsToast(buildErrorMessage(postRes)));
  }
  if (putRes.hasErrors) {
    dispatch(addMissingFieldsToast(buildErrorMessage(putRes)));
  }
  if (delRes.hasErrors) {
    dispatch(addMissingFieldsToast(buildErrorMessage(delRes)));
  }

  toastSaveResult(
    postRes,
    putRes,
    delRes,
    `${type}`,
    `${type}s {ids} saved.`,
    `${type}s {ids} deleted.`,
    dispatch,
  );

  return {
    itemCreatedKeys: postRes.savedTempIds,
    itemUpdatedKeys: [
      ...putRes.savedIds.map((id: number) => getKeyFromId(type, id)),
      ...delRes.savedIds.map((id: number) => getKeyFromId(type, id)),
    ],
  };
}
