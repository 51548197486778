import { SetStateAction } from 'react';
import { extractTime, utcToLocalizedTime } from '../../../models/dates';
import { isEvent } from '../../../models/events';
import { isProduction } from '../../../models/productions';
import { isFeed } from '../../../models/feeds';
import api from '../../../services/APIService';
import { fetchFeeds } from '../../api/feeds';
import { fetchProductions } from '../../api/productions';
import { fetchEvents } from '../../api/events';

/**
 * Fetch the model from backend the given associations object (feed,production,event).
 * The model fetched from server are mapped to item object for the association view.
 * eg. an event id 1000 has the following association:
 *
 * association :{
 *   feeds:[566,787]
 *   productions:|77,9000]
 * }
 * Then it calls this function with the above association object, the outcome will be to set the below object in setItems Callback
 *
 * {feeds:[ {566, {id:566,time:"20:30",label:"Sportscar Grand Prix - Canada"}},
 *          {787, {id:787,time:"00:30",label:"Birmingham Stallions - Philadelphia Stars"}}]},
 *  productions:[...same structure as feed]
 *  }
 * )
 *
 *
 * @param events array with event id
 * @param feeds array with feed id
 * @param productions array with production id
 * @param setItems callback to set the object with 3 arrays (event,production,feed) with the association for each id.
 *
 * @returns {Promise<void>}
 */
export async function loadExistingAssociations(
  { events = [], feeds = [], productions = [] }: any,
  setItems: {
    (value: any): void;
    (value: SetStateAction<{ events: {}; feeds: {}; productions: {} }>): void;
    (arg0: { events: any; productions: any; feeds: any }): void;
  },
) {
  //Call the backeend for each id in the array
  const eventRecords = await Promise.all(
    events.map((id: any) => api.get(`/events/${id}`)),
  );
  const prodRecords = await Promise.all(
    productions.map((id: any) => api.get(`/productions/${id}`)),
  );
  const feedRecords = await Promise.all(
    feeds.map((id: any) => api.get(`/feeds/${id}`)),
  );
  //Map each model to item.id and item for the view
  setItems({
    events: Object.fromEntries(
      eventRecords
        .map(RecordMappers.events)
        .map((item: any) => [item.id, item]),
    ),
    productions: Object.fromEntries(
      prodRecords
        .map(RecordMappers.productions)
        .map((item: any) => [item.id, item]),
    ),
    feeds: Object.fromEntries(
      feedRecords.map(RecordMappers.feeds).map((item: any) => [item.id, item]),
    ),
  });
}

/**
 * Map an event,feed or production models to an item value displayed in the associtation popup
 * @type
 */
const RecordMappers: any = {
  all: (data: any) =>
    (isEvent(data) && RecordMappers.events(data)) ||
    (isProduction(data) && RecordMappers.productions(data)) ||
    (isFeed(data) && RecordMappers.feeds(data)),
  events: (eventData: {
    id: any;
    event_date: any;
    event_time: any;
    event: any;
  }) => ({
    type: 'event',
    id: eventData.id,
    time: utcToLocalizedTime(eventData.event_date, eventData.event_time),
    label: eventData.event,
  }),
  productions: (prodData: {
    production_id: any;
    production_start: string | undefined;
    title: any;
  }) => ({
    type: 'production',
    id: prodData.production_id,
    time: extractTime(prodData.production_start),
    label: prodData.title,
  }),
  feeds: (feedData: {
    feed_id: any;
    feed_start: string | undefined;
    title: any;
    delete_datetime: string;
  }) => ({
    type: 'feed',
    id: feedData.feed_id,
    time: extractTime(feedData.feed_start),
    label: feedData.title,
    ...(feedData?.delete_datetime && { deleted: feedData?.delete_datetime }),
  }),
};

/**
 * load the items for the association popup, the items can be event, feed, and production.
 * This function retrieves the data from backend based on league and date and transform it to an item model for the view.
 *
 * @param source, type of data. feed,feed or  production
 * @param league league used to filter
 * @param date date used to filter
 * @param setItems callback function to return the fetched items.
 * @returns {Promise<void>}
 */
export async function loadOptionItems(
  { source, league, date }: { source: string; date: any; league: any },
  setItems: { (value: SetStateAction<never[]>): void; (arg0: any): void },
) {
  const queryDate = new Date(date);
  const promise =
    (source === 'all' && Promise.resolve({ all: [] })) ||
    (source === 'events' &&
      fetchEvents({
        event: { league_id: league ? [league] : [] },
        fromDate: queryDate,
        toDate: queryDate,
      })) ||
    (source === 'feeds' &&
      fetchFeeds({
        feed: { league_id: league ? [league] : [] },
        fromDate: queryDate,
        toDate: queryDate,
      })) ||
    (source === 'productions' &&
      fetchProductions({
        production: { league_id: league ? [league] : [] },
        fromDate: queryDate,
        toDate: queryDate,
      }));

  const response: any = await promise;
  const records = response[source];
  const filteredRecords = records.filter((record: any) => !record.isDeleted);
  const mapper = RecordMappers[source];
  const items = filteredRecords.map(mapper);
  setItems(items);
}
