import { UploadFile } from '@mui/icons-material';
import { Fab, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { UploadModal } from '../../container/Calendar/Upload/UploadModal';

export function UploadAction() {
  const [open, setOpen] = useState(false);

  return (
    <Stack>
      <Tooltip title="Upload events from Excel">
        <Fab
          id="upload-file"
          color="primary"
          aria-label="upload file"
          onClick={() => setOpen(!open)}
        >
          <UploadFile />
        </Fab>
      </Tooltip>
      <UploadModal open={open} setOpen={setOpen} />
    </Stack>
  );
}
