import { getIdFromKey, keyHasType } from '../../../models/records';
import { deltaToPayload } from '../../api/productions';
import api from '../../../services/APIService';
import { DataType } from '../../reducers/dataSlice';
import { save } from '../../api/dataProcesser';
import { BatchCall } from '../../api/common';

const createPostRequest = (creates: any, subItems?: any): BatchCall => {
  const productions = Object.values(creates).filter(
    (create: any) => create.type === 'production',
  );
  const payload = productions.map((production: any) => {
    const items = [];
    if (subItems) {
      const subs: any = Object.values(subItems);
      for (const sub in subs) {
        const productions: any = subs[sub]?.data?.productions;
        items.push(...productions);
      }
    }
    return deltaToPayload(production.production_id, production, items);
  });

  return {
    payloads: payload,
    apiCall: (payload: any) => api.post('/productions', payload),
    getId: (item: any) => item?.production_id,
  };
};

const createPutRequest = (
  changes: any,
  items: any,
  subItems?: any,
): BatchCall => {
  const payload = Object.keys(changes)
    .filter(key => keyHasType(key, 'production'))
    .filter(key => !changes[key].isDeleted)
    .map(key => {
      const id = getIdFromKey('production', key);
      if (subItems) {
        items = [];
        const subs: any = Object.values(subItems);
        for (const sub in subs) {
          const productions: any = subs[sub]?.data?.productions;
          items.push(...productions);
        }
      }
      return deltaToPayload(id, changes[key], items);
    })
    .filter(Boolean);

  return {
    payloads: payload,
    apiCall: ({ _id, ...payload }: any) =>
      api.put(`/productions/${_id}`, payload),
    getId: (item: any) => item.production_id,
  };
};

const createDeleteRequest = (changes: any): BatchCall => {
  const payload = Object.keys(changes)
    .filter(key => keyHasType(key, 'production'))
    .filter(key => !!changes[key].deleted_time)
    .map(key => getIdFromKey('production', key));

  return {
    payloads: payload,
    apiCall: (id: number) => api.delete(`/productions/${id}`),
    getId: (item: any) => item.production_id,
  };
};

export interface SaveActionProps {
  creates: any;
  changes: any;
  items?: any;
  dispatch: any;
  subItems?: any;
}

export const saveAction = async ({
  creates,
  changes,
  items,
  dispatch,
  subItems,
}: SaveActionProps) => {
  const postReq = createPostRequest(creates, subItems);
  const putReq = createPutRequest(changes, items, subItems);
  const delReq = createDeleteRequest(changes);
  const commits: any = await save({
    postReq,
    putReq,
    delReq,
    type: DataType.Production,
    dispatch,
  });

  return commits;
};
