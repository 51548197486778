import { useEffect, useRef } from 'react';
import { filterToQuery } from '../../../models/audits';
import api from '../../../services/AuditService';
import { InfiniteTable } from '../../components/InfiniteTable/InfiniteTable';
import { actions, getSlice } from '../../reducers/auditSlice';
import { DataType } from '../../reducers/dataSlice';
import { useAppSelector } from '../../reducers/hooks';
import { useSubRow } from './AuditSubTable';
import { useHistoryColumnSetting } from './HistoryColumnSetting';
import { getRowSize, useToggleRow } from './hooks';
import { auditRowStyle, expandRowStyle } from './style';

export function AuditTable() {
  const slice = useAppSelector(getSlice);
  const ref: any = useRef();

  const columnSetting: any = useHistoryColumnSetting();

  useEffect(() => {
    ref.current?.resetAfterIndex(0);
  }, [slice.subItems]);

  const renderSubRow = useSubRow;

  return (
    <InfiniteTable
      api={api}
      minWidth="1000px"
      columns={columnSetting}
      sub
      subTable={(item: any) => renderSubRow(item)}
      disableSubRow={(item: any) => {
        if (item?.entityChange) {
          return false;
        }
        return true;
      }}
      expandRowStyle={expandRowStyle}
      toggleRow={useToggleRow}
      slice={slice}
      actions={actions}
      readOnly
      type={DataType.Audit}
      rowStyle={auditRowStyle}
      passRef={ref}
      getRowHeight={(index: number, rows: any, creates: any, subItems: any) => {
        return getRowSize({ index, rows, subItems });
      }}
      filterToQuery={filterToQuery}
    />
  );
}
