import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { AssocItem } from './AssocItem';

interface AllAssociationsProps {
  editAssoc: any;
  assocItems: any;
  onToggle?: any;
}

export function AllAssociations({
  editAssoc,
  assocItems,
  onToggle,
}: AllAssociationsProps) {
  const linkedItems = Object.entries(editAssoc).flatMap(
    ([type, ids]) =>
      Array.isArray(ids) &&
      (ids as any)?.map((id: any) => assocItems[type][id]),
  );
  const loadedItems = linkedItems.filter(Boolean);

  return (
    <Stack sx={{ maxHeight: '200px', overflowY: 'auto' }}>
      {loadedItems.map(item => (
        <AssocItem
          key={item.id}
          item={item}
          onToggle={onToggle}
          showType
          isLinked
        />
      ))}
      {linkedItems.length === 0 && (
        <Box sx={{ padding: 1 }}>No associated items</Box>
      )}
      {loadedItems.length !== linkedItems.length && (
        <Box sx={{ padding: 1 }}>Some associated items could not be loaded</Box>
      )}
    </Stack>
  );
}
