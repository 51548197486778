import { useRef } from 'react';
import api from '../../../../services/APIService';
import { authService } from '../../../../services/AuthService';
import {
  actions,
  getSlice,
  refreshItem,
  resetItemAction,
} from '../../../reducers/eventsSlice';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';

import Roles from '../../../../models/Roles';
import { retrofitEvent } from '../../../../models/events';
import { filterToQueryParams } from '../../../../services/common';
import { InfiniteTable } from '../../../components/InfiniteTable/InfiniteTable';
import { useLiveUpdatesOnFilters } from '../../../components/hooks/websocketHook';
import { DataType } from '../../../reducers/dataSlice';
import { useEventColumnSettings } from './EventColumnSettings';
import { eventRowStyle } from './style';

export function EventsTable() {
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.EVENT_EDITOR]);
  const dataState = useAppSelector(getSlice);
  const columnsSettings = useEventColumnSettings(DataType.Event);
  const ref: any = useRef();
  const dispatch = useAppDispatch();

  useLiveUpdatesOnFilters(DataType.Event, {
    onOnline: (online: boolean) => {
      console.log(`ready for getting updates: ${online}`);
    },
    onDataUpdate: (data: any[]) => {
      //dispatch data update
      data.forEach(item => {
        const event = retrofitEvent(item);
        dispatch(refreshItem(event));
        setTimeout(() => {
          dispatch(resetItemAction(event));
        }, 3000);
      });
    },
  });

  return (
    <InfiniteTable
      api={api}
      minWidth="1600px"
      columns={columnsSettings}
      sub={false}
      slice={dataState}
      actions={actions}
      readOnly={readOnly}
      type={DataType.Event}
      rowStyle={eventRowStyle}
      passRef={ref}
      filterToQuery={filterToQueryParams}
    />
  );
}
