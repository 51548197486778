export const leaguePageBox = {
  width: 1,
  height: 1,
  display: 'flex',
  flexDirection: 'column',
};

export const leagePageHeader = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '20px',
  gap: 50,
};
