import { useContext } from 'react';
import { get } from 'lodash';
import { CellProps } from '..';
import { BaseItem } from '../../../reducers/dataSlice';
import { ToggleButtons } from '../../ToggleButton/ToggleButton';
import { TableContext } from '../InfiniteTable';
import { getKeyFromId } from '../../../../models/records';

export function ToggleCell<T extends BaseItem>({
  column,
  item,
  editable,
}: CellProps<T>) {
  const data = get(item, column.key);
  const toggleOptions: any = column.onGettingOptions
    ? column.onGettingOptions(item)
    : [];
  const { update, changes, errors, refreshed } = useContext(TableContext);
  const key = getKeyFromId(item.type, item._id);
  return (
    <ToggleButtons
      property={column.key}
      data={data}
      delta={changes[key] || []}
      errors={errors[key] || []}
      refreshed={refreshed[key] || []}
      style={column.style}
      staticValues={toggleOptions}
      toggleStyleActive={column.toggleStyleActive}
      update={update}
      type={item.type}
      rowId={item._id}
      readOnly={!editable}
    />
  );
}
