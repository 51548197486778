import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface LeagueOptionProps {
  disabled: boolean;
  label: string;
  id: string;
  value: any;
  options: any;
  onChange: any;
  error: string;
}

export function LeagueOptions({
  disabled,
  label,
  id,
  value,
  options,
  onChange,
  error,
}: LeagueOptionProps) {
  return (
    <FormControl
      variant="standard"
      sx={{ m: 1, minWidth: 200 }}
      error={!!error}
      disabled={disabled}
    >
      <InputLabel id={id}>{label}</InputLabel>
      {options.length > 0 && (
        <Select
          value={value}
          onChange={e => onChange(e.target.value)}
          id={id}
          name={id}
        >
          {options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}
