import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import {
  buildFilterFromCriteria,
  filterToCriteria,
} from '../../../../../models/filters';
import { TypeFilters } from '../../../../components/Filters';
import { FilterCriteria } from '../../../../components/Filters/FilterCriteria';
import { DataType } from '../../../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { updateNotifications } from '../../../../reducers/notificationSlice';
import {
  formControlLabel,
  notificationCriteriaBox,
  whenBox,
  whenBoxLabel,
} from '../style';

export function EventCriteria() {
  const dispatch = useAppDispatch();
  const { changes, errors } = useAppSelector(state => state.notifications);

  const allowFields = TypeFilters[DataType.Event].concat(TypeFilters['global']);

  const criteria =
    changes?.filter && Object.keys(changes?.filter).length !== 0
      ? filterToCriteria(changes?.filter, allowFields)
      : [];
  const [filter, setFilter] = useState(criteria);

  const handleChange = (newChange: any) => {
    dispatch(updateNotifications(newChange));
  };

  return (
    <Stack sx={whenBox}>
      <Box sx={whenBoxLabel}>
        <FormLabel>When do I want to be notified?</FormLabel>
        {errors?.filter && <Typography color="red">{errors.filter}</Typography>}
      </Box>
      <RadioGroup>
        <FormControlLabel
          id="all-events"
          value="all-events"
          control={<Radio id="all-events" />}
          label="All Events"
          checked={changes.all}
          onChange={() => {
            handleChange({
              productionLeader: false,
              productionCreator: false,
              filter: {},
              all: true,
            });
          }}
        />
        <FormControlLabel
          id="matches-criteria"
          value={DataType.Event}
          control={<Radio id="matches-criteria" />}
          label="Events that matches following criterias:"
          checked={!changes.all}
          onChange={() => {
            handleChange({ all: false });
          }}
        />
        <Stack sx={notificationCriteriaBox}>
          <FormControlLabel
            id="production-leader"
            label="Has associated a production where I am production leader"
            checked={changes.productionLeader}
            control={<Checkbox id="production-leader" />}
            onChange={(e: any) => {
              handleChange({
                productionLeader: e.target.checked,
              });
            }}
            disabled={changes.all}
            sx={formControlLabel}
          />

          <FormControlLabel
            id="production-creator"
            label="Has associated a production where I am production creator"
            checked={changes.productionCreator}
            control={<Checkbox id="production-creator" />}
            onChange={(e: any) => {
              handleChange({
                productionCreator: e.target.checked,
              });
            }}
            disabled={changes.all}
            sx={formControlLabel}
          />
          <FormControlLabel
            id="select-filters"
            label="Select filters"
            checked={Object.keys(changes?.filter).length > 0}
            control={<Checkbox id="select-filters" />}
            onChange={(e: any) => {
              handleChange({
                all: false,
                filter:
                  e.target.checked === false
                    ? {}
                    : buildFilterFromCriteria(criteria, allowFields),
              });
            }}
            disabled={changes.all}
            sx={formControlLabel}
          />
          {changes?.filter && Object.keys(changes?.filter).length > 0 && (
            <FilterCriteria
              criteria={filter}
              allowFields={allowFields}
              presetType="event"
              onChange={criterias => {
                setFilter(criterias);
                handleChange({
                  filter:
                    criterias.length > 0
                      ? buildFilterFromCriteria(criterias, allowFields)
                      : {},
                });
              }}
            />
          )}
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
