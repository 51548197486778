import { Add, ArrowDownward, Groups, Person } from '@mui/icons-material';
import {
  Box,
  Card,
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from '@mui/material';
import Roles from '../../../../models/Roles';
import { authService } from '../../../../services/AuthService';
import { DataType } from '../../../reducers/dataSlice';
import { Target } from './NotificationSetting';
import { groupCard, notificationTypeLabel } from './style';

interface NotificationCardProps {
  label: string;
  value: string;
  onAddNotification: (x: any) => any;
  notificationTargets: Target[];
  children: any;
  type: DataType;
}

export default function NotificationCard({
  label,
  value,
  onAddNotification,
  notificationTargets,
  children,
  type,
}: NotificationCardProps) {
  const actions = [
    { icon: <Person />, name: 'User' },
    { icon: <Groups />, name: 'Group' },
  ];
  const isAdmin = authService.hasRole([Roles.USER_ADMIN, Roles.ADMIN]);
  const selectedActions: any = [];
  notificationTargets.forEach((target: Target) => {
    const action = actions.find((action: any) => action.name === target);
    if (action) {
      selectedActions.push(action);
    }
  });

  return (
    <Card key={value} sx={groupCard}>
      <Box
        sx={{
          display: 'flex',
          '& button': {},
        }}
      >
        <Typography sx={notificationTypeLabel}>
          {label?.toUpperCase()}
        </Typography>
        {isAdmin && (
          <Box sx={{ transform: 'translateZ(0px)' }}>
            <SpeedDial
              id={`add-${type}`}
              ariaLabel={`add-${type}`}
              icon={
                <SpeedDialIcon
                  id={`add-${type}`}
                  openIcon={<ArrowDownward />}
                />
              }
              direction="down"
              sx={{
                position: 'absolute',
                right: 5,
                '&.MuiSpeedDial-root': {
                  '.MuiSpeedDial-fab': {
                    width: '36px',
                    height: '36px',
                  },
                },
              }}
            >
              {selectedActions &&
                selectedActions.map((action: any) => (
                  <SpeedDialAction
                    aria-label={`add-${action.name}`}
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => onAddNotification(action.name)}
                  />
                ))}
            </SpeedDial>
          </Box>
        )}
        {!isAdmin && (
          <Fab
            size="small"
            id={`add-${type}`}
            aria-label="add"
            color="primary"
            onClick={() => onAddNotification(Target.user)}
          >
            <Add />
          </Fab>
        )}
      </Box>
      <Box sx={{ height: 250, maxHeight: 250, overflow: 'scroll' }}>
        {children}
      </Box>
    </Card>
  );
}
