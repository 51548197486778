import { isEqual } from 'lodash';
import { retrofitEvents } from '../../models/events';
import { isTempId } from '../../models/records';
import { filterToQueryParams } from '../../services/common';

import { extractDate, extractTime } from '../../models/dates';
import { retrofitFeeds } from '../../models/feeds';
import { retrofitProductions } from '../../models/productions';
import api from '../../services/APIService';
import { DataType } from '../reducers/dataSlice';

export async function fetchEvents(filter: any, offset?: any) {
  try {
    let pending: any = new AbortController();
    const query = filterToQueryParams(filter, DataType.Event, offset);

    const { data, moreData } = await api.get(`/events?${query}`, pending);

    pending = null;

    return {
      events: retrofitEvents(data),
      isFinished: !moreData,
    };
  } catch (e) {
    console.warn(e);
    return { events: [], isFinished: true };
  }
}

/* Handles the uploading of events */
export async function uploadEvents(data: UploadModel) {
  const results = await uploadAllEvents(data);
  const allResults = [];
  for (const index in results) {
    if (results[index].status === 'Success') {
      allResults.push({
        filename: results[index].filename,
        status: results[index].status,
        imported: results[index].imported,
        total: results[index].total,
      });
    } else {
      allResults.push({
        filename: results[index].filename,
        status: results[index].response.data.status,
        errors: [...results[index].response.data.errors],
      });
    }
  }
  return { isLoading: false, loadedFiles: [...allResults] };
}

export interface UploadModel {
  league_id: number;
  files: any[];
}

export async function fetchEventWithAssociations(id: any) {
  const eventData = await api.get(`/events/${id}/expand`);

  return {
    ...eventData,
    productions: retrofitProductions(eventData?.productions),
    feeds: retrofitFeeds(eventData?.feeds),
  };
}

/* Function to handle the payload before the request is sent to the service */
export function deltaToPayload(id: number, delta: any, items?: any) {
  if (Object.keys(delta).length === 1 && !!delta.deleted_time) return null;

  let payload = { _id: id, ...delta };

  if (isTempId(payload.id)) delete payload.id;

  if (payload.type) delete payload.type;

  if (payload.start_timestamp) {
    payload = {
      ...payload,
      event_date: extractDate(payload.start_timestamp),
      event_time: extractTime(payload.start_timestamp),
    };
    delete payload.start_timestamp;
  }

  if (payload.itemAction) delete payload.itemAction;

  if (Object.keys(payload.comments || {}).length === 0) delete payload.comments;

  if (payload.productions) {
    for (const production of payload.productions) {
      if (isTempId(production.production_id)) delete production.production_id;
      if (!production.id) delete production.id;
    }
  }

  if (payload.feeds) {
    for (const feed of payload.feeds) {
      if (isTempId(feed.feed_id)) delete feed.feed_id;
      if (isTempId(feed._id)) delete feed._id;
      if (!feed.id) delete feed.id;
    }
  }

  if (payload.rights && payload.rights.length > 0) {
    const item = items && items.find((item: any) => item.id === id);
    if (item) {
      const itemRights = item.rights || [];
      if (itemRights.length > 0) {
        const changedRights: any = [];
        itemRights.forEach((right: any) => {
          const countryRight = payload.rights.find(
            (payloadRight: any) => payloadRight.country === right.country,
          );
          if (!isEqual(countryRight, right)) {
            changedRights.push(countryRight);
          }
        });
        if (changedRights.length > 0) {
          payload = {
            ...payload,
            rights: changedRights,
          };
        }
      }
    }
  }

  if (payload.has_eng_audio) {
    payload = {
      ...payload,
      audios: [
        { name: 'English', language: 'ENG', available: payload.has_eng_audio },
      ],
    };
  }
  return payload;
}

async function uploadAllEvents(data: any) {
  const url = `/events/upload/league/${data.league_id}`;
  const headers = {
    'content-type': 'multipart/form-data',
  };

  const allResults: any = [];
  await Promise.all(
    data.files.map(async (file: any) => {
      const formData = new FormData();
      formData.append('filename', file.name);
      formData.append('file', file.content);
      const { data } = await api.post(url, formData, headers);
      allResults.push({
        filename: file.name,
        ...data,
      });
    }),
  );
  return allResults;
}
