import { orderBy } from 'lodash';
import { v4 as uuid } from 'uuid';

/**
 *
 * @param newFilter new filter to add in storage
 */
export const populateFilterStorage = (newFilter: any) => {
  //Get Stored Filter
  const savedFiltersString = localStorage.getItem('filter');

  // Get session Id
  const sessionId = getSessionId();

  // add id to filter
  newFilter = {
    ...newFilter,
    sessionId,
  };

  // List to add filters
  let updatedFilters = [];
  if (savedFiltersString) {
    const savedFilterList: any = JSON.parse(savedFiltersString);
    if (savedFilterList && Array.isArray(savedFilterList)) {
      const index: number = savedFilterList.findIndex(
        (filter: any) => filter.sessionId === sessionId,
      );
      if (index > -1) {
        savedFilterList[index] = { ...savedFilterList[index], ...newFilter };
        updatedFilters = [...savedFilterList];
      } else {
        const copyFirstFilter = getCopy(savedFilterList);
        savedFilterList.push({
          ...copyFirstFilter,
          ...newFilter,
        });
        updatedFilters = [...savedFilterList];
      }
    } else {
      localStorage.removeItem('filter');
    }
  } else {
    updatedFilters.push({
      ...newFilter,
      date: new Date(),
    });
  }

  if (updatedFilters.length > 0) {
    localStorage.setItem('filter', JSON.stringify(updatedFilters));
  }
};

export const getFilterStorage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryId = urlParams.has('id') ? urlParams.get('id') : '';
  const sessionId: any = urlParams.has('sessionId')
    ? urlParams.get('sessionId')
    : '';
  const savedFilters = localStorage.getItem('filter');
  const id = getSessionId(sessionId);
  let storedFilter = null;

  if (savedFilters) {
    const storedFilters: any[] = JSON.parse(savedFilters);
    if (storedFilters && Array.isArray(storedFilters)) {
      const foundFilter = storedFilters.find(
        (storedFilter: any) => storedFilter.sessionId === id,
      );
      if (foundFilter && !sessionId) {
        storedFilter = { ...foundFilter };
      } else if (foundFilter && sessionId) {
        storedFilter = {
          ...foundFilter,
        };
        populateFilterStorage(storedFilter);
      } else {
        const parentCopy = getCopy(storedFilters);
        populateFilterStorage({ ...parentCopy });
        storedFilter = { parentCopy };
      }
    } else {
      localStorage.removeItem('filter');
    }
  }

  return { ...storedFilter, queryId, sessionId };
};

export const getSessionId = (sessionId?: string) => {
  const id = sessionId || sessionStorage.getItem('sessionId');
  if (!id) {
    const sessionId = uuid();
    sessionStorage.setItem('sessionId', sessionId);
    return sessionId;
  }
  return id;
};

const isToday = (storedDate: any) => {
  const today = new Date();
  const date = new Date(storedDate);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const hasStoredFilters = () => {
  const localStoredFilters: any = localStorage.getItem('filter');
  if (localStoredFilters) {
    const storedFilters: any = JSON.parse(localStoredFilters);
    if (Array.isArray(storedFilters)) {
      const currentFilters = storedFilters.filter((storedFilter: any) =>
        isToday(storedFilter.date),
      );
      if (currentFilters.length > 0) {
        localStorage.setItem('filter', JSON.stringify(currentFilters));
        return true;
      } else {
        localStorage.removeItem('filter');
      }
    } else {
      localStorage.removeItem('filter');
    }
  }
  return false;
};

export const getCopy = (storedFilters: any[]) => {
  const sortedList = orderBy(
    storedFilters,
    [(filter: any) => new Date(filter.date)],
    ['desc'],
  );
  const parentCopy = sortedList[0];
  return parentCopy;
};
