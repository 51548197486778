import { Dispatch, nanoid } from '@reduxjs/toolkit';
import Roles from '../../models/Roles';
import { hasStoredFilters, populateFilterStorage } from '../../models/storage';
import { authService } from '../../services/AuthService';
import api from '../../services/UserService';
import { NotificationBase } from '../container/Account/Notification/NotificationSetting';
import { addNetworkError, addToast } from '../reducers/appSlice';
import { DataType } from '../reducers/dataSlice';
import { addSavedFilters, setFilter } from '../reducers/filterSlice';
import {
  NotificationGroup,
  addNotifications,
  addUserGroups,
  updateNotifications,
} from '../reducers/notificationSlice';
import { addColumnSettings } from '../reducers/settingSlice';
import { AppDispatch } from '../reducers/store';
import { addUserInfo, clear, updateAccount } from '../reducers/userSlice';

const USER: string = '/users/profile/current';
const CURRENT: string = '/users/current';
const GROUPS: string = '/groups/';
const FILTER_URL = '/filters';
const FILTER_URL_CURRENT = '/filters/current';
const NOTIFICATION_URL = '/notifications/';
const SETTINGS_URL = '/users/settings/current';

export enum FilterAction {
  GET = 'get',
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}

interface UpdateData {
  action: FilterAction;
  dispatch: Dispatch<any>;
  filters?: any;
  id?: number;
}

export async function getAccountInfo(dispatch: any) {
  try {
    const profile: any = await api.get(USER);
    if (profile) {
      dispatch(addUserInfo(profile));
      dispatch(addSavedFilters(profile.filters));
      dispatch(addNotifications(profile.notifications));
      const isAdmin = authService.hasRole([Roles.ADMIN, Roles.USER_ADMIN]);
      if (isAdmin) {
        const userGroups = await getWorkingGroups();
        dispatch(addUserGroups(userGroups));
      }
      if (profile?.settings && profile.settings?.tableSettings) {
        dispatch(
          addColumnSettings({
            tableSettings: profile.settings.tableSettings,
          }),
        );
      }

      if (profile?.filters) {
        dispatch(
          setFilter({
            savedFilters: profile.filters,
          }),
        );
      }

      if (profile?.account?.countries && !hasStoredFilters()) {
        const filter = {
          customFilter: {
            countries: [...profile.account.countries],
          },
          filterPresets: [],
        };
        dispatch(setFilter(filter));
        populateFilterStorage(filter);
      }
    } else {
      dispatch(addNetworkError());
    }
  } catch (e) {
    dispatch(addNetworkError());
  }
}

export async function saveChanges(changes: any, dispatch: any) {
  const { account, country_code } = changes;
  let newProfile = {};
  if (Object.keys(account).length > 0) {
    for (const field of Object.keys(account)) {
      newProfile = {
        ...newProfile,
        [account[field].propertyName]: account[field].value,
      };
    }
  }
  if (country_code) {
    newProfile = {
      ...newProfile,
      country_code,
    };
  }
  newProfile = {
    ...newProfile,
  };
  try {
    const profile = await api.put(CURRENT, newProfile);
    if (profile) {
      dispatch(clear());
      dispatch(updateAccount(profile));
      dispatch(
        addToast({
          id: 'pofile-saved',
          message: 'Profile Saved',
          severity: 'success',
        }),
      );
    } else {
      dispatch(addNetworkError());
    }
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
  }
}

export async function save({ action, dispatch, filters, id }: UpdateData) {
  try {
    let result: any = {};
    switch (action) {
      case FilterAction.CREATE: {
        const { data } = await api.post(FILTER_URL_CURRENT, filters);
        result = { ...data };
        break;
      }
      case FilterAction.UPDATE:
        result = await api.put(FILTER_URL_CURRENT, filters);
        break;
      case FilterAction.DELETE:
        result = await api.delete(`${FILTER_URL}/${id}`);
        break;
      default:
        break;
    }
    dispatch(addSavedFilters(result));
    dispatch(
      setFilter({
        savedFilters: result,
      }),
    );
    if (action !== FilterAction.GET) {
      dispatch(
        addToast({
          id: 'filter-save',
          message: `Filter ${action}d`,
          severity: 'success',
        }),
      );
    }
    return result;
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
  }
}

async function getNotifications(
  dispatch: (arg0: { payload: any; type: string }) => void,
) {
  try {
    const result = await api.get(`${NOTIFICATION_URL}current`);
    dispatch(addNotifications(result));
    return result;
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
  }
}

export async function getNotification(
  id: any,
  type: DataType,
  dispatch: AppDispatch,
  groups: NotificationGroup[],
  mapToNotification: (
    result: any,

    groups: NotificationGroup[],
  ) => NotificationBase,
): Promise<boolean> {
  try {
    const successful = true;
    const result = await api.get(`${NOTIFICATION_URL}${id}?type=${type}`);

    dispatch(updateNotifications(mapToNotification(result[type], groups)));
    return successful;
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
    return false;
  }
}

export async function createNotification(
  id: any,
  notification: { [x: number]: any },
  dispatch: any,
) {
  try {
    const messageType = id ? 'changed' : 'created';
    const { data } = id
      ? await api.put(`${NOTIFICATION_URL}${id}`, notification)
      : await await api.post(NOTIFICATION_URL, notification);
    dispatch(
      addToast({
        id: `${messageType}-notification`,
        message: `Notification ${messageType}!`,
        severity: 'success',
      }),
    );
    getNotifications(dispatch);
    return data;
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
  }
}

export async function toggleNotification(
  id: any,
  notification: any,
  dispatch: any,
) {
  try {
    const result = await api.put(
      `${NOTIFICATION_URL}${id}/toggle`,
      notification,
    );

    dispatch(
      addToast({
        id: `update-notification-${id}-${nanoid()}`,
        message: 'Notification updated!',
        severity: 'success',
      }),
    );
    getNotifications(dispatch);
    return result;
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
  }
}

export async function deleteNotification(id: any, type: any, dispatch: any) {
  try {
    const result = await api.delete(`${NOTIFICATION_URL}${id}?type=${type}`);
    dispatch(
      addToast({
        id: `'delete-notification-${id}'`,
        message: 'Notification deleted!',
        severity: 'success',
      }),
    );
    getNotifications(dispatch);
    return result;
  } catch (e) {
    console.log(e);
    dispatch(addNetworkError());
  }
}

export async function updateSettings(settings: any) {
  const payload: any = { tableSettings: settings };
  await api.put(SETTINGS_URL, payload);
}

export async function getAllUserGroups() {
  const response = await api.get(GROUPS);
  return response;
}

export async function getWorkingGroups() {
  const response = await api.get(`${GROUPS}?type=workgroups`);
  return response;
}
