const BASIC_ROW_STYLE = {
  height: '35px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'stretch',
  borderBottom: '1px solid #eee',
  backgroundColor: '#fff',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#EBEEF0',
  },
  '> *': {
    boxSizing: 'border-box',
    flexGrow: 0,
    flexShrink: 0,
    height: '35px',

    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',

    borderRight: '1px solid #eee',
    fontSize: '0.70rem',

    '> .edit-cell, > input, > select': {
      width: '100%',
      fontSize: '0.70rem',
    },

    '> .edit-cell': {
      border: '0',
      padding: '0 3px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      '&.-focus, &:focus': {
        backgroundColor: '#cef',
        outline: '0',
      },
    },

    '> .-value': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '35px',

      padding: '0 3px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      '&.-left': {
        justifyContent: 'flex-start',
      },
      '&.-right': {
        justifyContent: 'flex-end',
      },
    },

    '&.-changed': {
      backgroundColor: '#ff8',
    },

    '&.-error': {
      backgroundColor: '#fcc !important',
    },

    '._badge': {
      padding: '2px 4px',
      border: '0',
      borderRadius: '4px',
      color: '#fff',
      fontWeight: 600,
    },

    'button._badge': {
      cursor: 'pointer',
    },
  },

  // Row-level styles

  '&.-new': {
    backgroundColor: '#cfc',
  },

  '&.-deleted': {
    color: '#f88',
  },
  '&.-disabled': {
    color: '#ABB2B9',
  },
  '.-strikethrough': {
    textDecoration: 'line-through',
    color: 'darkgrey',
  },

  '.-strikethrough .edit-cell': {
    color: 'darkgrey',
  },

  '&.-blinking-row': {
    animation: 'blink-animation 1s linear infinite',
  },

  '.blinking': {
    // backgroundColor: '#0000B9',
    animation: 'blink-animation 1s linear infinite',
  },
  '&.-header': {
    paddingRight: '12px',
    backgroundColor: '#eee',
    fontWeight: '600',
  },

  '&.-header > *': {
    padding: '8px',
    borderRightColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.70rem',
    textAlign: 'center !important',
    textTransform: 'capitalize !important',
  },

  '&.-subheader': {
    backgroundColor: '#ece7ec',
    fontWeight: '600',
  },
  '&.-subheader > *': {
    padding: '8px',
    borderRightColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.70rem',
    textAlign: 'center !important',
    textTransform: 'capitalize !important',
  },
};

export const BASIC_COLUMN_STYLE = {
  expand: {
    width: 24,
    border: '0px solid',
  },
};

export function createTableStyle(columnStyles: any) {
  const columns: any = {};
  const columnCss: any = { ...BASIC_COLUMN_STYLE };
  for (const colName in columnStyles) {
    const className = `_col_${colName}`;
    columns[colName] = className;

    const selector = `> .${className}`;
    const style = columnStyles[colName];

    // If the columnstyle only consists of a number then it defines the width
    if (typeof style === 'number') {
      columnCss[selector] = { width: `${style}px` };
    } else {
      columnCss[selector] = style;
    }
  }

  // Row Style consists of the common basic row styles and specific column styles
  const rowStyle = {
    ...BASIC_ROW_STYLE,
    ...columnCss,
  };

  return { rowStyle, columns };
}
