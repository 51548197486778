import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Badge from '@mui/material/Badge';
import { SettingsMenu } from './SettingsMenu';

const Settings = () => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        size="large"
        aria-label="show 4 new mails"
        aria-controls={open ? 'settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color="inherit"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <Badge badgeContent={0} color="error">
          <SettingsIcon />
        </Badge>
      </IconButton>
      <SettingsMenu anchor={anchorEl} open={open} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default Settings;
