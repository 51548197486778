import { createDataSlice } from './dataSlice';

const auditSlice = createDataSlice({
  sliceName: 'audit',
});

export const { reducer } = auditSlice; // generic name export of slice
export const {
  setRequestedTo,
  addItems,
  setFinished,
  clearList,
  setLoading,
  applyFilter,
  opensubItems,
  closeSubItems,
  setSubItems,
} = auditSlice.actions; //export all the action that can be usedby the slice

export const actions = auditSlice.actions;

export const getSlice = (state: any) => state.audit; // generic name export of slice
