import { DataType, ErrorMessage, ItemAction } from '../app/reducers/dataSlice';
import { nowToLocalJSDate, utcTimestampToLocalizedJSDate } from './dates';

const NEW_PREFIX = 'NEW';
const COPY_PREFIX = 'COPY';

let serial = 1;

export function createNewId() {
  return -serial++;
}

export function createCopyIdNew() {
  return -serial++;
}

export function createCopyId(id: string) {
  if (id.startsWith(COPY_PREFIX)) {
    const originalId = id.split('_')[1];
    return `${COPY_PREFIX}_${originalId}_${serial++}`;
  }

  return `${COPY_PREFIX}_${id}_${serial++}`;
}

export function isNewId(id: number) {
  return id < 0;
}

export function isCopyId(id: number) {
  return id < 0;
}

export function isCopyOf(id: number, originalId: any) {
  return isCopyId(id) && originalId;
}

export function isTempId(id: number) {
  return id < 0;
}

export function getKeyFromId(type: DataType | boolean, id: number) {
  return `${type}:${id}`;
}

export function getIdFromKey(type: string | any[], key: string): number {
  const x = key.substring(type.length + 1);
  const id: number = +x;
  return id;
}

export function keyHasType(key: string, type: string) {
  return key.startsWith(`${type}:`);
}

export function mergeListAndCreates(list: any, creates: any, type: string) {
  let dataType = type;
  if (type === DataType.Workspace) {
    dataType = DataType.Event;
  }
  const byId = Object.fromEntries(
    creates.map((item: { _id: any }) => [item._id, item]),
  );

  const newIds = Object.values(creates)
    .filter(
      (create: any) =>
        create.itemAction === ItemAction.NEW && create.type === dataType,
    )
    .map((newId: any) => newId._id);

  const nonCopies = [...newIds.map(id => byId[id]), ...list];
  const result = [];
  for (const item of nonCopies) {
    result.push(item);

    const copyIds = Object.values(creates)
      .filter((create: any) => create.itemAction === ItemAction.COPY)
      .filter((create: any) => create.copy_id === item._id)
      .map((newId: any) => newId._id);
    for (const copyId of copyIds) {
      result.push(byId[copyId]);
    }
  }

  return result;
}

/**
 * Validate if property is present in the object and not empty.
 * Return property name if empty, false otherwise
 *
 * @param obj
 * @param property
 * @returns property name if empty, false if the property is valid.
 */
export function validateIfEmpty(
  obj: { [x: string]: any },
  property: string,
): ErrorMessage | Boolean {
  if (property in obj && !obj[property]) {
    return { field: property, message: 'empty' };
  }
  return false;
}

export function validateTime(
  obj: { [x: string]: any },
  property: string,
): ErrorMessage | Boolean {
  if (property in obj && obj[property].includes('Invalid')) {
    return { field: property, message: 'invalid time' };
  }
  return false;
}

/**
 * Validate dates are valid and in range, start < end date
 * @param obj
 * @param startFieldName
 * @param endFieldName
 * @returns {boolean|*}
 */
export const validateDateRange = (
  obj: { [x: string]: any },
  startFieldName: string,
  endFieldName: string,
) => {
  if (validateIfEmpty(obj, startFieldName)) {
    return {
      field: startFieldName,
      message: 'start time is empty',
    };
  }
  if (validateIfEmpty(obj, endFieldName)) {
    return { field: endFieldName, message: 'end time is empty' };
  }

  const start: Date = utcTimestampToLocalizedJSDate(obj[startFieldName]);
  const end: Date = utcTimestampToLocalizedJSDate(obj[endFieldName]);
  if (Number.isNaN(start.getTime())) {
    return { field: startFieldName, message: 'not a number' };
  }
  const today: Date = nowToLocalJSDate();
  if (start.getTime() < today.getTime()) {
    return { field: startFieldName, message: 'time has passed' };
  }

  if (Number.isNaN(end.getTime())) {
    return { field: endFieldName, message: 'not a number' };
  }
  if (start.getTime() >= end.getTime()) {
    return {
      field: endFieldName,
      message: 'start date is bigger than end date',
    };
  }

  return false;
};
