import { FormLabel, Stack, ToggleButton } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { updateNotifications } from '../../../../reducers/notificationSlice';
import { day_button } from './style';

export function FeedCriteria() {
  const dispatch = useAppDispatch();
  const { changes } = useAppSelector(state => state.notifications);
  const days = [
    { id: 'monday', label: 'M' },
    { id: 'tuesday', label: 'T' },
    { id: 'wednesday', label: 'W' },
    { id: 'thursday', label: 'T' },
    { id: 'friday', label: 'F' },
    { id: 'saturday', label: 'S' },
    { id: 'sunday', label: 'S' },
  ];

  const [selectedDays, setSelectedDays] = useState<string[]>(
    changes?.notificationDay?.daysOfWeek || [],
  );

  return (
    <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormLabel>
        If the update is made on the following days of the week:{' '}
      </FormLabel>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        {days.map(({ id, label }) => (
          <ToggleButton
            id={id}
            key={id}
            sx={day_button}
            value={id}
            selected={selectedDays.includes(id)}
            onClick={(e: any) => {
              let newDays: string[] = [];
              if (selectedDays.includes(e.target.value)) {
                newDays = selectedDays.filter(
                  (selected: string) => selected !== e.target.value,
                );
              } else {
                newDays = [...selectedDays, e.target.value];
              }
              console.log(newDays);
              setSelectedDays(newDays);
              dispatch(
                updateNotifications({
                  notificationDay: { daysOfWeek: newDays },
                }),
              );
            }}
          >
            {label}
          </ToggleButton>
        ))}
      </Stack>
    </Stack>
  );
}
