import { Grow, Paper, Popper } from '@mui/material';
import { useEffect, useRef } from 'react';

interface PopoutProps {
  anchor: any;
  open: boolean;
  placement?: any;
  transformOrigin?: string;
  children: any;
}

export function Popout({
  anchor,
  open,
  placement = 'bottom-start',
  transformOrigin = 'left top',
  children,
}: PopoutProps) {
  const inner = useRef<any>();
  useEffect(() => {
    if (!open) return;
    Promise.resolve().then(() => {
      (inner as any).current
        ?.querySelector('input,select,textarea,button')
        ?.focus();
    });
  }, [open]);

  return (
    <Popper
      anchorEl={anchor.current}
      placement={placement}
      open={open}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin }}>
          <Paper elevation={2} sx={{ marginTop: 1 }} ref={inner}>
            {children}
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
