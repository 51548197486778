import { makeStyles } from '@mui/material';
import clsx from 'clsx';

export const scrollbarHorizontallStyle = {
  '&::-webkit-scrollbar': {
    height: 10,
    position: 'relative',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 3,
  },
};

export const scrollbarVerticalStyle = {
  '&::-webkit-scrollbar': {
    width: 10,
    overflow: 'hidden',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 3,
    overflow: 'hidden',
  },
};
