import { Looks3, Looks4, LooksOne, LooksTwo, Star } from '@mui/icons-material';
import { consolidateFilters } from '../../../models/filters';
import { importance } from '../../../models/rights';
import { staticService } from '../../../services/StaticService';
import {
  EventFilters,
  FeedFilters,
  FilterPreset,
  GlobalFilters,
  PresetType,
  ProductionFilters,
  TypeFilters,
  WorkspaceFilters,
} from './index';

export function getFilterCriterias(type: PresetType) {
  return type !== 'global'
    ? TypeFilters[type].concat(TypeFilters['global'])
    : TypeFilters[type];
}

export const RestrictedFilters = [
  ...TypeFilters['event'],
  ...TypeFilters['production'],
  ...TypeFilters['feed'],
];

export function getAllowedFilters(
  filterPresets: number[],
  filters: {
    [x: string]: any;
    global?: FilterPreset<GlobalFilters>[];
    production?: FilterPreset<ProductionFilters>[];
    feed?: FilterPreset<FeedFilters>[];
    workspace?: FilterPreset<WorkspaceFilters>[];
    event?: FilterPreset<EventFilters>[];
  },
  type: PresetType,
  customFilter: { [x: string]: any[] },
) {
  const onlyCustomFilters = { ...customFilter };
  delete onlyCustomFilters['ids'];
  const allowedOptions = TypeFilters[type].concat(TypeFilters['global']);

  let allowedFilters = {};

  for (const key in onlyCustomFilters) {
    if (allowedOptions.includes(key) && onlyCustomFilters[key].length > 0) {
      allowedFilters = { ...allowedFilters, [key]: onlyCustomFilters[key] };
    }
  }

  let allowed = {};
  const types = TypeFilters['types'];
  for (const index in filterPresets) {
    if (type === 'workspace') {
      for (const i in types) {
        const a = filters[types[i]]?.find(
          (value: { id: number }) => value.id === filterPresets[index],
        );
        if (a) {
          allowed = { ...allowed, ...a.filter };
        }
      }
    } else {
      const allowedPresetsByType: any =
        filters[type]?.find(
          (value: { id: number }) => value.id === filterPresets[index],
        ) || {};
      const allGlobalFilters: any =
        filters['global']?.find(
          (value: { id: number }) => value.id === filterPresets[index],
        ) || {};
      allowed = {
        ...allowed,
        ...allowedPresetsByType.filter,
        ...allGlobalFilters.filter,
      };
    }
  }
  const ids: number[] = [];

  const idsByType = customFilter['ids']?.filter(
    (custom: { type: string }) => custom.type === type,
  );

  if (idsByType) {
    for (const index in idsByType) {
      ids.push(idsByType[index].id);
    }
    allowedFilters = { ...allowedFilters, ids };
  }

  const combinedFilter = consolidateFilters([allowed, allowedFilters]);

  return combinedFilter;
}

export const levels = [
  {
    value: 100,
    header: <Star htmlColor="#F39C12" />,
    icon: Star,
    color: '#F39C12',
  },
  {
    value: 1,
    header: <LooksOne htmlColor="#EFC311" />,
    icon: LooksOne,
    color: '#EFC311',
  },
  {
    value: 2,
    header: <LooksTwo htmlColor="#2F8A28" />,
    icon: LooksTwo,
    color: '#2F8A28',
  },
  {
    value: 3,
    header: <Looks3 htmlColor="#0D559C" />,
    icon: Looks3,
    color: '#0D559C',
  },
  {
    value: 4,
    header: <Looks4 htmlColor="#AC5DBD" />,
    icon: Looks4,
    color: '#AC5DBD',
  },
];

export const historyTypes = [
  { value: 'event', label: 'Event' },
  { value: 'feed', label: 'Feed' },
  { value: 'production', label: 'Production' },
  { value: 'league', label: 'League' },
];

interface filterOptionsType {
  [key: string]: any;
}

export const FilterOptions: filterOptionsType = {
  sport_id: {
    label: 'Sport',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.sports.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.sports.all.map(
        (sport: { id: any; name: any }) => ({
          value: sport.id,
          label: sport.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  league_id: {
    label: 'League',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.leagues.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.leagues.all.map(
        (league: { id: any; sportType: any; name: any }) => ({
          value: league.id,
          label: `${league.sportType}: ${league.name}`,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  countries: {
    label: 'Country',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.countries.byId[value].label,
    getOptions: () =>
      (staticService as any).data.countries.all.map(
        (country: { code: string; label: string }) => ({
          value: country.code,
          label: country.label,
        }),
      ),
    getIcon: (value: string) => (
      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${value === 'uk' ? 'gb' : value}.png`}
        srcSet={`https://flagcdn.com/w40/${
          value === 'uk' ? 'gb' : value
        }.png 2x`}
        alt=""
      />
    ),
  },
  delivery_type_id: {
    label: 'Delivery type',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.deliveryTypes.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.deliveryTypes.all.map(
        (item: { id: any; name: any }) => ({
          value: item.id,
          label: item.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  run_type_id: {
    label: 'Run type',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.runTypes.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.runTypes.all.map(
        (item: { id: any; name: any }) => ({
          value: item.id,
          label: item.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  channel_id: {
    label: 'Channel',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.channels.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.channels.all.map(
        (item: { id: any; name: any }) => ({
          value: item.id,
          label: item.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  stream_equipment: {
    label: 'Stream',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.streams.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.streams.all.map(
        (item: { id: any; name: any }) => ({
          value: item.id,
          label: item.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  production_leader_id: {
    label: 'Production leader',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.prodLeaders.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.prodLeaders.all.map(
        (item: { id: any; name: any }) => ({
          value: item.id,
          label: item.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  editorial: {
    label: 'Editorial',
    getValueLabel: (value: string | number) =>
      (staticService as any).data.commentators.byId[value]?.name,
    getOptions: () =>
      (staticService as any).data.commentators.all.map(
        (item: { id: any; name: any }) => ({
          value: item.id,
          label: item.name,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  level: {
    label: 'Importance',
    getValueLabel: (value: any) => `Importance: ${value}`,
    getOptions: () =>
      levels.map(level => ({
        value: level.value,
        label: level.header,
        icon: level.icon,
        color: level.color,
      })),
    getIcon: (value: number) =>
      importance.find(imp => imp.level === value)?.icon || value,
  },
  entityType: {
    label: 'Type',
    getValueLabel: (value: any) => value,
    getOptions: () => historyTypes,
    getIcon: (value: any) => <></>,
  },
  mcr: {
    label: 'MCR',
    getValueLabel: (value: string | number) =>
      `MCR: ${(staticService as any).data.countries.byId[value].label}`,
    getOptions: () =>
      (staticService as any).data.countries.all.map(
        (country: { code: string; label: string }) => ({
          value: country.code,
          label: country.label,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
  takers: {
    label: 'WSC',
    getValueLabel: (value: string | number) =>
      `Wsc: ${(staticService as any).data.countries.byId[value].label}`,
    getOptions: () =>
      (staticService as any).data.countries.all.map(
        (country: { code: string; label: string }) => ({
          value: country.code,
          label: country.label,
        }),
      ),
    getIcon: (value: any) => <></>,
  },
};
