import { validateLeague } from '../../models/leagues';
import { createDataSlice } from './dataSlice';

const leaguesSlice = createDataSlice({
  sliceName: 'league',
  validator: validateLeague,
});

export const { reducer } = leaguesSlice;
export const {
  setRequestedTo,
  addItems,
  setFinished,
  create,
  update,
  clearCreates,
  clearList,
  setDetails,
  clearDetails,
  updateDetails,
  setLoading,
  applyFilter,
} = leaguesSlice.actions;

export const actions = leaguesSlice.actions;

export const getSlice = (state: any) => state.leagues;
