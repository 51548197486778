import { Upload } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import { nanoid } from '@reduxjs/toolkit';
import React, { useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../reducers/hooks';
import { getSlice, clearUploading } from '../../../reducers/eventsSlice';
import {
  actionButton,
  alertContent,
  alertStyle,
  circularProgress,
  feedBackContent,
  paper,
  paperContent,
  statusBox,
  statusButton,
} from './style';

export function UploadProgress() {
  const dispatch = useAppDispatch();
  const { uploading } = useAppSelector(getSlice);
  const [isOpen, setOpen] = useState(false);
  const ref: any = useRef();
  const files = uploading.loadedFiles || [];
  const loading = uploading.isLoading;
  if (files.length === 0 && !loading) return null;

  const handleAction = () => {
    setOpen(false);
    dispatch(clearUploading());
  };

  const getProgress = () => {
    if (files.length === 0) return;
    const filesWithErrors = files.filter(
      (file: any) => file.status === 'Error',
    );
    const hasErrors = filesWithErrors.length > 0;

    return (
      <Alert
        ref={ref}
        severity={hasErrors ? 'error' : 'success'}
        sx={alertStyle}
      >
        <Box sx={alertContent}>
          <Box sx={statusBox}>
            <Button
              variant="contained"
              color={hasErrors ? 'error' : 'success'}
              sx={statusButton}
              onClick={() => setOpen(true)}
            >
              Uploading {hasErrors ? 'failed' : 'completed'}
            </Button>
            <Popper anchorEl={ref.current} open={isOpen} placement="bottom">
              <Paper sx={paper} elevation={2}>
                <Box sx={paperContent}>
                  {files.map(
                    (
                      { filename, status, errors, imported, total }: any,
                      index: any,
                    ) => {
                      return (
                        <Box key={filename} sx={feedBackContent}>
                          <h4>
                            {index + 1}.{filename}{' '}
                          </h4>
                          {status === 'Error' &&
                            errors?.map((error: any) => (
                              <p key={nanoid()}>{error}</p>
                            ))}
                          {status === 'Success' && (
                            <p>Rows imported: {imported}</p>
                          )}
                          {status === 'Success' && <p>Total rows: {total}</p>}
                        </Box>
                      );
                    },
                  )}
                  <Box sx={actionButton}>
                    <Button onClick={handleAction}>OK</Button>
                  </Box>
                </Box>
              </Paper>
            </Popper>
          </Box>
        </Box>
      </Alert>
    );
  };
  return (
    <Stack sx={{ position: 'fixed', left: 1000, top: 80 }}>
      {loading ? (
        <Alert
          icon={<Upload />}
          variant="outlined"
          severity="info"
          sx={alertStyle}
        >
          <Box sx={alertContent}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <CircularProgress size={30} sx={circularProgress} />
            </Box>
            <Box sx={{ display: 'flex', padding: 1 }}>
              <Button>Uploading files...</Button>
            </Box>
          </Box>
        </Alert>
      ) : (
        getProgress()
      )}
    </Stack>
  );
}
