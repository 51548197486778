import { OpenInNew } from '@mui/icons-material';
import { Button, MenuList } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router';
import { NotificationsInbox } from '../../container/Account/Notification/InAppNotification/NotificationInbox';
import { NotificationItem } from '../../reducers/inAppNotificationSlice';

interface NotificationsMenuProps {
  anchor: any;
  open: boolean;
  setAnchorEl(value: any): any;
  notifications: NotificationItem[];
}

export function NotificationsMenu({
  anchor,
  open,
  setAnchorEl,
  notifications,
}: NotificationsMenuProps) {
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const routeChange = () => {
    history.push('/notifications');
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchor}
      id="notifications-menu"
      open={open}
      onClose={handleClose}
      style={{
        maxHeight: 600,
        width: 1000,
      }}
    >
      <MenuList>
        <MenuItem style={{ justifyContent: 'center' }} onClick={routeChange}>
          <Button variant="text" color="primary" endIcon={<OpenInNew />}>
            Expand notifications
          </Button>
        </MenuItem>
        {notifications.length === 0 ? (
          <MenuItem>No Notifications</MenuItem>
        ) : (
          <NotificationsInbox
            fontSizeStatus={12}
            gridMinWidth={750}
            timestampSize={10}
            changeContentSize={11}
            statusLabelFontSize={8}
          />
        )}
      </MenuList>
    </Menu>
  );
}
