import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '../../../reducers/hooks';
import { Notification, ProtocolType } from './NotificationSetting';
import { CAPTURES } from './helper';
import {
  captureInfo,
  notificationBox,
  notificationBoxSelect,
  notificationBoxText,
  sendInfo,
} from './style';

interface NotificationBoxProps {
  data: any;
  protocol: ProtocolType;
  notification: Notification;
  handleChange(x: any): any;
  information?: string;
}

export function NotificationBox({
  data,
  protocol,
  notification,
  handleChange,
  information,
}: NotificationBoxProps) {
  const { groups } = useAppSelector(state => state.notifications);
  const onChange = (value: any) => {
    handleChange({
      notificationBoxes: {
        ...data.notificationBoxes,
        [protocol]: {
          ...data.notificationBoxes[protocol],
          ...value,
        },
      },
    });
  };

  return (
    <Box>
      <Box sx={notificationBox}>
        <Stack sx={captureInfo}>
          <Typography>Up to</Typography>
          <TextField
            id={`${protocol}-ahead-time`}
            value={data.notificationBoxes[protocol]?.ahead?.time}
            onChange={e =>
              onChange({
                ahead: {
                  ...data.notificationBoxes[protocol].ahead,
                  time: e.target.value,
                },
              })
            }
            size="small"
            sx={notificationBoxText}
          />
          <Select
            id={`${protocol}-unit`}
            value={data.notificationBoxes[protocol]?.ahead?.unit}
            onChange={e =>
              onChange({
                ahead: {
                  ...data.notificationBoxes[protocol].ahead,
                  unit: e.target.value,
                },
              })
            }
            sx={notificationBoxSelect}
          >
            {CAPTURES.map(unit => (
              <MenuItem id={`${protocol}-${unit}`} key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
          <Typography>before the {notification.type} starts.</Typography>
        </Stack>
        {protocol === ProtocolType.email && notification?.getEmailOptions && (
          <Stack sx={sendInfo}>
            {notification?.getEmailOptions(
              onChange,
              data.notificationBoxes[protocol].sendFrequency,
            )}
          </Stack>
        )}

        {protocol === ProtocolType.in_app && (
          <Autocomplete
            id="select-workgroups"
            multiple
            value={data.notificationBoxes[protocol]?.targetGroup}
            options={groups}
            getOptionLabel={(option: any) => option?.name}
            disableCloseOnSelect
            renderInput={(params: any) => {
              const { key, ...rest } = params;
              return <TextField key={key} {...rest} label="Work Groups" />;
            }}
            renderOption={(props: any, option, { selected }) => {
              const { key, ...rest } = props;
              return (
                <li key={key} {...rest}>
                  <Checkbox
                    id={option.name}
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              );
            }}
            onChange={(e, options: any) => {
              onChange({
                targetGroup: options,
              });
            }}
          />
        )}
      </Box>
    </Box>
  );
}
