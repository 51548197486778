import { createTableStyle } from '../../../components/InfiniteTable/createTableStyle';

export const { rowStyle, columns }: any = createTableStyle({
  id: 150,
  name: {
    flexGrow: '1 !important',
    flex: 1,
    minWidth: '200px',
    textAlign: 'left',
  },
  shortName: {
    width: '200px',
    textAlign: 'center',
  },
  abbreviationName: {
    width: 120,
  },
  ruleSet: 150,
  sport: 200,
  rights: {
    width: 90,
    textAlign: 'center',
  },
  sportHeader: {
    width: 200,
  },
  abbreviationNameHeader: {
    width: 120,
  },
  rightsHeader: {
    width: 100,
    textAlign: 'center',
  },
});

export const leagueRowStyle = {
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F8F9F9',
    opacity: [0.9, 0.8, 0.7],
  },
  ...rowStyle,
};

export const rightsStyle = {
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  overflow: 'hidden',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  color: '#fff',
  fontWeight: 600,

  svg: {
    width: '12px',
    height: '12px',
  },

  '&.-level-0': {
    backgroundColor: '#ccc',
  },
  '&.-level-1': {
    backgroundColor: '#6c0',
  },
  '&.-level-2': {
    backgroundColor: '#06c',
  },
  '&.-level-3': {
    backgroundColor: '#f80',
  },
  '&.-level-4': {
    backgroundColor: '#888',
  },
  '&.-level-none': {
    backgroundColor: '#fcc',
  },
};
