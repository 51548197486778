import { Autocomplete, TextField } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { staticService } from '../../../services/StaticService';

interface LeagueFilterProps {
  filter: any;
  onChange(x: any): any;
  idField: any;
  presetType: string;
}

/** Filter for league and ID */
export function LeagueFilter({
  filter,
  onChange,
  idField,
  presetType,
}: LeagueFilterProps) {
  const [showError, setShowError] = useState(false);
  const leagueOptions = useMemo(
    () =>
      (staticService as any).data.leagues.all.map(
        (league: { id: any; sportType: any; name: any }) => ({
          value: league.id,
          label: `${staticService.getSportName(league.sportType)}: ${
            league.name
          }`,
        }),
      ),
    [],
  );

  const onChangeLeague = useCallback(
    (e: any, options: any, reason: string) => {
      setShowError(false);
      if (reason === 'createOption') {
        const id = Number(options.pop());
        const idAlreadyExists: boolean =
          (filter?.ids &&
            filter.ids.some(
              (value: any) => value.id === id && value.type === presetType,
            )) ||
          false;
        if (!idAlreadyExists) {
          if (!Number.isNaN(id)) {
            onChange({
              ...filter,
              ids: {
                [idField]: Number.isFinite(id) ? id : '',
                type: presetType,
              },
            });
          } else {
            setShowError(true);
          }
        }
      } else {
        onChange({
          ...filter,
          league_id: options.map((option: { value: any }) => option.value),
          sport: [],
        });
      }
    },
    [idField, filter, onChange, presetType],
  );

  return (
    <Autocomplete
      id="search-id-league"
      multiple
      freeSolo={!!idField}
      options={leagueOptions}
      filterOptions={(options, { inputValue }) =>
        options
          .filter(option => !filter.league_id?.includes(option.value))
          .filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()),
          )
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      renderInput={inputProps => (
        <TextField
          error={showError}
          helperText={showError ? 'Please select by id or league' : ''}
          {...inputProps}
          size="small"
          placeholder="Search by id or league"
        />
      )}
      renderTags={() => null}
      value={leagueOptions?.filter((option: { value: any }) =>
        filter.league_id?.includes(option.value),
      )}
      sx={{ width: '360px' }}
      onChange={onChangeLeague}
      autoComplete
      disableClearable
      clearOnBlur
      clearOnEscape
    />
  );
}
