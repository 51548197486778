import { useState, useEffect } from 'react';
import { staticService } from '../../../services/StaticService';

const checkRealms = (realms: any[]) =>
  realms.every(
    (realm: string | number) => (staticService as any).status[realm]?.ready,
  );

interface WithStaticDataProps {
  realms: string[];
  children: any;
}

export function WithStaticData({ realms, children }: WithStaticDataProps) {
  const [isReady, setReady] = useState(checkRealms(realms));

  useEffect(() => {
    if (!isReady) {
      const promises = realms.map(
        (realm: string | number) =>
          (staticService as any).status[realm]?.promise,
      );

      Promise.all(promises).then(() => {
        setReady(checkRealms(realms));
      });
    }
  }, [realms, isReady]);

  if (!isReady) return null;
  return children;
}
