import { Tooltip } from '@mui/material';

export const tooltipStyles = {
  tooltip: {
    backgroundColor: '#929495',
    border: '1px solid #E6E8ED',
    fontSize: '11px',
    color: 'white',
  },
};
interface TooltipBoxProps {
  value: any;
  className?: string;
}

export function TooltipBox({ value, className }: TooltipBoxProps) {
  return (
    <Tooltip
      title={value || ''}
      placement="bottom-start"
      sx={tooltipStyles}
      followCursor
    >
      <span className={className || ''}>{value || ''}</span>
    </Tooltip>
  );
}
