import { Key } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {
  accordionHeader,
  expandMore,
  filterAccordianDetails,
  filterCardContent,
  filterCardHeader,
  filterHeader,
  filterList,
  filterListItem,
  filtetListHeader,
} from '../style';
import { DeleteIcon } from './Actions';
import { FilterOptions } from '../../../components/Filters/FilterOptions';

interface Group {
  id: number;
  name: string;
  filter: any;
}

interface FilterGroupProps {
  title: string;
  filterGroup: Group[];
  criterias: string[];
}

export function FilterGroup({
  title,
  filterGroup,
  criterias,
}: FilterGroupProps) {
  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={filterCardHeader}
        sx={filterHeader}
      />
      <CardContent sx={filterCardContent}>
        <List sx={filterList}>
          {filterGroup?.map(({ id, name, filter }) => (
            <ListItem key={id} sx={filterListItem} disablePadding>
              <Accordion>
                <Stack sx={accordionHeader}>
                  <AccordionSummary
                    expandIcon={<ExpandMore sx={expandMore} />}
                    id={name}
                    sx={filtetListHeader}
                  >
                    {name}
                  </AccordionSummary>
                  <DeleteIcon id={id} />
                </Stack>
                <AccordionDetails>
                  <Stack sx={filterAccordianDetails}>
                    {criterias?.map(criteria =>
                      filter[criteria]?.map((value: Key | null | undefined) => (
                        <Chip
                          key={value}
                          size="small"
                          label={FilterOptions[
                            criteria as keyof typeof FilterOptions
                          ].getValueLabel(value)}
                          avatar={FilterOptions[
                            criteria as keyof typeof FilterOptions
                          ]?.getIcon(value)}
                        />
                      )),
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
