import { retroFitChanges } from '../../models/audits';
import api from '../../services/AuditService';

export async function loadChanges(type: string, id: any) {
  const data = await api.get(`/audit/changes/${type}/${id}`);
  let retroFitedData = data;
  if (data && data.length > 0) {
    retroFitedData = data.map((item: any) => {
      return {
        ...item,
        ...(item?.changes && { changes: retroFitChanges(item.changes) }),
      };
    });
  }
  return retroFitedData;
}
