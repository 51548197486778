import { Box } from '@mui/system';
import { useState } from 'react';
import Roles from '../../../models/Roles';
import { createNewProduction } from '../../../models/productions';
import { downloadLists } from '../../api/dataDownloader';
import { Actions } from '../../components/Actions/Actions';
import { CreateAction } from '../../components/Actions/CreateAction';
import { DownloadAction } from '../../components/Actions/DownloadAction';
import { SaveAction } from '../../components/Actions/SaveAction';
import { UndoAction } from '../../components/Actions/UndoAction';
import { AlertDialog } from '../../components/AlertModal/AlertDialog';
import { FilterToolbar } from '../../components/Filters/FilterToolbar';
import { useFilters } from '../../components/Filters/hooks';
import { Toasts } from '../../components/Toasts';
import { WithStaticData } from '../../components/WithStaticData';
import { DataType } from '../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import {
  applyFilter,
  clearList,
  commit,
  create,
  getSlice,
  rollback,
} from '../../reducers/productionsSlice';
import RenderOnRole from '../Auth/RenderOnRole';
import { saveAction } from './ActionHandler';
import { ProductionsTablenew } from './ProductionsTable/ProductionsTable';

export function ProductionPage() {
  useFilters(DataType.Production, applyFilter);

  const { items, creates, changes, errors } = useAppSelector(getSlice);
  const filter = useAppSelector(state => state.filter);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { defaultCountry } = useAppSelector(state => state.user);

  // Number of changes
  const changeCount = Object.keys(changes).length + Object.keys(creates).length;
  const hasErrors =
    Object.keys(errors).length > 0 &&
    Object.values(errors).some((itemErrors: any) => itemErrors.length > 0);

  const createAction = (date: any) => {
    const newProduction = createNewProduction({
      fromDate: date,
      country: defaultCountry,
    });
    dispatch(create(newProduction));
  };

  const undoAction = () => {
    dispatch(rollback());
  };

  return (
    <WithStaticData realms={['public', 'private']}>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FilterToolbar presetType="production" />
        <ProductionsTablenew />

        <Actions>
          <RenderOnRole
            roles={[
              Roles.ADMIN,
              Roles.PRODUCTION_CREATOR,
              Roles.PRODUCTION_EDITOR,
            ]}
          >
            {changeCount > 0 && <UndoAction onUndo={undoAction} />}
            {changeCount > 0 && (
              <SaveAction
                changeCount={changeCount}
                hasErrors={hasErrors}
                disabled={loading}
                onSave={async () => {
                  setLoading(true);
                  const commits: any = await saveAction({
                    creates,
                    changes,
                    items,
                    dispatch,
                  });
                  setLoading(false);
                  dispatch(commit(commits));
                  dispatch(clearList(false));
                }}
              />
            )}
            <RenderOnRole roles={[Roles.ADMIN, Roles.PRODUCTION_CREATOR]}>
              <CreateAction onSave={createAction} type="production" />
            </RenderOnRole>
          </RenderOnRole>
          <RenderOnRole
            roles={[
              Roles.ADMIN,
              Roles.PRODUCTION_CREATOR,
              Roles.PRODUCTION_EDITOR,
              Roles.PRODUCTION_VIEWER,
            ]}
          >
            <DownloadAction
              onDownload={() =>
                downloadLists({ filter, type: DataType.Production, dispatch })
              }
            />
          </RenderOnRole>
        </Actions>
        <Toasts />
        <AlertDialog undo={undoAction} />
      </Box>
    </WithStaticData>
  );
}
