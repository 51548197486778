import { createTableStyle } from '../../components/InfiniteTable/createTableStyle';

export const { rowStyle, columns }: any = createTableStyle({
  entityId: 100,
  entityType: 100,
  date: 100,
  time: 100,
  activity: 100,
  info: {
    flexGrow: '1 !important',
    overflow: 'hidden',
    flexShrink: 1,
  },
  author: {
    minWidth: 250,
  },
  origin: 100,
  id: 220,
  property: 140,
  type: 80,
  old: {
    flexGrow: '1 !important',
    overflow: 'hidden',
    flexShrink: 1,
    width: 580,
  },
  new: {
    flexGrow: '1 !important',
    overflow: 'hidden',
    flexShrink: 1,
    width: 580,
  },
});

export const auditRowStyle = {
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F8F9F9',
    opacity: [0.9, 0.8, 0.7],
  },
  ...rowStyle,
};

export const auditSubRowStyle = {
  ...rowStyle,
  '._badge': {
    backgroundColor: '#b17e9b',
  },
  backgroundColor: '#FBFCFC',
};

export const expandRowStyle = {
  border: '0px solid',
  borderBottom: '0px solid',
  '._expand': {
    padding: 0,
    border: 0,
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    cursor: 'pointer',
    color: '#cc0099',
  },
  '._expand_disabled': {
    padding: 0,
    border: 0,
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    cursor: 'pointer',
    color: 'white',
  },

  '._subs': {
    backgroundColor: '#f4f3f4',
    minWidth: 500,
    maxWidth: 1700,
  },
};

export const historyPageContainer = {
  width: 1,
  height: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const historyFilterContainer = {
  width: '90%',
  padding: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  '@media (max-width: 1600px)': {
    width: '100%',
  },
  '@media (max-width: 1400px)': {
    flexDirection: 'column',
    gap: 2,
  },
};
export const historyTableContainer = {
  height: 1,
  width: '90%',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const historyFilterOptionsContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 3,

  '@media (max-width: 1300px)': {
    flexWrap: 'wrap',
  },
};
