import { Check } from '@mui/icons-material';
import { Badge, CircularProgress, Fab } from '@mui/material';
import { Stack } from '@mui/system';

interface SaveActionProps {
  onSave: any;
  changeCount: number;
  hasErrors: boolean;
  disabled?: boolean;
}

export function SaveAction({
  onSave,
  changeCount,
  hasErrors,
  disabled,
}: SaveActionProps) {
  return (
    <Badge badgeContent={changeCount} color="secondary" overlap="circular">
      <Fab
        id="save-changes"
        color="primary"
        aria-label="save changes"
        disabled={hasErrors || disabled}
        onClick={onSave}
        sx={{ zIndex: 0 }}
      >
        {disabled ? <CircularProgress /> : <Check />}
      </Fab>
    </Badge>
  );
}
