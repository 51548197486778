import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import LeagueProvider from '../General/LeagueProvider';
import { providerContent, sourceContent } from '../style';

interface SeasonSourceProps {
  disabled: boolean;
  onChange: any;
  league: any;
}

const SeasonSource = ({ disabled, onChange, league }: SeasonSourceProps) => {
  const [checkManually, setCheckManually] = useState(
    league.manuallySeasonChecked,
  );
  const [checkProvider, setCheckProvider] = useState(league.providerChecked);
  const defaultValue =
    (checkProvider && 'Provider') || (checkManually && 'Manuel') || '';

  const handleProviderOption = () => {
    setCheckProvider(true);
    setCheckManually(false);
    if (league.mapping?.providerId) {
      onChange({
        manuallySeasonChecked: false,
        providerChecked: true,
        mapping: { ...league.mapping, disabled: false },
      });
    } else {
      onChange({
        manuallySeasonChecked: false,
        providerChecked: true,
        mapping: {},
      });
    }
  };
  const handleManuellOption = () => {
    setCheckManually(true);
    setCheckProvider(false);
    if (league.mapping?.providerId) {
      onChange({
        manuallySeasonChecked: true,
        providerChecked: false,
        mapping: { ...league.mapping, disabled: true },
      });
    } else {
      onChange({
        manuallySeasonChecked: true,
        providerChecked: false,
      });
    }
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="source-group"
        name="source-group"
        sx={{ alignItems: 'center', rowGap: '10px' }}
        value={defaultValue}
      >
        <Stack sx={sourceContent}>
          <Stack sx={providerContent}>
            <FormControlLabel
              value="Provider"
              control={<Radio />}
              label="Provider"
              onClick={handleProviderOption}
              disabled={disabled}
            />
            <LeagueProvider
              disabled={disabled}
              league={league}
              onChange={onChange}
            />
          </Stack>
          <FormControlLabel
            value="Manuel"
            control={<Radio />}
            label="Manually"
            onClick={handleManuellOption}
            disabled={disabled}
          />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};

export default SeasonSource;
