import { RestartAlt } from '@mui/icons-material';
import { Button, Grid, Icon, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DataType } from '../../../reducers/dataSlice';
import { useAppDispatch } from '../../../reducers/hooks';
import {
  TableType,
  updateColumnSettings,
} from '../../../reducers/settingSlice';
import { TAB_TYPE_PROPS } from './ColumnPreference';
import { ColumnToggleButton } from './ColumnToggleButton';
import { settingContent, settingHeader } from './style';

interface TableColumnProps {
  tableType: TableType;
  mainTableDataType: DataType;
  subTableDataType?: DataType;
  columns: [];
  usingDefaultSettings: boolean;
}

export function TableColumns({
  tableType,
  mainTableDataType,
  subTableDataType,
  columns,
  usingDefaultSettings,
}: TableColumnProps) {
  const dispatch = useAppDispatch();
  const handleToggleChange = (data: any) => {
    dispatch(
      updateColumnSettings({
        mainTableDataType,
        subTableDataType,
        tableType,
        data,
      }),
    );
  };

  const handleDefaultToggle = () => {
    dispatch(
      updateColumnSettings({ mainTableDataType, subTableDataType, tableType }),
    );
  };
  const type = subTableDataType || mainTableDataType;
  return (
    <Stack key={type} sx={settingContent}>
      <Stack sx={settingHeader}>
        <Typography variant="h2">{TAB_TYPE_PROPS[type].header}</Typography>
        <Icon> {TAB_TYPE_PROPS[type].icon}</Icon>
        <Button
          id={`restore-${TAB_TYPE_PROPS[type].header.toLowerCase()}-setting`}
          disabled={usingDefaultSettings}
          sx={{ marginLeft: 20, fontSize: 10 }}
          onClick={handleDefaultToggle}
          endIcon={<RestartAlt />}
          variant="outlined"
        >
          RESTORE
        </Button>
      </Stack>
      <Grid container spacing={4}>
        {columns.map((column: any) => (
          <Grid key={column.id} item xs="auto">
            <ColumnToggleButton
              column={column}
              color="success"
              handleToggleChange={handleToggleChange}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
