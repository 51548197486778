import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ChannelType } from '../../../services/Transport';
import {
  StreamReceiver,
  StreamReceiverCallback,
  streamService,
} from '../../../services/StreamService';
import { DataType } from '../../reducers/dataSlice';
import { useAppSelector } from '../../reducers/hooks';
import { formatDate } from '../../../models/dates';

export const useLiveUpdatesOnFilters = (
  dataType: DataType,
  callback: StreamReceiverCallback,
) => {
  const dispatch = useDispatch();

  //Just interesting in listening to changes on the filter.
  const { event, production, feed, workspace } = useAppSelector(
    state => state.filter,
  );
  let listeningFilter: any;
  switch (dataType) {
    case DataType.Event:
      listeningFilter = event;
      break;
    case DataType.Production:
      listeningFilter = production;
      break;
    case DataType.Workspace:
      listeningFilter = workspace;
      break;
    case DataType.Feed:
      listeningFilter = feed;
      break;
    default:
      console.log(`No filters for datatype ${dataType}`);
  }

  const receiver: StreamReceiver = useMemo(() => {
    let channelType: ChannelType;
    switch (dataType) {
      case DataType.Event:
        channelType = ChannelType.event;
        break;
      case DataType.Production:
        channelType = ChannelType.productions;
        break;
      case DataType.Feed:
        channelType = ChannelType.feeds;
        break;
      case DataType.Workspace:
        channelType = ChannelType.workspace;
        break;
      default:
        channelType = ChannelType.event;
    }
    return streamService.registerReceiver(channelType, callback);
  }, [callback, dataType]);

  // Subscribe to receive events updates
  useEffect(() => {
    const attributes = {
      filter: listeningFilter,
    };
    console.log(
      `receiver: ${receiver.id}, triggered useffect ${JSON.stringify(
        attributes,
      )}`,
    );
    streamService.requestChannel(attributes, receiver);
    //The callback return is executed on unmounted
    return () => {
      console.log(`leaving channel ${receiver.id}`);
      streamService.leaveChannel(receiver);
    };
  }, [listeningFilter, dataType, receiver, dispatch]);
};
