export function onCursorNavigation(e: any) {
  e.preventDefault();

  const button = e.target;
  const cell = button.parentNode;
  const row = cell.parentNode;

  if (e.key === 'ArrowUp') {
    const field = cell.dataset.field;
    const selector = `[data-field="${field}"] .edit-cell`;
    row.previousElementSibling?.querySelector(selector)?.focus();

    const table: any = document.querySelector('.infinite-InfiniteTable');
    const buttonRect = button.getBoundingClientRect();
    const tableRect = table.getBoundingClientRect();
    if (buttonRect.top - tableRect.top <= tableRect.height * 0.33) {
      table.scrollBy({ top: -buttonRect.height, behavior: 'instant' });
    }
  }
  if (e.key === 'ArrowDown') {
    const field = cell.dataset.field;
    const selector = `[data-field="${field}"] .edit-cell`;
    row.nextElementSibling?.querySelector(selector)?.focus();

    const table: any = document.querySelector('.infinite-InfiniteTable');
    const buttonRect = button.getBoundingClientRect();
    const tableRect = table.getBoundingClientRect();
    if (buttonRect.top - tableRect.top >= tableRect.height * 0.66) {
      table.scrollBy({ top: buttonRect.height, behavior: 'instant' });
    }
  }
  if (e.key === 'ArrowLeft') {
    let prevCell = cell.previousElementSibling;
    let prevButton = null;
    while (prevCell && !prevButton) {
      prevButton = prevCell.querySelector('[data-field] .edit-cell');
      prevCell = prevCell.previousElementSibling;
    }
    prevButton?.focus();
  }
  if (e.key === 'ArrowRight') {
    let nextCell = cell.nextElementSibling;
    let nextButton = null;
    while (nextCell && !nextButton) {
      nextButton = nextCell.querySelector('[data-field] .edit-cell');
      nextCell = nextCell.nextElementSibling;
    }
    nextButton?.focus();
  }
}
