import { Check, Close, Delete } from '@mui/icons-material';
import { CircularProgress, Fab, Modal } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface DeleteActionProps {
  handleDelete: any;
  disabled: boolean;
}

export function DeleteAction({ handleDelete, disabled }: DeleteActionProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Fab
        id="delete-league"
        color="error"
        onClick={() => setShowModal(true)}
        disabled={disabled}
      >
        {disabled ? <CircularProgress /> : <Delete />}
      </Fab>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            ...style,
            width: 400,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <h3>Are you sure you want to delete this league?</h3>
          <Stack direction="row" gap={3} justifyContent="flex-end">
            <Fab id="cancel-delete" onClick={() => setShowModal(false)}>
              <Close />
            </Fab>
            <Fab
              id="confirm-delete"
              color="primary"
              onClick={() => {
                setShowModal(false);
                handleDelete();
              }}
            >
              <Check />
            </Fab>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
