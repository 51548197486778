import { useContext } from 'react';
import { get } from 'lodash';
import { CellProps } from '..';
import { BaseItem } from '../../../reducers/dataSlice';
import { UpdateNotification } from '../../Notifications/UpdateNotification';
import { useAppDispatch } from '../../../reducers/hooks';
import { TableContext } from '../InfiniteTable';
import { getKeyFromId } from '../../../../models/records';
import { getCellClasses } from '../utils';
import { TooltipBox } from '../../Tooltip/Tooltip';
import { EditCell } from '../../EditCell';
import { ValueCell } from './ValueCell';

export function TextStatusCell<T extends BaseItem>({
  column,
  item,
  editable,
}: CellProps<T>) {
  const value = get(item, column.key);
  const dispatch = useAppDispatch();
  const { update, changes, errors, refreshed } = useContext(TableContext);
  const key = getKeyFromId(item.type, item._id);

  const refreshedColumns = (refreshed && refreshed[key]) || [];
  const getStatus = () => {
    return (
      <UpdateNotification
        createdTime={item.created_time || item.create_datetime}
        updateDateTime={item.updated_time || item.update_datetime}
        associatedUpdateTime={
          item?.aggregated_detail?.latest_updated_time || ''
        }
        status={item?.status}
      />
    );
  };

  if (!editable) {
    return (
      <ValueCell
        value={value}
        style={column.style}
        refreshed={refreshedColumns}
        property={column.key}
      />
    );
  }
  return (
    <EditCell
      className={getCellClasses({
        property: column.key,
        delta: changes[key] || [],
        errors: errors[key] || [],
        refreshed: refreshedColumns,
        other: [column.style],
      })}
      id={item._id}
      field={column.key}
      value={value}
      displayValue={
        <TooltipBox
          value={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span>{getStatus() || ''}</span>
              <span>{value}</span>
            </div>
          }
        />
      }
      initValue={(key: any) => key || value}
      onChange={(input: any) => {
        dispatch(
          update({
            type: item.type,
            _id: item._id,
            delta: { [column.key]: input },
          }),
        );
      }}
      renderEditor={(
        input: string | number | readonly string[] | undefined,
        { onInput, onKeyDown }: any,
      ) => (
        <input
          type="text"
          name={column.key}
          value={input}
          onKeyDown={onKeyDown}
          onInput={(e: any) => onInput(e.target.value)}
          onFocus={e => {
            if (value === input) e.target.select();
          }}
        />
      )}
    />
  );
}
