import { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { audioFormControl, audioRadioGroup } from './style';

interface LeagueAudioProps {
  disabled: boolean;
  league: any;
  onChange: any;
}

const LeagueAudio = ({ disabled, league, onChange }: LeagueAudioProps) => {
  const createdAudio =
    league && league?.audio?.length > 0 ? league.audio[0].available : 'UN';
  const [value, setValue] = useState(createdAudio);

  const handleChange = (event: any) => {
    const audioUpdate = [];
    audioUpdate.push({ language: 'ENG', available: event.target.value });
    onChange(audioUpdate);
    setValue(event.target.value);
  };

  return (
    <FormControl sx={audioFormControl}>
      <FormLabel id="audio">English audio?</FormLabel>
      <RadioGroup
        aria-labelledby="audio"
        name="audio-group"
        value={value}
        onChange={handleChange}
        sx={audioRadioGroup}
      >
        <FormControlLabel
          value="YES"
          control={<Radio disabled={disabled} />}
          label="Yes"
        />
        <FormControlLabel
          value="NO"
          control={<Radio disabled={disabled} />}
          label="No"
        />
        <FormControlLabel
          value="UN"
          control={<Radio disabled={disabled} />}
          label="?"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default LeagueAudio;
