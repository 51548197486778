import { Chip, ThemeProvider, Tooltip } from '@mui/material';
import { addHours } from 'date-fns';
import {
  isAroundSameTime,
  utcTimestampToJSDate,
  utcTimestampToLocalized,
} from '../../../models/dates';
import {
  themeCancelled,
  themeNew,
  themePostponed,
  themeUpdated,
} from './theme';

const isAfter = (boundary: any, time: number) => {
  return time > boundary;
};

interface UpdateNotificationProps {
  createdTime?: string;
  updateDateTime?: string;
  associatedUpdateTime?: string;
  status?: string;
}

export function UpdateNotification({
  createdTime,
  updateDateTime,
  associatedUpdateTime,
  status,
}: UpdateNotificationProps) {
  if (!updateDateTime && !createdTime) return <></>;

  const now = new Date();

  // Timestamp when the data was updated
  const wasUpdated: any =
    updateDateTime && utcTimestampToJSDate(updateDateTime);
  // Timestamp when the data was created
  const wasCreated: any = createdTime && utcTimestampToJSDate(createdTime);

  // Timestamp when the data was associated
  const associatedLatestUpdatedTime: any =
    associatedUpdateTime && utcTimestampToJSDate(associatedUpdateTime);

  // 24 hour
  const boundary = addHours(now, -24);

  // If created is within the boundary
  const isNew = wasCreated && isAfter(boundary, wasCreated);

  // If updated is within the boundary
  const isUpdated = wasUpdated && isAfter(boundary, wasUpdated);

  // If a association was added within the boundary
  const hasAssocietedUpdates =
    associatedLatestUpdatedTime &&
    isAfter(boundary, associatedLatestUpdatedTime);
  const eventStatus = status && status !== 'ON' ? status : '';

  if (
    !isNew &&
    !isUpdated &&
    !hasAssocietedUpdates &&
    !eventStatus &&
    eventStatus === 'New'
  ) {
    return <></>;
  }

  let eventStatusLabel: any = '';
  let actionLabel = '';
  let localTimestamp = '';
  let colorTheme: any = '';
  if (
    createdTime &&
    isAroundSameTime(wasCreated, wasUpdated) &&
    isNew &&
    !associatedLatestUpdatedTime &&
    !eventStatus
  ) {
    eventStatusLabel = 'NEW';
    actionLabel = 'Created: ';
    localTimestamp = utcTimestampToLocalized(createdTime);
    colorTheme = themeNew;
  } else if (
    updateDateTime &&
    isAfter(wasCreated, wasUpdated) &&
    !associatedLatestUpdatedTime &&
    isUpdated &&
    !eventStatus
  ) {
    eventStatusLabel = 'UPDATED';
    actionLabel = 'Last updated: ';
    localTimestamp = utcTimestampToLocalized(updateDateTime);
    colorTheme = themeUpdated;
  } else if (
    associatedUpdateTime &&
    associatedLatestUpdatedTime &&
    isAfter(wasUpdated, associatedLatestUpdatedTime)
  ) {
    eventStatusLabel = 'UPDATED';
    actionLabel = 'Association updated: ';
    localTimestamp = utcTimestampToLocalized(associatedLatestUpdatedTime);
    colorTheme = themeUpdated;
  } else if (
    updateDateTime &&
    associatedLatestUpdatedTime &&
    isAfter(associatedLatestUpdatedTime, wasUpdated)
  ) {
    eventStatusLabel = 'UPDATED';
    actionLabel = 'Last updated: ';
    localTimestamp = utcTimestampToLocalized(updateDateTime);
    colorTheme = themeUpdated;
  } else if (updateDateTime && eventStatus && eventStatus !== 'New') {
    eventStatusLabel = status;
    actionLabel = `${status}: `;
    localTimestamp = utcTimestampToLocalized(updateDateTime);
    colorTheme = status === 'POSTPONED' ? themePostponed : themeCancelled;
  }

  if (!eventStatusLabel) {
    return <></>;
  }

  colorTheme.components.MuiChip.styleOverrides.label.fontSize = 8;

  return (
    <ThemeProvider theme={colorTheme}>
      <Tooltip title={`${actionLabel} ${localTimestamp}`}>
        <Chip label={eventStatusLabel} />
      </Tooltip>
    </ThemeProvider>
  );
}
