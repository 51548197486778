import { fontWeight } from '@mui/system';

export const gridStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

export const avatarStyle = {
  backgroundColor: 'grey',
  width: '15px',
  height: '15px',
};

export const groupCard = {
  display: 'flex',
  flexDirection: 'column',
  padding: 2,
  width: 400,
};

export const notificationTypeLabel = {
  fontSize: '12px',
  backgroundColor: '#FAFAFA',
  color: 'black',
  width: 'fit-content',
  fontWeight: 'bold',
  padding: 1,
  margin: 1,
  flexGrow: 1,
};

export const paperStyle = {
  position: 'fixed',
  top: '10%',
  left: '40%',
  m: 0,
};

export const dialogContent = {
  width: 500,
};

export const createNotificationContent = {
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  maxHeight: 700,
};

export const addNotificationHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const addNotificationInfo = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};

export const notificationBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const captureInfo = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',
  marginLeft: '20px',
};

export const sendInfo = {
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  alignItems: 'center',
  marginLeft: '30px',
  color: 'rgba(0, 0, 0, 0.6)',
};

export const actionContent = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  justifyContent: 'space-between',
  padding: 2,
};

export const notificationActionButtons = {
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
};

export const notificationBoxText = {
  width: 50,
};

export const notificationBoxSelect = {
  height: 30,
  width: 120,
};

export const notificationInfoLabel = {
  fontSize: 12,
  fontWeight: 'bold',
};

export const notificationBoxHeader = {
  fontSize: 14,
  fontWeight: 'bold',
};

export const formControlLabel = {
  marginLeft: 0,
};

export const whenBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};

export const whenBoxLabel = {
  display: 'flex',
  flexDirection: 'row',
  gap: 3,
};

export const userTypeBox = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 5,
  gap: 1,
};

export const notificationCriteriaBox = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 5,
  gap: 1,
};
