import { useCallback } from 'react';
import { useAppDispatch } from '../../reducers/hooks';
import {
  closeSubItems,
  opensubItems,
  setSubItems,
} from '../../reducers/auditSlice';

export function useToggleRow({ subItems }: any) {
  const dispatch = useAppDispatch();

  const toggleRow = useCallback(
    async (item: any) => {
      const id = item._id;
      const { open, data } = subItems[id] || {};
      if (open) {
        dispatch(closeSubItems({ id }));
        return;
      }
      dispatch(opensubItems({ id }));
      if (!data) {
        dispatch(
          setSubItems({ id, data: item.entityChange.changes, type: 'audit' }),
        );
      }
    },
    [dispatch, subItems],
  );
  return toggleRow;
}

export function getRowSize({ index, rows, subItems }: any) {
  if (index >= rows.length) return 0;
  const auditData = rows[index];
  const id: any = auditData?._id;
  const subItem = subItems[id];
  const subItemsLength = subItem?.data.length;
  if (!subItem || !subItem.open || !subItem.data) {
    return 35;
  }

  return 35 * (2 + Math.max(1, subItemsLength));
}
