export const audioFormControl = {
  width: 'fit-content',
  height: 'fit-content',
  padding: '5px',
};

export const audioRadioGroup = {
  flexDirection: 'row',
  justifyContent: 'center',
};
