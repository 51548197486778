import { nanoid } from '@reduxjs/toolkit';
import { formatDate } from '../models/dates';
import { BatchCall, BatchResponse } from '../app/api/common';
import { BaseItem } from '../app/reducers/dataSlice'; // Creates request query of the filters

// Creates request query of the filters
export function filterToQueryParams(
  currentFilter: any,
  type: string,
  offset = 0,
) {
  const filter = {
    ...currentFilter[type],
    fromDate: currentFilter.fromDate,
    toDate: currentFilter.toDate,
    sortBy: currentFilter.sortBy,
    sortOrder: currentFilter.sortOrder,
  };

  const filterParams: any = {};
  for (const key in filter) {
    const value = filter[key];
    if (Array.isArray(value) && value.length > 0) {
      filterParams[key] = value;
    }
  }

  return new URLSearchParams({
    from: formatDate(filter.fromDate),
    to: formatDate(filter.toDate),
    next: offset,
    filter: JSON.stringify(filterParams),
  } as any).toString();
}

const isFailResponse = (response: { status: string; value: any }) => {
  if (response.status === 'rejected') {
    return true;
  }
  if (response.value) {
    const status = response.value.response?.status;
    if (!status) {
      return false;
    }
    return status !== 200 && status !== 201;
  }
  return false;
};
/**
 * Handle the Promise results collection the errors (if any) and the ids that have been through successfully.
 * @param results
 * @param getIdCallback
 */
const handleBatchResponse: any = (
  results: any[],
  getIdCallback: ((item: any) => any) | undefined,
) => {
  //Has error if promise result is not fullfilled or has response with error code different from 200
  const hasErrors = results.some(res => isFailResponse(res));

  const errorMessages: { id: string; error: any }[] = [];
  const getId = getIdCallback || ((data: { id: any }) => data.id);
  if (hasErrors) {
    //We handle the possible error response from backend. and return as object {id, error}
    results.forEach(
      (result: {
        status: string;
        value: any;
        reason: {
          message: any;
          config: { url: any };
          response: { data: { errors: { traces: any[] }; errorMessage: any } };
        };
      }) => {
        if (result.status === 'rejected') {
          collectErrors(result.reason, errorMessages);
        } else {
          collectErrors(result.value, errorMessages);
        }
      },
    );
  }

  const savedIds = results
    .filter(res => !isFailResponse(res))
    .map((res: { value: any }) => {
      return !res.value?.data ? getId(res.value) : getId(res.value?.data);
    });

  return { hasErrors, savedIds, errorMessages };
};

/**
 * Given a reason or value from the response, we collect the errors as id, error objects.
 *
 * The response from API can be as follow:
 * response : { data:{errors:{traces: {id,trace}[]} }}
 * response : { data:{errorMessage }}
 *
 * @param reasonOrValue reason if the response is not fulfilled or value with the response from API
 * @param errorMessages buffer to hold all errors from the batch
 */

const collectErrors = (
  reasonOrValue: any,
  errorMessages: { id: string; error: any }[],
): { id: string; error: any }[] => {
  if (!reasonOrValue.response || !reasonOrValue.response.data) {
    errorMessages.push({
      id: nanoid(),
      error: `${reasonOrValue.message} on url ${reasonOrValue.config.url}`,
    });
    return errorMessages;
  }

  if (reasonOrValue.response.data?.errors) {
    // map all the errors coming from the response in error array
    errorMessages.push(
      ...reasonOrValue.response.data.errors.traces.map(
        (trace: { id: any; trace: any }) => {
          return { id: trace.id, error: trace.trace };
        },
      ),
    );
  } else if (reasonOrValue.response.data?.errorMessage) {
    errorMessages.push({
      id: nanoid(),
      error: reasonOrValue.response.data.errorMessage,
    });
  }
  return errorMessages;
};

export async function runBatch(batchCall: BatchCall): Promise<BatchResponse> {
  if (batchCall.payloads.length === 0)
    return { hasErrors: false, savedIds: [] };

  const results = await Promise.allSettled(
    batchCall.payloads.map(batchCall.apiCall),
  );

  return handleBatchResponse(results, batchCall.getId);
}
export async function batchCreate(
  batchCall: BatchCall,
): Promise<BatchResponse> {
  if (batchCall.payloads.length === 0)
    return { hasErrors: false, savedIds: [], savedTempIds: [] };

  const results: any = await Promise.allSettled(
    batchCall.payloads.map(batchCall.apiCall),
  );

  const response = handleBatchResponse(results, batchCall.getId);

  const savedTempIds = batchCall.payloads
    .map(
      (create: { _id: any }, i: any) =>
        !isFailResponse(results[i]) && create._id,
    )
    .filter(Boolean);

  return {
    savedTempIds,
    ...response,
  };
}

export async function batchUpdate(
  batchCall: BatchCall,
): Promise<BatchResponse> {
  if (batchCall.payloads.length === 0)
    return { hasErrors: false, savedIds: [] };

  const results = await Promise.allSettled(
    batchCall.payloads.map(batchCall.apiCall),
  );

  return handleBatchResponse(results, batchCall.getId);
}

export async function batchDelete(
  batchCall: BatchCall,
): Promise<BatchResponse> {
  if (batchCall.payloads.length === 0)
    return { hasErrors: false, savedIds: [] };

  const results = await Promise.allSettled(
    batchCall.payloads.map(batchCall.apiCall),
  );

  return handleBatchResponse(results, batchCall.getId);
}
