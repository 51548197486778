import { Check } from '@mui/icons-material';
import { Button, FormControlLabel, Stack, Switch } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  buildFilterFromCriteria,
  filterToCriteria,
} from '../../../models/filters';
import { populateFilterStorage } from '../../../models/storage';
import { setShowDeleted } from '../../reducers/filterSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import { ChipPicker } from '../ChipPicker';
import { FilterCriteria } from './FilterCriteria';
import { SaveFilter } from './SaveFilter';

export function ComplexFilterEditor({
  filter,
  presets,
  presetType,
  allowFields,
  onCommit,
}: any) {
  const [name, setName] = useState('');
  const [editPresets, setEditPresets] = useState([...presets]);
  const [criteria, setCriteria] = useState(
    filterToCriteria(filter, allowFields),
  );
  const editFilter = useMemo(
    () => buildFilterFromCriteria(criteria, allowFields),
    [criteria, allowFields],
  );

  const allowPresets = useAppSelector(state => {
    const globals = state.filter.savedFilters.global || [];
    let typed: any = [];
    if (presetType === 'workspace') {
      Object.keys(state.filter.savedFilters).map(key => {
        return typed.push(...state.filter.savedFilters[key]);
      });
      return typed;
    } else {
      typed = state.filter.savedFilters[presetType] || [];
    }

    return presetType === 'global' ? globals : globals.concat(typed);
  });
  const presetOptions = useMemo(
    () =>
      allowPresets.map((preset: { name: any; id: any }) => ({
        label: preset.name,
        value: preset.id,
      })),
    [allowPresets],
  );

  const onApply = () => {
    const allCustomEmpty = Object.keys(editFilter).every(
      key => editFilter[key].length === 0,
    );

    let currentFilters = {};
    for (const key in filter) {
      if (!allowFields.includes(key)) {
        currentFilters = { ...currentFilters, [key]: filter[key] };
      }
    }

    const current = { ...editFilter, ...currentFilters };

    if (allCustomEmpty && !current) {
      onCommit({
        customFilter: [],
        filterPresets: editPresets,
        currentCustomFilters: [],
      });
    } else {
      onCommit({
        customFilter: editFilter,
        filterPresets: editPresets,
        currentCustomFilters: current,
      });
    }
  };

  const { showDeleted } = useAppSelector(state => state.filter);

  const [checked, setChecked] = useState(showDeleted);

  const dispatch = useAppDispatch();

  return (
    <Stack gap={1} sx={{ width: '480px', padding: 1 }}>
      {presetOptions.length > 0 && (
        <ChipPicker
          options={presetOptions}
          selected={editPresets}
          onChange={setEditPresets}
        />
      )}
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          margin: '5px 15px 1px 1px',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              inputProps={{ 'aria-label': 'ant design' }}
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked);
                dispatch(
                  setShowDeleted({
                    showDeleted: e.target.checked,
                  }),
                );
                populateFilterStorage({ showDeleted: e.target.checked });
              }}
              size="small"
            />
          }
          label="Show deleted"
          labelPlacement="start"
        />
      </Stack>
      <FilterCriteria
        criteria={criteria}
        allowFields={allowFields}
        presetType={presetType}
        onChange={setCriteria}
      />

      <Stack direction="row" justifyContent="flex-end">
        <SaveFilter
          typedPreset={{ type: presetType, filter: editFilter }}
          name={name}
          setName={setName}
          setCriteria={setCriteria}
        />
        <Button
          onClick={onApply}
          startIcon={<Check />}
          disabled={!!name || (!criteria.length && !editPresets.length)}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
}
