import { isNewId, isTempId } from '../../../models/records';
import { deleteSeasonById, saveLeague, updateLeague } from '../../api/leagues';

export const handleAddLeague = async (details: any, dispatch: any) => {
  const id = details.data._id;
  let changes = { ...details.changes };

  if (changes.mapping?.providerLeagueId && changes?.mapping?.providerId) {
    changes = {
      ...changes,
      mapping: {
        ...changes.mapping,
        provider_id: changes?.mapping?.providerId,
        provider_league_id: changes?.mapping.providerLeagueId,
      },
    };
  }

  if (changes?.seasons?.length > 0) {
    changes = {
      ...changes,
      seasons: changes.seasons.map((season: any) => {
        if (!season.updated_time) {
          return {
            name: season.name,
            startDate: season.startDate,
            endDate: season.endDate,
          };
        }
        return season;
      }),
    };
  }

  if (changes?.rights?.length > 0) {
    changes = {
      ...changes,
      rights: changes.rights.map((right: any) => {
        if (isTempId(right.id)) {
          return {
            country: right.country,
            rightStart: right.rightStart,
            rightEnd: right.rightEnd,
            type: right.type,
            level: right.level,
          };
        }
        return right;
      }),
    };
  }

  if (details?.data?.audio && !changes?.audio) {
    changes = {
      ...changes,
      audio: details.data.audio,
    };
  }

  const payload: any = changes;

  if (changes?.deletedSeasons?.length > 0) {
    payload.deletedSeasons.forEach((element: any) => {
      if (!isNewId(element)) {
        deleteSeasonById(element, dispatch);
      }
    });
    delete payload.deletedSeasons;
    delete payload.seasons;
  }

  let response = {};
  if (isNewId(id)) {
    response = await saveLeague(payload, dispatch);
  } else {
    if (Object.values(payload).length !== 0) {
      response = await updateLeague(id, payload, dispatch);
    }
  }
  return response;
};
