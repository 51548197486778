import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { mergeListAndCreates } from '../../../models/records';
import { loadMoreData } from '../../api/dataLoader';
import { BaseItem } from '../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import {
  scrollbarHorizontallStyle,
  scrollbarVerticalStyle,
} from '../../styles/scrollbar/ScrollbarStyles';
import EmptyRowsBox from './EmptyRowsBox';
import LoadingBox from './LoadingBox';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { BASIC_COLUMN_STYLE } from './createTableStyle';
import { ColumnType, InfiniteTableProps } from './index';

import '../../../App.css';

const BATCH_SIZE = 200;
const TRIGGER_THRESHOLD = Math.trunc(BATCH_SIZE * 0.4);

export const TableContext = React.createContext({} as any);

export function InfiniteTable<T extends BaseItem>(
  tableProps: InfiniteTableProps<T>,
) {
  const {
    visibleItems,
    creates,
    requestedTo,
    isFinished,
    changes,
    errors,
    refreshed,
    subItems,
    isLoading,
  } = tableProps.slice;
  const { update, create, undoChange, undoCreate } = tableProps.actions;
  const {
    readOnly,
    columns,
    type,
    actions,
    rowStyle,
    subTable,
    onRowClick,
    passRef,
    disableSubRow,
    expandRowStyle,
  }: any = tableProps;

  const filter = useAppSelector(state => state.filter);

  // If show deleted is set on filter display all items else remove the deleted items.
  const list = filter.showDeleted
    ? visibleItems
    : visibleItems?.filter(vl => !vl?.isDeleted);

  // Merge the list with the new created rows
  const rows = useMemo(() => {
    return mergeListAndCreates(list, Object.values(creates), type);
  }, [list, creates, type]);

  //Default row size
  const getRowSize = (index: any) => {
    return tableProps?.getRowHeight
      ? tableProps.getRowHeight(index, rows, creates, subItems)
      : 35;
  };

  // If table has subTables add expand column
  const extendedColumns = useMemo(() => {
    return tableProps?.sub
      ? [
          {
            key: 'expand',
            header: '',
            datatype: ColumnType.Expand,
            style: BASIC_COLUMN_STYLE.expand,
            editable: false,
          },
          ...columns,
        ]
      : columns;
  }, [tableProps.sub, columns]);

  // Add states and actions to a context
  const context = useMemo(
    () => ({
      creates,
      changes,
      subItems,
      errors,
      refreshed,
      update,
      create,
      undoChange,
      undoCreate,
      disableSubRow,
      expandRowStyle,
      toggleRow: tableProps.toggleRow,
    }),
    [
      creates,
      changes,
      subItems,
      errors,
      refreshed,
      update,
      create,
      undoChange,
      undoCreate,
      disableSubRow,
      expandRowStyle,
      tableProps.toggleRow,
    ],
  );

  const dispatch = useAppDispatch();

  // Load first batch of data
  useEffect(() => {
    if (!isFinished && visibleItems.length === 0) {
      loadMoreData({
        api: tableProps.api,
        type,
        fromIndex: 0,
        toIndex: BATCH_SIZE,
        filter,
        dispatch,
        sliceActions: actions,
        filterToQuery: tableProps.filterToQuery,
      });
    }
  }, [
    visibleItems,
    isFinished,
    filter,
    dispatch,
    type,
    actions,
    tableProps.api,
    tableProps.filterToQuery,
  ]);

  // render a row for the given index as a callback within the VariableSizeList component
  // it only renders the row visible on the screen
  // @ts-ignore
  const renderRow = useCallback(
    ({ index, style }: any) => {
      if (!rows[index]) return <></>;
      return (
        <TableRow
          item={rows[index]}
          style={style}
          readOnly={readOnly}
          columns={extendedColumns}
          tableRowStyle={rowStyle}
          onRowClick={onRowClick}
          subRows={subTable}
        />
      );
    },
    [rows, readOnly, rowStyle, extendedColumns, subTable, onRowClick],
  );

  // Callback for infinite scroller. It's been call while scrolling to fetch more data when it's needed.
  const triggerLoad = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (!isFinished && requestedTo < toIndex) {
        loadMoreData({
          api: tableProps.api,
          type,
          fromIndex,
          toIndex,
          filter,
          dispatch,
          sliceActions: actions,
          filterToQuery: tableProps.filterToQuery,
        });
      }
    },
    [
      requestedTo,
      isFinished,
      filter,
      dispatch,
      type,
      actions,
      tableProps.api,
      tableProps.filterToQuery,
    ],
  );

  const itemCount = list.length + Object.keys(creates).length;

  return (
    <TableContext.Provider value={context}>
      <Stack
        sx={[
          {
            flex: '1',
            overflowX: 'auto',
            overflowY: 'hidden',
          },
          scrollbarHorizontallStyle,
        ]}
      >
        {/*Create our headers based on colunbsettigs*/}
        <TableHeader
          columns={extendedColumns}
          rowStyle={rowStyle}
          sub={tableProps.sub}
          minWidth={tableProps.minWidth}
        />
        {/*When data is loading a circular indicator is visible*/}
        {isLoading && <LoadingBox />}
        {/* If loading is finished an no data is found display message*/}
        {!isLoading && rows.length === 0 && <EmptyRowsBox />}
        <Stack
          sx={[
            {
              flex: '1',
              minWidth: tableProps.minWidth,
              overflowX: 'hidden',
              overflowY: 'auto',
            },
            scrollbarVerticalStyle,
          ]}
        >
          <AutoSizer>
            {({ width, height }: any) => (
              // Breaks large data to smaller chunks. Data is loaded just-in-time while you are scrolling
              <InfiniteLoader
                itemCount={Number.POSITIVE_INFINITY} //Number of rows in list; can be arbitrary high number if actual number is unknown.
                minimumBatchSize={BATCH_SIZE} //Minimum number of rows to be loaded at a time; defaults to 10.
                threshold={TRIGGER_THRESHOLD} //Threshold at which to pre-fetch data; defaults to 15.
                isItemLoaded={index => index < itemCount} //tracking the loaded state of each item.
                loadMoreItems={triggerLoad} // Callback to be invoked when more rows must be loaded
              >
                {({ onItemsRendered, ref }) => (
                  <VariableSizeList
                    className="infinite-table scrollbar-style"
                    style={{
                      flex: 1,
                      overflowX: 'hidden',
                      overflowY: 'scroll',
                    }}
                    ref={list => {
                      ref(list);
                      if (passRef) {
                        passRef.current = list;
                      }
                    }}
                    width={width}
                    height={height}
                    itemCount={itemCount}
                    itemSize={getRowSize}
                    onItemsRendered={onItemsRendered}
                    overscanCount={20}
                  >
                    {renderRow}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Stack>
      </Stack>
    </TableContext.Provider>
  );
}
