import { deltaToPayload } from '../../api/events';
import api from '../../../services/APIService';
import { save } from '../../api/dataProcesser';
import { DataType } from '../../reducers/dataSlice';
import { getIdFromKey, keyHasType } from '../../../models/records';
import { BatchCall } from '../../api/common';

const createPostRequest = (creates: any): BatchCall => {
  const events = Object.values(creates).filter(
    (create: any) => create.type === 'event',
  );
  const payload = events.map((event: any) => deltaToPayload(event.id, event));
  const headers = {
    'content-type': 'application/json',
  };

  return {
    payloads: payload,
    apiCall: ({ _id, league_id, season_id, ...body }: any) =>
      api.post(`/events/league/${league_id}`, body, headers),
  };
};

const createPutRequest = (changes: any, items: any): BatchCall => {
  const payload = Object.keys(changes)
    .filter(key => keyHasType(key, 'event'))
    .filter(key => !changes[key].isDeleted)
    .map(key => {
      const id = getIdFromKey('event', key);
      return deltaToPayload(id, changes[key], items);
    })
    .filter(Boolean);

  return {
    payloads: payload,
    apiCall: ({ _id, ...payload }: any) => api.put(`/events/${_id}`, payload),
  };
};

const createDeleteRequest = (changes: any): BatchCall => {
  const payload = Object.keys(changes)
    .filter(key => keyHasType(key, 'event'))
    .filter(key => !!changes[key].deleted_time)
    .map(key => getIdFromKey('event', key));

  return {
    payloads: payload,
    apiCall: (id: any) => api.delete(`/events/${id}`),
  };
};

export const onSave = async (
  creates: any,
  changes: any,
  dispatch: any,
  items: any,
) => {
  const postReq = createPostRequest(creates);
  const putReq = createPutRequest(changes, items);
  const delReq = createDeleteRequest(changes);
  const commits: any = await save({
    postReq,
    putReq,
    delReq,
    type: DataType.Event,
    dispatch,
  });

  return commits;
};
