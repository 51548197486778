import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  buildFilterFromCriteria,
  filterToCriteria,
} from '../../../models/filters';
import { FilterCriteria } from './FilterCriteria';
import { SaveFilter } from './SaveFilter';
import { TypeFilters, getAllowedPresetFilters } from './index';

interface PresetFilterEditorProps {
  typedPreset: any;
  onSave(x: any): any;
  tab: string;
}

export function PresetFilterEditor({
  typedPreset,
  onSave,
  tab,
}: PresetFilterEditorProps) {
  const type = typedPreset.type === 'global' ? 'event' : typedPreset.type;
  const [name, setName] = useState(typedPreset.name);
  const allowFields: any = getAllowedPresetFilters(tab, typedPreset.type);

  const [criteria, setCriteria] = useState(
    filterToCriteria(typedPreset.filter, allowFields),
  );
  const editFilter = useMemo(
    () => buildFilterFromCriteria(criteria, allowFields),
    [criteria, allowFields],
  );

  return (
    <Stack gap={1} sx={{ width: '480px', padding: 1 }}>
      <FilterCriteria
        criteria={criteria}
        allowFields={allowFields}
        presetType={type}
        onChange={setCriteria}
      />
      <SaveFilter
        typedPreset={{ ...typedPreset, filter: editFilter }}
        onSave={onSave}
        name={name}
        setName={setName}
      />
    </Stack>
  );
}
