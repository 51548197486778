import { TextField } from '@mui/material';
import { useState } from 'react';
import { unionBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { getSlice, updateDetails } from '../../../../reducers/leaguesSlice';

const SeasonNameInput = ({ id, value }: any) => {
  const [enteredName, setEnteredName] = useState(value);
  const { details } = useAppSelector(getSlice);
  const dispatch = useAppDispatch();

  const handleEnteredInput = (event: any) => {
    const currentChanges = details.changes.seasons || [];
    const existedSeasons = details.data.seasons || [];
    const currentSeasons = unionBy(currentChanges, existedSeasons, 'id');
    const newSeasons = currentSeasons.map((season: any) => {
      if (season.id === id) {
        return {
          ...season,
          name: event.target.value,
        };
      }
      return season;
    });

    dispatch(updateDetails({ seasons: newSeasons }));
    setEnteredName(event.target.value);
  };

  return (
    <TextField
      id="season-name"
      name="season-name"
      variant="standard"
      value={enteredName}
      onChange={handleEnteredInput}
    />
  );
};

export default SeasonNameInput;
