import { CellProps } from '..';
import {
  extractDate,
  extractTime,
  parseTimeInput,
  utcTimestampToLocalized,
  utcTimestampToLocalizedTime,
} from '../../../../models/dates';
import { BaseItem } from '../../../reducers/dataSlice';
import { TextCell } from './TextCell';

export function unRender(newTime: any, oldDateTime: any) {
  const date = extractDate(utcTimestampToLocalized(oldDateTime));
  const time = parseTimeInput(newTime);
  return time ? `${date}T${time}` : date;
}

export function render(timestamp: any) {
  if (!extractTime(timestamp)) return '-';
  return utcTimestampToLocalizedTime(timestamp);
}

export function TimeCell<T extends BaseItem>({
  column,
  item,
  editable,
}: CellProps<T>) {
  return (
    <TextCell
      column={column}
      item={item}
      editable={editable}
      unRender={(newInput: any, oldInput: any) => unRender(newInput, oldInput)}
      render={(value: string) => render(value)}
    />
  );
}
