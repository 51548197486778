import styled from '@emotion/styled';
import { ContentCopy, Public } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { Stack, maxWidth, width } from '@mui/system';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useContext, useMemo } from 'react';
import { staticService } from '../../../../services/StaticService';
import { EditCell } from '../../../components/EditCell';
import { CellProps } from '../../../components/InfiniteTable';
import { TableContext } from '../../../components/InfiniteTable/InfiniteTable';
import { BaseItem } from '../../../reducers/dataSlice';
import { useAppDispatch } from '../../../reducers/hooks';
import { EditAudio } from './EditAudio';
import { getAudioList } from './ProductionAudio';

import { abbreviateName } from '../../../../models/strings';
import { talentCountryCell, talentTableCell } from './style';

import Roles from '../../../../models/Roles';
import { getKeyFromId } from '../../../../models/records';
import { authService } from '../../../../services/AuthService';
import { ValueCell } from '../../../components/InfiniteTable/cells';
import { EditCommentators } from './EditCommentators';

function useProductionAudio(data: any) {
  const audio = data.audio_layout;
  const layouts = staticService.data.audioLayouts.byId;
  const result = useMemo(() => {
    const audios = getAudioList(audio);
    return audios
      .map((layoutId: any) => layouts[layoutId]?.name)
      .filter(Boolean)
      .map((value: any) => (
        <span key={value + nanoid()} data-value={value}>
          {value.substring(0, 3)}
        </span>
      ));
  }, [audio, layouts]);
  return result;
}

export function ProductionAudio<T extends BaseItem>({
  column,
  item,
}: CellProps<T>) {
  const dispatch = useAppDispatch();
  const { update, changes, refreshed } = useContext(TableContext);
  const displayValue = useProductionAudio(item);
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.PRODUCTION_EDITOR]);

  const key = getKeyFromId(item.type, item._id);
  const refreshedColumns = (refreshed && refreshed[key]) || [];

  if (!column.editable || item.isDeleted || readOnly) {
    return (
      <div className={column.style}>
        <Stack
          className="edit-cell"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
          tabIndex={0}
        >
          <div className="-tags">{displayValue || '-'}</div>
        </Stack>
      </div>
    );
  } else {
    return (
      <EditCell
        popout
        field="audio"
        className={clsx(
          column.style,
          changes.audio_layout && '-changed',
          refreshedColumns.audio_layout && 'blinking',
        )}
        id={item._id}
        value={item.audio_layout}
        displayValue={<div className="-tags">{displayValue || '-'}</div>}
        initValue={() => item.audio_layout}
        renderEditor={(_: any, { onCommit }: any) => (
          <EditAudio data={item} onCommit={onCommit} />
        )}
        onChange={(input: any) =>
          dispatch(
            update({
              type: item.type,
              _id: item._id,
              delta: { audio_layout: input || [] },
            }),
          )
        }
      />
    );
  }
}

export function useProductionCommentators(item: BaseItem) {
  return useMemo(() => {
    return item.commentators
      ? item.commentators
          .filter(Boolean)
          .map((c: any) => staticService.data.commentators.byId[c.id]?.name)
          .map(abbreviateName)
          .join(', ')
      : '';
  }, [item]);
}

export const tooltipStyles = {
  tooltip: {
    backgroundColor: '#929495',
  },
};

const ColorButton = styled(Button)(() => ({
  color: 'black',
  backgroundColor: '#EBEEF0',
  padding: 0,
  margin: 0,
  height: 20,
  fontSize: 9,
  fontWeight: 'bold',
  wordWrap: 'break-word',
  whiteSpace: 'break-spaces',
  '&:hover': {
    backgroundColor: 'grey',
    color: 'white',
  },
}));

export function useFullProductionCommentators(data: any) {
  return useMemo(() => {
    const commentators = !data.commentators
      ? []
      : [
          ...data.commentators.map((c: any) => ({
            id: c.id,
            name: staticService.data.commentators.byId[c.id]?.name || '-',
            country: staticService.data.commentators.byId[c.id]?.country || '-',
            skill:
              staticService.data.skills.all
                .filter((l: any) => l.id === c.skill)
                .map((l: any) => l.name)
                .join(', ') || '-',
            location:
              staticService.data.locations.all.find(
                (l: any) => l.id === c.location,
              )?.name || '-',
          })),
        ];

    const handleCopy = async (e: any, name: any) => {
      e.stopPropagation();
      try {
        await navigator.clipboard.writeText(name);
      } catch (error) {
        console.warn('Copy failed', error);
      }
    };

    const handleCopyAll = async (e: any) => {
      e.stopPropagation();
      try {
        const names = commentators
          .map(commentator => commentator.name)
          .join(' & ');
        await navigator.clipboard.writeText(names);
      } catch (error) {
        console.warn('Copy failed', error);
      }
    };
    return (
      <Table sx={{ width: 150 }}>
        <TableHead sx={{ height: 20 }}>
          <TableRow>
            <TableCell sx={talentTableCell}>
              <Public />
            </TableCell>
            <TableCell sx={talentTableCell}>Name</TableCell>
            <TableCell sx={talentTableCell}>Skill</TableCell>
            <TableCell sx={talentTableCell}>Location</TableCell>
            <TableCell>
              <ColorButton size="small" onClick={(e: any) => handleCopyAll(e)}>
                Copy
              </ColorButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {commentators?.map(c => (
            <TableRow key={`${c.id}-${nanoid()}`}>
              <TableCell sx={talentCountryCell}>{c.country}</TableCell>
              <TableCell sx={talentTableCell}>{c.name}</TableCell>
              <TableCell sx={talentTableCell}>{c.skill}</TableCell>
              <TableCell sx={talentTableCell}>{c.location}</TableCell>
              <TableCell sx={talentCountryCell}>
                <IconButton
                  sx={{ color: 'white' }}
                  onClick={e => handleCopy(e, c.name)}
                >
                  <ContentCopy />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [data]);
}

export function ProductionCommentators<T extends BaseItem>({
  column,
  item,
}: CellProps<T>) {
  const dispatch = useAppDispatch();
  const { update, changes, refreshed } = useContext(TableContext);
  const displayValue = useProductionCommentators(item);
  const title = useFullProductionCommentators(item);
  const key = getKeyFromId(item.type, item._id);
  const refreshedColumns = (refreshed && refreshed[key]) || [];

  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.PRODUCTION_EDITOR]);

  if (!column.editable || item.isDeleted || readOnly) {
    return (
      <ValueCell
        value={
          <Tooltip
            placement="left"
            title={title || ''}
            sx={tooltipStyles.tooltip}
          >
            <span>{displayValue}</span>
          </Tooltip>
        }
        style={column.style}
        property={column.key}
        refreshed={refreshedColumns}
      />
    );
  } else {
    return (
      <EditCell
        popout
        field="commentator"
        className={clsx(
          column.style,
          changes.commentators && '-changed',
          refreshedColumns.commentators && 'blinking',
        )}
        id={item._id}
        value={item.commentators}
        displayValue={
          <Tooltip
            placement="bottom-start"
            title={title || ''}
            sx={tooltipStyles.tooltip}
          >
            <span>{displayValue}</span>
          </Tooltip>
        }
        initValue={() => item.commentators}
        renderEditor={(input: any, { onCommit }: any) => (
          <EditCommentators
            country={item.country}
            commentators={input}
            onCommit={onCommit}
          />
        )}
        onChange={input =>
          dispatch(
            update({
              type: item.type,
              _id: item._id,
              delta: { commentators: input || [] },
            }),
          )
        }
      />
    );
  }
}
