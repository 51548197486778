import { Delete, RestoreFromTrash } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { JSDateToUTC } from '../../../models/dates';
import { BaseItem } from '../../reducers/dataSlice';
import { useAppDispatch } from '../../reducers/hooks';
import { TableContext } from '../InfiniteTable/InfiniteTable';

interface DeleteIconprops {
  item: BaseItem;
}

export default function DeleteIcon({ item }: DeleteIconprops) {
  const dispatch = useAppDispatch();
  const { update } = useContext(TableContext);
  const isDeleted = !!item.isDeleted;

  return (
    <IconButton
      color="default"
      size="small"
      aria-label={isDeleted ? 'restore' : 'delete'}
      onClick={() =>
        dispatch(
          update({
            type: item.type,
            _id: item._id,
            delta: {
              deleted_time: isDeleted ? undefined : JSDateToUTC(new Date()),
              isDeleted: true,
            },
          }),
        )
      }
    >
      <Delete />
    </IconButton>
  );
}
