import {
  AccessTime,
  AdminPanelSettings,
  CalendarToday,
  DateRange,
  Description,
  DynamicForm,
  EmojiEvents,
  Event,
  Feed,
  HdrStrong,
  Language,
  LiveTv,
  Loop,
  Numbers,
  OndemandVideo,
  People,
  PlayCircleFilled,
  SatelliteAlt,
  Scoreboard,
  SpatialTracking,
  SportsHandball,
  SportsScore,
  StopCircle,
  Stream,
  Timelapse,
  Title,
  TransferWithinAStation,
} from '@mui/icons-material';
import { DataType } from '../../../reducers/dataSlice';

export const DEFAULT_EVENT_COLUMN_SETTINGS = [
  { id: 'id', header: 'Id', required: false, hidden: false, icon: <Numbers /> },
  {
    id: 'week',
    header: 'Week',
    required: false,
    hidden: false,
    icon: <DateRange />,
  },
  {
    id: 'day',
    header: 'Day',
    required: false,
    hidden: false,
    icon: <CalendarToday />,
  },
  {
    id: 'date',
    header: 'Date',
    required: true,
    hidden: false,
    icon: <Event />,
  },
  {
    id: 'start',
    header: 'Start',
    required: true,
    hidden: false,
    icon: <AccessTime />,
  },
  {
    id: 'sport',
    header: 'Sport',
    required: true,
    hidden: false,
    icon: <SportsHandball />,
  },
  {
    id: 'league',
    header: 'League',
    required: true,
    hidden: false,
    icon: <Scoreboard />,
  },
  {
    id: 'title',
    header: 'Event',
    required: true,
    hidden: false,
    icon: <Title />,
  },
  {
    id: 'stage',
    header: 'Stage',
    required: false,
    hidden: false,
    icon: <SportsScore />,
  },
  {
    id: 'round',
    header: 'Round',
    required: false,
    hidden: false,
    icon: <Loop />,
  },
  {
    id: 'audio',
    header: 'Audio',
    required: false,
    hidden: false,
    icon: <SpatialTracking />,
  },
  {
    id: 'dk',
    header: 'Denmark',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/dk.png',
  },
  {
    id: 'ee',
    header: 'Estonia',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/ee.png',
  },
  {
    id: 'fi',
    header: 'Finland',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/fi.png',
  },
  {
    id: 'is',
    header: 'Iceland',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/is.png',
  },
  {
    id: 'lv',
    header: 'Latvia',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/lv.png',
  },
  {
    id: 'lt',
    header: 'Lithuania',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/lt.png',
  },
  {
    id: 'nl',
    header: 'Netherlands',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/nl.png',
  },
  {
    id: 'no',
    header: 'Norway',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/no.png',
  },
  {
    id: 'pl',
    header: 'Poland',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/pl.png',
  },
  {
    id: 'se',
    header: 'Sweden',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/se.png',
  },
  {
    id: 'uk',
    header: 'United Kingdom',
    required: false,
    hidden: false,
    src: 'https://flagcdn.com/w20/gb.png',
  },
  {
    id: 'admin',
    header: 'Admin',
    required: true,
    hidden: false,
    icon: <AdminPanelSettings />,
  },
];

export const DEFAULT_FEED_COLUMN_SETTINGS = [
  { id: 'id', header: 'Id', required: false, hidden: false, icon: <Numbers /> },
  {
    id: 'week',
    header: 'Week',
    required: false,
    hidden: false,
    icon: <DateRange />,
  },
  {
    id: 'day',
    header: 'Day',
    required: false,
    hidden: false,
    icon: <CalendarToday />,
  },
  {
    id: 'date',
    header: 'Date',
    required: true,
    hidden: false,
    icon: <Event />,
  },
  {
    id: 'start',
    header: 'Start',
    required: true,
    hidden: false,
    icon: <PlayCircleFilled />,
  },
  {
    id: 'end',
    header: 'End',
    required: true,
    hidden: false,
    icon: <StopCircle />,
  },
  {
    id: 'duration',
    header: 'Duration',
    required: false,
    hidden: false,
    icon: <Timelapse />,
  },
  {
    id: 'type',
    header: 'Type',
    required: true,
    hidden: false,
    icon: <SatelliteAlt />,
  },
  {
    id: 'template',
    header: 'Template',
    required: false,
    hidden: false,
    icon: <Description />,
  },
  {
    id: 'sport',
    header: 'Sport',
    required: true,
    hidden: false,
    icon: <SportsHandball />,
  },
  {
    id: 'league',
    header: 'League',
    required: true,
    hidden: false,
    icon: <Scoreboard />,
  },
  {
    id: 'title',
    header: 'Title',
    required: true,
    hidden: false,
    icon: <Title />,
  },
  {
    id: 'rec',
    header: 'REC',
    required: false,
    hidden: false,
    icon: <HdrStrong />,
  },
  {
    id: 'mcr',
    header: 'MCR',
    required: false,
    hidden: false,
    icon: <HdrStrong />,
  },
  {
    id: 'admin',
    header: 'Admin',
    required: true,
    hidden: false,
    icon: <SpatialTracking />,
  },
];

export const DEFAULT_PRODUCTION_COLUMN_SETTINGS = [
  {
    id: 'id',
    header: 'Id',
    required: false,
    hidden: false,
    icon: <Numbers />,
  },
  {
    id: 'week',
    header: 'Week',
    required: false,
    hidden: false,
    icon: <DateRange />,
  },
  {
    id: 'day',
    header: 'Day',
    required: false,
    hidden: false,
    icon: <CalendarToday />,
  },
  {
    id: 'date',
    header: 'Date',
    required: true,
    hidden: false,
    icon: <Event />,
  },
  {
    id: 'start',
    header: 'Start',
    required: true,
    hidden: false,
    icon: <PlayCircleFilled />,
  },
  {
    id: 'end',
    header: 'End',
    required: true,
    hidden: false,
    icon: <StopCircle />,
  },
  {
    id: 'duration',
    header: 'Duration',
    required: false,
    hidden: false,
    icon: <Timelapse />,
  },
  {
    id: 'country',
    header: 'Country',
    required: true,
    hidden: false,
    icon: <Language />,
  },
  {
    id: 'run_type',
    header: 'Run Type',
    required: true,
    hidden: false,
    icon: <DynamicForm />,
  },
  {
    id: 'template',
    header: 'Template',
    required: false,
    hidden: false,
    icon: <Description />,
  },
  {
    id: 'sport',
    header: 'Sport',
    required: true,
    hidden: false,
    icon: <SportsHandball />,
  },
  {
    id: 'league',
    header: 'League',
    required: true,
    hidden: false,
    icon: <Scoreboard />,
  },
  {
    id: 'title',
    header: 'Title',
    required: true,
    hidden: false,
    icon: <Title />,
  },
  {
    id: 'channel',
    header: 'Channel',
    required: true,
    hidden: false,
    icon: <LiveTv />,
  },
  {
    id: 'stream',
    header: 'Viaplay STR',
    required: false,
    hidden: true,
    icon: <Stream />,
  },
  { id: 'audio', header: 'Audio', required: false, hidden: false },
  {
    id: 'takers',
    header: 'WSC',
    required: false,
    hidden: true,
    icon: <SatelliteAlt />,
  },
  {
    id: 'production_leader',
    header: 'Producton Leader',
    required: true,
    hidden: false,
    icon: <TransferWithinAStation />,
  },
  {
    id: 'editorial',
    header: 'Editorial',
    required: false,
    hidden: false,
    icon: <People />,
  },
  {
    id: 'admin',
    header: 'Admin',
    required: true,
    hidden: false,
    icon: <SpatialTracking />,
  },
];

interface COLUMN {
  [key: string]: any;
}

export const getDefaultSetting = (full?: boolean) => {
  const DEFAULT_PREFERENCE = [
    {
      table: DataType.Event,
      columns: full
        ? DEFAULT_EVENT_COLUMN_SETTINGS
        : DEFAULT_EVENT_COLUMN_SETTINGS.map((column: any) => ({
            id: column.id,
            hidden: column.hidden,
          })),
      subTables: [],
    },
    {
      table: DataType.Feed,
      columns: full
        ? DEFAULT_FEED_COLUMN_SETTINGS
        : DEFAULT_FEED_COLUMN_SETTINGS.map((column: any) => ({
            id: column.id,
            hidden: column.hidden,
          })),
      subTables: [],
    },
    {
      table: DataType.Production,
      columns: full
        ? DEFAULT_PRODUCTION_COLUMN_SETTINGS
        : DEFAULT_PRODUCTION_COLUMN_SETTINGS.map((column: any) => ({
            id: column.id,
            hidden: column.hidden,
          })),
      subTables: [],
    },
    {
      table: DataType.Workspace,
      columns: full
        ? DEFAULT_EVENT_COLUMN_SETTINGS
        : DEFAULT_EVENT_COLUMN_SETTINGS.map((column: any) => ({
            id: column.id,
            hidden: column.hidden,
          })),
      subTables: [
        {
          table: DataType.Feed,
          columns: full
            ? DEFAULT_FEED_COLUMN_SETTINGS
            : DEFAULT_FEED_COLUMN_SETTINGS.map((column: any) => ({
                id: column.id,
                hidden: column.hidden,
              })),
        },
        {
          table: DataType.Production,
          columns: full
            ? DEFAULT_PRODUCTION_COLUMN_SETTINGS
            : DEFAULT_PRODUCTION_COLUMN_SETTINGS.map((column: any) => ({
                id: column.id,
                hidden: column.hidden,
              })),
        },
      ],
    },
  ];
  return DEFAULT_PREFERENCE;
};

/**
 *
 * @param userSettings the users current saved settings
 * @param type (feed, production, workspace, event)
 * @returns combinationsettings based on usersettings or default settings
 */
export function getMergeSettings(
  userSettings: any,
  table: string,
  defaultSettings: any,
) {
  let mergedSettings: any = {
    table,
    subTables: [],
  };
  const { columns: defaultMainColumns, subTables: defaultSubTables } =
    defaultSettings.find((setting: any) => setting.table === table);
  const { columns: userColumns, subTables: userSubTables } = userSettings.find(
    (setting: any) => setting.table === table,
  );

  if (defaultMainColumns && defaultMainColumns.length > 0) {
    const { mergedColumns, usingDefaultSetting } = getMergedColumns(
      defaultMainColumns,
      userColumns,
    );

    mergedSettings = {
      ...mergedSettings,
      usingDefaultTableSettings: usingDefaultSetting,
      columns: mergedColumns,
    };
  }

  if (defaultSubTables && defaultSubTables.length > 0) {
    const mergedSubColumns: any = [];
    defaultSubTables.forEach((defaultSubTable: any) => {
      const { columns: defaultColumns } = defaultSubTable;
      const { columns: userSubColumns } = userSubTables.find(
        (userSubColumn: any) => userSubColumn.table === defaultSubTable.table,
      );
      const { mergedColumns, usingDefaultSetting } = getMergedColumns(
        defaultColumns,
        userSubColumns,
      );
      mergedSubColumns.push({
        table: defaultSubTable.table,
        usingDefaultTableSettings: usingDefaultSetting,
        columns: mergedColumns,
      });
    });
    mergedSettings = {
      ...mergedSettings,
      subTables: [...mergedSubColumns],
    };
  }

  return mergedSettings;
}

export const getMergedColumns = (
  defaultColumns: any[],
  currentColumns: any[],
) => {
  const subColumns: any = [];
  const usingDefaultSubHiddenSettings: boolean[] = [];
  defaultColumns.forEach((subColumn: any) => {
    const userSubColumn = currentColumns.find(
      (userColumn: any) => userColumn.id === subColumn.id,
    );
    let isHidden = subColumn.hidden;
    if (
      !userSubColumn ||
      (userSubColumn && userSubColumn.hidden === subColumn.hidden)
    ) {
      usingDefaultSubHiddenSettings.push(true);
    } else {
      usingDefaultSubHiddenSettings.push(false);
      isHidden = userSubColumn.hidden;
    }

    subColumns.push({
      ...subColumn,
      hidden: isHidden,
    });
  });
  return {
    mergedColumns: subColumns,
    usingDefaultSetting: !usingDefaultSubHiddenSettings.includes(false),
  };
};

interface SettingsProps {
  table: string;
  subTable?: string;
  userSettings: any;
}

export function getSettingsByType({
  table,
  subTable,
  userSettings,
}: SettingsProps) {
  const { columns: mainColumns, subTables: mainSubTales } = userSettings.find(
    (setting: any) => setting.table === table,
  );

  if (subTable) {
    const { columns: subColumns } = mainSubTales.find(
      (mainSubTable: any) => mainSubTable.table === subTable,
    );
    return subColumns;
  }
  return mainColumns;
}

export const TAB_TYPE_PROPS: any = {
  event: { header: 'EVENT', icon: <EmojiEvents htmlColor="#FC9403" /> },
  feed: { header: 'FEED', icon: <Feed htmlColor="#0D559C" /> },
  production: {
    header: 'PRODUCTION',
    icon: <OndemandVideo htmlColor="#AC5DBD" />,
  },
  workspace: { header: 'EVENT', icon: <EmojiEvents htmlColor="#FC9403" /> },
};
