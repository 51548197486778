import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { staticService } from '../../../../../services/StaticService';

interface LeagueProviderProps {
  disabled: boolean;
  league: any;
  onChange: any;
}

const LeagueProvider = ({
  disabled,
  league,
  onChange,
}: LeagueProviderProps) => {
  const providers = staticService.data.providers.all.concat({
    id: '',
    name: 'N/A',
  });

  const [enteredLeagueProvider, setEnteredLeagueProvider] = useState(
    league?.mapping?.providerLeagueId,
  );
  const [providerName, setProviderName] = useState('');

  let savedProvider = '';
  if (league?.mapping?.providerId) {
    const provider = providers.find(
      (provider: any) => Number(league.mapping.providerId) === provider.id,
    );
    if (provider) {
      savedProvider = provider.name;
    }
  }

  const handleEnteredProvider = (event: any) => {
    const newProviderSourceId = event.target.value;
    setEnteredLeagueProvider(newProviderSourceId);
    let mapping: any = {
      ...league.mapping,
      providerLeagueId: newProviderSourceId,
      providerId: league.mapping.providerId || '',
    };
    if (league.mapping.providerId && !newProviderSourceId) {
      mapping = {
        id: league.mapping.id,
        deleted: true,
        providerId: '',
        providerLeagueId: '',
      };
    }

    onChange({
      mapping,
    });
  };

  const handleProvider = (event: any) => {
    const provider = providers.find(
      (provider: any) => provider.name === event.target.value,
    );
    setProviderName(provider.name);
    onChange({
      mapping: {
        providerLeagueId: league.mapping.providerLeagueId || '',
        providerId: provider.id,
      },
    });
  };
  return (
    <Stack direction="row" gap={10} alignItems="center" flexWrap="wrap">
      <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
        <InputLabel id="provider">Provider</InputLabel>
        <Select
          value={providerName || savedProvider}
          disabled={
            league.manuallySeasonChecked || !league.providerChecked || disabled
          }
          onChange={handleProvider}
          name="provider"
        >
          {providers.map((provider: any) => (
            <MenuItem key={provider.id} value={provider.name}>
              {provider.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="provider-league-id"
        name="provider-league-id"
        label="Provider League Id"
        variant="standard"
        sx={{ width: '200px' }}
        value={enteredLeagueProvider}
        onChange={handleEnteredProvider}
        disabled={
          league.manuallySeasonChecked || !league.providerChecked || disabled
        }
      />
    </Stack>
  );
};

export default LeagueProvider;
