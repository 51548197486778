import { deltaToFeedPayload } from '../../api/feeds';
import api from '../../../services/APIService';
import { getIdFromKey, keyHasType } from '../../../models/records';
import { DataType } from '../../reducers/dataSlice';
import { save } from '../../api/dataProcesser';
import { BatchCall } from '../../api/common';

const createPostRequest = (creates: any): BatchCall => {
  const feeds = Object.values(creates).filter(
    (create: any) => create.type === 'feed',
  );
  const payload = feeds.map((feed: any) =>
    deltaToFeedPayload(feed.feed_id, feed),
  );

  return {
    payloads: payload,
    apiCall: (payload: any) => api.post('/feeds', payload),
    getId: (item: any) => item.feed_id,
  };
};

const createPutRequest = (changes: any): BatchCall => {
  const payload = Object.keys(changes)
    .filter(key => keyHasType(key, 'feed'))
    .filter(key => !changes[key].isDeleted)
    .map(key => {
      const id = getIdFromKey('feed', key);
      return deltaToFeedPayload(id, changes[key]);
    })
    .filter(Boolean);

  return {
    payloads: payload,
    apiCall: ({ _id, ...payload }: any) => api.put(`/feeds/${_id}`, payload),
    getId: (item: any) => item.feed_id,
  };
};

const createDeleteRequest = (changes: any) => {
  const payload = Object.keys(changes)
    .filter(key => keyHasType(key, 'feed'))
    .filter(key => !!changes[key].deleted_time)
    .map(key => getIdFromKey('feed', key));

  return {
    payloads: payload,
    apiCall: (id: any) => api.delete(`/feeds/${id}`),
    getId: (item: any) => item.feed_id,
  };
};

export const onSave = async (creates: any, changes: any, dispatch: any) => {
  const postReq = createPostRequest(creates);
  const putReq = createPutRequest(changes);
  const delReq = createDeleteRequest(changes);
  const commits: any = await save({
    postReq,
    putReq,
    delReq,
    type: DataType.Feed,
    dispatch,
  });

  return commits;
};
