const ADMIN = 'admin';
const PRODUCTION_VIEWER = 'production_viewer';
const PRODUCTION_EDITOR = 'production_editor';
const PRODUCTION_CREATOR = 'production_creator';
const FEED_VIEWER = 'feed_viewer';
const FEED_EDITOR = 'feed_editor';
const FEED_CREATOR = 'feed_creator';
const LEAGUE_VIEWER = 'league_viewer';
const LEAGUE_EDITOR = 'league_editor';
const LEAGUE_CREATOR = 'league_creator';
const EVENT_VIEWER = 'event_viewer';
const EVENT_EDITOR = 'event_editor';
const EVENT_CREATOR = 'event_creator';
const USER_ADMIN = 'user_admin';
const TRAFFIC = 'traffic';

const Roles = {
  ADMIN,
  PRODUCTION_VIEWER,
  PRODUCTION_EDITOR,
  PRODUCTION_CREATOR,
  FEED_EDITOR,
  FEED_CREATOR,
  FEED_VIEWER,
  TRAFFIC,
  LEAGUE_EDITOR,
  LEAGUE_CREATOR,
  LEAGUE_VIEWER,
  EVENT_EDITOR,
  EVENT_CREATOR,
  EVENT_VIEWER,
  USER_ADMIN,
};

export default Roles;
