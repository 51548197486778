import { color } from '@mui/system';

export const inboxContent = {
  overflow: 'auto',
  maxHeight: '90vh',
};

export const actionContent = { display: 'flex', marginTop: 3, marginLeft: 2 };

export const headerContent = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const titleContent = {
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
};

export const dateIconContent = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
};

export const bodyContent = {
  display: 'flex',
  flexDirection: 'column',
};

export const inboxStatusContent = { display: 'flex', alignItems: 'self-start' };
