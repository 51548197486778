import { InputAdornment, Stack, TextField } from '@mui/material';
import * as icons from '@mui/icons-material';
import { useAppDispatch } from '../../../reducers/hooks';
import { update } from '../../../reducers/userSlice';
import { inputPropsStack } from '../style';

export interface AccountField {
  id: string;
  label: string;
  icon?: keyof typeof icons;
  value?: any;
  disabled?: boolean;
  extraInputProps?: any;
}

export function AccountTextField({
  id,
  label,
  icon,
  value,
  disabled,
  extraInputProps,
}: AccountField) {
  const dispatch = useAppDispatch();

  // ! Non-null assertion operator
  const Icon = icons[icon!];

  return (
    <TextField
      id={id}
      label={label}
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Stack sx={inputPropsStack}>
              <Icon />
              {extraInputProps}
            </Stack>
          </InputAdornment>
        ),
      }}
      value={value}
      disabled={disabled}
      onChange={e => {
        dispatch(
          update({
            type: 'account',
            id,
            delta: e.target.value,
          }),
        );
      }}
    />
  );
}
