import { isEmpty } from 'lodash';
import { BaseItem, ErrorMessage } from '../app/reducers/dataSlice';
import { createNewId, validateIfEmpty } from './records';
import { DEFAULT_START_OFFSET } from './productions';

export function createNewLeague() {
  return {
    _id: createNewId(),
    name: '',
    shortName: '',
    abbreviationName: '',
    sportType: '',
    ruleSet: '',
    rights: [],
    seasons: [],
    mapping: {
      providerId: '',
      providerLeagueId: '',
    },
    default_start_offset: DEFAULT_START_OFFSET,
    disabled: false,
    deletedSeasons: [],
    audio: [{ language: 'ENG', available: 'UN' }],
    manuallySeasonChecked: false,
    providerChecked: false,
  };
}

export function filterLeagues(filter: string, leagues: any) {
  const filteredLeagues = filter
    ? leagues.filter(
        (league: any) =>
          league.id.toString().includes(filter?.toLowerCase()) ||
          league.name?.toLowerCase().includes(filter?.toLowerCase()) ||
          league.sportType?.toLowerCase().includes(filter?.toLowerCase()) ||
          (league.abbreviationName &&
            league.abbreviationName
              ?.toLowerCase()
              .includes(filter?.toLowerCase())),
      )
    : leagues;
  return filteredLeagues;
}

export function validateLeague(
  leagueData: BaseItem,
  items?: any,
): ErrorMessage[] {
  return [
    validateIfEmpty(leagueData, 'name'),
    validateIfEmpty(leagueData, 'shortName'),
    validateAbbreviation(leagueData, 'abbreviationName', items),
    validateIfEmpty(leagueData, 'sportType'),
    validateIfEmpty(leagueData, 'ruleSet'),
    validateRights(leagueData, 'rights'),
    validateSeasons(leagueData, 'seasons'),
    validateMapping(leagueData, 'mapping'),
  ] as ErrorMessage[];
}

function validateRights(data: any, field: string) {
  if (data[field] && data[field].length > 0) {
    const errors: any = [];
    data[field].forEach((right: any) => {
      const keys = Object.keys(right);
      const emptyFields: any = [];
      keys.forEach((rightField: string) => {
        emptyFields.push(validateIfEmpty(right, rightField));
      });
      const messages = emptyFields.filter(Boolean);
      if (messages.length > 0) {
        errors.push({ field: right.id, message: messages });
      }
    });
    if (errors.length > 0) {
      return { field: 'rights', message: errors };
    }
  }
  return false;
}

function validateSeasons(data: any, field: string) {
  if (data[field] && data[field].length > 0) {
    const errors: any = [];
    data[field].forEach((season: any) => {
      const keys = Object.keys(season);
      const emptyFields: any = [];
      keys.forEach((seasonField: string) => {
        if (
          seasonField !== 'manuallyChanged' &&
          seasonField !== 'dateChanged' &&
          seasonField !== 'sourceId'
        ) {
          emptyFields.push(validateIfEmpty(season, seasonField));
        }
      });
      const messages = emptyFields.filter(Boolean);
      if (messages.length > 0) {
        errors.push({ field: season.id, message: messages });
      }
    });
    if (errors.length > 0) {
      return { field: 'seasons', message: errors };
    }
  }
  return false;
}

function validateAbbreviation(data: any, field: string, items: any) {
  const abbreviations = items.map((item: any) => item.abbreviationName);
  if (data[field]) {
    const duplicate = abbreviations.some(
      (abbreviation: any) =>
        String(abbreviation).toLowerCase() === data[field].toLowerCase(),
    );
    if (duplicate) {
      return {
        field: 'abbreviationName',
        message: 'Abbreviation name already exists!',
      };
    }
  }
  return validateIfEmpty(data, field);
}

function validateMapping(data: any, field: string) {
  if (
    data.providerChecked &&
    isEmpty(data[field].providerId) &&
    isEmpty(data[field].providerLeagueId)
  ) {
    return {
      field: 'mapping',
      message: 'Missing provider info',
    };
  }
  return false;
}
