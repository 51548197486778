import { get } from 'lodash';
import { useContext } from 'react';
import { CellProps } from '..';
import { getKeyFromId } from '../../../../models/records';
import { BaseItem } from '../../../reducers/dataSlice';
import { useAppDispatch } from '../../../reducers/hooks';
import { SelectCell } from '../../EditCell';
import { TableContext } from '../InfiniteTable';
import { getCellClasses } from '../utils';
import { DisplayValue } from '../../../api/common';
import { ValueCell } from './ValueCell';
import { TooltipBox } from '../../Tooltip/Tooltip';

export function DropdownCell<T extends BaseItem>({
  column,
  item,
  editable,
}: CellProps<T>) {
  const selectOptions = column.onGettingOptions
    ? column.onGettingOptions(item)
    : [];
  const value = get(item, column.key);
  const displayValue: DisplayValue =
    column.onGetDisplayValue &&
    column.onGetDisplayValue(selectOptions, value, column.style);
  const options = Object.values(selectOptions);
  const dispatch = useAppDispatch();
  const { update, changes, errors, refreshed } = useContext(TableContext);
  const key = getKeyFromId(item.type, item._id);
  const refreshedColumns = (refreshed && refreshed[key]) || [];
  if (!editable) {
    return (
      <ValueCell
        value={displayValue?.label}
        style={displayValue?.style || column.style}
        align={column.textAlign}
        refreshed={refreshedColumns}
        property={column.key}
      />
    );
  }

  return (
    <SelectCell
      field={column.key}
      className={getCellClasses({
        property: column.key,
        delta: changes[key] || [],
        errors: errors[key] || [],
        refreshed: refreshedColumns,
        other: [displayValue?.style || column.style],
      })}
      id={item._id}
      value={value}
      displayValue={<TooltipBox value={displayValue?.label} />}
      options={options}
      onChange={async (input: any) => {
        let deltaUpdate = { [column.key]: input };
        if (column.onBeforeChange) {
          deltaUpdate = await column.onBeforeChange(input, item);
        }
        dispatch(
          update({
            type: item.type,
            _id: item._id,
            delta: deltaUpdate,
          }),
        );
      }}
    />
  );
}
