import { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { staticService } from '../../../../services/StaticService';

export function LeagueSelect({ onChange, width }: any) {
  const leagueOptions = useMemo(
    () =>
      staticService.data.leagues.all.map((league: any) => ({
        value: league.id,
        label: `${staticService.getSportName(league.sportType)}: ${
          league.name
        }`,
      })),
    [],
  );

  return (
    <Autocomplete
      disablePortal
      id="league"
      options={leagueOptions}
      sx={{ width }}
      renderOption={(props: any, option: any) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      renderInput={params => <TextField {...params} label="League" />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, value) => onChange(value)}
    />
  );
}
