export const createLeagueHeader = {
  flexDirection: 'row',
  gap: 5,
  paddingLeft: '20px',
  flexWrap: 'wrap',
};

export const leageInfoField = {
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
};

export const createLeagueCard = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export const stack = {
  width: '95%',
  padding: '40px',
  maxHeight: '65vh',
  overflowY: 'scroll !important',
};

export const createLeagueCardContent = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 3,
};

export const createLeagueContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  height: '100%',
};

export const generarlInfoContent = {
  gap: 10,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

export const InputStyle = {
  display: 'flex',
  columnGap: '50px',
  alignItems: 'center',
};

export const card = {
  display: 'flex',
  flexDirection: 'column',
  columnGap: '20px',
  height: 'fit-content',
  width: '100%',
};

export const cardContent = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  height: 'fit-content',
};

export const rightsBox = {
  width: '100%',
  height: '100%',
  bgcolor: 'white',
};

export const cardHeaderTitle = {
  fontSize: 15,
};

export const table = {
  border: '1px solid #eee',
  maxHeight: '200px',
  overflow: 'scroll',
};

export const tableHeader = {
  border: '1px solid #eee',
  width: '20%',
  backgroundColor: '#EBEDEF',
  fontWeight: 600,
};

export const tableRow = {
  border: '1px solid #eee',
  width: '10%',
};

export const tableRowAlert = {
  border: '1px solid #eee',
  width: '10%',
  backgroundColor: '#fcc',
};

export const generalInfo = {
  width: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const sourceContent = {
  flexDirection: 'column',
  gap: 2,
};

export const providerContent = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  flexWrap: 'wrap',
};

export const sourceAudioStack = {
  width: '80%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const dateInputErrorText = {
  color: 'red',
};

export const gridContainer = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'auto',
};
