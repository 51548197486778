import {
  MarkEmailReadOutlined,
  MarkEmailUnread,
  PriorityHigh,
} from '@mui/icons-material';
import { Chip, ThemeProvider } from '@mui/material';
import { staticService } from '../../../services/StaticService';
import { themeCancelled, themeNew, themeOther, themeUpdated } from './theme';
import { NotificationItem } from '../../reducers/inAppNotificationSlice';
import {
  extractDate,
  extractTime,
  utcTimestampToLocalizedClean,
} from '../../../models/dates';

export const handleNotificationHref = (notification: NotificationItem) => {
  switch (notification.message_type) {
    case 'production':
      return `/productions?id=${notification.data.production_id}`;
    case 'feed':
      return `/feeds?id=${notification.data.feed_id}`;
    case 'event':
      return `/events?id=${notification.data.id}`;
    case 'league':
      return `/leagues?id=${notification.data.id}`;
    case 'placeholder':
      return `${process.env.REACT_APP_BOOKING_URL}/production/${notification.data.itemId}`;
    default:
      return '/';
  }
};

export const handleNotificationTitle = (notification: NotificationItem) => {
  switch (notification.message_type) {
    case 'production':
      return `(${notification.data.country}) - (${notification.data.channel_name}) - ${notification.data.title}`;
    case 'feed':
      return `${notification.data.title}`;
    case 'event':
      return `${notification.data.event}`;
    case 'league':
      return `${notification.data.name}`;
    case 'placeholder':
      return `${notification?.data?.title || ''}`;
    default:
      return '/';
  }
};

export const handleStartInfo = (notification: NotificationItem) => {
  switch (notification.message_type) {
    case 'production':
      return `${handleLeague(notification)} - ${
        notification.data.production_start
      }`;
    case 'feed':
      return `${handleLeague(notification)} - ${notification.data.feed_start}`;
    case 'event':
      return `${handleLeague(notification)} - ${notification.data.event_date} ${
        notification.data.event_time
      }`;
    case 'placeholder':
      return `${notification.data.itemType}: ${notification.data.itemId}`;
    case 'league':
      return 'not sure what to show here';
    default:
      return '/';
  }
};

export const handleUpdateTime = (notification: NotificationItem) => {
  return utcTimestampToLocalizedClean(notification.created_at);
};

export const handleAction = (
  notification: NotificationItem,
  statusLabelFontSize: number,
) => {
  let label = '';
  let colorTheme: any = themeOther;
  switch (notification.action) {
    case 'create':
      label = 'CREATED';
      colorTheme = themeNew;
      break;
    case 'update':
      label = 'UPDATED';
      colorTheme = themeUpdated;
      break;
    case 'delete':
      label = 'DELETED';
      colorTheme = themeCancelled;
      break;
    default:
      label = 'UNKNOWN';
      colorTheme = themeOther;
      break;
  }

  colorTheme.components.MuiChip.styleOverrides.label.fontSize =
    statusLabelFontSize;

  return (
    <ThemeProvider theme={colorTheme}>
      <Chip label={label} />
    </ThemeProvider>
  );
};

export const handleMessageType = (notification: NotificationItem) => {
  switch (notification.message_type) {
    case 'production':
      return 'Production';
    case 'event':
      return 'Event';
    case 'feed':
      return 'Feed';
    case 'league':
      return 'League';
    case 'placeholder':
      return 'Placeholder';
    default:
      return 'Unknown';
  }
};

export const handleLeague = (notification: NotificationItem) => {
  const league = staticService.data.leagues?.all.find(
    (league: any) => league.id === notification.data.league_id,
  );

  return league ? league.name : 'Unknown';
};

export const ICONS = [
  <MarkEmailUnread sx={{ color: '#73ACEE' }} />,
  <MarkEmailReadOutlined />,
  <PriorityHigh sx={{ color: 'red' }} />,
];
