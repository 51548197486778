import { utcTimestampToLocalizedClean } from '../../models/dates';
import { retrofitFeeds } from '../../models/feeds';
import { isTempId } from '../../models/records';
import api from '../../services/APIService';
import { filterToQueryParams } from '../../services/common';
import { DataType } from '../reducers/dataSlice';

export async function fetchFeeds(filter: any, offset?: any) {
  try {
    let pending: any = new AbortController();
    const query = filterToQueryParams(filter, DataType.Feed, offset);

    const { data, moreData } = await api.get(`/feeds?${query}`, pending);

    pending = null;

    return {
      feeds: retrofitFeeds(data),
      isFinished: !moreData,
    };
  } catch (e) {
    console.warn(e);
    return { feeds: [], isFinished: true };
  }
}

/* Function to handle the payload before the request is sent to the service */
export function deltaToFeedPayload(id: any, delta: any, item?: any) {
  if (Object.keys(delta).length === 1 && !!delta.delete_datetime) return null;

  let payload = { _id: id, ...delta, update_datetime: item?.update_datetime };
  if (isTempId(payload.feed_id)) delete payload.feed_id;

  if (payload.type) delete payload.type;

  if (payload.start_timestamp) {
    payload = {
      ...payload,
      feed_start: utcTimestampToLocalizedClean(payload.start_timestamp),
    };
    delete payload.start_timestamp;
  }

  if (payload.end_timestamp) {
    payload = {
      ...payload,
      feed_end: utcTimestampToLocalizedClean(payload.end_timestamp),
    };
    delete payload.end_timestamp;
  }

  if (payload.itemAction) delete payload.itemAction;

  if (!payload.delivery_type) delete payload.delivery_type;
  if (!payload.stream_equipment) delete payload.stream_equipment;
  if (Object.keys(payload.comments || {}).length === 0) delete payload.comments;

  return payload;
}
