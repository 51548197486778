import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import RenderOnAuthenticated from '../../container/Auth/RenderOnAuthenticated';
import NentIcon from '../Logo/NentIcon';
import { Notifications } from '../Notifications';
import { Settings } from '../Settings';
import { UserAuth } from '../User';
import NavTabs from './NavTabs';

export default function Navigation() {
  return (
    <AppBar sx={{ backgroundColor: '#212D33' }}>
      <Toolbar>
        <NentIcon />
        <NavTabs />
        <Box sx={{ flexGrow: 1 }} />
        <RenderOnAuthenticated>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Notifications />
            <Settings />
          </Box>
        </RenderOnAuthenticated>
        <UserAuth />
      </Toolbar>
    </AppBar>
  );
}
