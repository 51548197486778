import { NotificationsInbox } from './NotificationInbox';

export function InAppNotificaitons() {
  return (
    <>
      <NotificationsInbox
        fontSizeStatus={18}
        gridMinWidth={1000}
        timestampSize={14}
        changeContentSize={16}
        statusLabelFontSize={10}
      />
    </>
  );
}
