import { useContext, useMemo } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Box, Stack } from '@mui/system';
import { DataType } from '../../../reducers/dataSlice';
import { mergeListAndCreates } from '../../../../models/records';
import { TableContext } from '../../../components/InfiniteTable/InfiniteTable';
import { authService } from '../../../../services/AuthService';
import Roles from '../../../../models/Roles';
import { TableHeader } from '../../../components/InfiniteTable/TableHeader';
import { expandRowStyle } from './style';
import { PlaceholderRow } from './PlaceholderRow';
import { TableRow } from '../../../components/InfiniteTable/TableRow';
import { createSubProduction } from '../../../../models/productions';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { create } from '../../../reducers/workspaceSlice';
import { createSubFeed } from '../../../../models/feeds';
import { productionRowStyle } from '../../Production/ProductionsTable/style';
import { feedRowStyle } from '../../Feed/FeedsTable/style';
import { useFeedColumnSettings } from '../../Feed/FeedsTable/FeedColumSetting';
import { useProductionColumnSettings } from '../../Production/ProductionsTable/ProductionColumnSettings';

export function useSubRow(item: any) {
  const { creates, subItems } = useContext(TableContext);
  const { account } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  const canCreateProd = authService.hasRole([
    Roles.ADMIN,
    Roles.PRODUCTION_CREATOR,
  ]);

  const canCreateFeed = authService.hasRole([Roles.ADMIN, Roles.FEED_CREATOR]);

  const readOnlyProd = !authService.hasRole([
    Roles.ADMIN,
    Roles.PRODUCTION_EDITOR,
  ]);
  const readOnlyFeed = !authService.hasRole([Roles.ADMIN, Roles.FEED_EDITOR]);

  const feedHeaderSettings: any = useFeedColumnSettings(
    DataType.Workspace,
    DataType.Feed,
  );
  const feedtableSettings: any = useFeedColumnSettings(
    DataType.Workspace,
    DataType.Feed,
  );
  const prodHeaderSettings: any = useProductionColumnSettings(
    DataType.Workspace,
    DataType.Production,
  );
  const prodTableSettings: any = useProductionColumnSettings(
    DataType.Workspace,
    DataType.Production,
  );

  const { prodList, feedList } = useMemo(() => {
    if (!subItems[item._id]?.data) return { prodList: [], feedList: [] };

    const eventId = item._id;
    const productions = subItems[item._id].data.productions || [];
    const feeds = subItems[item._id].data.feeds || [];

    const newProds = Object.values(creates).filter(
      (create: any) =>
        create.type === DataType.Production &&
        create.associations?.events?.includes(eventId),
    );
    const newFeeds = Object.values(creates).filter(
      (create: any) =>
        create.type === DataType.Feed &&
        create.associations?.events?.includes(eventId),
    );

    return {
      prodList: mergeListAndCreates(productions, newProds, DataType.Production),
      feedList: mergeListAndCreates(feeds, newFeeds, DataType.Feed),
    };
  }, [subItems, item, creates]);

  const newSubProduction = (data: any) => {
    const newProduction = createSubProduction(data, account.defaultCountry);
    dispatch(create(newProduction));
  };

  const newSubFeed = (data: any) => {
    const newFeed = createSubFeed(data);
    dispatch(create(newFeed));
  };

  return (
    <Box
      sx={{
        ...expandRowStyle,
        flex: 1,
        marginLeft: '74px',
        overflowY: 'scroll hidden',
      }}
    >
      {subItems[item._id]?.open && (
        <Stack className="_subs">
          <TableHeader
            key={`${item._id}-${nanoid()}`}
            columns={prodHeaderSettings}
            sub
            rowStyle={productionRowStyle}
            canCreate={canCreateProd}
            item={item}
            badgeName="prod"
            createSubRow={newSubProduction}
            minWidth="1600px"
          />
          {!prodList.length && <PlaceholderRow type="prod" />}
          {prodList.map((data: any) => (
            <TableRow
              key={`${data._id}-${item._id}-${nanoid()}`}
              item={data}
              readOnly={readOnlyProd}
              columns={prodTableSettings}
              tableRowStyle={[
                { ...productionRowStyle, backgroundColor: '#f4f3f4' },
              ]}
            />
          ))}
        </Stack>
      )}
      {subItems[item._id]?.open && (
        <Stack className="_subs">
          <TableHeader
            key={`${item._id}-${nanoid()}`}
            columns={feedHeaderSettings}
            sub
            rowStyle={feedRowStyle}
            canCreate={canCreateFeed}
            item={item}
            badgeName="feed"
            createSubRow={newSubFeed}
            minWidth="1600px"
          />
          {!feedList.length && <PlaceholderRow type="feed" />}
          {feedList.map((data: any) => (
            <TableRow
              key={`${data.id}-${item._id}-${nanoid()}`}
              item={data}
              readOnly={readOnlyFeed}
              columns={feedtableSettings}
              tableRowStyle={[{ ...feedRowStyle, backgroundColor: '#f4f3f4' }]}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
