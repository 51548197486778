import { filterToQueryParams } from '../../services/common';
import { DataType } from '../reducers/dataSlice';
import api from '../../services/APIService';
import { addToast, setIsLoading } from '../reducers/appSlice';

interface DownloadProps {
  filter: any;
  type: DataType;
  dispatch?: any;
}

export async function downloadLists({ filter, type, dispatch }: DownloadProps) {
  try {
    const query = filterToQueryParams(filter, type);
    const headers = {
      'content-type': 'application/json',
    };
    dispatch(setIsLoading(true));
    const response: any = await api.post(
      `export/${type}s?${query}`,
      null,
      headers,
    );

    await getDocument(type, response, dispatch);
  } catch (e: any) {
    dispatch(
      addToast({
        id: 'table-created',
        message: `Not able to download table: ${e}`,
        severity: 'error',
      }),
    );
    console.warn(e);
  }
}

export async function getDocument(type: string, response: any, dispatch: any) {
  try {
    const headers = {
      'content-type': 'application/json',
    };
    const responseType = 'arraybuffer';

    const reportId = response?.data;

    if (reportId) {
      const interval = setInterval(async () => {
        const response = await api.post(
          `export/${reportId}`,
          null,
          headers,
          responseType,
        );
        if (!response) {
          dispatch(
            addToast({
              id: 'table-created',
              message: 'Error, not able to create report',
              severity: 'warning',
            }),
          );
          dispatch(setIsLoading(false));
          clearInterval(interval);
          return;
        }

        if (response.status === 200) {
          if (response?.data?.byteLength > 0) {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${type}s.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(
              addToast({
                id: 'table-created',
                message: 'List dowloaded successfully',
                severity: 'success',
              }),
            );
          } else {
            dispatch(
              addToast({
                id: 'table-created',
                message:
                  response.data || 'No data, change filters or try again',
                severity: 'warning',
              }),
            );
          }
          dispatch(setIsLoading(false));
          clearInterval(interval);
        }

        if (response.status !== 200 && response.status !== 201) {
          dispatch(
            addToast({
              id: 'table-created',
              message: 'No data, change filters or try again',
              severity: 'warning',
            }),
          );
          dispatch(setIsLoading(false));
          clearInterval(interval);
        }
      }, 2000);
    } else {
      dispatch(
        addToast({
          id: 'table-created',
          message: 'Not able to download table',
          severity: 'error',
        }),
      );
    }
  } catch (e: any) {
    dispatch(
      addToast({
        id: 'table-created',
        message: `Not able to download table: ${e}`,
        severity: 'error',
      }),
    );
    dispatch(setIsLoading(false));
    console.warn(e);
  }
}
