import { Key } from 'react';
import { Stack } from '@mui/material';
import { getFilterCriterias } from '../../../components/Filters/FilterOptions';
import { filterCards } from '../style';
import { FilterGroup } from './FilterGroup';
import { useAppSelector } from '../../../reducers/hooks';
import { TypeFilters } from '../../../components/Filters';

export function Filter() {
  const { savedFilters } = useAppSelector(state => state.filter);
  return (
    <Stack sx={filterCards}>
      {TypeFilters.types.map((filterType: Key | null | undefined) => (
        <FilterGroup
          key={filterType}
          title={
            (filterType as any).charAt(0).toUpperCase() +
            (filterType as any).slice(1)
          }
          filterGroup={savedFilters[filterType as any]}
          criterias={getFilterCriterias(filterType)}
        />
      ))}
    </Stack>
  );
}
