import { EditNoteRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { DataType } from '../../../reducers/dataSlice';
import { TableSettings } from './TableSettings';

interface SettingsProps {
  dataType: DataType;
  subType?: DataType;
}
export function SettingsIcon({ dataType, subType }: SettingsProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
      }}
    >
      {!subType && (
        <IconButton
          id={`edit-${dataType}-column-setting`}
          onClick={() => setOpen(true)}
        >
          <EditNoteRounded htmlColor="black" />
        </IconButton>
      )}
      <TableSettings open={open} setOpen={setOpen} dataType={dataType} />
    </Stack>
  );
}
