import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { Box } from '@mui/system';

import { Navigation, Routes } from '../../components/Navigation';
import { CalendarPage } from '../Calendar';
import { NotFoundPage } from '../ErrorBoundary';
import { LeaguePage } from '../League';
import { Workspace } from '../Workspace';
import Roles from '../../../models/Roles';
import RolesRoute from '../Auth/RolesRoute';
import { FeedPage } from '../Feed/FeedPage';
import { ProductionPage } from '../Production/ProductionPage';
import { HistoryPage } from '../History/HistoryPage';
import { InAppNotificaitons } from '../Account/Notification/InAppNotification/InAppNotifications';

const Main = () => (
  <Box
    sx={{
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      paddingTop: '80px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Navigation />
    <Box sx={{ flex: '1', overflow: 'hidden' }}>
      <Switch>
        <Route exact path="/" component={() => <CalendarPage />} />
        <Route exact path={Routes.events} component={() => <CalendarPage />} />
        <RolesRoute
          path={Routes.productions}
          roles={[
            Roles.ADMIN,
            Roles.PRODUCTION_EDITOR,
            Roles.PRODUCTION_CREATOR,
            Roles.PRODUCTION_VIEWER,
          ]}
        >
          <ProductionPage />
        </RolesRoute>

        <RolesRoute
          path={Routes.league}
          roles={[
            Roles.ADMIN,
            Roles.LEAGUE_CREATOR,
            Roles.LEAGUE_EDITOR,
            Roles.LEAGUE_VIEWER,
          ]}
        >
          <LeaguePage />
        </RolesRoute>
        <RolesRoute
          path={Routes.feeds}
          roles={[
            Roles.ADMIN,
            Roles.FEED_CREATOR,
            Roles.FEED_EDITOR,
            Roles.FEED_VIEWER,
          ]}
        >
          <FeedPage />
        </RolesRoute>

        <RolesRoute
          path={Routes.notifications}
          roles={[
            Roles.ADMIN,
            Roles.PRODUCTION_CREATOR,
            Roles.PRODUCTION_EDITOR,
            Roles.PRODUCTION_VIEWER,
          ]}
        >
          <InAppNotificaitons />
        </RolesRoute>
        <RolesRoute
          path={Routes.workspace}
          roles={[
            Roles.ADMIN,
            Roles.PRODUCTION_CREATOR,
            Roles.PRODUCTION_EDITOR,
            Roles.PRODUCTION_VIEWER,
          ]}
        >
          <Workspace />
        </RolesRoute>
        <RolesRoute
          path={Routes.history}
          roles={[
            Roles.ADMIN,
            Roles.EVENT_VIEWER,
            Roles.FEED_VIEWER,
            Roles.PRODUCTION_VIEWER,
            Roles.LEAGUE_VIEWER,
          ]}
        >
          <HistoryPage />
        </RolesRoute>
        <Route path="*" render={() => <NotFoundPage />} />
      </Switch>
    </Box>
  </Box>
);

export default Main;
