import { CellProps } from '..';
import {
  extractDate,
  parseDateInput,
  utcTimestampToLocalizedDate,
  utcTimestampToLocalizedTime,
} from '../../../../models/dates';
import { BaseItem } from '../../../reducers/dataSlice';
import { TextCell } from './TextCell';

/* Function to transform the date correct timestamp */
export function unRender(newInput: any, oldInput: any) {
  const time = utcTimestampToLocalizedTime(oldInput);
  const date = parseDateInput(newInput, extractDate(oldInput));
  return time ? `${date}T${time}` : date;
}

export function render(timestamp: any) {
  return utcTimestampToLocalizedDate(timestamp);
}
export function DateCell<T extends BaseItem>({
  column,
  item,
  editable,
}: CellProps<T>) {
  return (
    <TextCell
      column={column}
      item={item}
      editable={editable}
      unRender={(newInput: any, oldInput: any) => unRender(newInput, oldInput)}
      render={(value: string) => render(value)}
    />
  );
}
