import { Groups } from '@mui/icons-material';
import { Avatar, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { getNotification, toggleNotification } from '../../../api/user';
import { DataType } from '../../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { updateNotifications } from '../../../reducers/notificationSlice';
import { clear } from '../../../reducers/userSlice';
import NotificationCard from './NotificationCard';
import { NotificationHandlerPage } from './NotificationHandlerPage';
import {
  Notification,
  NotificationBase,
  Target,
  useNotificationSetting,
} from './NotificationSetting';
import NotificationToggle from './NotificationToggle';
import { avatarStyle, gridStyle } from './style';

export function NotificationPage() {
  const { options } = useNotificationSetting();
  const { groups } = useAppSelector(state => state.notifications);

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<Notification>();
  const [choosenTarget, setChoosenTarget] = useState<Target>(Target.user);

  const handleClick = async (
    id: any,
    type: DataType,
    mapToNotification: any,
  ) => {
    const isSuccessful: boolean = await getNotification(
      id,
      type,
      dispatch,
      groups,
      mapToNotification,
    );
    if (isSuccessful) {
      setOpen(!open);
    }
  };

  const handleAddNotification = (newNotification: any) => {
    dispatch(updateNotifications(newNotification));
    setOpen(!open);
  };

  const handleToggle = async (id: number, checked: any, type: any) => {
    const disabled = !checked;
    await toggleNotification(id, { [type]: { disabled } }, dispatch);
    dispatch(clear());
  };

  return (
    <Box sx={{ width: 1050 }}>
      <Grid container rowSpacing={2} sx={gridStyle}>
        {options?.map(
          (
            {
              label,
              name,
              type,
              list,
              newNotification,
              mapToNotification,
              notificationOptions,
            }: Notification,
            index: number,
          ) => (
            <Grid item xs={5} key={name} sx={gridStyle}>
              <NotificationCard
                key={name}
                label={label}
                value={name}
                type={type}
                notificationTargets={notificationOptions}
                onAddNotification={(target: any) => {
                  setChoosenTarget(target);
                  setNotification(options[index]);
                  handleAddNotification(newNotification);
                }}
              >
                {list?.map(
                  ({ id, name, disabled, targetGroup }: NotificationBase) => (
                    <NotificationToggle
                      key={id}
                      id={id}
                      notificationName={name}
                      checked={disabled}
                      type={type}
                      icon={
                        targetGroup &&
                        targetGroup?.length > 0 && (
                          <Avatar sx={avatarStyle}>
                            <Groups />
                          </Avatar>
                        )
                      }
                      onToggle={handleToggle}
                      onLabelClick={() => {
                        if (targetGroup && targetGroup.length > 0) {
                          setChoosenTarget(Target.group);
                        } else {
                          setChoosenTarget(Target.user);
                        }
                        setNotification(options[index]);
                        handleClick(id, type, mapToNotification);
                      }}
                    />
                  ),
                )}
              </NotificationCard>
            </Grid>
          ),
        )}
      </Grid>
      <Box>
        {open && (
          <NotificationHandlerPage
            open={open}
            setOpen={setOpen}
            notification={notification}
            target={choosenTarget}
          />
        )}
      </Box>
    </Box>
  );
}
