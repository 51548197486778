import { Component, createRef } from 'react';

import { Popout } from '../Popout';
import { onCursorNavigation } from '../InfiniteTable/cursorNavigation';

function forceFocusStyle(e: {
  target: { classList: { toggle: (arg0: string, arg1: boolean) => void } };
  type: string;
}) {
  e.target.classList.toggle('-focus', e.type === 'focus');
}

interface EditCellProps {
  className: any;
  id: number;
  field: string;
  value: any;
  displayValue?: any;
  popout?: any;
  renderEditor?: any;
  onChange(x: any): any;
  initValue?: any;
  pass?: any;
}

export class EditCell extends Component<EditCellProps> {
  static focusedCell: any = null;

  static async focusCell(
    id: any,
    field: any,
    isEditing: boolean,
    force?: boolean | undefined,
  ) {
    const cellSelector = `[data-id="${id}"][data-field="${field}"]`;
    const subSelector = isEditing ? 'input,select' : '.edit-cell';
    if (!isEditing && this.focusedCell !== cellSelector && !force) {
      return;
    }

    await Promise.resolve();
    const cell = document.querySelector(cellSelector);
    const focusable = cell?.querySelector(subSelector);
    (focusable as any)?.focus();

    if (isEditing) {
      this.focusedCell = cellSelector;
    } else {
      this.focusedCell = null;
    }
  }

  ref = createRef<any>();

  constructor(props: any | Readonly<{}>) {
    super(props);

    this.state = {
      isEditing: false,
      editValue: undefined,
    };
  }

  render() {
    const {
      className,
      id,
      field,
      value,
      displayValue,
      popout,
      renderEditor,
      onChange,
      initValue,
      ...pass
    } = this.props;
    const { isEditing, editValue } = (this as any).state;

    const editor =
      isEditing &&
      renderEditor(editValue, {
        onKeyDown: this.onKeyDownEdit,
        onInput: this.onInputEdit,
        onCommit: this.onCommitEdit,
        onBlur: this.onBlur,
      });

    return (
      <div
        ref={this.ref}
        className={className}
        data-id={id}
        data-field={field}
        {...pass}
      >
        {(!isEditing || popout) && (
          <button
            type="button"
            className="edit-cell"
            name={field}
            onFocus={forceFocusStyle}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDownIdle}
            onClick={this.onClick}
          >
            {displayValue || value || '-'}
          </button>
        )}
        {isEditing && !popout && editor}
        {isEditing && popout && (
          <Popout
            anchor={this.ref}
            open
            placement="bottom-end"
            transformOrigin="center top"
          >
            {editor}
          </Popout>
        )}
      </div>
    );
  }

  // Events

  onBlur = (e: any) => {
    forceFocusStyle(e);
    if (e.target.value) {
      (this as any).props.onChange(e.target.value);
      this.stopEditing();
    }
  };

  onClick = () => {
    if (!(this as any).state.isEditing) {
      (this as any).startEditing();
    }
  };

  onClickAway = (e: { target: any }) => {
    if (!(this as any).ref.current?.contains(e.target)) {
      if ((this as any).state.editValue !== (this as any).props.value) {
        (this as any).props.onChange((this as any).state.editValue);
      }
      this.stopEditing();
    }
  };

  onKeyDownIdle = (e: {
    key: string;
    nativeEvent: { preventDefault: () => void };
  }) => {
    if (e.key.startsWith('Arrow')) {
      onCursorNavigation(e.nativeEvent);
    } else if (['Enter', ' ', 'F2'].includes(e.key)) {
      (this as any).startEditing();
    } else if (e.key.length === 1) {
      e.nativeEvent.preventDefault();
      (this as any).startEditing(e.key);
    }
  };

  onKeyDownEdit = (e: {
    key: string;
    nativeEvent: {
      stopImmediatePropagation: () => void;
      preventDefault: () => void;
    };
  }) => {
    let handled = false;
    if (e.key === 'Escape') {
      handled = true;
    } else if (e.key === 'Enter') {
      handled = true;
      (this as any).props.onChange((this as any).state.editValue);
    }

    if (handled) {
      this.stopEditing();
      e.nativeEvent.stopImmediatePropagation();
      e.nativeEvent.preventDefault();
    }
  };

  onInputEdit = (newValue: any) => {
    this.setState({ editValue: newValue });
  };

  onCommitEdit = (newValue: any) => {
    (this as any).props.onChange(newValue);
    this.stopEditing();
  };

  // Internal

  startEditing = (key: undefined) => {
    const { id, field, initValue = (v: any) => v } = (this as any).props;
    this.setState({
      isEditing: true,
      editValue: initValue(key),
    });
    EditCell.focusCell(id, field, true);

    window.setTimeout(() => {
      window.addEventListener('click', this.onClickAway);
    }, 0);
  };

  stopEditing = () => {
    const { id, field } = (this as any).props;
    this.setState({ isEditing: false, editValue: undefined });
    EditCell.focusCell(id, field, false);

    window.removeEventListener('click', this.onClickAway);
  };
}
