import { Check, Close, Undo } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { clear } from '../../../reducers/userSlice';
import { actionButtons } from '../style';
import { saveChanges } from '../../../api/user';

export function Actions() {
  const { changes } = useAppSelector(state => state.user);

  const dispatch = useAppDispatch();

  const hasChanges =
    Object.keys(changes.account).length > 0 || changes?.country_code;

  const onRegret = () => {
    dispatch(clear());
  };
  return (
    <Stack sx={actionButtons}>
      {hasChanges && (
        <>
          <Button startIcon={<Close />} onClick={onRegret}>
            CANCEL
          </Button>
          <Button
            startIcon={<Check />}
            onClick={() => saveChanges(changes, dispatch)}
          >
            SAVE CHANGES
          </Button>
          <Button startIcon={<Undo />} onClick={onRegret}>
            UNDO
          </Button>
        </>
      )}
    </Stack>
  );
}
