import { Box } from '@mui/system';
import { useState } from 'react';
import Roles from '../../../models/Roles';
import { createNewEvent } from '../../../models/events';
import { downloadLists } from '../../api/dataDownloader';
import { Actions } from '../../components/Actions/Actions';
import { CreateAction } from '../../components/Actions/CreateAction';
import { DownloadAction } from '../../components/Actions/DownloadAction';
import { SaveAction } from '../../components/Actions/SaveAction';
import { UndoAction } from '../../components/Actions/UndoAction';
import { UploadAction } from '../../components/Actions/UploadAction';
import { FilterToolbar } from '../../components/Filters/FilterToolbar';
import { useFilters } from '../../components/Filters/hooks';
import { Toasts } from '../../components/Toasts';
import { WithStaticData } from '../../components/WithStaticData';
import { DataType } from '../../reducers/dataSlice';
import {
  applyFilter,
  clearList,
  commit,
  create,
  getSlice,
  rollback,
} from '../../reducers/eventsSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import RenderOnRole from '../Auth/RenderOnRole';
import { onSave } from './ActionsHandler';
import { EventsTable } from './EventsTable/EventsTable';

export function CalendarPage() {
  useFilters(DataType.Event, applyFilter);

  const dispatch = useAppDispatch();

  const { creates, changes, errors, items } = useAppSelector(getSlice);
  const filter = useAppSelector(state => state.filter);
  const [loading, setLoading] = useState<boolean>(false);
  const changeCount = Object.keys(changes).length + Object.keys(creates).length;
  const hasErrors =
    Object.keys(errors).length > 0 &&
    Object.values(errors).some((itemErrors: any) => itemErrors.length > 0);

  const createAction = (date: any) => {
    const newEvent = createNewEvent(date);
    dispatch(create(newEvent));
  };

  return (
    <WithStaticData realms={['public']}>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FilterToolbar presetType="event" />
        <EventsTable />

        <Actions>
          <RenderOnRole
            roles={[Roles.ADMIN, Roles.EVENT_CREATOR, Roles.EVENT_EDITOR]}
          >
            {changeCount > 0 && (
              <UndoAction onUndo={() => dispatch(rollback())} />
            )}
            {changeCount > 0 && (
              <SaveAction
                onSave={async () => {
                  setLoading(true);
                  const commits: any = await onSave(
                    creates,
                    changes,
                    dispatch,
                    items,
                  );
                  setLoading(false);
                  dispatch(commit(commits));
                  dispatch(clearList(false));
                }}
                hasErrors={hasErrors}
                changeCount={changeCount}
                disabled={loading}
              />
            )}
            <RenderOnRole roles={[Roles.ADMIN, Roles.EVENT_CREATOR]}>
              <CreateAction onSave={createAction} type="event" />
              <UploadAction />
            </RenderOnRole>
          </RenderOnRole>
          <RenderOnRole
            roles={[
              Roles.ADMIN,
              Roles.EVENT_CREATOR,
              Roles.EVENT_EDITOR,
              Roles.EVENT_VIEWER,
            ]}
          >
            <DownloadAction
              onDownload={() =>
                downloadLists({ filter, type: DataType.Event, dispatch })
              }
            />
          </RenderOnRole>
        </Actions>
        <Toasts />
      </Box>
    </WithStaticData>
  );
}
