import { Add } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { card, cardContent, table, tableHeader } from '../style';
import CreatedSeasonRow from './CreatedSesonRow';
import { createNewId } from '../../../../../models/records';

interface LeagueSeasonsProps {
  disabled: boolean;
  league: any;
  onChange: any;
}

const LeagueSeasons = ({ disabled, league, onChange }: LeagueSeasonsProps) => {
  const createdSeasons = league && league?.seasons ? league.seasons : [];

  const handleAdd = () => {
    const newSeason = {
      id: createNewId(),
      name: '',
      startDate: '',
      endDate: '',
    };
    let currentSeasons = [];
    currentSeasons = [...createdSeasons];

    currentSeasons.push(newSeason);

    onChange({ seasons: currentSeasons });
  };

  const handleDeleteRow = (id: any) => {
    const newList = createdSeasons.filter((season: any) => season.id !== id);
    onChange({ seasons: newList });

    const deletedSeasons = [...league.deletedSeasons];
    deletedSeasons.push(id);
    onChange({ deletedSeasons });
  };

  const renderRow = () => {
    if (createdSeasons?.length > 0) {
      return (
        <CardContent sx={cardContent}>
          <Table size="small" sx={table}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeader}>Name</TableCell>
                <TableCell sx={tableHeader}>Start Date</TableCell>
                <TableCell sx={tableHeader}>End Date</TableCell>
                <TableCell sx={tableHeader}>Source Id</TableCell>
                <TableCell sx={tableHeader} />
              </TableRow>
            </TableHead>
            <TableBody>
              {createdSeasons.map((season: any) => (
                <CreatedSeasonRow
                  key={season.id}
                  id={season.id}
                  league={league}
                  season={season}
                  handleDeleteRow={handleDeleteRow}
                  disabled={disabled}
                />
              ))}
            </TableBody>
          </Table>
        </CardContent>
      );
    }
  };

  return (
    <Card sx={card}>
      <CardHeader
        title="Seasons"
        action={
          <Fab
            id="add-season"
            color="secondary"
            size="small"
            disabled={disabled}
            onClick={handleAdd}
          >
            <Add sx={{ fontSize: 20 }} />
          </Fab>
        }
      />
      {renderRow()}
    </Card>
  );
};

export default LeagueSeasons;
