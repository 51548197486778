import { useCallback } from 'react';
import { Chip } from '@mui/material';
import { Box } from '@mui/system';

interface ChipPickerProps {
  options: any[];
  selected: string[];
  onChange(x: any, y: any): any;
}

export function ChipPicker({ options, selected, onChange }: ChipPickerProps) {
  const change = useCallback(
    (value: string) => {
      const newValues = !selected.includes(value)
        ? [...selected, value]
        : selected.filter(v => v !== value);
      onChange(newValues, value);
    },
    [selected, onChange],
  );

  return (
    <Box sx={{ display: 'flex', flexFlow: 'row wrap' }}>
      {options.map(option => (
        <Chip
          variant="filled"
          key={option.value}
          label={option.label}
          color={selected.includes(option.value) ? 'primary' : undefined}
          sx={{ marginRight: 1, marginBottom: 1 }}
          onClick={() => change(option.value)}
        />
      ))}
    </Box>
  );
}
