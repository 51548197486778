import { sortBy } from 'lodash';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { DataType } from '../../../reducers/dataSlice';
import { useAppSelector } from '../../../reducers/hooks';
import { EventCriteria } from './Criterias/EventCriteria';

import Roles from '../../../../models/Roles';
import { FilterCriterias } from '../../../components/Filters';
import { NotificationGroup } from '../../../reducers/notificationSlice';
import { FeedCriteria } from './Criterias/FeedCriteria';
import { ProductionCritera } from './Criterias/ProductionCriteria';
import {
  createNewNofificationBox,
  mapToEventNotification,
  mapToFeedNotification,
  mapToPlaceholderNotification,
  mapToProductionNotification,
} from './helper';
import { createNewId } from '../../../../models/records';
import { authService } from '../../../../services/AuthService';

export type TargetGroup = {
  id: string;
  name: string;
};

export enum ProtocolType {
  sms = 'sms',
  email = 'email',
  in_app = 'in_app',
}

export type AHEAD = {
  time: string;
  unit: string;
};

export type SendFrequency = 'daily' | 'immediately' | '';

export const IMMEDIATELY_EMAIL_INFORMATION =
  'Send email when the change occurs.';
export const DAILY_EMAIL_INFORMATION =
  'Emails will be sent at 6:00 a.m. CET daily.';

export type ProtocolSendInfo = {
  ahead: AHEAD;
  sendFrequency: SendFrequency;
  targetGroup?: TargetGroup[];
};

export type NotificationBox = {
  sms?: ProtocolSendInfo;
  email?: ProtocolSendInfo;
  in_app?: ProtocolSendInfo;
};

export type NotificationBase = {
  [x: string]: any;
  id: number;
  name: string;
  type: DataType;
  disabled: boolean;
  notificationBoxes: NotificationBox;
  targetGroup?: string[];
};

export interface EventNotification extends NotificationBase {
  all: boolean;
  filter: FilterCriterias;
  created: boolean;
  deleted: boolean;
  productionLeader: boolean;
  productionCreator: boolean;
  notifyAllChanges: boolean;
  startTimeChange: boolean;
}

export interface ProductionNotification extends NotificationBase {
  all: boolean;
  created: boolean;
  deleted: boolean;
  filter: FilterCriterias;
  runTypeChange: boolean;
  timeChange: boolean;
}

export interface FeedNotification extends NotificationBase {
  notificationDay: any;
}

export interface CriteriaProps {
  data: any;
  errors: any;
  handleChange: any;
}

export type Protocol = {
  label: string;
  type: ProtocolType;
  frequency?: SendFrequency;
  information?: string;
};

export type UserInfo = {
  [x: string]: any;
  email: string;
  sms: string;
  in_app: string;
};

export type Notification = {
  label: string;
  name: string;
  type: DataType;
  renderCriteriaComponent?: ReactNode;
  list?: NotificationBase[];
  newNotification: NotificationBase;
  getNewNotificationBox: (
    type: ProtocolType,
    frequency?: SendFrequency,
  ) => ProtocolSendInfo;
  getProtocol: (target: Target) => Protocol[] | [];
  getEmailOptions?: (onChange: any, sendFrequency: SendFrequency) => ReactNode;
  userInfo: UserInfo;
  notificationOptions: Target[];
  mapToNotification: (
    notification: any,
    groups: NotificationGroup[],
  ) => NotificationBase;
};

export type NotificationOptions = {
  events: Notification;
  feeds: Notification;
  productions: Notification;
  placeholders?: Notification;
};

const newNotification: NotificationBase = {
  id: createNewId(),
  name: '',
  type: DataType.Unknown,
  disabled: false,
  notificationBoxes: {},
};

const newEventNotification: EventNotification = {
  ...newNotification,
  type: DataType.Event,
  productionLeader: false,
  productionCreator: false,
  notifyAllChanges: false,
  startTimeChange: false,
  created: false,
  deleted: false,
  all: true,
  filter: {},
};

const newProductionNotification: ProductionNotification = {
  ...newNotification,
  type: DataType.Production,
  timeChange: false,
  runTypeChange: false,
  created: false,
  deleted: false,
  all: true,
  filter: {},
};

const newPlaceholderNotification: NotificationBase = {
  ...newNotification,
  type: DataType.Placeholder,
};

const newFeedNotification: NotificationBase = {
  ...newNotification,
  type: DataType.Feed,
  created: false,
  deleted: false,
  anyChanges: false,
};

export const SMS: Protocol = {
  label: 'SMS',
  type: ProtocolType.sms,
  frequency: 'immediately',
};

export const EMAIL: Protocol = {
  label: 'Email',
  frequency: 'immediately',
  information: DAILY_EMAIL_INFORMATION,
  type: ProtocolType.email,
};

export const DAILY_EMAIL: Protocol = {
  label: 'Email',
  frequency: 'daily',
  information: DAILY_EMAIL_INFORMATION,
  type: ProtocolType.email,
};

export const IN_APP: Protocol = {
  label: 'In Application',
  type: ProtocolType.in_app,
};

export enum Target {
  user = 'User',
  group = 'Group',
}

export const useNotificationSetting = (): { options: any[] } => {
  const { events, productions, placeholders, feeds } = useAppSelector(
    state => state.notifications.items,
  );
  const { account, country_code } = useAppSelector(state => state.user);
  const isAdmin = authService.hasRole([Roles.USER_ADMIN, Roles.ADMIN]);

  const userInfo: UserInfo = {
    email: account?.email?.value || '',
    sms: (country_code !== '-' && country_code + account.phone?.value) || '',
    in_app: isAdmin ? 'admin' : '',
  };

  const getOptions = (
    canBeSentImmediately: boolean = false,
    onChange?: any,
    sendFrequency?: SendFrequency,
  ) => {
    if (canBeSentImmediately) {
      const checked = sendFrequency === 'immediately';
      return (
        <Stack>
          <FormControlLabel
            id="daily"
            label={DAILY_EMAIL_INFORMATION}
            control={<Checkbox defaultChecked id="daily" />}
            disabled
          />
          <FormControlLabel
            id="immediately"
            label={IMMEDIATELY_EMAIL_INFORMATION}
            control={<Checkbox id="immediately" />}
            checked={checked}
            onChange={(e: any) => {
              if (e.target.checked) {
                onChange({
                  sendFrequency: 'immediately',
                });
              } else {
                onChange({
                  sendFrequency: 'daily',
                });
              }
            }}
          />
        </Stack>
      );
    }

    return <Typography color="grey">{DAILY_EMAIL_INFORMATION}</Typography>;
  };

  const notificationOptions: NotificationOptions = {
    events: {
      label: 'Events',
      name: 'events',
      type: DataType.Event,
      list: sortBy(events, 'id'),
      renderCriteriaComponent: <EventCriteria />,
      newNotification: newEventNotification,
      getNewNotificationBox: (type: ProtocolType) => {
        return createNewNofificationBox(type);
      },
      getProtocol: (target: Target) => {
        return target === Target.user ? [DAILY_EMAIL, SMS] : [];
      },
      getEmailOptions: () => getOptions(),
      notificationOptions: [Target.user],
      userInfo,
      mapToNotification: (notification: any, groups: NotificationGroup[]) =>
        mapToEventNotification(notification, groups),
    },
    feeds: {
      label: 'Feeds',
      name: 'feeds',
      type: DataType.Feed,
      list: sortBy(feeds, 'id'),
      renderCriteriaComponent: <FeedCriteria />,
      newNotification: newFeedNotification,
      getNewNotificationBox: (
        type: ProtocolType,
        frequency?: SendFrequency,
      ) => {
        return createNewNofificationBox(type, frequency);
      },
      getProtocol: (target: Target) => {
        return target === Target.user ? [EMAIL] : [IN_APP];
      },
      getEmailOptions: (onChange: any, sendFrequency: SendFrequency) =>
        getOptions(true, onChange, sendFrequency),
      notificationOptions: [Target.user, Target.group],
      userInfo,
      mapToNotification: (notification: any, groups: NotificationGroup[]) =>
        mapToFeedNotification(notification, groups),
    },
    productions: {
      label: 'Productions',
      name: 'productions',
      type: DataType.Production,
      list: sortBy(productions, 'id'),
      renderCriteriaComponent: <ProductionCritera />,
      newNotification: newProductionNotification,
      getNewNotificationBox: (type: ProtocolType) => {
        return createNewNofificationBox(type);
      },
      getProtocol: (target: Target) => {
        return target === Target.user ? [DAILY_EMAIL, SMS] : [IN_APP];
      },
      getEmailOptions: () => getOptions(),
      notificationOptions: [Target.user, Target.group],
      userInfo,
      mapToNotification: (notification: any, groups: NotificationGroup[]) =>
        mapToProductionNotification(notification, groups),
    },
    ...(isAdmin && {
      placeholders: {
        label: 'Placeholders',
        name: 'placeholders',
        type: DataType.Placeholder,
        list: sortBy(placeholders, 'id'),
        newNotification: newPlaceholderNotification,
        getNewNotificationBox: (type: ProtocolType) => {
          return createNewNofificationBox(type);
        },
        getProtocol: (target: Target) => {
          return target === Target.group ? [IN_APP] : [];
        },
        notificationOptions: [Target.group],
        userInfo,
        mapToNotification: (notification: any, groups: NotificationGroup[]) =>
          mapToPlaceholderNotification(notification, groups),
      },
    }),
  };

  return { options: Object.values(notificationOptions) };
};
