import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { diff } from 'deep-object-diff';
import { isEmpty, uniqBy } from 'lodash';
import { getKeyFromId, isNewId, isTempId } from '../../models/records';
import { diffItem } from '../../models/diffItem';
import { retrofitProduction } from '../../models/productions';
import { retrofitFeed } from '../../models/feeds';

// eslint-disable-next-line no-shadow
export enum DataType {
  Production = 'production',
  Feed = 'feed',
  Event = 'event',
  Workspace = 'workspace',
  League = 'league',
  Audit = 'audit',
  Notification = 'notification',
  Placeholder = 'placeholder',
  Unknown = 'unknown',
}

// eslint-disable-next-line no-shadow
export enum ItemAction {
  NEW = 'NEW',
  COPY = 'COPY',
  REFRESHED = 'REFRESHED',
}

export interface BaseItem {
  [x: string]: any;

  _id: number;
  type: DataType;
  action?: ItemAction;
  deleted_time?: string;
}

export interface DataState<T extends BaseItem> {
  items: T[]; // All Items
  visibleItems: T[]; // Items that are visible in the page
  requestedTo: number;
  loadedTo: number;
  isLoading: boolean;
  isFinished: boolean;
  changes: ItemData;
  errors: ValidationErrors;
  creates: ItemData;
  uploading: UploadStatus; // For tables that has an uploading action
  subItems: any; // If the tables has subTables
  details: any; // If the table has a detail view for each row
  refreshed: ItemData;
}

export interface ItemBatch<T extends BaseItem> {
  items: T[];
  isFinished: boolean;
}

export interface ErrorMessage {
  field: string;
  message: string;
}

export interface UploadStatus {
  isLoading: boolean;
  uploadingFiles: string[];
}

export interface ValidationErrors {
  [key: string]: ErrorMessage[];
}

interface SliceProps<T extends BaseItem> {
  [key: string]: any;

  sliceName: string;
  deltaTransformer?: (delta: any, item: T) => T;
  validator?: (item: BaseItem) => ErrorMessage[];
  aggregateDetailCollector?: (delta: any) => any;
  aggregateDetailMerger?: (currentAggregated: any, delta: any) => any;
  subItemsMerger?: (item: T, delta: any) => T;
  subItemsReseter?: (item: T) => T;
}

export interface UpdatePayload extends BaseItem {
  delta: any;
}

export interface Detail {
  open: boolean; // Keep track if details view is open
  data: any; // Data to propulate the details view
  changes: any; // keeps track of the changes made on the items
  errors: any; // keep track of the errors
}

export interface ItemData {
  [key: string]: any;
}

interface CommitChanges {
  itemCreatedKeys: string[]; //keys as type:id eg. events:123
  itemUpdatedKeys: string[];
}

const CLEAR_LIST = {
  items: [],
  visibleItems: [],
  requestedTo: 10,
  loadedTo: 0,
  isFinished: false,
  isLoading: false,
  subItems: {},
  details: {},
  refreshed: {},
};

const CLEAR_CHANGES = {
  changes: {},
  creates: [],
  errors: {},
};

const EMPTY_STATE = {
  ...CLEAR_LIST,
  ...CLEAR_CHANGES,
  uploading: {
    isLoading: false,
    uploadingFiles: [],
  },
};
/**
 * Find the item within the state for the given id.
 * Return a BaseItem
 */
const findOldItem = (dataState: DataState<BaseItem>, id: number) => {
  return dataState.items.find(item => item._id === id);
};

const findAndReplace = (
  key: string,
  dataState: DataState<BaseItem>,
  toReplace: BaseItem,
  updateSubitems: boolean = false,
  markDirtySubItems: boolean = false,
): DataState<BaseItem> => {
  const currentItems = [...dataState.items];
  const currentVisibleItems = [...dataState.visibleItems];
  const index = currentItems.findIndex(item => item._id === toReplace._id);
  let updateDiff = {};
  if (index >= 0) {
    updateDiff = diffItem(currentItems[index], toReplace, true);
    currentItems[index] = toReplace;
  }
  const indexVisible = currentVisibleItems.findIndex(
    item => item._id === toReplace._id,
  );
  if (indexVisible >= 0) {
    currentVisibleItems[indexVisible] = toReplace;
  }
  //replace the item + the subItems

  if (updateSubitems) {
    return {
      ...dataState,
      items: currentItems,
      visibleItems: currentVisibleItems,
      refreshed: {
        ...dataState.refreshed,
        [key]: updateDiff, //diff on parent
        ...toReplace.diff, //diff on subitems calculated on the merger
      },
      subItems: {
        ...dataState.subItems,
        [toReplace._id]: {
          ...dataState.subItems[toReplace._id],
          data: toReplace,
        },
      },
    };
  }
  return {
    ...dataState,
    items: currentItems,
    visibleItems: currentVisibleItems,
    refreshed: {
      ...dataState.refreshed,
      [key]: updateDiff,
    },
    ...(markDirtySubItems && {
      subItems: {
        ...dataState.subItems,
        [toReplace._id]: {
          open: false,
        },
      },
    }),
  };
};

const findSubItems = (dataState: DataState<BaseItem>, id: number) => {
  return dataState.subItems[id];
};

/**
 * Base dataSlice for DataSate
 * @param sliceName slice name eg.events, production
 * @param additionalReducers extra reducers not present in the base slice
 * @param transformDelta optional function to transform delta to item data.
 * @param validate optional function to perform data validation on the item. Returns a list of errors as result if any.
 */
export function createDataSlice<T extends BaseItem>(props: SliceProps<T>) {
  const {
    sliceName,
    deltaTransformer,
    validator,
    aggregateDetailCollector,
    aggregateDetailMerger,
    subItemsMerger,
    subItemsReseter,
  } = props;

  const initialState: DataState<BaseItem> = {
    ...EMPTY_STATE,
  };

  return createSlice({
    name: sliceName,
    initialState,
    reducers: {
      /**
       * Apply the filter on filterslice on this dataSlice.
       * @param state
       * Function will clear all states which will trigger a load of data
       */
      applyFilter: state => {
        //Will set empty state which will trigger the load more on the infinite table
        return {
          ...state,
          ...EMPTY_STATE,
        };
      },

      /**
       * Get next batch of data
       * @param state The current State
       * @param action The incomming payload
       * @returns previous stata, updated requestTo, isFinished false (we are still loading data)
       */
      setRequestedTo: (state, action: PayloadAction<number>) => ({
        ...state,
        requestedTo: action.payload,
        isFinished: false,
      }),

      /**
       * Add the new items
       * @param state The previous state
       * @param action the new data
       * @returns items
       */
      addItems: (state, action: PayloadAction<ItemBatch<T>>) => {
        const { items, isFinished } = action.payload;
        const allItems = [...state.items, ...items];
        const uniqueItems = uniqBy(allItems, '_id');

        return {
          ...state,
          items: uniqueItems,
          visibleItems: uniqueItems,
          requestedTo: uniqueItems.length,
          isFinished,
        };
      },
      /**
       * Set finished to true when data is done loading
       * @param state previous state
       * @returns previous state, requestTo (current list length), isFinshed
       */
      setFinished: state => ({
        ...state,
        requestedTo: state.visibleItems?.length,
        isFinished: true,
      }),
      /**
       *
       * @param state Previous State
       * @param action Optional if payload update isFinished
       * @returns
       */
      clearList: (state, action?: PayloadAction<any>) => ({
        ...state,
        ...CLEAR_LIST,
        ...(action?.payload && { isFinished: action.payload }),
      }),
      /**
       * State to keep track if request is finished or not (used to display loading button)
       * @param state Prevous state
       * @param action boolean
       * @returns isLoading (true/false)
       */
      setLoading: (state, action: PayloadAction<boolean>) => ({
        ...state,
        isLoading: action.payload,
      }),

      /**
       * Create a new entry in the state
       * @param state Previous state
       * @param action the new created item
       * New items are stored with key [type, id]: {}
       * Also checks if the new row has errors that will be stored in the error state and can be used to display on the row
       */
      create: (state, action: PayloadAction<T>) => {
        const key = getKeyFromId(action.payload.type, action.payload._id);
        return {
          ...state,
          creates: {
            ...state.creates,
            [key]: {
              ...action.payload,
            },
          },

          errors: validateItem(action.payload, key, state.errors, validator),
        };
      },

      /**
       * Update the given entry in the state. it can be an exisiting one or a newly created.
       * @param state Previous State
       * @param action new Change
       */
      update: (state, action: PayloadAction<UpdatePayload>) => {
        const { type, _id, delta } = action.payload;
        const key = getKeyFromId(type, _id);
        const isNew = isTempId(_id);
        //if new entry we take it directly from the creates list. Since it has not been committed.
        //if not new we locate the old item and the changes(not committed) so far and merge the delta changes.
        const oldItem = isNew
          ? state.creates[key]
          : {
              ...findOldItem(state, _id),
              ...state.changes[key],
            };
        const realDelta = deltaTransformer
          ? deltaTransformer(delta, oldItem)
          : delta;
        const newItem = { ...oldItem, ...realDelta };
        //If no changes we don't change state
        if (!oldItem || !Object.keys(diff(oldItem, newItem)).length)
          return state;

        if (isNew) {
          return {
            ...state,
            creates: {
              ...state.creates,
              [key]: replaceInList(state.creates, delta, newItem),
            },
            errors: validateItem(
              { ...newItem, type },
              key,
              state.errors,
              validator,
            ),
          };
        } else {
          let valuesToValidate = { ...newItem, type };
          const oldSubItem = findItemInSubItems(_id, state);
          valuesToValidate = { ...oldSubItem, ...valuesToValidate };
          return {
            ...state,
            changes: {
              ...state.changes,
              [key]: {
                ...state.changes[key],
                ...realDelta,
              },
            },
            errors: validateItem(
              valuesToValidate,
              key,
              state.errors,
              validator,
            ),
          };
        }
      },

      /**
       * Used for live updates. When a new change comes refresh the item to display the new change on the UI
       * @param state
       * @param action
       * @returns
       */
      refreshItem: (state, action: PayloadAction<T>) => {
        const { type, _id } = action.payload;
        const key = getKeyFromId(type, _id);
        //Update the given item with the delta coming in.
        //Will update the subitems if we have any.
        const oldItem: any = findOldItem(state, _id);
        if (oldItem) {
          const delta: any = deltaTransformer
            ? deltaTransformer(action.payload, oldItem)
            : action.payload;
          const hasSubItems: boolean = state.subItems[_id]?.open;
          //has items but they are closed, mark as dirty to fetch again when it opens
          const markAsDirty: boolean =
            state.subItems[_id] && !state.subItems[_id].open;

          //Collect the current aggregated info if it has any
          const { aggregated_detail } = oldItem;
          //Merge aggregate details if any
          const aggregatedDetails =
            aggregateDetailMerger &&
            aggregateDetailMerger(aggregated_detail, action.payload);

          //Merge the subitems if it has any
          const itemToMerge =
            hasSubItems && subItemsMerger
              ? subItemsMerger(oldItem, delta)
              : { ...oldItem, ...delta };

          const mergedItem = {
            ...itemToMerge,
            ...(aggregatedDetails && {
              aggregated_detail: aggregatedDetails,
            }),
          };
          //Replace the main row
          return findAndReplace(
            key,
            state,
            mergedItem,
            hasSubItems,
            markAsDirty,
          );
        }
      },

      resetItemAction: (state, action: PayloadAction<T>) => {
        const { type, _id } = action.payload;
        const item: any = findOldItem(state, _id);
        if (item === undefined) {
          return state;
        }
        const key = getKeyFromId(type, _id);
        const hasSubItems: boolean = state.subItems[_id]?.open;
        const resetItem =
          hasSubItems && subItemsReseter
            ? subItemsReseter(item)
            : {
                ...item,
                action: undefined,
              };
        const currentRefreshed = { ...state.refreshed };
        delete currentRefreshed[key];
        if (resetItem?.diff) {
          for (const subItemKey of resetItem.diff) {
            delete currentRefreshed[subItemKey];
          }
        }

        const current_state = findAndReplace(
          key,
          state,
          resetItem,
          hasSubItems,
        );
        return {
          ...current_state,
          refreshed: {
            ...currentRefreshed,
          },
        };
      },

      /**
       * Called when changes (new items or updated) has been persisted in backed.
       * Removing the commited changes for the state, since it might have partial changes committed.
       * @param state
       * @param action
       */
      commit: (state, action: PayloadAction<CommitChanges>) => {
        const { itemCreatedKeys, itemUpdatedKeys } = action.payload;
        if (itemCreatedKeys.length === 0 && itemUpdatedKeys.length === 0)
          return state;

        const creates = Object.fromEntries(
          Object.values(state.creates).filter(
            create => !itemCreatedKeys.includes(create._id),
          ),
        );

        const changes = Object.fromEntries(
          Object.entries(state.changes).filter(
            ([id]) => !itemUpdatedKeys.includes(id),
          ),
        );
        const errors = Object.fromEntries(
          Object.entries(state.errors).filter(
            ([id]) => !itemUpdatedKeys.includes(id),
          ),
        );

        return {
          ...state,
          creates,
          changes,
          errors,
        };
      },

      /**
       * Clear all created items from the state and its errors if any.
       * @param state
       */
      clearCreates: state => ({
        ...state,
        creates: [],
        errors: filterErrors(state.errors, Object.keys(state.creates)),
      }),

      /**
       * Clear the changes made for the given item
       * @param state
       * @param action
       */
      undoChange: (state, action: PayloadAction<T>) => {
        const { type, _id } = action.payload;
        const key = getKeyFromId(type, _id);

        const changes: any = { ...state.changes };
        delete changes[key];
        const errors = filterErrors(state.errors, [key]);

        return {
          ...state,
          changes,
          errors,
        };
      },

      /**
       * Clear the given item created in the state
       * @param state
       * @param action
       */
      undoCreate: (state, action: PayloadAction<T>) => {
        const { type, _id } = action.payload;
        const key = getKeyFromId(type, _id);

        const creates = { ...state.creates };
        delete creates[key];
        const errors = filterErrors(state.errors, [key]);

        return {
          ...state,
          creates,
          errors,
        };
      },

      /**
       * Clear all changes
       * @param state Previous state
       * @returns
       */
      rollback: state => {
        return {
          ...state,
          ...CLEAR_CHANGES,
        };
      },
      /**
       * Set state of uploading and the files to be uploaded
       * @param state Previous state
       * @param action {isLoading, files}
       * @returns
       */
      setUploading: (state, action: PayloadAction<UploadStatus>) => {
        return {
          ...state,
          uploading: {
            ...action.payload,
          },
        };
      },

      /**
       * When uploading is done, clear all the files and set isLoading: false
       * @param state
       * @returns
       */
      clearUploading: state => ({
        ...state,
        uploading: {
          isLoading: false,
          uploadingFiles: [],
        },
      }),

      /**
       * Used when clicking on row opens a detail view, can be new or created
       * @param state
       * @param action
       * @returns
       */
      setDetails: (state: any, action: PayloadAction<any>) => {
        const data = { ...action.payload };
        return {
          ...state,
          details: {
            open: true,
            data,
            changes: {},
            errors: isNewId(data._id)
              ? validateDetail(data, validator, state.items)
              : {},
          },
        };
      },
      /**
       * Update
       * @param state
       * @param action
       * @returns
       */
      updateDetails: (state: any, action: PayloadAction<any>) => {
        const currentItem = { ...state.details.data, ...action.payload };
        const currentChanges = { ...state.details.changes, ...action.payload };

        return {
          ...state,
          details: {
            ...state.details,
            data: {
              ...currentItem,
            },
            changes: {
              ...state.details.changes,
              ...action.payload,
            },
            errors: isNewId(state.details.data._id)
              ? validateDetail(currentItem, validator, state.items)
              : validateDetail(currentChanges, validator, state.items),
          },
        };
      },
      clearDetails: (state: any) => ({
        ...state,
        details: {},
      }),

      /**
       * If a row has the option to click and show a subrow
       * @param state
       * @param action
       * @returns
       */
      opensubItems: (state: any, action: PayloadAction<any>) => ({
        ...state,
        subItems: {
          ...state.subItems,
          [action.payload.id]: {
            ...state.subItems[action.payload.id],
            open: true,
          },
        },
      }),

      closeSubItems: (state: any, action: PayloadAction<any>) => ({
        ...state,
        subItems: {
          ...state.subItems,
          [action.payload.id]: {
            ...state.subItems[action.payload.id],
            open: false,
          },
        },
      }),

      setSubItems: (state: any, action: PayloadAction<any>) => {
        const { type, _id } = action.payload;
        if (type === 'audit') {
          return {
            ...state,
            subItems: {
              ...state.subItems,
              [action.payload.id]: {
                ...state.subItems[action.payload.id],
                data: action.payload.data,
              },
            },
          };
        } else {
          const key = getKeyFromId(type, _id);
          //payload contains the main object with nested data
          const aggregatedDetails =
            aggregateDetailCollector &&
            aggregateDetailCollector(action.payload.data);
          //the payload is the full object with the subitems array. The aggregated details belong to the data shown in the main row
          const item = findOldItem(state, action.payload.id);
          if (item) {
            const updatedItem = {
              ...item,
              ...action.payload.data,
              ...(aggregatedDetails && {
                aggregated_detail: aggregatedDetails,
              }),
            };

            return findAndReplace(key, state, updatedItem, true);
          }
        }

        return state;
      },

      removeDetails: (state: any, action: PayloadAction<any>) => {
        const { ids } = action.payload;
        if (ids.length === 0) return state;

        const details = { ...state.details };
        for (const id of ids) {
          const { open } = details[id] || {};
          details[id] = { open };
        }

        return {
          ...state,
          details,
        };
      },

      removeSubItems: (state: any, action: PayloadAction<any>) => {
        const { ids } = action.payload;
        if (ids.length === 0) return state;

        const subItems = { ...state.subItems };
        for (const id of ids) {
          const { open } = subItems[id] || {};
          subItems[id] = { open };
        }

        return {
          ...state,
          subItems,
        };
      },

      clearSubItems: (state: any) => ({
        ...state,
        subItems: {},
      }),
    },
  });
}

function replaceInList(list: ItemData, oldItem: any, newItem: BaseItem) {
  list = { ...oldItem, ...newItem };
  return list;
}

function validateItem(
  currentItem: BaseItem,
  key: string,
  errors: ValidationErrors,
  validate?: (item: BaseItem) => ErrorMessage[],
): ValidationErrors {
  if (validate) {
    return {
      ...errors,
      [key]: validate(currentItem).filter(Boolean),
    };
  }
  return errors;
}

function validateDetail(
  currentItem: BaseItem,
  validate?: (item: BaseItem, allItems: BaseItem) => ErrorMessage[],
  items?: any,
): ErrorMessage {
  let newErrors: any = {};
  if (validate) {
    const errorArray = validate(currentItem, items).filter(Boolean);
    newErrors = Object.fromEntries(
      errorArray.map((error: any) => [error.field, error.message]),
    );
    return newErrors;
  }
  return newErrors;
}

/**
 * Filter out the errors for the given key
 * @param errors
 * @param keys
 */
function filterErrors(validationErrors: ValidationErrors, keys: string[]) {
  return Object.fromEntries(
    Object.entries(validationErrors).filter(([key]) => !keys.includes(key)),
  );
}

/**
 *
 * @param id current item id
 * @param dataState all states
 * @returns item in subitems
 */
const findItemInSubItems = (id: number, dataState: DataState<BaseItem>) => {
  const subItems = { ...dataState.subItems };
  let oldItem = {};
  if (subItems) {
    for (const parentId in subItems) {
      if (subItems[parentId].open) {
        const productions = [...subItems[parentId]?.data?.productions] || [];
        const feeds = [...subItems[parentId]?.data?.feeds] || [];
        if (productions.length > 0) {
          const production = productions.find(
            (production: any) => production._id === id,
          );
          if (!isEmpty(production)) {
            oldItem = { ...retrofitProduction(production) };
          }
        }

        if (feeds.length > 0) {
          const feed = feeds.find((feed: any) => feed._id === id);
          if (!isEmpty(feed)) {
            oldItem = { ...retrofitFeed(feed) };
          }
        }
      }
    }
  }
  return oldItem;
};
