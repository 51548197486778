import {
  HourglassEmpty,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { TableContext } from '../InfiniteTable';

interface ExpandCellProps {
  item: any;
}

export function ExpandCell({ item }: ExpandCellProps) {
  const { subItems, toggleRow, disableSubRow, expandRowStyle } =
    useContext(TableContext);

  const toggleRowClick = toggleRow({ subItems });

  const disabled = disableSubRow(item);

  const Icon: any =
    (!subItems[item._id]?.open && KeyboardArrowRight) ||
    (!subItems[item._id]?.data && HourglassEmpty) ||
    KeyboardArrowDown;

  const className = disabled ? '_expand_disabled' : '_expand';
  return (
    <Box sx={expandRowStyle}>
      <button
        type="button"
        className={className}
        disabled={disabled}
        onClick={() => toggleRowClick(item)}
      >
        <Icon />
      </button>
    </Box>
  );
}
