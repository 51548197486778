import { createDataSlice } from './dataSlice';
import {
  transformProductionDelta,
  validateProduction,
} from '../../models/productions';

const productionsSlice = createDataSlice({
  sliceName: 'productions',
  validator: validateProduction,
  deltaTransformer: transformProductionDelta,
});

export const { reducer } = productionsSlice;
export const {
  create,
  commit,
  applyFilter,
  setRequestedTo,
  addItems,
  setFinished,
  update,
  rollback,
  clearList,
  undoCreate,
  undoChange,
  clearCreates,
  refreshItem,
  resetItemAction,
} = productionsSlice.actions;

export const actions = productionsSlice.actions;

export const getSlice = (state: any) => state.productions;
