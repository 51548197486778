import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import { Actions } from './Actions';
import { NotificationName } from './NotificationName';
import {
  addNotificationHeader,
  addNotificationInfo,
  createNotificationContent,
  dialogContent,
  notificationBoxHeader,
  notificationCriteriaBox,
  paperStyle,
} from './style';

import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import {
  clear,
  updateNotifications,
} from '../../../reducers/notificationSlice';
import useNotificationActions, {
  NotificationActionProps,
} from './Criterias/hooks';
import { NotificationOption } from './NotificationOption';
import { Notification, Target } from './NotificationSetting';
import { shouldBeDisabled } from './helper';

interface NotificationHandlerPageProps<T> {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  notification?: Notification;
  target: Target;
}

export function NotificationHandlerPage<T>({
  open,
  setOpen,
  notification,
  target,
}: NotificationHandlerPageProps<T>) {
  const actions: NotificationActionProps[] = useNotificationActions(
    notification?.type,
  );
  const { changes, errors } = useAppSelector(state => state.notifications);

  const dispatch = useAppDispatch();

  const handleChange = (newChange: any) => {
    dispatch(updateNotifications(newChange));
  };

  return (
    <Dialog
      hideBackdrop
      open={open}
      maxWidth="xl"
      PaperProps={{ sx: paperStyle }}
    >
      <DialogContent sx={dialogContent}>
        <Stack sx={createNotificationContent}>
          <Box sx={addNotificationHeader}>
            <Typography sx={notificationBoxHeader}>
              Add new notification for {notification?.name}
            </Typography>
            <Button
              id="close-button"
              startIcon={<Close />}
              onClick={() => {
                dispatch(clear());
                setOpen(!open);
              }}
            />
          </Box>
          <Stack sx={addNotificationInfo}>
            <NotificationName
              data={changes}
              errors={errors}
              handleChange={handleChange}
            />
            {notification?.renderCriteriaComponent}
            {actions.length > 0 && <Divider />}
            <Stack display="flex" flexDirection="row" gap={2}>
              {actions.length > 0 && (
                <>
                  <FormLabel>Action made on {notification?.type} is:</FormLabel>
                  {errors?.action && (
                    <Typography color="red">{errors.action}</Typography>
                  )}
                </>
              )}
            </Stack>
            <Box sx={notificationCriteriaBox}>
              {actions.length > 0 &&
                actions.map(({ id, label }: NotificationActionProps) => (
                  <FormControlLabel
                    key={id}
                    id={id}
                    label={label}
                    control={<Checkbox id={id} />}
                    disabled={shouldBeDisabled(changes, id)}
                    checked={changes[id as keyof typeof changes]}
                    onChange={(e: any) => {
                      handleChange({
                        [id]: e.target.checked,
                      });
                    }}
                  />
                ))}
            </Box>

            <Divider />
            <NotificationOption
              data={changes}
              handleChange={handleChange}
              errors={errors}
              notification={notification}
              target={target}
            />
            <Divider />
          </Stack>
        </Stack>
      </DialogContent>
      <Actions open={open} setOpen={setOpen} />
    </Dialog>
  );
}
