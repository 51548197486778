import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { unionBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { getSlice, updateDetails } from '../../../../reducers/leaguesSlice';

const LeagueRightsType = ({ id, type, setDisplayRightType }: any) => {
  const { details } = useAppSelector(getSlice);
  const dispatch = useAppDispatch();

  const handleChange = (event: any) => {
    const currentChanges = details.changes.rights || [];
    const existedRights = details.data.rights || [];
    const currentRights = unionBy(currentChanges, existedRights, 'id');
    const newRights = currentRights.map((right: any) => {
      if (right.id === id) {
        return {
          ...right,
          type: event.target.value,
        };
      }
      return right;
    });

    dispatch(updateDetails({ rights: newRights }));
    setDisplayRightType(false);
  };
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleChange}
        defaultValue={type}
      >
        <FormControlLabel value="SH" control={<Radio />} label="Shared" />
        <FormControlLabel value="EX" control={<Radio />} label="Exclusive" />
        <FormControlLabel value="UN" control={<Radio />} label="Uncertain" />
      </RadioGroup>
    </FormControl>
  );
};

export default LeagueRightsType;
