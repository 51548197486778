import { Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { authService } from '../../../services/AuthService';
import Roles from '../../../models/Roles';
import { Routes } from './Routes';

export default function NavTabs() {
  const TABS = ['events'];
  if (
    authService.hasRole([
      Roles.ADMIN,
      Roles.FEED_VIEWER,
      Roles.FEED_EDITOR,
      Roles.FEED_CREATOR,
    ])
  ) {
    TABS.push('feeds');
  }
  if (
    authService.hasRole([
      Roles.ADMIN,
      Roles.PRODUCTION_CREATOR,
      Roles.PRODUCTION_EDITOR,
      Roles.PRODUCTION_VIEWER,
    ])
  ) {
    TABS.push('productions');
    TABS.push('workspace');
  }

  const history = useHistory();
  const location = useLocation();

  const currentTab = location.pathname.substring(1);
  const tabIndex = TABS.includes(currentTab) ? TABS.indexOf(currentTab) : false;
  const handleChange = (newTabIndex: any) => {
    const newTab = TABS[newTabIndex];
    const path = Routes[newTab];
    history.push(path);
  };

  return (
    <Tabs
      value={tabIndex}
      aria-label="navigation tabs"
      TabIndicatorProps={{
        style: { background: '#fff', color: 'white', textDecoration: 'none' },
      }}
    >
      {TABS.map(tabName => (
        <Tab
          key={tabName}
          label={tabName}
          component={Link}
          to={`${tabName}?sessionId=${sessionStorage.getItem('sessionId')}`}
          onChange={handleChange}
        />
      ))}
    </Tabs>
  );
}
