import { Box, CircularProgress } from '@mui/material';

export default function LoadingBox() {
  return (
    <Box
      sx={{
        display: 'flex',
        margin: 20,
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
