import { useContext } from 'react';
import { get } from 'lodash';
import { CellProps } from '../index';
import { ValueCell } from './ValueCell';
import { BaseItem } from '../../../reducers/dataSlice';
import { EditCell } from '../../EditCell';
import { useAppDispatch } from '../../../reducers/hooks';
import { TooltipBox } from '../../Tooltip/Tooltip';
import { TableContext } from '../InfiniteTable';
import { getCellClasses } from '../utils';
import { getKeyFromId } from '../../../../models/records';

export function TextCell<T extends BaseItem>({
  column,
  item,
  editable,
  render,
  unRender,
}: CellProps<T>) {
  const value = get(item, column.key);
  const defaultRenderValue = render ? render(value) : value;
  const renderValue = column.render
    ? column.render(column, item)
    : defaultRenderValue;
  const dispatch = useAppDispatch();
  const { update, changes, errors, refreshed } = useContext(TableContext);
  const key = getKeyFromId(item.type, item._id);

  const refreshedColumns = (refreshed && refreshed[key]) || [];
  if (!editable) {
    return (
      <ValueCell
        value={renderValue}
        style={column.style}
        refreshed={refreshedColumns}
        property={column.key}
        align={column.textAlign}
      />
    );
  } else {
    return (
      <EditCell
        className={getCellClasses({
          property: column.key,
          delta: changes[key] || [],
          errors: errors[key] || [],
          refreshed: refreshedColumns,
          other: [column.style],
        })}
        id={item._id}
        field={column.key}
        value={value}
        displayValue={
          <TooltipBox
            value={
              column.onGetRenderValue
                ? column.onGetRenderValue(column, item, renderValue)
                : renderValue
            }
          />
        }
        initValue={(key: any) => key || renderValue}
        onChange={(input: any) => {
          const modifiedData = unRender ? unRender(input, value) : input;
          const delta = column.onBeforeChange
            ? column.onBeforeChange(modifiedData, item)
            : modifiedData;
          if (delta) {
            dispatch(
              update({
                type: item.type,
                _id: item._id,
                delta,
              }),
            );
          }
        }}
        renderEditor={(input: any, { onInput, onKeyDown, onBlur }: any) => (
          <input
            type="text"
            name={column.key}
            value={input}
            onKeyDown={onKeyDown}
            onInput={(e: any) => onInput(e.target.value)}
            onBlur={(e: any) => onBlur(e)}
            onFocus={e => {
              if (renderValue === input) e.target.select();
            }}
          />
        )}
      />
    );
  }
}
