import { useRef } from 'react';
import Roles from '../../../../models/Roles';
import { authService } from '../../../../services/AuthService';
import { InfiniteTable } from '../../../components/InfiniteTable/InfiniteTable';
import { useLiveUpdatesOnFilters } from '../../../components/hooks/websocketHook';
import { DataType } from '../../../reducers/dataSlice';
import {
  actions,
  getSlice,
  refreshItem,
  resetItemAction,
} from '../../../reducers/feedsSlice';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { useFeedColumnSettings } from './FeedColumSetting';
import { feedRowStyle } from './style';

import { retrofitFeed } from '../../../../models/feeds';
import api from '../../../../services/APIService';
import { filterToQueryParams } from '../../../../services/common';

export function FeedsTableNew() {
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.FEED_EDITOR]);
  const dataState = useAppSelector(getSlice);
  const columnsSettings: any = useFeedColumnSettings(DataType.Feed);
  const ref: any = useRef();
  const dispatch = useAppDispatch();
  useLiveUpdatesOnFilters(DataType.Feed, {
    onDataUpdate: (data: any[]) => {
      //dispatch data update
      data.forEach(item => {
        const feed = retrofitFeed(item);
        dispatch(refreshItem(feed));
        setTimeout(() => {
          dispatch(resetItemAction(feed));
        }, 3000);
      });
    },
  });

  return (
    <InfiniteTable
      api={api}
      minWidth="1900px"
      columns={columnsSettings}
      sub={false}
      slice={dataState}
      actions={actions}
      readOnly={readOnly}
      type={DataType.Feed}
      rowStyle={feedRowStyle}
      passRef={ref}
      filterToQuery={filterToQueryParams}
    />
  );
}
