import { sortBy } from 'lodash';
import { filterToQueryParams } from '../../services/common';
import { addNetworkError } from '../reducers/appSlice';
import { retrofitFeeds } from '../../models/feeds';
import { DataType } from '../reducers/dataSlice';
import { retrofitEvents } from '../../models/events';
import { retrofitProductions } from '../../models/productions';
import { retrofitLeagues } from './leagues';
import { staticService } from '../../services/StaticService';
import { filterLeagues } from '../../models/leagues';
import { retroFitAudits } from '../../models/audits';

interface SliceActions {
  addItems: any;
  setFinished: any;
  setRequestedTo: any;
  setLoading: any;
}

export interface DataProps {
  api: any; // Service used to load more data
  type: DataType; // type productions,feeds, etc....
  fromIndex: number; // default 0
  toIndex: number; // to
  filter: any; // filter used to build query
  dispatch: any; // update store
  sliceActions: SliceActions; // slice for the different typed
  filterToQuery(filter: any, type: string, index: any): any; // function to use to build the query
}

interface BaseUrls {
  [key: string]: any;
}

const dataUrls: BaseUrls = {
  feed: '/feeds',
  event: '/events',
  production: '/productions',
  workspace: '/events',
  league: '/leagues',
  audit: '/audit',
};

export const getUrl = (type: string, query: string): string => {
  if (type === DataType.League) {
    return `${dataUrls[type]}?status=all`;
  }
  if (type === DataType.Workspace) {
    query = `${query}&association=true`;
  }

  return `${dataUrls[type]}?${query}`;
};

// Loads data:
// * first batch,
// * On filter change
// * Scroll of infinite table

export async function loadMoreData({
  api,
  type,
  fromIndex,
  toIndex,
  filter,
  dispatch,
  sliceActions,
  filterToQuery,
}: DataProps) {
  if (toIndex <= fromIndex) return;
  const { addItems, setFinished, setRequestedTo, setLoading } = sliceActions;
  dispatch(setRequestedTo(toIndex));

  try {
    // Exclude league because it does not use query filter
    if (filter[type] && type !== DataType.League) {
      const query = filterToQuery(filter, type, fromIndex);
      dispatch(setLoading(true));
      const { items, isFinished } = await fetchData(type, query, api);

      if (items.length > 0) {
        dispatch(
          addItems({
            isFinished,
            items,
          }),
        );
      } else {
        dispatch(setFinished());
      }
      dispatch(setLoading(false));
    }

    if (filter[type] && type === DataType.League) {
      const { data, isFinished }: any = await getData(filter, type);
      if (data.length > 0) {
        dispatch(
          addItems({
            isFinished,
            items: data,
          }),
        );
      }
    }
  } catch (e) {
    console.log(e);
    dispatch(setLoading(false));
    dispatch(addNetworkError());
  }
}

interface ResponseData {
  items: any;
  isFinished: boolean;
}

export async function fetchData(
  type: DataType,
  query: any,
  api: any,
): Promise<ResponseData> {
  try {
    let pending: any = new AbortController();
    const url = getUrl(type, query);

    const response = await api.get(url, pending);
    pending = null;

    const { data, isFinished }: any = retrofitData(type, response);

    return {
      items: data,
      isFinished,
    };
  } catch (e) {
    console.warn(e);
    return { items: [], isFinished: true };
  }
}

export async function getData(filters: any, type: DataType) {
  const allLeagues = await staticService.data.allLeagues.all;
  const leagueFilter = filters[type]?.filter;
  const filteredLeagues = filterLeagues(leagueFilter, allLeagues);
  const leagues = retrofitData(type, filteredLeagues);
  return leagues;
}

/* Make the data fit the UI modell */
function retrofitData(type: DataType, response: any) {
  switch (type) {
    case DataType.Event:
      return {
        data: retrofitEvents(response.data),
        isFinished: !response.moreData,
      };
    case DataType.Feed:
      return {
        data: retrofitFeeds(response.data),
        isFinished: !response.moreData,
      };
    case DataType.Production:
      return {
        data: retrofitProductions(response.data),
        isFinished: !response.moreData,
      };
    case DataType.Workspace:
      return {
        data: retrofitEvents(response.data),
        isFinished: !response.moreData,
      };
    case DataType.League: {
      const leagues: any = retrofitLeagues(response);
      const sorted = sortBy(leagues, 'sportType');
      return { data: sorted, isFinished: true };
    }
    case DataType.Audit: {
      return {
        data: retroFitAudits(response.data),
        isFinished: !response.moreData,
      };
    }
    default:
      return [];
  }
}
