import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { SyntheticEvent } from 'react';
import { useAppDispatch } from '../../../reducers/hooks';
import { updateNotifications } from '../../../reducers/notificationSlice';
import { NotificationBox } from './NotificationBox';
import {
  Notification,
  Protocol,
  ProtocolType,
  SendFrequency,
  Target,
} from './NotificationSetting';
import { formControlLabel, whenBoxLabel } from './style';

interface NotificationOptionProps {
  data: any;
  handleChange(x: any): any;
  errors: any;
  notification?: Notification;
  target: Target;
}

export function NotificationOption({
  data,
  handleChange,
  errors,
  notification,
  target,
}: NotificationOptionProps) {
  const dispatch = useAppDispatch();

  const handleCheck = async (
    e: SyntheticEvent<Element, Event>,
    type: ProtocolType,
    frequency?: SendFrequency,
  ) => {
    if ((e as any).target.checked) {
      // Add protocol when checked
      dispatch(
        updateNotifications({
          notificationBoxes: {
            ...data.notificationBoxes,
            [type]: notification?.getNewNotificationBox(type, frequency),
          },
        }),
      );
    } else {
      // Remove protocol if is unchecked
      const newBox = { ...data.notificationBoxes };
      delete newBox[type];
      dispatch(updateNotifications({ notificationBoxes: newBox }));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={whenBoxLabel}>
        <FormLabel>How do I want to be notified:</FormLabel>
        {errors?.protocol && (
          <Typography color="red">{errors.protocol}</Typography>
        )}
      </Box>
      {notification
        ?.getProtocol(target)
        ?.map(({ label, type, frequency, information }: Protocol) => (
          <Box
            key={type}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <FormControlLabel
              id={type}
              control={<Checkbox id={type} />}
              label={`${label} ( ${
                notification?.userInfo[type] ||
                `To enable ${type} notifications update account information`
              } )`}
              onChange={e => handleCheck(e, type, frequency)}
              checked={!!data?.notificationBoxes[type]}
              disabled={!notification?.userInfo[type]}
              sx={formControlLabel}
            />
            {!!data?.notificationBoxes[type] && (
              <NotificationBox
                data={data}
                protocol={type}
                handleChange={handleChange}
                notification={notification}
                information={information}
              />
            )}
          </Box>
        ))}
    </Box>
  );
}
