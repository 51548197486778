import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FilterAction, save } from '../../../api/user';

const { Delete } = require('@mui/icons-material');

interface DeleteProps {
  id: number;
}

export function DeleteIcon({ id }: DeleteProps) {
  const dispatch = useDispatch();
  return (
    <IconButton
      size="small"
      onClick={() => save({ action: FilterAction.DELETE, dispatch, id })}
    >
      <Delete />
    </IconButton>
  );
}
