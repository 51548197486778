import React from 'react';
import { EditCell } from './EditCell';

const ALL_DIGITS = /^\d+$/;

const forceNumber = (s: string) => (ALL_DIGITS.test(s) ? Number(s) : s);

interface SelectCellProps {
  className: any;
  id: number;
  field: string;
  options: any;
  value: any;
  displayValue: any;
  onChange(x: any): any;
  pass?: any;
}

export function SelectCell({
  className,
  id,
  field,
  options,
  value,
  displayValue,
  onChange,
  ...pass
}: SelectCellProps) {
  return (
    <EditCell
      className={className}
      id={id}
      field={field}
      value={value}
      displayValue={displayValue}
      onChange={onChange}
      initValue={() => value}
      renderEditor={(input: any, { onCommit, onKeyDown }: any) => (
        <select
          name={field}
          value={input || ''}
          onKeyDown={onKeyDown}
          onChange={e => onCommit(forceNumber(e.target.value))}
        >
          {options.map(
            (option: {
              value: React.Key | readonly string[] | null | undefined;
              label:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined;
            }) => (
              <option key={(option as any).value} value={(option as any).value}>
                {option.label}
              </option>
            ),
          )}
        </select>
      )}
      {...pass}
    />
  );
}
