import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { Snackbar } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  NOTIFICATION_STATUS,
  NotificationItem,
  getSlice,
} from '../../reducers/inAppNotificationSlice';
import { NotificationsMenu } from './NotificationsMenu';

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isNotifMenuOpen = Boolean(anchorEl);
  const notifications: NotificationItem[] = useSelector(getSlice).notifications;
  const serviceOnline: NotificationItem[] = useSelector(getSlice).serviceOnline;
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [notificationItem, setNotificationItem] = useState<
    NotificationItem | undefined
  >(undefined);

  const handleExited = () => {
    setNotificationItem(undefined);
  };

  const handleNotificationClose = (
    event: SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackOpen(false);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="show new notifications"
        color="inherit"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <Badge
          badgeContent={
            notifications &&
            notifications.filter(
              notification =>
                notification.status === NOTIFICATION_STATUS.UNREAD,
            ).length
          }
          color="error"
        >
          {serviceOnline ? <NotificationsIcon /> : <NotificationsOffIcon />}
        </Badge>
      </IconButton>
      <NotificationsMenu
        anchor={anchorEl}
        open={isNotifMenuOpen}
        setAnchorEl={setAnchorEl}
        notifications={notifications}
      />

      <Snackbar
        key={notificationItem ? notificationItem.id : undefined}
        TransitionProps={{ onExited: handleExited }}
        message={notificationItem ? notificationItem.message : undefined}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isSnackOpen}
        autoHideDuration={5000}
        onClose={handleNotificationClose}
      />
    </>
  );
};

export default Notifications;
