import {
  ChannelType,
  InMessageType,
  RequestMessage,
  ResponseMessage,
  Transport,
  TransportCallback,
  TransportStatus,
} from './Transport';
import WebsocketService from './WebsocketTransport';

/**
 * Streaming service used by the application to send and receive streaming data from the websocket layer.
 * The component will use only one websocket connection (one transport) for the whole application.
 * It will keep track the channel subscription and will route the message received to the right receiver.
 */
export interface StreamReceiver {
  id: number;
  type: ChannelType;
  onOnline?: (online: boolean) => void;
  onDataUpdate: (data: any[]) => void;
}

export interface StreamReceiverCallback {
  onOnline?: (online: boolean) => void;
  onDataUpdate: (data: any[]) => void;
}

export interface ChannelAttributes {
  filter?: any;
  from?: string;
  to?: string;
}

const RECONNECT_TRIES = 3;

/**
 * Component to handle and keep track of the channels and receivers.
 * Will handle the reponses coming from the websocket layer and handle it accordingly.
 */
class StreamService implements TransportCallback {
  transport: Transport = {} as Transport;

  started: boolean = false;

  restarting: boolean = false;

  token: string = '';

  reconnectTries: number = 0;

  status: TransportStatus = TransportStatus.initialized;

  channels: any = {};

  receivers: any = {};

  receiverToChannel: any = {};

  receiverToChannelRequest: any = {};

  receiverByType: any = {};

  requestRef: number = 0;

  receiverIdGenerator: number = 0;

  onStatus = (status: TransportStatus) => {
    if (
      this.status === TransportStatus.online &&
      status === TransportStatus.offline
    ) {
      this.restarting = true;
    }
    this.status = status;
    const online = this.status === TransportStatus.online;
    const backOnline = this.restarting && online;
    this.notifyReceivers(online);
    if (this.status === TransportStatus.offline) {
      this.reconnect();
    }
    if (backOnline) {
      //Restore subscrition if it has been reconnected
      this.restarting = false;
      this.restoreSubscriptions();
    }
  };

  private notifyReceivers = (online: boolean) => {
    for (const key in this.receivers) {
      const receiver: StreamReceiver = this.receivers[key];
      if (receiver.onOnline) {
        receiver.onOnline(online);
      }
    }
  };

  private restoreSubscriptions = () => {
    const requests: RequestMessage[] = Object.values(
      this.receiverToChannelRequest,
    );
    console.log(`restoring subscriptions ${requests.length} subs`);
    requests.forEach(request => {
      this.transport.sendRequest(request);
    });
  };

  private cleanState = () => {
    this.channels = {};
    this.receivers = {};
    this.requestRef = 0;
    this.receiverToChannel = {};
  };

  onResponse = (response: ResponseMessage) => {
    switch (response.type) {
      case InMessageType.ch_created: {
        if (response.ref === undefined) {
          console.log(`Missing request reference ${JSON.stringify(response)}`);
          return;
        }

        if (response.channel_id === undefined) {
          console.log(`Missing channel id ${JSON.stringify(response)}`);
          return;
        }
        const receiver: StreamReceiver = this.receivers[response.ref];

        this.channels[response.channel_id] = receiver;
        this.receiverToChannel[response.ref] = response.channel_id;
        this.transport.subscribe(response.channel_id);
        break;
      }

      case InMessageType.sub_done: {
        console.log(`subscribed to channel: ${response.channel_id}`);
        if (response.channel_id) {
          const receiver = this.channels[response.channel_id];
          if (receiver && receiver.onOnline) {
            receiver.onOnline(true);
          }
        }

        break;
      }

      case InMessageType.sub_error: {
        console.log(`sub error channel: ${response.channel_id}`);
        break;
      }

      case InMessageType.unsub_done: {
        if (response.channel_id) {
          console.log(`unsubscribed from channel:  ${response.channel_id}`);
          delete this.channels[response.channel_id];
        }
        break;
      }
      case InMessageType.ch_error: {
        console.log(`Invalid channel request: ${response.message}`);
        break;
      }

      case InMessageType.ch_update: {
        if (response.channel_id) {
          const receiver = this.channels[response.channel_id];
          if (receiver) {
            receiver.onDataUpdate(response.data);
            break;
          }
          console.log(`No receiver found for channel ${receiver}`);
        }

        break;
      }

      default: {
        console.log(`Unexpected response ${response.type}`);
      }
    }
  };

  reconnect = () => {
    ++this.reconnectTries;
    console.log(`reconnecting ws... trying ${this.reconnectTries}`);
    setTimeout(() => this.connect(this.token), 1000);

    // if (this.reconnectTries <= RECONNECT_TRIES) {
    //   console.log(`reconnecting ws... trying ${this.reconnectTries}`);
    //   setTimeout(() => this.connect(this.token), 1000);
    // } else {
    //   console.log(
    //     `not able to connect to websocket after ${this.reconnectTries} tries`,
    //   );
    // }
  };

  private connect = (token: string): boolean => {
    try {
      this.transport = new WebsocketService(
        `${process.env.REACT_APP_WS_URL}`,
        token,
        this,
      );
      return true;
    } catch (error) {
      console.log(`Not able to connect ${error}`);
    }

    return false;
  };

  start = (token: string) => {
    if (!this.started) {
      this.token = token;
      this.started = this.connect(this.token);
    }
  };

  /**
   * Build ethe channel request according to the Websocket specs.
   * @param type
   * @param attributes
   * @param requestReference
   */
  private buildChannelRequest = (
    type: ChannelType,
    attributes: ChannelAttributes,
    requestReference: number,
  ) => {
    switch (type) {
      case ChannelType.event:
        return {
          event_request: {
            ...attributes,
            ref: requestReference,
          },
        };
      case ChannelType.productions:
        return {
          production_request: {
            ...attributes,
            ref: requestReference,
          },
        };
      case ChannelType.feeds:
        return {
          feed_request: {
            ...attributes,
            ref: requestReference,
          },
        };

      case ChannelType.workspace:
        return {
          wsp_request: {
            ...attributes,
            ref: requestReference,
          },
        };

      case ChannelType.notification:
        return {
          notification_request: {
            ...attributes,
            ref: requestReference,
          },
        };

      default:
        throw Error(`Invalid request type ${type}`);
    }
  };

  /**
   * Register receiver giving an id
   *
   * Before requesting for a channel, we need to register the receiver.
   *
   * @param channlType
   * @param callback
   * @returns
   */
  registerReceiver = (
    channelType: ChannelType,
    callback: StreamReceiverCallback,
  ): StreamReceiver => {
    let receiver: StreamReceiver = this.receiverByType[channelType];
    if (receiver) {
      return receiver;
    }
    receiver = {
      id: ++this.receiverIdGenerator,
      type: channelType,
      ...callback,
    };
    this.receivers[receiver.id] = receiver;
    this.receiverByType[channelType] = receiver;
    return receiver;
  };

  isCommunicationReady = (): boolean => {
    if (!this.started) {
      console.log('Websocket layer not started');
      return false;
    }
    if (this.status === TransportStatus.offline) {
      console.log('Websocket layer offline');
      return false;
    }
    return true;
  };

  /**
   * Send a request for a channel for the given criterias. The backend service will resolve your request to a unique channel id shared among
   * many other users. After receiving the channel id, it automatically subscribe to it.
   * @param attributes
   * @param receiver
   */
  requestChannel = (
    attributes: ChannelAttributes,
    receiver: StreamReceiver,
  ) => {
    if (!this.isCommunicationReady()) {
      return;
    }
    if (!this.started) {
      console.log('Websocket layer not started');
      return;
    }
    if (this.status === TransportStatus.offline) {
      console.log('Websocket layer offline');
      return;
    }
    if (receiver.id === undefined) {
      console.log(`Receiver must be registered first ${receiver.type}`);
      return;
    }

    const request: RequestMessage = this.buildChannelRequest(
      receiver.type,
      attributes,
      receiver.id,
    );

    this.receiverToChannelRequest[receiver.id] = request;
    this.transport.sendRequest(request);
  };

  leaveChannel = (receiver: StreamReceiver) => {
    const channelId = this.receiverToChannel[receiver.id];
    if (channelId) {
      this.transport.unsubscribe(channelId);
      delete this.receiverToChannel[receiver.id];
      delete this.receiverToChannelRequest[receiver.id];
    }
  };
}

export const streamService = new StreamService();
