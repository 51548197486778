import { Badge, Stack } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Roles from '../../../../models/Roles';
import { createSubFeed } from '../../../../models/feeds';
import { createSubProduction } from '../../../../models/productions';
import { getKeyFromId, isNewId } from '../../../../models/records';
import { authService } from '../../../../services/AuthService';
import { TableContext } from '../../../components/InfiniteTable/InfiniteTable';
import { DataType, ItemAction } from '../../../reducers/dataSlice';
import { columns } from '../../Calendar/EventsTable/style';
import { openAndLoad } from './useToggleRow';

const buttonStyle = {
  height: '35px',
  alignItems: 'center',

  button: {
    width: '24px',
    height: '24px',
    border: '2px solid #fff',
    borderRadius: '50%',
    color: '#fff',

    ':enabled': {
      cursor: 'pointer',
    },
    ':disabled': {
      opacity: 0.5,
    },

    '&.-prod': {
      backgroundColor: '#b17e9b',
    },
    '&.-feed': {
      backgroundColor: '#96a9b3',
    },
  },
};

const buttonStyleBlinking = {
  ...buttonStyle,
  animation: 'blink-animation 1s linear infinite',
};

interface EventCounterProps {
  item: any;
}

export function EventCounter({ item }: EventCounterProps) {
  const dispatch = useDispatch();
  const { create, creates, changes, subItems, refreshed } =
    useContext(TableContext);
  const disableProd = !authService.hasRole([
    Roles.ADMIN,
    Roles.PRODUCTION_EDITOR,
  ]);
  const disableFeed = !authService.hasRole([Roles.ADMIN, Roles.FEED_EDITOR]);

  const key = getKeyFromId(item.type, item._id);
  const refreshedColumns = (refreshed && refreshed[key]) || [];

  const columnStyle =
    'aggregated_detail' in refreshedColumns ? buttonStyleBlinking : buttonStyle;

  const prodsChanged = useMemo(
    () =>
      Object.values(creates).some(
        (create: any) =>
          create.type === DataType.Production &&
          create.associations?.events?.includes(item._id),
      ) ||
      subItems.data?.productions?.some(
        (prod: any) => changes[getKeyFromId(DataType.Production, prod._id)],
      ),
    [subItems, creates, changes, item],
  );

  const feedsChanged = useMemo(
    () =>
      Object.values(creates).some(
        (create: any) =>
          create.type === DataType.Feed &&
          create.associations?.events?.includes(item._id),
      ) ||
      subItems.data?.productions?.some(
        (feed: any) => changes[getKeyFromId(DataType.Feed, feed._id)],
      ),
    [subItems, creates, changes, item],
  );

  const onClickProd = () => {
    if (item.itemAction !== ItemAction.NEW) {
      const newProduction = createSubProduction(item);
      dispatch(create(newProduction));
      openAndLoad(item._id, subItems, dispatch);
    }
  };

  const onClickFeed = () => {
    if (item.itemAction !== ItemAction.NEW) {
      const newFeed = createSubFeed(item);
      dispatch(create(newFeed));
      openAndLoad(item._id, subItems, dispatch);
    }
  };

  return (
    <Stack className={columns.subCount} direction="row" sx={columnStyle}>
      {!isNewId(item._id) && (
        <Badge
          variant="dot"
          color="secondary"
          overlap="circular"
          invisible={!prodsChanged}
        >
          <button
            type="button"
            className="-prod"
            onClick={onClickProd}
            disabled={disableProd}
          >
            {item?.aggregated_detail?.productions?.length || '0'}
          </button>
        </Badge>
      )}
      {!isNewId(item._id) && (
        <Badge
          variant="dot"
          color="secondary"
          overlap="circular"
          invisible={!feedsChanged}
        >
          <button
            type="button"
            className="-feed"
            onClick={onClickFeed}
            disabled={disableFeed}
          >
            {item?.aggregated_detail?.feeds?.length || '0'}
          </button>
        </Badge>
      )}
    </Stack>
  );
}
