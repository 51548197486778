import { useAppSelector } from '../app/reducers/hooks';
import { getFilterStorage } from './storage';

export function filterToCriteria(
  filter: { [s: string]: unknown } | ArrayLike<unknown>,
  allowFields?: string | string[] | undefined,
) {
  return Object.entries(filter)
    .filter(([field]) => !allowFields || allowFields.includes(field))
    .flatMap(([field, value]) =>
      Array.isArray(value)
        ? value.map(item => ({ field, value: item }))
        : { field, value },
    );
}

export function deduplicateFilter(filter: { [x: string]: any }) {
  for (const field in filter) {
    filter[field] = Array.from(new Set(filter[field]));
  }
  return filter;
}

export function buildBlankFilter(allowFields: never[]) {
  return Object.fromEntries(allowFields.map((field: any) => [field, []]));
}

export function buildFilterFromCriteria(
  criteria: { field: any; value: any }[],
  allowFields = [],
) {
  const filter = buildBlankFilter(allowFields);

  for (const row of criteria) {
    if (!filter[row.field]) {
      filter[row.field] = [];
    }
    filter[row.field].push(row.value);
  }

  return deduplicateFilter(filter);
}

export function consolidateFilters(filters: any[], allowFields = []) {
  const result = buildBlankFilter(allowFields);

  for (const filter of filters) {
    for (const field in filter) {
      if (!result[field]) {
        result[field] = [];
      }
      result[field] = result[field].concat(filter[field]);
    }
  }

  return deduplicateFilter(result);
}

export const useStoredFilters = () => {
  const filter = useAppSelector(state => state.filter);
  const storedFilter = getFilterStorage();
  let customFilter = {};
  let filterPresets: number[] = [];
  if (storedFilter) {
    customFilter = storedFilter?.customFilter || {};
    filterPresets = storedFilter?.filterPresets || [];
  } else {
    customFilter = {
      ...filter.customFilter,
    };
    filterPresets = [...filter?.filterPresets];
  }

  return { customFilter, filterPresets };
};
