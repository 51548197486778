import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import RenderOnRole from '../../container/Auth/RenderOnRole';
import Roles from '../../../models/Roles';
import { authService } from '../../../services/AuthService';
import { useAppDispatch } from '../../reducers/hooks';
import { clearList as ClearLeagueList } from '../../reducers/leaguesSlice';
import { clearList as ClearAuditList } from '../../reducers/auditSlice';

interface SettingsMenuProps {
  anchor: any;
  open: boolean;
  setAnchorEl(value: any): any;
}

export function SettingsMenu({ anchor, open, setAnchorEl }: SettingsMenuProps) {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAdmin = () => {
    authService.openUserManagement();
    setAnchorEl(null);
  };

  const openLeague = () => {
    dispatch(ClearLeagueList(false));
    setAnchorEl(null);
  };

  const openAuditHistory = () => {
    dispatch(ClearAuditList(false));
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchor}
      id="settings-menu"
      open={open}
      onClose={handleClose}
    >
      <RenderOnRole
        roles={[
          Roles.ADMIN,
          Roles.LEAGUE_CREATOR,
          Roles.LEAGUE_EDITOR,
          Roles.LEAGUE_VIEWER,
        ]}
      >
        <MenuItem onClick={openLeague} component={Link} to="/league">
          Leagues
        </MenuItem>
        <MenuItem onClick={openAuditHistory} component={Link} to="/history">
          Audit History
        </MenuItem>
      </RenderOnRole>

      <RenderOnRole roles={[Roles.ADMIN, Roles.USER_ADMIN]}>
        <MenuItem onClick={openAdmin}>User Administration</MenuItem>
      </RenderOnRole>
    </Menu>
  );
}
