import { Box } from '@mui/system';
import { BaseItem } from '../../reducers/dataSlice';
import { CreateButton } from './CreateSubRowButton';
import { ColumnSetting, HeaderProps } from './index';

export function TableHeader<T extends BaseItem>({
  columns,
  sub,
  rowStyle,
  canCreate,
  item,
  badgeName,
  createSubRow,
  minWidth,
}: HeaderProps<T>) {
  return (
    <Box
      className={sub && canCreate ? '-subheader' : '-header'}
      sx={{
        minWidth,
        fontWeight: 600,
        overflow: 'visible',
        ...rowStyle,
      }}
    >
      {columns.map((column: ColumnSetting) => {
        if (!column) return;
        if (column.key === '_id' || column.key === 'id') {
          return (
            <span key={column.key} className={column.style}>
              {sub && canCreate ? (
                <span className="_badge">{badgeName}</span>
              ) : (
                column.header
              )}
            </span>
          );
        }
        if (column.key === 'admin') {
          return (
            <span
              key={`${column.key}-${column.header}`}
              className={column.style}
            >
              {column.header}
              {sub && canCreate && (
                <CreateButton
                  badgeName={badgeName}
                  item={item}
                  onCreateSubRow={createSubRow}
                />
              )}
            </span>
          );
        }
        return (
          <span key={`${column.key}-${column.header}`} className={column.style}>
            {column.header}
          </span>
        );
      })}
    </Box>
  );
}
