import { useRef } from 'react';
import Roles from '../../../../models/Roles';
import { authService } from '../../../../services/AuthService';
import { ColumnSetting, ColumnType } from '../../../components/InfiniteTable';
import { InfiniteTable } from '../../../components/InfiniteTable/InfiniteTable';
import { BaseItem, DataType } from '../../../reducers/dataSlice';
import { useAppSelector } from '../../../reducers/hooks';
import { actions, getSlice } from '../../../reducers/leaguesSlice';
import { columns, leagueRowStyle } from './style';
import { staticService } from '../../../../services/StaticService';
import { RenderRightColumn } from '../../Calendar/EventsTable/CustomRows';
import api from '../../../../services/APIService';
import { filterToQueryParams } from '../../../../services/common';

interface LeaguesTableProps {
  handleClickedRow: any;
}

export function LeaguesTable({ handleClickedRow }: LeaguesTableProps) {
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.LEAGUE_EDITOR]);
  const slice = useAppSelector(getSlice);
  const ref: any = useRef();

  const columnsSettings: ColumnSetting[] = [
    {
      key: '_id',
      header: 'ID',
      datatype: ColumnType.Text,
      style: columns.id,
      editable: false,
    },
    {
      key: 'name',
      header: 'Name',
      datatype: ColumnType.Text,
      style: columns.name,
      editable: false,
      textAlign: 'left',
    },
    {
      key: 'sportType',
      header: 'Sport',
      datatype: ColumnType.Text,
      style: columns.sport,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        const sport = staticService.data.sports.all.find(
          (sport: any) => sport.internalName === item.sportType,
        );
        if (sport) {
          return sport.name;
        }
        return item.sportType;
      },
    },
    {
      key: 'abbreviationName',
      header: 'Abreviation',
      datatype: ColumnType.Text,
      style: columns.abbreviationName,
      editable: false,
    },
    ...staticService.data.countries.all.map((country: any) => {
      return {
        key: country.code,
        header: country.code.toUpperCase(),
        datatype: ColumnType.Custom,
        style: columns.rights,
        editable: false,
        render: (x: any, y: any) => RenderRightColumn({ column: x, item: y }),
      };
    }),
  ];

  return (
    <InfiniteTable
      api={api}
      minWidth="1700px"
      columns={columnsSettings}
      slice={slice}
      actions={actions}
      readOnly={readOnly}
      type={DataType.League}
      rowStyle={leagueRowStyle}
      onRowClick={handleClickedRow}
      passRef={ref}
      filterToQuery={filterToQueryParams}
    />
  );
}
