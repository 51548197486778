import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Roles from '../../../models/Roles';
import { FilterToolbar } from '../../components/Filters/FilterToolbar';
import { useFilters } from '../../components/Filters/hooks';
import { Toasts } from '../../components/Toasts';
import { WithStaticData } from '../../components/WithStaticData';
import RenderOnRole from '../Auth/RenderOnRole';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import {
  applyFilter,
  clearList,
  clearSubItems,
  commit,
  create,
  getSlice,
  rollback,
} from '../../reducers/workspaceSlice';
import { EventsTable } from './EventsTable/EventsTable';
import { Actions } from '../../components/Actions/Actions';
import { CreateAction } from '../../components/Actions/CreateAction';
import { createNewEvent } from '../../../models/events';
import { UndoAction } from '../../components/Actions/UndoAction';
import { SaveAction } from '../../components/Actions/SaveAction';
import { DataType } from '../../reducers/dataSlice';
import { getKeyFromId } from '../../../models/records';
import { saveAction as saveProductions } from '../Production/ActionHandler';
import { loadAndSet } from './EventsTable/useToggleRow';
import { onSave as saveEvents } from '../Calendar/ActionsHandler';
import { onSave as saveFeed } from '../Feed/ActionsHandler';
import { DownloadAction } from '../../components/Actions/DownloadAction';
import { downloadLists } from '../../api/dataDownloader';

export function Workspace() {
  useFilters(DataType.Workspace, applyFilter);
  const { fromDate, toDate } = useAppSelector(state => state.filter);

  const dispatch = useAppDispatch();
  const filter = useAppSelector(state => state.filter);

  const { creates, changes, errors, subItems, items } =
    useAppSelector(getSlice);
  const [loading, setLoading] = useState<boolean>(false);
  const changeCount = Object.keys(changes).length + Object.keys(creates).length;
  const hasErrors =
    Object.keys(errors).length > 0 &&
    Object.values(errors).some((itemErrors: any) => itemErrors.length > 0);

  const onCreate = () => {
    const newEvent = createNewEvent(fromDate);
    dispatch(create(newEvent));
  };

  // Clear details after navigating away
  useEffect(() => {
    dispatch(clearSubItems());
  }, [dispatch]);

  // Clear details when changing date range
  useEffect(() => {
    dispatch(clearSubItems());
  }, [fromDate, toDate, dispatch]);

  const onSaveAction = async () => {
    setLoading(true);
    const eventCommits: any = await saveEvents(
      creates,
      changes,
      dispatch,
      items,
    );
    const prodCommits: any = await saveProductions({
      creates,
      changes,
      subItems,
      dispatch,
    });
    const feedCommits: any = await saveFeed(creates, changes, dispatch);
    setLoading(false);

    dispatch(
      commit({
        itemCreatedKeys: [
          ...eventCommits.itemCreatedKeys,
          ...prodCommits.itemCreatedKeys,
          ...feedCommits.itemCreatedKeys,
        ],
        itemUpdatedKeys: [
          ...eventCommits.itemUpdatedKeys,
          ...prodCommits.itemUpdatedKeys,
          ...feedCommits.itemUpdatedKeys,
        ],
      }),
    );

    //If events were saved, clear the list
    if (
      eventCommits.itemUpdatedKeys.length > 0 ||
      eventCommits.itemCreatedKeys.length > 0
    ) {
      dispatch(clearList(false));
    }

    // If prods/feeds were saved, reload those details
    // If prods/feeds were created, update the counters on the events
    const clearDetailIds = [];
    for (const eventKey in subItems) {
      const eventId = Number(eventKey);
      const eventDetails = subItems[eventKey];

      const prodsCreated = Object.values(creates).some(
        (create: any) =>
          create.type === DataType.Production &&
          create.associations?.events?.includes(eventId),
      );
      const feedsCreated = Object.values(creates).some(
        (create: any) =>
          create.type === DataType.Feed &&
          create.associations?.events?.includes(eventId),
      );
      const prodsChanged = eventDetails.data?.productions?.some(
        (prod: any) => !!changes[getKeyFromId(DataType.Production, prod._id)],
      );
      const feedsChanged = eventDetails.data?.feeds?.some(
        (feed: any) => !!changes[getKeyFromId(DataType.Feed, feed._id)],
      );
      if (prodsChanged || feedsChanged || prodsCreated || feedsCreated) {
        clearDetailIds.push(eventId);
      }
    }

    clearDetailIds.forEach(eventId => loadAndSet(eventId, dispatch));
  };

  return (
    <WithStaticData realms={['public', 'private']}>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FilterToolbar presetType="workspace" />
        <EventsTable />
        <Actions>
          <RenderOnRole
            roles={[Roles.ADMIN, Roles.EVENT_CREATOR, Roles.EVENT_EDITOR]}
          >
            {changeCount > 0 && (
              <UndoAction onUndo={() => dispatch(rollback())} />
            )}
            {changeCount > 0 && (
              <SaveAction
                changeCount={changeCount}
                hasErrors={hasErrors}
                onSave={onSaveAction}
                disabled={loading}
              />
            )}
            <CreateAction onSave={onCreate} type="workspace" />
          </RenderOnRole>
          <RenderOnRole
            roles={[
              Roles.ADMIN,
              Roles.EVENT_CREATOR,
              Roles.EVENT_EDITOR,
              Roles.EVENT_VIEWER,
            ]}
          >
            <DownloadAction
              onDownload={() =>
                downloadLists({ filter, type: DataType.Workspace, dispatch })
              }
            />
          </RenderOnRole>
        </Actions>
        <Toasts />
      </Box>
    </WithStaticData>
  );
}
