import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { authService } from '../../../services/AuthService';
import { CalendarPage } from '../Calendar';
/**
 * The component will be rendered accordign to the given roles, if not will fallback to CalendarPage (default page)
 * @param {*} param0
 * @returns
 */

interface RolesRouteProps {
  roles: any;
  children: any;
  path: any;
}
const RolesRoute = ({ roles, children, path }: RolesRouteProps) => (
  <Route path={path}>
    {authService.hasRole(roles) ? children : <CalendarPage />}
  </Route>
);

RolesRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RolesRoute;
