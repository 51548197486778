import { BaseItem, DataState, DataType } from '../../reducers/dataSlice';
import { DisplaySet } from '../../api/common';
import { AdminIcons } from './cells/AdminCell';

// eslint-disable-next-line no-shadow
export enum ColumnType {
  Admin,
  Boolean,
  Custom,
  Date,
  DateTime,
  Dropdown,
  Empty,
  Expand,
  Text,
  TextStatus,
  Time,
  Toggle,
}

export interface ColumnSetting {
  key: string; // Name of field in object
  header: any; // Header that will be used
  datatype?: ColumnType; // What type is column [text, date, time, etc..]
  style?: string; // styling for the column
  subheader?: boolean; // if the table has a subtable
  editable: boolean; // If you can click on column and edit
  editRole: boolean;
  textAlign?: string; // left, right, center, etc
  toggleStyleActive: string; //style when toggle is active
  checkEdit?: (item: BaseItem) => any; // function to check if you can edit column
  onGetIconToDisplay?: (
    item: BaseItem,
    changes: any,
    type: string,
  ) => AdminIcons; // used for the admin columns to define which icon to display
  onGettingOptions?: (item: BaseItem) => DisplaySet; // Used when column is a dropdown and when getting options
  onBeforeChange?: (delta: any, item: BaseItem) => any; // If you have to habdle the data before it get changed (ceck another column)
  onGetDisplayValue?: (options: any, value: any, style?: any) => any; // Used for dropdown columns to get the display value
  onGetRenderValue?: (
    column: ColumnSetting,
    item: BaseItem,
    value?: any,
  ) => any;
  render?: (column: ColumnSetting, item: BaseItem, subItems?: any) => void; // Used when you want to handle the data before its displayed (ex. changing from utc to cet)
  onCopy?: (item: BaseItem, dispatch: any) => any; // used when a rows gets copied, to handle what to copy
}

export interface InfiniteTableProps<T extends BaseItem> {
  api: any; // service to use when fetching data
  type: DataType; // predefined data type
  columns: ColumnSetting[]; //columns
  sub?: boolean; // contains subtable
  subTable?: any; // the subtables
  disableSubRow?: (item: BaseItem) => boolean; // disabble subrow
  toggleRow?(item: any, subItems: any): any; // function that handles when the row is open
  expandRowStyle?: any; // optional style used for the expand row
  slice: DataState<T>; // reducer slice used for the table
  actions: any; // actions used by the data slice
  passRef?: string; // ref to table
  readOnly?: boolean; // defines if table is readOnly
  rowStyle?: any; // rowstyles
  getRowHeight?: (
    index: number,
    rows: any,
    creates: any,
    subItems: any,
  ) => number; // calculates the row height when using subtables
  onRowClick?: any; // function to use if clicking row leads to action
  filterToQuery: any; // pass query that is used for the filters
  minWidth: string;
}

export interface HeaderProps<T extends BaseItem> {
  columns: ColumnSetting[]; // Pass defined column settings
  sub?: boolean;
  rowStyle: any;
  canCreate?: any;
  item?: any;
  slice?: DataState<T>;
  badgeName?: string;
  createSubRow?(item: any): any;
  minWidth: string;
}

export interface RowProps<T extends BaseItem> {
  item: T;
  columns: ColumnSetting[];
  style?: any;
  readOnly?: boolean;
  tableRowStyle?: any;
  subRows?: any;
  onRowClick?: any;
}

export interface CellProps<T extends BaseItem> {
  item: T;
  column: ColumnSetting;
  editable?: boolean;
  render?: (input: any) => any;
  unRender?: (newInput: any, oldInput: any) => any;
}
