import { useState, useEffect } from 'react';
import { Alert, Stack } from '@mui/material';
import { removeMissingFieldToast, removeToast } from '../../reducers/appSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';

export function Toasts() {
  const { toasts, missingFieldToasts } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();

  const [timers, setTimers] = useState({});
  useEffect(() => {
    setTimers(reconcileTimers(toasts, timers, dispatch));
  }, [toasts, timers, dispatch]);

  return (
    <Stack sx={{ position: 'fixed', bottom: 16, left: 16 }} gap={1}>
      {toasts.map((toast: any) => (
        <Alert
          id="crud-message"
          key={toast.id}
          severity={toast.severity}
          sx={{ width: '100%' }}
        >
          {toast.message}
        </Alert>
      ))}
      {missingFieldToasts.map((toast: any) => (
        <Alert
          key={toast.id}
          severity={toast.severity}
          onClose={() => dispatch(removeMissingFieldToast(toast.id))}
          sx={{ whiteSpace: 'pre-line' }}
        >
          {toast.message}
        </Alert>
      ))}
    </Stack>
  );
}

function reconcileTimers(
  toasts: any[],
  timers: { [x: string]: any },
  dispatch: any,
) {
  let result = timers;
  for (const toast of toasts) {
    if (!timers[toast.id]) {
      result = {
        ...result,
        [toast.id]: setTimeout(() => dispatch(removeToast(toast)), 5000),
      };
    }
  }

  for (const toastId in timers) {
    if (!toasts.some((toast: { id: string }) => toast.id === toastId)) {
      result = { ...result };
      delete result[toastId];
    }
  }

  return result;
}
