import { utcTimestampToLocalizedClean } from '../../models/dates';
import { isTempId } from '../../models/records';
import api from '../../services/APIService';
import { filterToQueryParams } from '../../services/common';
import { retrofitProductions } from '../../models/productions';
import { DataType } from '../reducers/dataSlice';

export async function fetchProductions(filter: any, offset?: any) {
  try {
    let pending: any = new AbortController();
    const query = filterToQueryParams(filter, DataType.Production, offset);
    const { data, moreData } = await api.get(`/productions?${query}`, pending);
    pending = null;

    return {
      productions: retrofitProductions(data),
      isFinished: !moreData,
    };
  } catch (e) {
    console.warn(e);
    return { productions: [], isFinished: true };
  }
}

/* Function to handle the payload before the request is sent to the service */
export function deltaToPayload(id: any, delta: any, productions?: any) {
  const item: any = productions?.find(
    (production: any) => production._id === id,
  );
  if (Object.keys(delta).length === 1 && !!delta.delete_datetime) return null;

  let payload = { _id: id, ...delta, update_datetime: item?.update_datetime };
  if (isTempId(payload.production_id)) delete payload.production_id;

  if (payload.start_timestamp) {
    payload = {
      ...payload,
      production_start: utcTimestampToLocalizedClean(payload.start_timestamp),
    };
    delete payload.start_timestamp;
  }

  if (payload.end_timestamp) {
    payload = {
      ...payload,
      production_end: utcTimestampToLocalizedClean(payload.end_timestamp),
    };
    delete payload.end_timestamp;
  }

  if (payload.itemAction) delete payload.itemAction;

  if (!payload.stream_equipment) delete payload.stream_equipment;

  if (Object.keys(payload.comments || {}).length === 0) delete payload.comments;
  if (Object.keys(payload.associations || {}).length === 0)
    delete payload.associations;
  if (Object.keys(payload.audio_layout || {}).length === 0)
    delete payload.audio_layout;
  //  if ((payload.commentators || []).length === 0) delete payload.commentators;

  if (item && delta.commentators) {
    const commentators = [...delta.commentators];

    for (const oldComm of item.commentators) {
      if (
        oldComm.nsb_id &&
        !commentators.some(comm => comm.nsb_id === oldComm.nsb_id)
      ) {
        commentators.push({
          nsb_id: oldComm.nsb_id,
          action: 'delete',
        });
      }
    }
    payload.commentators = commentators;
  }

  return payload;
}
