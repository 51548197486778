import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { BaseItem } from '../../reducers/dataSlice';

interface CreateSubRowProps {
  item: BaseItem;
  badgeName?: string;
  onCreateSubRow?(item: any): any;
}

export function CreateButton({
  item,
  badgeName,
  onCreateSubRow,
}: CreateSubRowProps) {
  return (
    <IconButton
      id={`create-sub-${badgeName}`}
      onClick={() => onCreateSubRow && onCreateSubRow(item)}
    >
      <Add />
    </IconButton>
  );
}
