import { Stack } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { useAppDispatch } from '../../reducers/hooks';
import { EditCell } from '../EditCell';
import { onCursorNavigation } from '../InfiniteTable/cursorNavigation';
import { getCellClasses } from '../InfiniteTable/utils';

interface ToggleButtonProps {
  property: string;
  data: any;
  delta: any;
  errors: any;
  style: any;
  staticValues: [];
  update: any;
  type: string;
  rowId: number;
  readOnly?: boolean;
  refreshed: [];
  toggleStyleActive: string;
}

export function ToggleButtons({
  property,
  data,
  delta,
  errors,
  style,
  staticValues,
  update,
  type,
  rowId,
  readOnly,
  refreshed,
  toggleStyleActive,
}: ToggleButtonProps) {
  const [isEditing, setIsEditing] = useState<Boolean>(false);
  const [subFocus, setSubFocus] = useState<number>(0);
  const [array, setArray] = useState<any>(data || []);
  const dispatch = useAppDispatch();

  const styleActive = toggleStyleActive;

  const toggle = (index: number, commitNow?: boolean) => {
    const value = staticValues[index];
    console.log(`toggling ${value} ${index} ${commitNow}`);
    const newArray = array.includes(value)
      ? array.filter((item: any) => item !== value)
      : [...array, value];

    if (commitNow) {
      trigger(newArray);
    } else {
      setArray([...newArray]);
    }
  };

  const trigger = (newArray: []) => {
    dispatch(
      update({
        type,
        _id: rowId,
        delta: { [property]: newArray },
      }),
    );
    EditCell.focusCell(data.feed_id, property, false, true);
  };

  const commit = () => {
    trigger(array);
    setIsEditing(false);
  };

  const cancel = () => {
    setArray(data[property] || []);
    setIsEditing(false);
  };

  const onKeyDownIdle = (e: any) => {
    if (e.nativeEvent.key === 'Enter') {
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopPropagation();
      setIsEditing(true);
      setSubFocus(0);
      return;
    }

    onCursorNavigation(e.nativeEvent);
  };

  const onKeyDownEdit = (e: any) => {
    const countryCount = staticValues.length;

    let handled = false;
    if (e.key === 'ArrowLeft') {
      handled = true;
      setSubFocus((countryCount + subFocus - 1) % countryCount);
    }
    if (e.key === 'ArrowRight') {
      handled = true;
      setSubFocus((subFocus + 1) % countryCount);
    }
    if (e.key === ' ') {
      handled = true;
      toggle(subFocus);
    }
    if (e.key === 'Enter') {
      handled = true;
      commit();
    }
    if (e.key === 'Escape') {
      handled = true;
      cancel();
    }

    if (handled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onClick = (e: any) => {
    if (readOnly) return;
    const index = Number(e.target.name);
    if (Number.isFinite(index)) {
      toggle(index, true);
    }
  };

  return (
    <div
      className={getCellClasses({
        property,
        delta,
        errors,
        refreshed,
        other: [style],
      })}
      data-id={data._id}
      data-field={property}
    >
      <Stack
        className="edit-cell"
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        tabIndex={0}
        onKeyDown={isEditing ? onKeyDownEdit : onKeyDownIdle}
        onClick={onClick}
      >
        {staticValues.map((value: any, i: any) => (
          <button
            type="button"
            key={value}
            className={clsx(
              '_option',
              array.includes(value) && styleActive,
              isEditing && subFocus === i && '-focus',
            )}
            tabIndex={-1}
            name={i}
            disabled={readOnly}
          >
            {value}
          </button>
        ))}
      </Stack>
    </div>
  );
}
