import { WarningAmber } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { removeDialog } from '../../reducers/appSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';

export function AlertDialog({ undo }: any) {
  const { alertDialog } = useAppSelector((state: any) => state.app);
  const disptach = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (alertDialog?.message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [alertDialog]);

  const handleConfirm = () => {
    disptach(removeDialog());
  };

  const handleRevert = () => {
    undo();
    disptach(removeDialog());
  };

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ display: 'flex', gap: 2 }}>
        <WarningAmber color="warning" />
        {alertDialog.title}
      </DialogTitle>
      <DialogContent sx={{ width: 450 }}>
        <DialogContentText>{alertDialog.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Yes</Button>
        <Button onClick={handleRevert}>No</Button>
      </DialogActions>
    </Dialog>
  );
}
