import { useRef } from 'react';
import Roles from '../../../../models/Roles';
import { authService } from '../../../../services/AuthService';
import { InfiniteTable } from '../../../components/InfiniteTable/InfiniteTable';
import { DataType } from '../../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import {
  actions,
  getSlice,
  refreshItem,
  resetItemAction,
} from '../../../reducers/productionsSlice';
import { productionRowStyle } from './style';
import { useProductionColumnSettings } from './ProductionColumnSettings';
import { retrofitProduction } from '../../../../models/productions';
import { useLiveUpdatesOnFilters } from '../../../components/hooks/websocketHook';
import api from '../../../../services/APIService';
import { filterToQueryParams } from '../../../../services/common';

export function ProductionsTablenew() {
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.PRODUCTION_EDITOR]);
  const dataState = useAppSelector(getSlice);
  const columnsSettings: any = useProductionColumnSettings(DataType.Production);
  const ref: any = useRef();
  const dispatch = useAppDispatch();
  useLiveUpdatesOnFilters(DataType.Production, {
    onDataUpdate: (data: any[]) => {
      //dispatch data update
      console.log('getting data ');
      data.forEach(item => {
        const prod = retrofitProduction(item);
        console.log(`getting data ${prod._id}`);
        dispatch(refreshItem(prod));
        setTimeout(() => {
          dispatch(resetItemAction(prod));
        }, 3000);
      });
    },
  });

  return (
    <InfiniteTable
      api={api}
      minWidth="1900px"
      columns={columnsSettings}
      sub={false}
      slice={dataState}
      actions={actions}
      readOnly={readOnly}
      type={DataType.Production}
      rowStyle={productionRowStyle}
      passRef={ref}
      filterToQuery={filterToQueryParams}
    />
  );
}
