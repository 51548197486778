import { Button, Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { isNewId } from '../../../../models/records';
import { createNotification, deleteNotification } from '../../../api/user';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { clear } from '../../../reducers/notificationSlice';
import { createNotificationBoxRequest } from './helper';
import { actionContent, notificationActionButtons } from './style';

interface ActionsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function Actions({ open, setOpen }: ActionsProps) {
  const { changes, errors } = useAppSelector(state => state.notifications);
  const dispatch = useAppDispatch();
  const disableRemove = isNewId(changes.id);

  const handleRemove = async () => {
    await deleteNotification(changes.id, changes.type, dispatch);
    dispatch(clear());
    setOpen(!open);
  };

  const handleAccept = () => {
    const { targetGroup, boxes } = createNotificationBoxRequest(
      changes.notificationBoxes,
    );

    const notificationRequest: any = {
      [changes.type]: {
        ...changes,
        targetGroup,
        notificationBoxes: boxes,
      },
    };

    if (isNewId(notificationRequest[changes.type].id)) {
      delete notificationRequest[changes.type].id;
    }
    createNotification(
      notificationRequest[changes.type]?.id,
      notificationRequest,
      dispatch,
    );

    dispatch(clear());
    setOpen(!open);
  };

  return (
    <Stack sx={actionContent}>
      <Button
        id="delete-notification"
        onClick={handleRemove}
        disabled={disableRemove}
      >
        Remove
      </Button>
      <Stack sx={notificationActionButtons}>
        <Button
          id="cancel-update"
          onClick={() => {
            dispatch(clear());
            setOpen(!open);
          }}
        >
          Cancel
        </Button>
        <Button
          id="accept-update"
          onClick={handleAccept}
          disabled={Object.values(errors).length !== 0}
        >
          Accept
        </Button>
      </Stack>
    </Stack>
  );
}
