/**
 * Defines the action and messages sent to the websocket layter
 */
export interface Transport {
  sendRequest: (request: RequestMessage) => void;

  subscribe: (channelId: string) => void;

  unsubscribe: (channelId: string) => void;
}

/**
 * Defines the callabak action to handle the responses or websocket layter status
 */
export interface TransportCallback {
  onStatus: (ready: TransportStatus) => void;
  onResponse: (response: ResponseMessage) => void;
}

export enum TransportStatus {
  online,
  offline,
  initialized,
}

export enum ChannelType {
  event,
  productions,
  feeds,
  workspace,
  notification,
}

/**
 * Message received from the websocket layer
 */
export enum InMessageType {
  ch_created = 'ch_created',
  ch_error = 'ch_error',
  sub_done = 'sub_done',
  sub_error = 'sub_error',
  unsub_done = 'unsub_done',
  ch_update = 'ch_update',
  logout = 'logout',
}

/**
 * Messages supported to send out on the websocket layer.
 * According to websocket-stream-service specs
 */
export interface RequestMessage {
  event_request?: any;
  production_request?: any;
  feed_request?: any;
  wsp_request?: any;
  notification_request?: any;
}

export interface ResponseMessage {
  channel_id?: string;
  type: InMessageType;
  data?: any;
  ref?: number;
  message?: string;
}

export interface ChannelId {
  channel_id: string;
  origin?: string;
}

export interface SubscriptionRequest {
  sub: ChannelId;
}

export interface UnSubscriptionRequest {
  unsub: ChannelId;
}

export enum WS_ERROR_CODE {
  DISCONNECTED = 1006,
}
