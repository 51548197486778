import {
  Check,
  Close,
  Looks3,
  Looks4,
  LooksOne,
  LooksTwo,
  SmartDisplay,
  Star,
} from '@mui/icons-material';
import { importanceStyle } from '../app/container/Calendar/EventsTable/style';

export const rightStatus = [
  { value: 'EX', label: 'Available', icon: <Check htmlColor="#FDFEFE" /> },
  { value: 'SH', label: 'Shared', icon: '\u00B1' },
  { value: 'UN', label: 'Uncertain', icon: '?' },
  { value: 'DIS', label: 'Not Available', icon: <Close /> },
  { value: 'V', label: 'Viaplay Only', icon: <SmartDisplay /> },
  { value: 'VP', label: 'Viaplay', icon: 'V' },
  { value: 'TP', label: 'Third Party', icon: '3' },
];

export function gettype(type: string) {
  if (!type) {
    return '';
  }
  const rightLabel =
    rightStatus.find(right => right.value === type)?.label || '';
  return rightLabel;
}

export const importance = [
  {
    value: 100,
    level: 100,
    icon: <Star htmlColor="#F39C12" />,
  },
  {
    value: 1,
    level: 1,
    icon: (
      <LooksOne fontSize="medium" className="-level-1" sx={importanceStyle} />
    ),
  },
  {
    value: 2,
    level: 2,
    icon: (
      <LooksTwo fontSize="medium" className="-level-2" sx={importanceStyle} />
    ),
  },
  {
    value: 3,
    level: 3,
    icon: (
      <Looks3 fontSize="medium" className="-level-3" sx={importanceStyle} />
    ),
  },
  {
    value: 4,
    level: 4,
    icon: (
      <Looks4 fontSize="medium" className="-level-4" sx={importanceStyle} />
    ),
  },
];

export function getIcon(type: string) {
  if (!type) {
    return <Close />;
  }
  const icon = rightStatus?.find(right => right.value === type)?.icon || (
    <Close />
  );
  return icon;
}
