import { authService } from '../../../services/AuthService';

interface RenderOnAuthenticatedProps {
  children: any;
}

const RenderOnAuthenticated = ({ children }: RenderOnAuthenticatedProps) =>
  authService.isLoggedIn() ? children : null;

export default RenderOnAuthenticated;
