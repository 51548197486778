import clsx from 'clsx';

interface CellClassProps {
  property: string;
  delta: [];
  errors: [];
  other: any[];
  refreshed: [];
}

/**
 *
 * @param property field in row
 * @param delta created or changes items
 * @param errors if the property has some errors
 * @returns changed or error to mark the cells that has changes or errors
 */
export function getCellClasses({
  property,
  delta,
  errors,
  other,
  refreshed,
}: CellClassProps) {
  return clsx(
    property in delta && '-changed',
    errors.some((error: any) => error.field === property) && '-error',
    property in refreshed && 'blinking',
    ...other,
  );
}
