import { findIndex, isEqual, isEqualWith, omit } from 'lodash';

export interface ObjectDiff {
  added: {} | ObjectDiff;
  updated: {
    [propName: string]: Update | ObjectDiff;
  };
  removed: {} | ObjectDiff;
  unchanged: {} | ObjectDiff;
}

export interface Update {
  oldValue: any;
  newValue: any;
}

/**
 * @return if obj is an Object, including an Array.
 */
const isObject = (obj: any) => {
  return obj !== null && typeof obj === 'object';
};
const excludingIdsIfNotPresent = (one: any, other: any): any => {
  if (
    (Object.hasOwn(one, 'id') && Object.hasOwn(other, 'id')) ||
    (!Object.hasOwn(one, 'id') && !Object.hasOwn(other, 'id'))
  ) {
    return {
      oneClean: one,
      otherClean: other,
    };
  }

  return {
    oneClean: omit(one, ['id']),
    otherClean: omit(other, ['id']),
  };
};

const containsInArray = (listOfItem: any[], obj: any): boolean => {
  return (
    findIndex(listOfItem, o => {
      const { oneClean, otherClean } = excludingIdsIfNotPresent(o, obj);
      return isEqual(oneClean, otherClean);
    }) !== -1
  );
};

const diffArray = (oldArray: any[], newArray: any[]): any => {
  const updated: any[] = [];
  for (const newItem of newArray) {
    if (!containsInArray(oldArray, newItem)) {
      updated.push(newItem);
    }
  }
  for (const oldItem of oldArray) {
    if (!containsInArray(newArray, oldItem)) {
      updated.push(oldItem);
    }
  }
  return updated;
};

/**
 * @param oldObj The previous Object or Array.
 * @param newObj The new Object or Array.
 * @param deep If the comparison must be performed deeper than 1st-level properties.
 * @return A difference summary between the two objects.
 */
export const diffItem = (
  oldObj: any,
  newObj: any,
  deep = false,
): ObjectDiff => {
  const updated: any = {};
  //check existing properties that might have changed
  for (const oldProp in oldObj) {
    const oldPropValue = oldObj[oldProp];
    if (Object.hasOwn(newObj, oldProp)) {
      const newPropValue = newObj[oldProp];
      //do array diff
      if (Array.isArray(newPropValue) && Array.isArray(oldPropValue)) {
        const diff = diffArray(oldPropValue, newPropValue);
        if (Object.keys(diff).length > 0) {
          updated[oldProp] = diff;
        }
      } else if (newPropValue !== oldPropValue) {
        //if they are objecg and deep enabled we add the diff only if they ar not empty.
        if (deep && isObject(oldPropValue) && isObject(newPropValue)) {
          const diff = diffItem(oldPropValue, newPropValue, deep);
          if (Object.keys(diff).length !== 0) {
            updated[oldProp] = diff;
          }
        } else {
          updated[oldProp] = { newValue: newPropValue };
        }
      }
    }
  }
  //Add properties we did not have before
  for (const newProp in newObj) {
    const newPropValue = newObj[newProp];
    if (!Object.hasOwn(oldObj, newProp)) {
      updated[newProp] = { newValue: newPropValue };
    }
  }

  return updated;
};
