import { difference } from 'lodash';
import { BaseItem, DataType, ItemAction } from '../app/reducers/dataSlice';
import { validateEvent } from './events';
import { transformFeedDelta, validateFeed } from './feeds';
import { transformProductionDelta, validateProduction } from './productions';
import { diffItem } from './diffItem';
import { getKeyFromId } from './records';

export function validateWorkspace(item: any) {
  if (item.type === DataType.Event) {
    return validateEvent(item);
  }
  if (item.type === DataType.Production) {
    return validateProduction(item);
  }
  if (item.type === DataType.Feed) {
    return validateFeed(item);
  }
  return [];
}

export function transformWorkspaceDelta(delta: any, workspace: any) {
  const data = { ...workspace };
  if (workspace.type === DataType.Production) {
    return transformProductionDelta(delta, data);
  }
  if (workspace.type === DataType.Feed) {
    return transformFeedDelta(delta, data);
  }
  return delta;
}

export const aggregatedInfoCollector = (payload: any) => {
  return {
    productions:
      removeDuplicates(payload.productions?.map((p: any) => p.production_id)) ||
      [],
    feeds: removeDuplicates(payload.feeds?.map((f: any) => f.feed_id)) || [],
    latest_updated_time: payload.update_datetime,
  };
};

/**
 * Merge the current aggregated details according to the information coming in as delta.
 * @param currentAggregated
 * @param delta
 */
export const aggregatedInfoMerger = (currentAggregated: any, delta: any) => {
  const productionIdsToDelete = delta.productions
    ?.filter((p: any) => p.delete_datetime)
    .map((p: any) => p.production_id);

  const feedIdsToDelete = delta.feeds
    ?.filter((f: any) => f.delete_datetime)
    .map((f: any) => f.feed_id);
  //Got the new delta aggregated info excluding the deleted.
  const aggregatedInfo = {
    productions:
      delta.productions
        ?.filter((p: any) => !p.delete_datetime)
        .map((p: any) => p.production_id) || [],
    feeds:
      delta.feeds
        ?.filter((f: any) => !f.delete_datetime)
        .map((f: any) => f.feed_id) || [],
    latest_updated_time: delta.update_datetime,
  };
  const currentProductions = currentAggregated
    ? currentAggregated.productions || []
    : [];
  const currentFeeds = currentAggregated ? currentAggregated.feeds || [] : [];
  //Exclude the deleted from the currentProduction if any
  const productionExcludingDeleted = difference(
    currentProductions,
    productionIdsToDelete,
  );
  const feedExcludingDeleted = difference(currentFeeds, feedIdsToDelete);

  return {
    productions: removeDuplicates([
      ...productionExcludingDeleted,
      ...aggregatedInfo.productions,
    ]),
    feeds: removeDuplicates([...feedExcludingDeleted, ...aggregatedInfo.feeds]),
    latest_updated_time: aggregatedInfo.latest_updated_time,
  };
};
const mergeItemFromArray = (
  item: BaseItem,
  deltaArray: BaseItem[],
  subItemsDiff: any,
) => {
  if (!deltaArray) return item;
  const foundItem: any = deltaArray.find((e: BaseItem) => e._id === item._id);
  if (foundItem) {
    const updateDiff = diffItem(item, foundItem, true);
    const subItemkey = getKeyFromId(item.type, item._id);
    subItemsDiff[subItemkey] = updateDiff;
    return { ...item, ...foundItem };
  }
  return item;
};
const removeDuplicates = (input: any[]): any[] => {
  return input.filter((element, index) => {
    return input.indexOf(element) === index;
  });
};

const contains = (value: any, index: number, array: any[]): boolean => {
  return array.indexOf(value) > -1;
};

const removeItems = (input: any[], toDelete: any[]): any[] => {
  for (const value of toDelete) {
    const index = input.indexOf(value);
    if (index > -1) {
      input.splice(index, 1);
    }
  }

  return input;
};

export const mergerWithSubItems = (item: BaseItem, delta: any) => {
  const { productions = [], feeds = [] } = item;
  //Identify the new productions/feeds, which are the ones not present in the given item
  let newProductionOnes = delta.productions
    ? delta.productions.filter(
        (p: any) =>
          productions.findIndex((item: any) => item._id === p._id) === -1,
      )
    : [];
  let newFeedOnes = delta.feeds
    ? delta.feeds.filter(
        (p: any) => feeds.findIndex((item: any) => item._id === p._id) === -1,
      )
    : [];
  //Add the refresh flag to highlight the ui
  newProductionOnes = newProductionOnes.map((p: BaseItem) => {
    return {
      ...p,
      action: ItemAction.REFRESHED,
    };
  });

  newFeedOnes = newFeedOnes.map((f: BaseItem) => {
    return {
      ...f,
      action: ItemAction.REFRESHED,
    };
  });
  //Replace the existing ones with the changes coming on delta and add new ones.
  const subItemsDiff = {};
  const mergedProductions = [
    ...productions.map((item: BaseItem) =>
      mergeItemFromArray(item, delta.productions, subItemsDiff),
    ),
    ...newProductionOnes,
  ];

  const mergedFeeds = [
    ...feeds.map((item: BaseItem) =>
      mergeItemFromArray(item, delta.feeds, subItemsDiff),
    ),
    ...newFeedOnes,
  ];
  const topItem = { ...item, ...delta };

  return {
    ...topItem,
    productions: mergedProductions,
    feeds: mergedFeeds,
    diff: subItemsDiff,
  };
};

export const reseterWithSubItems = (item: BaseItem): any => {
  const { productions = [], feeds = [] } = item;
  const updatedKeys: string[] = [];
  const resetedProds = productions.map((p: BaseItem) => {
    updatedKeys.push(getKeyFromId(p.type, p._id));
    return { ...p, action: undefined };
  });
  const resetedFeeds = feeds.map((f: BaseItem) => {
    updatedKeys.push(getKeyFromId(f.type, f._id));
    return { ...f, action: undefined };
  });
  return {
    ...item,
    action: undefined,
    productions: resetedProds,
    feeds: resetedFeeds,
    diff: updatedKeys,
  };
};
