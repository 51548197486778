import { Stack } from '@mui/system';
import { AssociationIcon } from '../../RowAdmin/AssociationIcon';
import CopyIcon from '../../RowAdmin/CopyIcon';
import DeleteIcon from '../../RowAdmin/DeleteIcon';
import HistoryIcon from '../../RowAdmin/HistoryIcon';
import UndoIcon from '../../RowAdmin/UndoIcon';

export interface AdminIcons {
  history?: boolean;
  copy?: boolean;
  associations?: boolean;
  delete?: boolean;
  undo?: boolean;
}

interface AdminCellProps {
  column: any;
  item: any;
  readOnly?: boolean;
  isNew?: boolean;
  className?: any;
  displayIcon?: AdminIcons;
  canEdit?: boolean;
}

export function AdminCell({
  item,
  column,
  isNew,
  displayIcon,
  className,
  readOnly,
  canEdit,
}: AdminCellProps) {
  return (
    <div className={className}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        {displayIcon?.history && (
          <HistoryIcon item={item} readOnly={!canEdit} />
        )}
        {displayIcon?.copy && (
          <CopyIcon item={item} createCopy={column.onCopy} />
        )}
        {displayIcon?.associations && (
          <AssociationIcon isNew={isNew} readOnly={!canEdit} item={item} />
        )}
        {displayIcon?.delete && <DeleteIcon item={item} />}
        {displayIcon?.undo && <UndoIcon item={item} isNew={isNew} />}
      </Stack>
    </div>
  );
}
