import React, { useRef, useState } from 'react';
import { Button } from '@mui/material';
import { Cancel, FilterList } from '@mui/icons-material';
import { Popout } from '../Popout';
import { ComplexFilterEditor } from './ComplexFilterEditor';

interface FilterButtonType {
  filter: any;
  presets: number[];
  presetType: string;
  allowFields: string[];
  onCommit(value: any): any;
}

export function FilterButton({
  filter,
  presets,
  presetType,
  allowFields,
  onCommit,
}: FilterButtonType) {
  const [isEditing, setEditing] = useState(false);
  const anchor = useRef(null);

  return (
    <>
      <Button
        variant="text"
        startIcon={isEditing ? <Cancel /> : <FilterList />}
        ref={anchor}
        onClick={() => setEditing(!isEditing)}
      >
        {isEditing ? 'Close' : 'Filter'}
      </Button>

      <Popout anchor={anchor} open={isEditing}>
        {isEditing && (
          <ComplexFilterEditor
            filter={filter}
            presets={presets}
            presetType={presetType}
            allowFields={allowFields}
            onCommit={(result: any) => {
              onCommit(result);
              setEditing(false);
            }}
          />
        )}
      </Popout>
    </>
  );
}
