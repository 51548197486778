import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as loc from 'date-fns/locale';
import { addDays } from 'date-fns';
import { SetStateAction, useCallback, useRef, useState } from 'react';
import { compareDate } from '../../../models/dates';

interface DateRangePickerProps {
  range: any[];
  onChange(x: any): any;
  maxDate?: any;
}

export default function DateRangePicker({
  range,
  onChange,
  maxDate,
}: DateRangePickerProps) {
  const fromButtonRef = useRef<any>();
  const toButtonRef = useRef<any>();

  const [pickerMode, setPickerMode] = useState<any>(null);
  const togglePicker = useCallback(
    (name: SetStateAction<string>) => {
      setPickerMode(pickerMode === name ? null : name);
    },
    [pickerMode],
  );

  const pickerValue =
    (pickerMode === 'from' && range[0]) ||
    (pickerMode === 'to' && range[1]) ||
    null;

  const dateStringValue = pickerValue ? pickerValue?.toLocaleDateString() : '';

  const pickerCallback = useCallback(
    (newValue: any) => {
      setPickerMode(null);

      const [fromDate, toDate] = range;
      if (pickerMode === 'from') {
        const newFrom = newValue;
        const newTo =
          compareDate(newFrom, toDate) < 0
            ? new Date(toDate)
            : new Date(newFrom);
        onChange([newFrom, newTo]);
      }
      if (pickerMode === 'to') {
        const newTo = newValue;
        const newFrom =
          compareDate(newTo, fromDate) > 0
            ? new Date(fromDate)
            : new Date(newTo);
        onChange([newFrom, newTo]);
      }
    },
    [range, onChange, pickerMode],
  );

  const onStep = useCallback(
    (direction: string) => {
      setPickerMode(null);
      if (direction === 'down') {
        const newFrom = addDays(range[0], -1);
        onChange([newFrom, range[1]]);
      } else {
        const newTo = addDays(range[1], 1);
        onChange([range[0], newTo]);
      }
    },
    [range, onChange],
  );

  const ancorEl =
    (pickerMode === 'from' && fromButtonRef.current) ||
    (pickerMode === 'to' && toButtonRef.current);

  return (
    <Box>
      <ButtonGroup>
        <Button onClick={() => onStep('down')}>
          <ChevronLeft />
        </Button>
        <Button ref={fromButtonRef} onClick={() => togglePicker('from')}>
          {range[0].toLocaleDateString('sv-SE')}
        </Button>
        <Button ref={toButtonRef} onClick={() => togglePicker('to')}>
          {range[1].toLocaleDateString('sv-SE')}
        </Button>
        <Button onClick={() => onStep('up')}>
          <ChevronRight />
        </Button>
      </ButtonGroup>
      <Popper open={!!pickerMode} anchorEl={ancorEl} transition>
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={2}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={loc['enGB']}
              >
                <ClickAwayListener onClickAway={() => setPickerMode(null)}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    minDate={new Date().setFullYear(
                      new Date().getFullYear() - 2,
                    )}
                    maxDate={maxDate}
                    value={pickerValue}
                    onChange={pickerCallback}
                    slotProps={{
                      day: {
                        'aria-label': dateStringValue,
                      },
                    }}
                  />
                </ClickAwayListener>
              </LocalizationProvider>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
