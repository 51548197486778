import { Close } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemAvatar,
  ListItemText,
  PaperProps,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '../../reducers/hooks';
import { Filter } from './Filter/Filter';
import { NotificationPage } from './Notification/Notification';
import { Profile } from './Profile/Profile';
import {
  accountContent,
  accountDialogPaper,
  accountHeader,
  cardContent,
  dialogAvatar,
  dialogBox,
} from './style';

interface AccountPageProps {
  open: boolean;
  setOpen(value: boolean): any;
  type: string;
}

export function AccountPage({ open, setOpen, type }: AccountPageProps) {
  const { account, initials, fullname } = useAppSelector(state => state.user);
  const { email } = account;

  function getType() {
    if (type === 'Profile') return <Profile />;
    if (type === 'Filter') return <Filter />;
    if (type === 'Notification') return <NotificationPage />;
  }

  return (
    <Dialog
      hideBackdrop
      open={open}
      maxWidth="xl"
      PaperProps={accountDialogPaper as PaperProps}
      scroll="paper"
    >
      <DialogTitle>
        <Stack sx={accountHeader}>
          <ListItemAvatar>
            <Avatar sx={dialogAvatar}>{initials}</Avatar>
          </ListItemAvatar>
          <ListItemText secondary={email.value} primary={fullname} />
          <Button
            id="close"
            startIcon={<Close />}
            onClick={() => setOpen(!open)}
          />
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack sx={accountContent}>
          <Box sx={dialogBox}>
            <Card sx={cardContent}>{getType()}</Card>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
