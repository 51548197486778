import { Delete } from '@mui/icons-material';
import {
  Chip,
  ClickAwayListener,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { unionBy } from 'lodash';
import { tableRow, tableRowAlert } from '../style';
import SeasonNameInput from './SeasonNameInput';
import { SeasonStartDate, SeasonEndDate } from './SeasonDates';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { getSlice, updateDetails } from '../../../../reducers/leaguesSlice';

const CreatedSeasonRow = ({
  season,
  handleDeleteRow,
  disabled,
  league,
  id,
}: any) => {
  const [displaySeasonNameInput, setDisplaySeasonNameInput] = useState(false);
  const [displaySeasonStart, setDisplaySeasonStart] = useState(false);
  const [displaySeasonEnd, setDisplaySeasonEnd] = useState(false);
  const providerMapping = !!season.sourceId;
  const dispatch = useAppDispatch();
  const { details } = useAppSelector(getSlice);

  const handleDelete = () => {
    handleDeleteRow(season.id);
  };

  const onDeleteManual = () => {
    const currentChanges = details.changes.seasons || [];
    const existedSeasons = details.data.seasons || [];
    const currentSeasons = unionBy(currentChanges, existedSeasons, 'id');
    const newSeasons = currentSeasons.map((currentSeason: any) => {
      if (currentSeason.id === id) {
        return {
          ...currentSeason,
          manuallyChanged: false,
          dateChanged: false,
        };
      }
      return currentSeason;
    });

    dispatch(updateDetails({ seasons: newSeasons }));
  };
  return (
    <TableRow>
      {!displaySeasonNameInput || disabled ? (
        <TableCell
          id="season-name"
          sx={season.name ? tableRow : tableRowAlert}
          scope="row"
          tabIndex={season.id}
          onClick={() => setDisplaySeasonNameInput(true)}
        >
          {season.name}
        </TableCell>
      ) : (
        <ClickAwayListener onClickAway={() => setDisplaySeasonNameInput(false)}>
          <TableCell id="season-name">
            <SeasonNameInput id={season.id} value={season.name} />
          </TableCell>
        </ClickAwayListener>
      )}

      {!displaySeasonStart || disabled ? (
        <TableCell
          id="season-start-date"
          sx={season.startDate ? tableRow : tableRowAlert}
          onClick={() => setDisplaySeasonStart(true)}
        >
          {season.startDate}
        </TableCell>
      ) : (
        <ClickAwayListener onClickAway={() => setDisplaySeasonStart(false)}>
          <TableCell id="season-start-date">
            <SeasonStartDate id={season.id} startDate={season.startDate} />
          </TableCell>
        </ClickAwayListener>
      )}
      {!displaySeasonEnd || disabled ? (
        <TableCell
          id="season-end-date"
          sx={season.endDate ? tableRow : tableRowAlert}
          onClick={() => setDisplaySeasonEnd(true)}
        >
          {season.endDate}
        </TableCell>
      ) : (
        <ClickAwayListener onClickAway={() => setDisplaySeasonEnd(false)}>
          <TableCell id="season-end-date">
            <SeasonEndDate id={season.id} endDate={season.endDate} />
          </TableCell>
        </ClickAwayListener>
      )}
      <TableCell
        id="Source Id"
        sx={tableRow}
        scope="row"
        tabIndex={season?.sourceId}
      >
        {season?.sourceId}
      </TableCell>
      <TableCell
        sx={{
          ...tableRow,
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          width: '90%',
        }}
      >
        <IconButton
          disabled={providerMapping || disabled}
          onClick={handleDelete}
        >
          <Delete />
        </IconButton>
        {season?.dateChanged && (
          <Tooltip title="This season has been manually changed, to restore remove badge and save.">
            <Chip label="Manual" onDelete={onDeleteManual} color="primary" />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CreatedSeasonRow;
