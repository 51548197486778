import { Box } from '@mui/system';
import { rowStyle, columns } from '../../Production/ProductionsTable/style';
import { ValueCell } from '../../../components/InfiniteTable/cells/ValueCell';

interface PlaceHolderRowProps {
  type: string;
}

export function PlaceholderRow({ type }: PlaceHolderRowProps) {
  return (
    <Box sx={{ ...rowStyle, backgroundColor: '#f4f3f4' }}>
      <ValueCell
        style={columns.title}
        value={
          (type === 'prod' &&
            'There are no productions associated with this event.') ||
          (type === 'feed' && 'There are no feeds associated with this event.')
        }
        refreshed={[]}
        property={columns.key}
      />
    </Box>
  );
}
