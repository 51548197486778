import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Toast = {
  id: string;
  message: string;
  severity?: string;
};

interface AlertDialog {
  title: string;
  message: string;
}

const appSlice = createSlice({
  name: 'app',
  initialState: {
    toasts: [] as Toast[],
    missingFieldToasts: [] as Toast[],
    alertDialog: {},
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    addToast: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    },
    addMissingFieldsToast: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        missingFieldToasts: [...state.missingFieldToasts, ...action.payload],
      };
    },
    addNetworkError: state => ({
      ...state,
      toasts: [
        ...state.toasts,
        {
          id: 'network-error',
          message: 'An unknown network error occurred. Please try again.',
          severity: 'error',
        },
      ],
    }),
    addDialogMessage: (state, action: PayloadAction<AlertDialog>) => {
      return {
        ...state,
        alertDialog: action.payload,
      };
    },
    removeToast: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        toasts: state.toasts.filter(toast => toast.id !== action.payload.id),
      };
    },
    removeMissingFieldToast: (state, action: PayloadAction<any>) => {
      const remainingToasts = state.missingFieldToasts.filter(
        field => field.id !== action.payload,
      );
      return {
        ...state,
        missingFieldToasts: [...remainingToasts],
      };
    },
    removeDialog: state => {
      return {
        ...state,
        alertDialog: {},
      };
    },
    clearMissingFieldToast: state => {
      return {
        ...state,
        missingFieldToasts: [],
      };
    },
  },
});

export const {
  addToast,
  addNetworkError,
  addDialogMessage,
  removeToast,
  addMissingFieldsToast,
  removeMissingFieldToast,
  clearMissingFieldToast,
  removeDialog,
  setIsLoading,
} = appSlice.actions;

export const { reducer } = appSlice;
