import { createTableStyle } from '../../../components/InfiniteTable/createTableStyle';

export const { rowStyle, columns }: any = createTableStyle({
  id: {
    width: 60,
    'a:-webkit-any-link': {
      color: '#cc0099',
      textAlign: 'center',
      textDecoration: 'none',
    },
    'a:-webkit-any-link:hover': {
      color: '#ff0000',
    },
  },
  week: 40,
  day: 40,
  date: 80,
  start: 48,
  end: {
    width: 60,
    button: {
      textAlign: 'left',
      padding: 'revert',
    },
  },
  duration: 48,

  sport: 80,
  league: {
    minWidth: 80,
    flex: '0.6 1 100px',
    overflow: 'hidden',
    button: {
      textAlign: 'left',
    },
  },
  title: {
    flex: '2 1 100px',
    flexShrink: '1',
    overflow: 'hidden',
    minWidth: 120,
    button: {
      textAlign: 'left',
    },
  },
  delivery: 80,
  template: 80,

  country: {
    width: '350px',

    '._option ': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: '#eee',
      border: '2px solid transparent',
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',

      '&.-mcr-active': {
        backgroundColor: '#7d83b6',
        color: '#fff',
      },
      '&.-rec-active': {
        backgroundColor: '#a6087f',
        color: '#fff',
      },
      '&.-focus': {
        borderColor: '#f40',
      },
    },
  },
  admin: 150,
});

export const feedRowStyle = {
  ...rowStyle,

  '._badge': {
    backgroundColor: '#96a9b3',
  },
};
