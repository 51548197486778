import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setDay } from 'date-fns';
import {
  EventFilters,
  FeedFilters,
  FilterCriterias,
  FilterPresets,
  ProductionFilters,
  WorkspaceFilters,
} from '../components/Filters';
import {
  extractDate,
  LOCALE_ESTABLISHED,
  TIMEZONE_ESTABLISHED,
} from '../../models/dates';
import { getAllowedFilters } from '../components/Filters/FilterOptions';
import { DataType } from './dataSlice';
import { getFilterStorage } from '../../models/storage';

export interface FilterState {
  [key: string]: any;

  customFilter: FilterCriterias;
  filterPresets: number[];
  workspace: WorkspaceFilters;
  production: ProductionFilters;
  feed: FeedFilters;
  event: EventFilters;
  league: {};
  savedFilters: FilterPresets;
  fromDate: Date;
  toDate: Date;
  sortBy: 'date';
  sortOrder: 1;
  showDeleted: boolean;
}

const initialState: FilterState = {
  customFilter: {},
  filterPresets: [],
  workspace: {},
  production: {},
  feed: {},
  event: {},
  league: {},
  audit: {},
  savedFilters: {
    production: [],
    global: [],
    event: [],
    feed: [],
    workspace: [],
  },
  fromDate: getFromDate(),
  toDate: getToDate(),
  sortBy: 'date',
  sortOrder: 1,
  showDeleted: getShowDeletedStatus(),
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    addSavedFilters: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        savedFilters: { ...action.payload },
      };
    },
    setFilterDates: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        fromDate: payload.fromDate || state.fromDate,
        toDate: payload.toDate || state.toDate,
      };
    },

    setFilter: (state, { payload }: PayloadAction<any>) => {
      const eventFilters = getAllowedFilters(
        payload.filterPresets || state.filterPresets,
        payload.savedFilters || state.savedFilters,
        DataType.Event,
        payload.customFilter || state.customFilter,
      );
      const prodFilters = getAllowedFilters(
        payload.filterPresets || state.filterPresets,
        payload.savedFilters || state.savedFilters,
        DataType.Production,
        payload.customFilter || state.customFilter,
      );
      const feedDataFilters = getAllowedFilters(
        payload.filterPresets || state.filterPresets,
        payload.savedFilters || state.savedFilters,
        DataType.Feed,
        payload.customFilter || state.customFilter,
      );

      const workspaceFilters = getAllowedFilters(
        payload.filterPresets || state.filterPresets,
        payload.savedFilters || state.savedFilters,
        DataType.Workspace,
        payload.customFilter || state.customFilter,
      );

      const leagueFilters = payload.league;

      const auditFilters = payload.audit;

      let delta = {
        ...state,
      };
      if (eventFilters) {
        delta = {
          ...delta,
          event: eventFilters,
        };
      }
      if (prodFilters) {
        delta = {
          ...delta,
          production: prodFilters,
        };
      }

      if (feedDataFilters) {
        delta = {
          ...delta,
          feed: feedDataFilters,
        };
      }

      if (workspaceFilters) {
        delta = {
          ...delta,
          workspace: workspaceFilters,
        };
      }

      if (leagueFilters) {
        delta = {
          ...delta,
          league: leagueFilters,
        };
      }

      if (auditFilters) {
        delta = {
          ...delta,
          audit: auditFilters,
        };
      }

      delta = {
        ...delta,
      };

      return {
        ...delta,
        customFilter: payload.customFilter || state.customFilter,
        filterPresets: payload.filterPresets || state.filterPresets,
      };
    },
    setShowDeleted: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      showDeleted: payload.showDeleted,
    }),
  },
});

export function getFromDate() {
  const { fromDate } = getFilterStorage();
  if (!fromDate) {
    return new Date();
  }
  const storedDate = new Date(fromDate.date);
  const today = new Date();
  if (storedDate.getDate() !== today.getDate()) {
    return new Date();
  }
  const time = today.toLocaleString(LOCALE_ESTABLISHED, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    timeZone: TIMEZONE_ESTABLISHED,
  });
  return new Date(`${extractDate(fromDate.value)}T${time}`);
}

export function getToDate() {
  const { toDate } = getFilterStorage();
  if (!toDate) {
    return setDay(new Date(), 0, { weekStartsOn: 1 });
  }

  const storedDate = new Date(toDate.date);
  const today = new Date();
  if (storedDate.getDate() !== today.getDate()) {
    return setDay(new Date(), 0, { weekStartsOn: 1 });
  }
  const time = today.toLocaleString(LOCALE_ESTABLISHED, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  return new Date(`${extractDate(toDate.value)}T${time}`);
}

function getShowDeletedStatus() {
  const { showDeleted } = getFilterStorage();
  return showDeleted;
}

export const { addSavedFilters, setFilter, setFilterDates, setShowDeleted } =
  filterSlice.actions;
export const { reducer } = filterSlice;
