import { CloseRounded } from '@mui/icons-material';
import { Fab } from '@mui/material';

interface CloseActionProps {
  onClose: any;
  disabled?: boolean;
}

export function CloseAction({ onClose, disabled }: CloseActionProps) {
  return (
    <Fab aria-label="close" disabled={disabled} onClick={onClose}>
      <CloseRounded />
    </Fab>
  );
}
