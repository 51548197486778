import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close, Check } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { staticService } from '../../../services/StaticService';
import { AllAssociations } from './AllAssociations';
import { AssocItem } from './AssocItem';
import { loadExistingAssociations, loadOptionItems } from './hooks';

interface EditAssociationsProps {
  data: any;
  isNew: any;
  onCommit(x: any): any;
  onCancel(): any;
}

interface AssocFilterProps {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
}

interface TypedAssociationsProps {
  currentItem: any;
  editAssoc: any;
  assocItems: any;
  results: any;
  filter: any;
  onToggle: any;
}

export const EditAssociations = forwardRef(
  ({ data, isNew, onCommit, onCancel }: EditAssociationsProps, ref: any) => {
    // From state
    // form the row data we define the state component with the current associations.
    const [editAssoc, setEditAssoc] = useState({
      events: [],
      feeds: [],
      productions: [],
      ...data.associations,
    });

    // State with the association item and data model.
    // Records, mapped by type and ID, e.g.:
    // assocItems.events[12345] = { id: 12345, event: 'Event Name', ...etc }
    // assocItems.feeds[67890] = { feed_id: 67890, title: 'Feed Name', ...etc }
    const [assocItems, setAssocItems] = useState<any>({
      events: {},
      feeds: {},
      productions: {},
    });

    useEffect(() => {
      if (!isNew) {
        loadExistingAssociations(data.associations, setAssocItems);
      }
    }, [data, isNew]);

    const [filter, setFilter] = React.useState<any>({
      source: 'all',
      date: data.start_timestamp,
      league: data.league_id,
    });

    const [results, setResults] = useState([]);

    useEffect(() => {
      loadOptionItems(filter, setResults);
    }, [filter, setResults]);

    const onToggle = useCallback(
      (item: { type: string; id: any }, link: any) => {
        const type: any =
          (item.type === 'event' && 'events') ||
          (item.type === 'production' && 'productions') ||
          (item.type === 'feed' && 'feeds');

        if (link) {
          setAssocItems({
            ...assocItems,
            [type]: {
              ...assocItems[type],
              [item.id]: item,
            },
          });
        }

        setEditAssoc({
          ...editAssoc,
          [type]: link
            ? editAssoc[type].concat(item.id)
            : editAssoc[type].filter((id: any) => item.id !== id),
        });
      },
      [editAssoc, assocItems],
    );

    return (
      <Stack ref={ref} spacing={1} sx={{ padding: 1 }}>
        <AssocFilter filter={filter} setFilter={setFilter} />

        {filter.source === 'all' && (
          <AllAssociations
            editAssoc={editAssoc}
            assocItems={assocItems}
            onToggle={onToggle}
          />
        )}
        {filter.source !== 'all' && (
          <TypedAssociations
            currentItem={data}
            filter={filter}
            editAssoc={editAssoc}
            assocItems={assocItems}
            results={results}
            onToggle={onToggle}
          />
        )}

        <Stack direction="row" justifyContent="flex-end">
          <Button startIcon={<Close />} onClick={onCancel}>
            Cancel
          </Button>
          <Button startIcon={<Check />} onClick={() => onCommit(editAssoc)}>
            Done
          </Button>
        </Stack>
      </Stack>
    );
  },
);

const DUMMY_LEAGUE = { value: 0, label: 'N/A' };

function AssocFilter({ filter, setFilter }: AssocFilterProps) {
  const date = parseISO(filter.date);
  const leagueOptions = useMemo(
    () =>
      (staticService as any).data.leagues.all
        .map((league: { id: any; sportType: any; name: any }) => ({
          value: league.id,
          label: `${staticService.getSportName(league.sportType)}: ${
            league.name
          }`,
        }))
        .concat(DUMMY_LEAGUE),
    [],
  );

  return (
    <Stack spacing={1}>
      <RadioGroup
        row
        value={filter.source}
        onChange={e => setFilter({ ...filter, source: e.target.value })}
      >
        <FormControlLabel value="all" label="all" control={<Radio />} />
        <FormControlLabel value="events" label="events" control={<Radio />} />
        <FormControlLabel value="feeds" label="feeds" control={<Radio />} />
        <FormControlLabel
          value="productions"
          label="productions"
          control={<Radio />}
        />
      </RadioGroup>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Autocomplete
          autoComplete
          options={leagueOptions}
          filterOptions={(options, { inputValue }) =>
            options.filter(option =>
              option.label.toLowerCase().includes(inputValue.toLowerCase()),
            )
          }
          renderInput={inputProps => (
            <TextField
              {...inputProps}
              size="small"
              placeholder="Search or select league"
            />
          )}
          value={
            leagueOptions.find(
              (option: { value: any }) => filter.league === option.value,
            ) || DUMMY_LEAGUE
          }
          sx={{ width: '240px' }}
          onChange={(e, option) =>
            setFilter({ ...filter, league: option?.value })
          }
        />
        <DatePicker
          format="yyyy-MM-dd"
          value={new Date(filter.date)}
          onChange={(date: any) => setFilter({ ...filter, date })}
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
        />
      </Stack>
    </Stack>
  );
}

function TypedAssociations({
  currentItem,
  editAssoc,
  assocItems,
  results,
  filter,
  onToggle,
}: TypedAssociationsProps) {
  const linkedItems = editAssoc[filter.source]
    .filter((id: string) => id in assocItems[filter.source])
    .map((itemId: string | number) => assocItems[filter.source][itemId]);
  const optionItems = results
    .filter((result: any) => !editAssoc[filter.source]?.includes(result.id))
    .filter((item: any) => item.id !== currentItem._id);

  return (
    <Stack sx={{ maxHeight: '200px', overflowY: 'auto' }}>
      {linkedItems.map((item: { id: React.Key | null | undefined }) => (
        <AssocItem key={item.id} item={item} onToggle={onToggle} isLinked />
      ))}
      {optionItems.map((item: { id: React.Key | null | undefined }) => (
        <AssocItem key={item.id} item={item} onToggle={onToggle} />
      ))}
      {optionItems.length === 0 && linkedItems.length === 0 && (
        <Box sx={{ padding: 1 }}>No results</Box>
      )}
    </Stack>
  );
}
