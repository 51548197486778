import { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { unionBy } from 'lodash';
import { staticService } from '../../../../../services/StaticService';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { getSlice, updateDetails } from '../../../../reducers/leaguesSlice';

const RightsCountry = ({ id, rightCountry, setDisplayRightsChoice }: any) => {
  const { details } = useAppSelector(getSlice);
  const dispatch = useAppDispatch();
  const [selectedCountry, setSelectedCountry] = useState(rightCountry);

  const handleChange = (event: any) => {
    const currentChanges = details.changes.rights || [];
    const existedRights = details.data.rights || [];
    const currentRights = unionBy(currentChanges, existedRights, 'id');
    const newRights = currentRights.map((right: any) => {
      if (right.id === id) {
        return {
          ...right,
          country: event.target.value,
        };
      }
      return right;
    });

    dispatch(updateDetails({ rights: newRights }));
    setDisplayRightsChoice(false);
    setSelectedCountry(event.target.value);
  };
  return (
    <FormControl
      id={`select-country-${id}`}
      variant="standard"
      sx={{ m: 1, minWidth: 100 }}
    >
      <Select value={selectedCountry} onChange={handleChange} id="country">
        {staticService.data.countries.all.map(({ code: country }: any) => (
          <MenuItem key={country} value={country}>
            {country.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RightsCountry;
