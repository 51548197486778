import { Box, Button, Switch } from '@mui/material';
import { DataType } from '../../../reducers/dataSlice';

interface NotificationToggleProps {
  id: number;
  notificationName: string;
  checked: boolean;
  icon?: any;
  type: DataType;
  onToggle: (id: number, checked: boolean, type: string) => any;
  onLabelClick: (id: number, type: string) => any;
}

export default function NotificationToggle({
  id,
  notificationName,
  checked,
  icon,
  type,
  onToggle,
  onLabelClick,
}: NotificationToggleProps) {
  return (
    <Box key={id} sx={{ display: 'flex', alignItems: 'center' }}>
      <Switch
        id={notificationName}
        checked={!checked}
        onChange={(e: any) => onToggle(id, e.target.checked, type)}
      />
      <Button
        id={notificationName}
        variant="text"
        sx={{
          width: 200,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textAlign: 'left',
          display: 'block',
        }}
        onClick={() => onLabelClick(id, type)}
      >
        {notificationName}
      </Button>
      {icon}
    </Box>
  );
}
