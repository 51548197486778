import { useMemo } from 'react';
import { Chip } from '@mui/material';
import {
  buildFilterFromCriteria,
  filterToCriteria,
} from '../../../models/filters';
import { TypeFilters } from '.';
import { validFilters } from './validation';
import { FilterOptions } from './FilterOptions';

export const useSelectedCriterias = (
  filter: any,
  onChange: any,
  presetType: any,
) => {
  const items = useMemo(() => filterToCriteria(filter), [filter]);
  const diplayItems = TypeFilters[presetType].concat(TypeFilters['global']);
  const filters = validFilters(items, diplayItems, presetType);

  function deleteCriteria(item: { field: string; value: any }) {
    let idCriterias = {};
    if (item.field === 'ids') {
      idCriterias = items.filter((old: any) => old.value.id !== item.value);
    }

    let newCriteria = items.filter(
      old =>
        old.field !== item.field ||
        (old.value !== item.value && item.field !== 'ids'),
    );

    if ((idCriterias as any).length > 0) {
      newCriteria = [...newCriteria, ...(idCriterias as any)];
    }

    onChange(buildFilterFromCriteria(newCriteria));
  }

  const selectedCriterias = filters.map((item: any) => {
    return (
      <Chip
        key={`${item.field}=${item.value}`}
        label={
          FilterOptions[item.field]?.getValueLabel(item.value) || item.value
        }
        onDelete={() => deleteCriteria(item)}
      />
    );
  });

  return selectedCriterias;
};
