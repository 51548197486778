import { Download } from '@mui/icons-material';
import { CircularProgress, Fab, Tooltip } from '@mui/material';
import { useAppSelector } from '../../reducers/hooks';

export function DownloadAction({ onDownload }: any) {
  const { isLoading } = useAppSelector(state => state.app);
  return (
    <Tooltip title="Download Excel Report">
      <Fab
        color="default"
        aria-label="download"
        onClick={onDownload}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress /> : <Download />}
      </Fab>
    </Tooltip>
  );
}
