import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 12,
          '&.Mui-selected': {
            color: '#fcf6fb',
          },
        },
        textColorPrimary: {
          color: '#fcf6fb',
        },
        textColorSecondary: {
          color: 'black',
          '&.Mui-selected': {
            color: 'black',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 10,
          alignContent: 'center',
          backgroundColor: '#D6DBDF',
          '& .MuiSvgIcon-root': {
            fontSize: 15,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: '100%',
          margin: 5,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 13,
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'ViaplaySans',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 11,
    h5: {
      fontSize: 14,
      fontWeight: 10,
    },
    subtitle1: {
      fontSize: 12,
    },
  },
  palette: {
    secondary: {
      main: '#9c27b0',
    },
  },
  spacing: 5,
});

export default theme;
