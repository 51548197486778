interface routesType {
  [key: string]: any;
}

export const Routes: routesType = {
  events: '/events',
  workspace: '/workspace',
  productions: '/productions',
  league: '/league',
  feeds: '/feeds',
  history: '/history',
  notifications: '/notifications',
};

export const RouteTypes: routesType = {
  event: '/events',
  workspace: '/workspace',
  production: '/productions',
  league: '/league',
  feed: '/feeds',
  history: '/history',
  notifications: '/notifications',
};
