import { useState } from 'react';
import { unionBy } from 'lodash';
import CalendarDateInput from '../Right/CalendarDateInput';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { getSlice, updateDetails } from '../../../../reducers/leaguesSlice';
import { isTempId } from '../../../../../models/records';

export function SeasonStartDate({ id, startDate }: any) {
  const { details } = useAppSelector(getSlice);
  const dispatch = useAppDispatch();

  const handleChange = (value: any) => {
    const currentChanges = details.changes.seasons || [];
    const existedSeasons = details.data.seasons || [];
    const currentSeasons = unionBy(currentChanges, existedSeasons, 'id');
    let date = new Date(value).toLocaleDateString('sv-SE');
    const today = new Date().toLocaleDateString('sv-SE');
    if (date < today) {
      date = '';
    }
    const newSeasons = currentSeasons.map((season: any) => {
      if (season.id === id) {
        let changedSeason = {
          ...season,
          startDate: date,
        };
        if (details.data?.mapping.id && season?.sourceId) {
          changedSeason = {
            ...changedSeason,
            manuallyChanged: true,
          };
        }
        return changedSeason;
      }
      return season;
    });

    dispatch(updateDetails({ seasons: newSeasons }));
  };

  return (
    <>
      <CalendarDateInput
        id={id}
        rightValue={startDate}
        handleValue={handleChange}
        field="season-start-date"
      />
    </>
  );
}

export function SeasonEndDate({ id, endDate }: any) {
  const { details } = useAppSelector(getSlice);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  const handleChange = (value: any) => {
    const currentChanges = details.changes.seasons || [];
    const existedSeasons = details.data.seasons || [];
    const currentSeasons = unionBy(currentChanges, existedSeasons, 'id');
    let date = new Date(value).toLocaleDateString('sv-SE');
    const today = new Date().toLocaleDateString('sv-SE');
    if (date < today) {
      date = '';
    }
    setError('');
    const newSeasons = currentSeasons.map((season: any) => {
      if (season.id === id) {
        if (date === season.startDate) {
          setError('Not allowed to choose the same date as start date!');
          return {
            ...season,
            endDate: '',
          };
        }
        if (date < season.startDate) {
          setError('Not allowed to choose a date before the start date!');
          return {
            ...season,
            endDate: '',
          };
        }
        let changedSeason = {
          ...season,
          endDate: date,
        };
        if (details.data?.mapping?.id && season?.sourceId) {
          changedSeason = {
            ...changedSeason,
            manuallyChanged: true,
          };
        }
        return changedSeason;
      }
      return season;
    });

    dispatch(updateDetails({ seasons: newSeasons }));
  };

  return (
    <>
      <CalendarDateInput
        id={id}
        rightValue={endDate}
        handleValue={handleChange}
        error={error}
        field="season-end-date"
      />
    </>
  );
}
