import React, { useState } from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import { Add, Check, Delete } from '@mui/icons-material';
import { nanoid } from '@reduxjs/toolkit';
import { staticService } from '../../../../services/StaticService';
import { replaceAtIndex, trapEvent } from '../../../components/hooks/utils';

const style = {
  padding: 2,
  minWidth: '480px',
  fontSize: '12px',

  '> ._item': {
    display: 'grid',
    gridTemplateColumns: '100px 120px 160px 100px auto',
    gap: '16px',
  },
};

export function EditCommentators({ country, commentators, onCommit }: any) {
  const [editList, setEditList] = useState(
    commentators.map((c: any) => ({
      id: c.id,
      nsb_id: c.nsb_id,
      location: c.location,
      skill: c.skill,
      country: staticService.data.commentators.byId[c.id]?.country,
    })),
  );

  const onAdd = () => {
    const first = staticService.data.commentators.all.find(
      (c: any) => c.country === country,
    );
    setEditList([
      ...editList,
      {
        country: '',
        skill: '',
        id: 0,
        location: 0,
      },
    ]);
  };

  const onEdit = (i: any, newData: any) =>
    setEditList(replaceAtIndex(editList, i, newData));

  const onDelete = (i: any) => {
    const list = [...editList.slice(0, i), ...editList.slice(i + 1)];
    setEditList(list);
  };

  const allowSave = editList.every((c: any) => c.id && c.location);

  const onSave = () =>
    onCommit(
      editList.map((data: any) => ({
        id: data.id,
        nsb_id: data.nsb_id,
        skill: data.skill,
        location: data.location,
      })),
    );

  return (
    <Stack gap={2} sx={style} onClick={trapEvent} onKeyDown={trapEvent}>
      {editList.map((data: any, i: any) => (
        <CommentatorRow
          key={nanoid()}
          data={data}
          onChange={(newData: any) => onEdit(i, newData)}
          onDelete={() => onDelete(i)}
        />
      ))}
      <Stack direction="row" justifyContent="flex-end">
        <Button disableFocusRipple startIcon={<Add />} onClick={onAdd}>
          Add
        </Button>
        <Button
          disableFocusRipple
          startIcon={<Check />}
          disabled={!allowSave}
          onClick={onSave}
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
}

function CommentatorRow({ data, onChange, onDelete }: any) {
  return (
    <div key={data.id} className="_item">
      <select
        value={data.country}
        onChange={e => {
          const country = e.target.value;
          onChange({
            country,
          });
        }}
      >
        <option key="0" value="">
          Country
        </option>
        {staticService.data.countries.all.map((country: any) => (
          <option key={country.code} value={country.code}>
            {country.code.toUpperCase()}
          </option>
        ))}
      </select>

      <select
        value={data.skill}
        onChange={e => {
          const skill = Number(e.target.value);
          onChange({
            ...data,
            skill,
          });
        }}
      >
        <option key="0" value="0">
          Skill
        </option>
        {staticService.data.skills.all
          .filter((s: any) => s.country === data.country)
          .map((s: any) => (
            <option key={s.id} value={s.id}>
              {s.name}
            </option>
          ))}
      </select>

      <select
        value={data.id}
        onChange={e => onChange({ ...data, id: Number(e.target.value) })}
      >
        <option key="0" value="0">
          Name
        </option>
        {staticService.data.commentators.all
          .filter(
            (c: any) =>
              c.country === data.country && c.skills.includes(data.skill),
          )
          .map((c: any) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
      </select>

      <select
        value={data.location}
        onChange={e => onChange({ ...data, location: Number(e.target.value) })}
      >
        <option key="0" value="0">
          Location
        </option>
        {staticService.data.locations.all.map((l: any) => (
          <option key={l.id} value={l.id}>
            {l.name}
          </option>
        ))}
      </select>

      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
    </div>
  );
}
