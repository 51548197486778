import { useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { AvatarGroup, ClickAwayListener, Grid, Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelectedCriterias } from './SelectedCriterias';
import { FilterCriterias } from '.';
import { useSelectedPresets } from './SelectedPresets';
import { Popout } from '../Popout';

interface SelectedFilterProps {
  customFilter: any;
  filterPresets: any;
  presetType: string;
  onChangeFilter: any;
  onSave: any;
}

export function SelectedFilters({
  customFilter,
  filterPresets,
  presetType,
  onChangeFilter,
  onSave,
}: SelectedFilterProps) {
  const anchor = useRef();
  const [open, setOpen] = useState<boolean>(false);
  const max = 6;
  const selectedCriterias = useSelectedCriterias(
    customFilter,
    (newCustom: FilterCriterias) =>
      onChangeFilter({
        filterPresets,
        customFilter: newCustom,
      }),
    presetType,
  );

  const { presets, readOnlyPresets } = useSelectedPresets(
    filterPresets,
    presetType,
    (newPresets: number[]) =>
      onChangeFilter({
        customFilter,
        filterPresets: newPresets,
      }),
  );

  const filters = [...presets, ...selectedCriterias];
  const readOnlyFilters = [...readOnlyPresets, ...selectedCriterias];

  return (
    <Stack direction="row" gap={1}>
      <AvatarGroup
        max={max}
        ref={anchor}
        onClick={(e: any) => {
          if (e.target?.innerText?.includes('+')) setOpen(!open);
        }}
        sx={{
          '& .MuiAvatar-root': {
            width: 24,
            height: 24,
            fontSize: 12,
            backgroundColor: '#CC0099',
          },
        }}
      >
        {filters.map(filter => filter)}
      </AvatarGroup>
      <Popout anchor={anchor} open={open}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper sx={{ width: 500, padding: 2 }}>
            <Grid container spacing={2}>
              {readOnlyFilters.map(filter => (
                <Grid item xs={4} key={nanoid()}>
                  {filter}
                </Grid>
              ))}
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popout>
    </Stack>
  );
}
