import { Delete } from '@mui/icons-material';
import {
  ClickAwayListener,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { tableRow, tableRowAlert } from '../style';
import LeagueImportance from './LeagueImportance';
import LeagueRightsType from './LeagueRightsType';
import RightsCountry from './RightsCountry';
import { RightStartDateInput, RightEndDateInput } from './RightDates';

const CreatedRightsRow = ({ right, handleDeleteRow, disabled }: any) => {
  const [displayRightsChoice, setDisplayRightsChoice] = useState(false);
  const [displayRightsStart, setDisplayRightsStart] = useState(false);
  const [displayRightsEnd, setDisplayRightsEnd] = useState(false);
  const [displayRightType, setDisplayRightType] = useState(false);

  const handleDelete = () => {
    handleDeleteRow(right.id);
  };

  const getFullTextType = (value: string) => {
    switch (value) {
      case 'SH':
        return 'Shared';
      case 'EX':
        return 'Exclusive';
      default:
        if (value) {
          return 'Uncertain';
        }
        return '';
    }
  };

  const handleKey = (event: any, setState: any) => {
    if (event.key === 'Enter') {
      setState(true);
    }
  };

  return (
    <TableRow>
      {!displayRightsChoice || disabled ? (
        <TableCell
          id="country"
          sx={right.country ? tableRow : tableRowAlert}
          onClick={() => setDisplayRightsChoice(true)}
          scope="row"
          tabIndex={right.id}
          onKeyDown={event => handleKey(event, setDisplayRightsChoice)}
        >
          {right.country.toUpperCase()}
        </TableCell>
      ) : (
        <TableCell id="country">
          <RightsCountry
            id={right.id}
            rightCountry={right.country}
            setDisplayRightsChoice={setDisplayRightsChoice}
          />
        </TableCell>
      )}
      <LeagueImportance id={right.id} level={right.level} disabled={disabled} />

      {!displayRightsStart || disabled ? (
        <TableCell
          id="rights-start-date"
          sx={right.rightStart ? tableRow : tableRowAlert}
          onClick={() => setDisplayRightsStart(true)}
          tabIndex={0}
          onKeyDown={event => handleKey(event, setDisplayRightsStart)}
        >
          {right.rightStart}
        </TableCell>
      ) : (
        <ClickAwayListener onClickAway={() => setDisplayRightsStart(false)}>
          <TableCell id="rights-start-date">
            <RightStartDateInput
              id={right.id}
              rightStartDate={right.rightStart}
            />
          </TableCell>
        </ClickAwayListener>
      )}

      {!displayRightsEnd || disabled ? (
        <TableCell
          id="rights-end-date"
          sx={right.rightEnd ? tableRow : tableRowAlert}
          onClick={() => setDisplayRightsEnd(true)}
          tabIndex={0}
          onKeyDown={event => handleKey(event, setDisplayRightsEnd)}
        >
          {right.rightEnd}
        </TableCell>
      ) : (
        <ClickAwayListener onClickAway={() => setDisplayRightsEnd(false)}>
          <TableCell id="rights-end-date">
            <RightEndDateInput id={right.id} rightEndDate={right.rightEnd} />
          </TableCell>
        </ClickAwayListener>
      )}

      {!displayRightType || disabled ? (
        <TableCell
          id="rights-type"
          sx={right.type ? tableRow : tableRowAlert}
          onClick={() => setDisplayRightType(true)}
          tabIndex={0}
          onKeyDown={event => handleKey(event, setDisplayRightType)}
        >
          {getFullTextType(right.type)}
        </TableCell>
      ) : (
        <TableCell id="rights-type">
          <LeagueRightsType
            id={right.id}
            type={right.type}
            setDisplayRightType={setDisplayRightType}
          />
        </TableCell>
      )}
      <TableCell sx={tableRow}>
        <IconButton onClick={handleDelete} disabled={disabled}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CreatedRightsRow;
