import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import codes from 'country-calling-code';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { phoneFormControl, profileContent } from '../style';
import { AccountTextField } from './AccountTextField';
import { Actions } from './Actions';
import { staticService } from '../../../../services/StaticService';
import { update } from '../../../reducers/userSlice';

export function Profile() {
  const { account, changes, country_code } = useAppSelector(
    state => state.user,
  );
  const code = changes.country_code || country_code;
  const dispatch = useAppDispatch();
  const accountInfo = { ...account, ...changes.account };
  const fields = Object.keys(account);
  const getValue = () => {
    const countries = codes.filter((code: any) =>
      staticService.data.countries.all.find(
        (country: any) => country.label === code.country,
      ),
    );
    return (
      <FormControl variant="standard" sx={phoneFormControl}>
        <InputLabel id="label">Code</InputLabel>
        {countries.length > 0 && (
          <Select
            label="Country"
            value={code}
            disableUnderline
            onChange={(e: any) => {
              dispatch(
                update({
                  type: 'country_code',
                  delta: e.target.value,
                }),
              );
            }}
          >
            <MenuItem value="-">None</MenuItem>
            {countries.map(country => (
              <MenuItem key={country.country} value={country.countryCodes[0]}>
                {`+ ${country.countryCodes[0]} ${country.isoCode2}`}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    );
  };
  return (
    <Box sx={{ width: '500px' }}>
      <Stack sx={profileContent}>
        {accountInfo &&
          fields?.map((field: string) => (
            <AccountTextField
              key={field}
              id={accountInfo[field].id}
              label={accountInfo[field].label}
              icon={accountInfo[field].icon}
              value={accountInfo[field].value}
              disabled={accountInfo[field].disabled}
              extraInputProps={field === 'phone' ? getValue() : <></>}
            />
          ))}
      </Stack>
      <Actions />
    </Box>
  );
}
