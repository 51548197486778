import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface NotificationNameProps {
  data: any;
  errors: any;
  handleChange(x: any): any;
}

export function NotificationName({
  data,
  errors,
  handleChange,
}: NotificationNameProps) {
    const [name, setName] = useState(data.name);
  return (
    <Box>
      <TextField
        id="notification_name"
        label="Notification name"
        variant="outlined"
        sx={{ width: '50%' }}
        error={!!errors?.name}
        value={name}
        onBlur={() => handleChange({ name })}
        onChange={e => setName(e.target.value)}
      />
    </Box>
  );
}
