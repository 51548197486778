const { createTheme } = require('@mui/material');

export const themeNew = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: 'transparent',
          alignItems: 'center !important',
          margin: 1,
        },
        label: {
          padding: 3,
          fontSize: 8,
          fontWeight: '600',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '0.25em',
          backgroundColor: 'green',
        },
      },
    },
  },
});

export const themeUpdated = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: 'transparent',
          border: 'none',
          alignItems: 'center !important',
        },
        label: {
          padding: 3,
          fontSize: 8,
          fontWeight: '600',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '0.25em',
          backgroundColor: '#337ab7',
        },
      },
    },
  },
});

export const themePostponed = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: 'transparent',
          alignItems: 'center !important',
          margin: 1,
        },
        label: {
          padding: 3,
          fontSize: 8,
          fontWeight: '600',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '0.25em',
          backgroundColor: 'orange',
        },
      },
    },
  },
});

export const themeCancelled = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: 'transparent',
          alignItems: 'center !important',
          margin: 1,
        },
        label: {
          padding: 3,
          fontSize: 8,
          fontWeight: '600',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '0.25em',
          backgroundColor: 'red',
        },
      },
    },
  },
});

export const themeOther = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 3,
          backgroundColor: 'transparent',
          alignItems: 'center !important',
          margin: 1,
        },
        label: {
          padding: 3,
          fontSize: 8,
          fontWeight: '600',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '0.25em',
          backgroundColor: 'grey',
        },
      },
    },
  },
});
