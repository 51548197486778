import { Chip, ThemeProvider } from '@mui/material';
import {
  utcTimestampToLocalizedDate,
  utcTimestampToLocalizedTime,
} from '../../../models/dates';
import { ColumnSetting, ColumnType } from '../../components/InfiniteTable';
import {
  themeCancelled,
  themeNew,
  themeOther,
  themeUpdated,
} from '../../components/Notifications/theme';
import { BaseItem } from '../../reducers/dataSlice';
import { columns } from './style';

export const useHistoryColumnSetting = () => {
  return [
    {
      key: 'perform_at',
      header: 'Date',
      datatype: ColumnType.Text,
      style: columns.date,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        return utcTimestampToLocalizedDate(item.perform_at);
      },
    },
    {
      key: 'perform_at',
      header: 'Time',
      datatype: ColumnType.Text,
      style: columns.time,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        return utcTimestampToLocalizedTime(item.perform_at);
      },
    },
    {
      key: 'entityType',
      header: 'Entity',
      datatype: ColumnType.Text,
      style: columns.entityType,
      editable: false,
    },
    {
      key: 'entityId',
      header: 'Entity Id',
      datatype: ColumnType.Text,
      style: columns.entityId,
      editable: false,
    },
    {
      key: 'activity',
      header: 'Activity',
      datatype: ColumnType.Text,
      style: columns.activity,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        let colorTheme = themeOther;
        const label = item.activity.toUpperCase();

        if (item.activity.toLowerCase() === 'create') {
          colorTheme = themeNew;
        }
        if (item.activity.toLowerCase() === 'update') {
          colorTheme = themeUpdated;
        }

        if (item.activity.toLowerCase() === 'delete') {
          colorTheme = themeCancelled;
        }

        colorTheme.components.MuiChip.styleOverrides.label.fontSize = 8;

        return (
          <ThemeProvider theme={colorTheme}>
            <Chip label={label} />
          </ThemeProvider>
        );
      },
    },
    {
      key: 'info',
      header: 'info',
      datatype: ColumnType.Text,
      style: columns.info,
      editable: false,
      textAlign: 'left',
    },
    {
      key: 'origin',
      header: 'Origin',
      datatype: ColumnType.Text,
      style: columns.origin,
      editable: false,
    },
    {
      key: 'perform_by',
      header: 'Updated By',
      datatype: ColumnType.Text,
      style: columns.author,
      editable: false,
      textAlign: 'left',
    },
  ];
};
