import { TextField } from '@mui/material';

interface TextFieldProps {
  disabled: boolean;
  name: string;
  label: string;
  value: string;
  onChange: any;
  helperText?: string;
  error: string;
}

export function LeagueTextField({
  disabled,
  name,
  label,
  value,
  onChange,
  helperText,
  error,
}: TextFieldProps) {
  return (
    <TextField
      disabled={disabled}
      id={name}
      name={name}
      error={!!error}
      label={label}
      helperText={helperText === 'empty' ? '' : helperText}
      variant="standard"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
}
