import { Chip, Stack, Switch } from '@mui/material';
import { useState } from 'react';

interface LeagueStatusProps {
  isDisabled: boolean;
  disabled: boolean;
  onChange: any;
}

const LeagueStatus = ({
  disabled,
  isDisabled,
  onChange,
}: LeagueStatusProps) => {
  const [checked, setChecked] = useState<boolean>(isDisabled);

  const handleChange = (event: any) => {
    setChecked(!event.target.checked);
    onChange(!event.target.checked);
  };

  return (
    <Stack direction="row" gap={5}>
      <Switch
        inputProps={{ 'aria-label': 'ant design' }}
        onChange={handleChange}
        checked={!checked}
        disabled={disabled}
      />
      {!checked && <Chip label="Enabled" color="success" />}
      {checked && <Chip label="Disabled" />}
    </Stack>
  );
};

export default LeagueStatus;
