import React from 'react';

import './NotFoundPage.scss';

const NotFoundPage = () => (
  <section className="error">
    <h1>Website under construction!</h1>
    <p>This may be because we no longer have this page. Do you have questions? </p>
    <a href="https://help.viaplay.com/sv" title="Contact customer service">Contact customer service</a>
  </section>
);

export default NotFoundPage;
