import { Button } from '@mui/material';
import { isToday } from 'date-fns';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { populateFilterStorage } from '../../../models/storage';
import { setFilterDates } from '../../reducers/filterSlice';

interface TodatButtonProps {
  filter: any;
  onClick: any;
}

export function TodayButton({ filter, onClick }: TodatButtonProps) {
  const dispatch = useDispatch();

  const isActive = isToday(filter.fromDate) && isToday(filter.toDate);
  //  const onClick = useCallback(() => {
  //    dispatch(
  //      setFilterDates({
  //        fromDate: new Date(),
  //        toDate: new Date(),
  //      }),
  //    );

  //    // Add the dates to the local storage too keep filers when refreshing page
  //    const dates = {
  //      fromDate: {
  //        value: new Date(),
  //        date: new Date(),
  //      },
  //      toDate: {
  //        value: new Date(),
  //        date: new Date(),
  //      },
  //    };
  //    populateFilterStorage(dates);
  //  }, [dispatch]);

  return (
    <Button variant={isActive ? 'contained' : 'outlined'} onClick={onClick}>
      Today
    </Button>
  );
}
