export const expandRowStyle = {
  border: '0px solid',
  borderBottom: '0px solid',
  '._expand': {
    padding: 0,
    border: 0,
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    cursor: 'pointer',
    color: '#cc0099',
  },
  '._expand_disabled': {
    padding: 0,
    border: 0,
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    cursor: 'pointer',
    color: 'white',
  },

  '._subs': {
    backgroundColor: '#f4f3f4',
  },
};
