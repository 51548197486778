import { isEmpty } from 'lodash';
import { DataType } from '../../../reducers/dataSlice';
import { NotificationGroup } from '../../../reducers/notificationSlice';
import {
  AHEAD,
  ProtocolSendInfo,
  EventNotification,
  NotificationBase,
  NotificationBox,
  ProductionNotification,
  ProtocolType,
  TargetGroup,
  FeedNotification,
  SendFrequency,
  DAILY_EMAIL_INFORMATION,
  IMMEDIATELY_EMAIL_INFORMATION,
} from './NotificationSetting';

export const CAPTURES = ['month', 'week', 'day', 'hour'];

const initialAhead: AHEAD = {
  time: '',
  unit: '',
};

const initialSmsBox: ProtocolSendInfo = {
  ahead: initialAhead,
  sendFrequency: 'immediately',
};

const initialInAppBox: ProtocolSendInfo = {
  ahead: initialAhead,
  sendFrequency: 'immediately',
  targetGroup: [],
};

const initialEmailBox: ProtocolSendInfo = {
  ahead: initialAhead,
  sendFrequency: 'immediately',
};

const initialDailyEmailBox: ProtocolSendInfo = {
  ahead: initialAhead,
  sendFrequency: 'daily',
};

export function createNewNofificationBox(
  type: ProtocolType,
  frequency?: SendFrequency,
): ProtocolSendInfo {
  switch (type) {
    case ProtocolType.sms:
      return initialSmsBox;
    case ProtocolType.email:
      if (frequency && frequency === 'immediately') {
        return initialEmailBox;
      }
      return initialDailyEmailBox;
    case ProtocolType.in_app:
      return initialInAppBox;
    default:
      return initialSmsBox;
  }
}

export function groupBy(savedBoxes: any, key: string): NotificationBox {
  let notificationGroups: any = {};

  if (savedBoxes) {
    for (const element of savedBoxes) {
      if (!notificationGroups[element[key]]) {
        notificationGroups = { ...notificationGroups, [element[key]]: {} };
      }
      notificationGroups[element[key]] = { ...element };
    }
  }

  return notificationGroups;
}

export function mapToNotificationBase(
  notification: any,
  type: DataType,
  groups: NotificationGroup[],
): NotificationBase {
  const notificationBase: NotificationBase = {
    id: notification.id,
    type,
    name: notification.name,
    disabled: notification.disabled,
    notificationBoxes: notification?.targetGroup
      ? getgroups(notification, groups)
      : groupBy(notification?.notificationBoxes, 'protocol'),
  };
  return notificationBase;
}

export function mapToEventNotification(
  notification: any,
  groups: NotificationGroup[],
): EventNotification {
  const notificationBase: NotificationBase = mapToNotificationBase(
    notification,
    DataType.Event,
    groups,
  );
  const filterIsEmpty =
    notification?.filter && Object.values(notification?.filter).every(isEmpty);
  const notProductionLeader = !notification.productionLeader;
  const notCreator = !notification.productionCreator;
  const all = filterIsEmpty && notCreator && notProductionLeader;
  const eventNotification: EventNotification = {
    ...notificationBase,
    productionLeader: notification.productionLeader,
    productionCreator: notification.productionCreator,
    notifyAllChanges: notification.notifyAllChanges,
    startTimeChange: notification.startTimeChange,
    created: notification.created,
    deleted: notification.deleted,
    all,
    filter: notification.filter || {},
  };

  return eventNotification;
}

export function mapToProductionNotification(
  notification: any,
  groups: NotificationGroup[],
): ProductionNotification {
  const notificationBase: NotificationBase = mapToNotificationBase(
    notification,
    DataType.Production,
    groups,
  );
  const productionNotification: ProductionNotification = {
    ...notificationBase,
    timeChange: notification.timeChange,
    runTypeChange: notification.runTypeChange,
    created: notification.created,
    deleted: notification.deleted,
    all:
      notification?.filter &&
      Object.values(notification?.filter).every(isEmpty),
    filter: notification.filter || {},
  };

  return productionNotification;
}

export function mapToPlaceholderNotification(
  notification: any,
  groups: NotificationGroup[],
): NotificationBase {
  const placeholderNotificationBase: NotificationBase = mapToNotificationBase(
    notification,
    DataType.Placeholder,
    groups,
  );

  return placeholderNotificationBase;
}

export function mapToFeedNotification(
  notification: any,
  groups: NotificationGroup[],
): NotificationBase {
  const notificationBase: NotificationBase = mapToNotificationBase(
    notification,
    DataType.Feed,
    groups,
  );

  const feedNotification: FeedNotification = {
    ...notificationBase,
    notificationDay: notification.notificationDay || {},
  };

  return feedNotification;
}

export function getgroups(notifications: any, groups: any): NotificationBox {
  const mappedUserGroups: TargetGroup[] = [];
  if (notifications?.targetGroup) {
    notifications.targetGroup.forEach((userGroup: string) => {
      const foundGroup = groups.find((group: any) => group.id === userGroup);
      mappedUserGroups.push(foundGroup);
    });
  }

  const notificationBox: NotificationBox = groupBy(
    notifications.notificationBoxes,
    'protocol',
  );

  return {
    in_app: {
      ...(notificationBox?.in_app as ProtocolSendInfo),
      targetGroup: mappedUserGroups,
    },
  };
}

export function createNotificationBoxRequest(notificationBoxes: any) {
  const boxes = [];
  let targetGroup = [];
  for (const key in notificationBoxes) {
    if (key === ProtocolType.in_app) {
      targetGroup = notificationBoxes[key].targetGroup.map(
        (targetGroup: any) => targetGroup.id,
      );
    }
    boxes.push({
      protocol: key,
      ...notificationBoxes[key],
    });
  }
  return { targetGroup, boxes };
}

export function shouldBeDisabled<T>(options: T, id: string) {
  if (
    id === 'notifyAllChanges' &&
    options['startTimeChange' as keyof typeof options]
  ) {
    return true;
  }
  if (
    id === 'startTimeChange' &&
    options['notifyAllChanges' as keyof typeof options]
  ) {
    return true;
  }
  return false;
}

export function getEmailInfo(frequency: SendFrequency) {
  switch (frequency) {
    case 'daily':
      return DAILY_EMAIL_INFORMATION;
    case 'immediately':
      return IMMEDIATELY_EMAIL_INFORMATION;
    default:
      return '';
  }
}
