import { Chip } from '@mui/material';
import { Dispatch, SetStateAction, useRef } from 'react';
import { Popout } from '../Popout';
import { PresetFilterEditor } from './PresetFilterEditor';

interface PresetChipProps {
  preset: any;
  isOpen?: boolean;
  setOpen?: Dispatch<SetStateAction<number>>;
  onDelete: any;
  tab: string;
}

export function PresetChip({
  preset,
  isOpen,
  setOpen,
  onDelete,
  tab,
}: PresetChipProps) {
  const anchor = useRef<any>();

  return (
    <>
      <Chip
        component="a"
        ref={anchor}
        label={preset.name}
        color={isOpen ? 'primary' : 'default'}
        onClick={() => {
          if (setOpen) {
            setOpen(isOpen ? 0 : preset.id);
          }
        }}
        onDelete={onDelete}
      />
      <Popout anchor={anchor} open={isOpen || false}>
        <PresetFilterEditor
          tab={tab}
          typedPreset={preset}
          onSave={() => {
            if (setOpen) setOpen(0);
          }}
        />
      </Popout>
    </>
  );
}
