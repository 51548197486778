import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

const ItemStyle = {
  fontSize: '0.75rem',

  '> ._id': { width: '48px' },
  '> ._type': { width: '64px' },
  '> ._time': { width: '60px' },
  '> ._label': { flex: '1' },
  '> ._deleted': { color: '#f88', textDecoration: 'line-through' },
};

interface AssocItemProps {
  item: any;
  showType?: any;
  isLinked?: any;
  onToggle: any;
}

export function AssocItem({
  item,
  showType,
  isLinked,
  onToggle,
}: AssocItemProps) {
  // If linked item is deleted display red text
  const deleted = item?.deleted ? '_deleted' : '';

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={ItemStyle}>
      {onToggle && (
        <IconButton
          aria-label={isLinked ? 'remove link' : 'link'}
          color={isLinked ? 'primary' : 'default'}
          onClick={() => onToggle(item, !isLinked)}
        >
          {isLinked ? <RemoveCircle /> : <AddCircle />}
        </IconButton>
      )}
      {showType && <div className={`_type ${deleted}`}>{item.type}</div>}
      <div className={`_id ${deleted}`}>{item.id}</div>
      <div className={`_time ${deleted}`}>{item.time}</div>
      <div className={`_label ${deleted}`}>{item.label}</div>
    </Stack>
  );
}
