import { streamService } from '../../../services/StreamService';
import { ChannelType } from '../../../services/Transport';
import {
  addNotification,
  NotificationItem,
  removeNotification,
  setServiceStatus,
} from '../../reducers/inAppNotificationSlice';

export function addUserNotifications(dispatch: any) {
  const receiver = streamService.registerReceiver(ChannelType.notification, {
    onOnline: (online: boolean) => {
      console.log(`ready for getting notification updates: ${online}`);
      dispatch(setServiceStatus(online));
    },
    onDataUpdate: (data: any[]) => {
      // console.log(`on notification data update ${JSON.stringify(data)}`);
      // data = { ...data, type: DataType.Notification };
      const notifications: NotificationItem[] = data
        .filter(incoming => !incoming.deleted_time)
        .map(incoming => {
          return {
            id: incoming.notification_id,
            message: incoming.message,
            message_type: incoming.message_type,
            status: 0,
            action: incoming.action,
            created_at: incoming.created_time,
            data: incoming.item,
          };
        });

      if (notifications.length > 0) {
        console.log(`getting ${notifications.length} notifications`);
        dispatch(addNotification(notifications));
      }

      const deletedIds: string[] = data
        .filter(incoming => incoming.deleted_time)
        .map(incoming => incoming.notification_id);

      if (deletedIds.length > 0) {
        console.log(`deleting ${deletedIds.length} notifications`);
        dispatch(removeNotification(deletedIds));
      }
    },
  });
  streamService.requestChannel({}, receiver);

  return () => {
    console.log(`leaving channel ${receiver.id}`);
    streamService.leaveChannel(receiver);
  };
}
