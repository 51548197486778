import { getISOWeek } from 'date-fns';
import { getSeasonByLeagueIdAndDate } from '../app/api/leagues';
import {
  BaseItem,
  DataType,
  ErrorMessage,
  ItemAction,
} from '../app/reducers/dataSlice';
import {
  utcToJSDate,
  getLocalizedDay,
  buildTimestamp,
  JSDateToUTCDate,
} from './dates';
import {
  createCopyIdNew,
  createNewId,
  validateIfEmpty,
  validateTime,
} from './records';

export const isEvent = (record: { id: any }) => !!record.id;

export const BLANK_EVENT = {
  _id: 0,
  sport: '',
  league_id: 0,
  event: '',
  comments: {},
  stage: '',
};

function getAvailability(audios: any): string {
  const engAudio = audios?.find((audio: any) => audio.language === 'ENG');
  return engAudio?.available ? engAudio.available : 'UN';
}

export const retrofitEvent = (eventData: any) => {
  return {
    ...eventData,
    _id: eventData.id,
    type: DataType.Event,
    isDeleted: !!eventData.deleted_time,

    ...(eventData.event_date && {
      calendar_week: getISOWeek(
        utcToJSDate(eventData.event_date, eventData.event_time),
      ),
      day: getLocalizedDay(
        utcToJSDate(eventData.event_date, eventData.event_time),
      ),
      start_timestamp: buildTimestamp(
        eventData.event_date,
        eventData.event_time,
      ),
      start_time: buildTimestamp(eventData.event_date, eventData.event_time),
    }),
    ...(eventData.audios && {
      has_eng_audio: getAvailability(eventData.audios),
    }),
    ...(eventData.league_id &&
      eventData.sport && {
        sport_league: {
          sport: eventData.sport,
          league_id: eventData.league_id,
        },
      }),
  };
};

export function retrofitEvents(events = []) {
  return events.map((eventData: any) => retrofitEvent(eventData));
}

export function createNewEvent(fromDate = new Date()) {
  const id = createNewId();

  const start = fromDate;
  start.setMinutes(0);

  return {
    ...BLANK_EVENT,
    _id: id,
    type: DataType.Event,
    start_timestamp: JSDateToUTCDate(start),
    start_time: JSDateToUTCDate(start),
    season_id: '',
    itemAction: ItemAction.NEW,
  };
}

export async function copyEvent(
  eventData: {
    [x: string]: any;
    id?: any;
    rights?: any;
    updated_time?: any;
    updated_by?: any;
    created_time?: any;
    created_by?: any;
  },
  dispatch: any,
) {
  const id = createCopyIdNew();
  const seasons = await getSeasonByLeagueIdAndDate(
    eventData.league_id,
    eventData.event_date,
  );
  const seasonId = seasons.length > 0 ? seasons[0].id : '';
  const rights = eventData?.rights?.map(
    (right: { country: any; level: any; type: any }) => {
      return {
        country: right.country,
        level: right.level,
        type: right.type,
      };
    },
  );

  // Exclude updated_time, updated_by, created_time, created_by
  const { updated_time, updated_by, created_time, created_by, ...data } =
    eventData;
  return {
    ...data,
    _id: id,
    id,
    copy_id: eventData.id,
    season_id: seasonId,
    rights: rights || [],
    itemAction: ItemAction.COPY,
  };
}

// Validate required fields
export function validateEvent(eventData: BaseItem): ErrorMessage[] {
  return eventData.deleted_time
    ? []
    : ([
        validateIfEmpty(eventData, 'sport'),
        validateIfEmpty(eventData, 'league_id'),
        validateIfEmpty(eventData, 'event'),
        validateIfEmpty(eventData, 'event_date'),
        validateTime(eventData, 'start_time'),
      ] as ErrorMessage[]);
}
