import { setFilterDates } from '../../reducers/filterSlice';
import { useAppDispatch } from '../../reducers/hooks';
import { DateRangePicker } from '../DateRangePicker';
import { TodayButton } from './TodayButton';
import { populateFilterStorage } from '../../../models/storage';

interface DateFilterProps {
  filter: any;
  onChangeRange: any;
  onTodayClick: any;
}

export function DateFilter({
  filter,
  onChangeRange,
  onTodayClick,
}: DateFilterProps) {
  const { fromDate, toDate } = filter;

  return (
    <>
      <TodayButton filter={filter} onClick={onTodayClick} />
      <DateRangePicker range={[fromDate, toDate]} onChange={onChangeRange} />
    </>
  );
}
