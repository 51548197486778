import { Download } from '@mui/icons-material';
import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { baseStyle, focusedStyle, acceptStyle, rejectStyle } from './style';

interface DropzoneProps {
  onChange(x: any): any;
  acceptedFileTypes: any;
  onRejection(x: any): any;
}

export default function Dropzone({
  onChange,
  acceptedFileTypes,
  onRejection,
}: DropzoneProps) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDropAccepted: acceptedFiles => {
        onChange(acceptedFiles);
      },
      onDropRejected: fileRejections => {
        onRejection(fileRejections);
      },
      accept: acceptedFileTypes,
    });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Download />
        <p>Drag n drop file here, or click to select files</p>
      </div>
    </div>
  );
}
