import {
  Close,
  Delete,
  Info,
  InsertDriveFile,
  Upload,
} from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadEvents } from '../../../api/events';
import { Dropzone } from '../../../components/Dropzone';
import { fileSize, timeout, VALID_TYPES } from './hooks';
import { LeagueSelect } from './LeagueSelect';
import {
  dialogContent,
  fileInfo,
  uploadAction,
  uploadButton,
  uploadStackContent,
  uploadStackHeader,
} from './style';
import { setUploading, clearUploading } from '../../../reducers/eventsSlice';

export function UploadModal({ open, setOpen }: any) {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [selectedLeague, setSelectedLeague] = useState<any>('');
  const [disableUpload, setDisableUpload] = useState<boolean>(true);
  const [rejectedFiles, setRejectedFiles] = useState<any>([]);

  useEffect(() => {
    if (selectedFiles.length > 0 && selectedLeague) {
      setDisableUpload(false);
    } else {
      setDisableUpload(true);
    }
  }, [selectedFiles, selectedLeague]);

  const onChangeFiles = (newFiles: any) => {
    const mappedFiles = newFiles.map((file: any) => {
      return {
        name: file.name,
        size: fileSize(file.size),
        content: file,
        preview: URL.createObjectURL(file),
      };
    });
    setSelectedFiles([...selectedFiles, ...mappedFiles]);
  };

  const onRejection = (newRejectedFiles: any) => {
    const mappedFiles = newRejectedFiles.map(({ file, errors }: any) => {
      return {
        name: file.name,
        size: fileSize(file.size),
        content: file,
        errors: [...errors],
      };
    });
    setRejectedFiles([...rejectedFiles, ...mappedFiles]);
  };

  const handleDelete = (name: any) => {
    const filteredAcceptedFiles = selectedFiles.filter(
      (file: any) => file.name !== name,
    );
    if (filteredAcceptedFiles) {
      setSelectedFiles([...filteredAcceptedFiles]);
    }
    const filteredRejectedFiles = rejectedFiles.filter(
      (file: any) => file.name !== name,
    );
    if (filteredRejectedFiles) {
      setRejectedFiles([...filteredRejectedFiles]);
    }
  };

  const onChangeLeague = (newLeague: any) => {
    setSelectedLeague(newLeague);
  };

  const handleUpload = async () => {
    const data = {
      league_id: selectedLeague.value,
      files: selectedFiles,
    };

    setOpen(!open);
    dispatch(
      setUploading({
        isLoading: true,
        uploadingFiles: [],
      }),
    );
    const result: any = await uploadEvents(data);

    setSelectedFiles([]);
    setRejectedFiles([]);

    if (result) {
      await timeout(5000);
      dispatch(setUploading(result));
      await timeout(20000);
      dispatch(clearUploading());
    }
  };
  const uploadedFiles = [...selectedFiles, ...rejectedFiles];

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogContent sx={dialogContent}>
        <Stack sx={uploadStackHeader}>
          <h4>Upload events from file</h4>
          <Button startIcon={<Close />} onClick={() => setOpen(!open)} />
        </Stack>
        <Divider />
        <Stack sx={uploadStackContent}>
          <LeagueSelect onChange={onChangeLeague} width={500} />
          <Stack direction="row" gap={2}>
            <Info />
            <Typography>Event time must be in UTC/GMT</Typography>
          </Stack>
          <List sx={{ width: 'fit-content' }}>
            {uploadedFiles.map(({ name, size, errors, preview }) => (
              <ListItem
                key={name}
                secondaryAction={
                  <IconButton onClick={() => handleDelete(name)}>
                    <Delete />
                  </IconButton>
                }
                disablePadding
                disabled={!!errors}
              >
                <Stack sx={fileInfo}>
                  <InsertDriveFile />
                  <span>
                    <a href={preview} target="_bland">
                      {name}
                    </a>
                  </span>
                  <span>({size})</span>
                  {errors &&
                    errors.map((error: any) => (
                      <span style={{ color: 'red' }}>
                        (filetype not accepted)
                      </span>
                    ))}
                </Stack>
              </ListItem>
            ))}
          </List>
          <Dropzone
            onChange={onChangeFiles}
            acceptedFileTypes={VALID_TYPES['xlsx']}
            onRejection={onRejection}
          />
        </Stack>
        <Stack sx={uploadAction}>
          <a
            href={`${window.location.origin}/templates/event_template.xlsx`}
            target="_bland"
            rel="noreferrer"
          >
            event_template.xlsx
          </a>
          <Button
            sx={uploadButton}
            variant="contained"
            endIcon={<Upload />}
            onClick={() => handleUpload()}
            disabled={disableUpload}
          >
            Upload Files
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
