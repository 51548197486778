import { addHours, addMinutes } from 'date-fns';
import {
  BaseItem,
  DataType,
  ErrorMessage,
  ItemAction,
} from '../app/reducers/dataSlice';
import { staticService } from '../services/StaticService';
import {
  JSDateToUTC,
  calculateDuration,
  localizedCleanToUTC,
  nowToLocalJSDate,
} from './dates';
import {
  createCopyIdNew,
  createNewId,
  validateDateRange,
  validateIfEmpty,
} from './records';

export const isFeed = (record: { feed_id: any }) => !!record.feed_id;

export const BLANK_FEED = {
  _id: 0,
  delivery_type_id: 0,
  template_two: 0,
  created_by: 'a@b',
  updated_by: 'a@b',
  league_id: 0,
  title: '',
  sport_type: '',
  recording_loc: [],
  mcr: [],
  comments: {},
};
export const retrofitFeed = (feed: any) => {
  const { leagues } = (staticService as any).data;
  return {
    ...BLANK_FEED,
    ...feed,
    _id: feed.feed_id,
    type: DataType.Feed,
    sport_type: leagues.byId[feed.league_id]?.sportType,
    start_timestamp: localizedCleanToUTC(feed.feed_start),
    start_time: localizedCleanToUTC(feed.feed_start),
    end_timestamp: localizedCleanToUTC(feed.feed_end),
    duration: calculateDuration(
      localizedCleanToUTC(feed.feed_start),
      localizedCleanToUTC(feed.feed_end),
    ),
    isDeleted: !!feed.delete_datetime,
    template: {
      one: feed?.template_one || 0,
      two: feed?.template_two || 0,
    },
  };
};

export function retrofitFeeds(feeds = []) {
  return feeds.map((feed: any) => retrofitFeed(feed));
}

export function createNewFeed(fromDate = nowToLocalJSDate()) {
  const id = createNewId();

  const start = fromDate;
  start.setMinutes(0);

  const end = new Date(start);
  end.setHours(end.getHours() + 1);

  return {
    ...BLANK_FEED,
    _id: id,
    type: DataType.Feed,
    start_timestamp: JSDateToUTC(start),
    start_time: JSDateToUTC(start),
    end_timestamp: JSDateToUTC(end),
    duration: calculateDuration(JSDateToUTC(start), JSDateToUTC(end)),
    delivery_type_id: 0,
    itemAction: ItemAction.NEW,
  };
}

export function createSubFeed(eventData: {
  event_time: any;
  event_date: any;
  sport: any;
  id: any;
  league_id: any;
  event: any;
}) {
  const eventDate = eventData.event_time
    ? new Date(`${eventData.event_date}T${eventData.event_time}`)
    : new Date(`${eventData.event_date}`);
  const startDate = addMinutes(eventDate, 0);
  const endDate = addHours(eventDate, 2);
  if (!eventData.event_time) {
    startDate.setMinutes(0);
    startDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setHours(0);
  }
  const sport = staticService.data.sports.all.find(
    (s: any) => s.internalName === eventData.sport,
  );

  return {
    ...createNewFeed(eventDate),
    associations: { events: [eventData.id] },
    sport_type: sport.internalName,
    league_id: eventData.league_id,
    title: eventData.event,
  };
}

export function copyFeed(feed: any) {
  const id = createCopyIdNew();
  return {
    ...feed,
    delete_datetime: undefined,
    _id: id,
    copy_id: feed.feed_id,
    feed_id: id,
    itemAction: ItemAction.COPY,
    start_timestamp: localizedCleanToUTC(feed.feed_start),
    start_time: localizedCleanToUTC(feed.feed_start),
    end_timestamp: localizedCleanToUTC(feed.feed_end),
  };
}

// Validate required fields
export function validateFeed(feedData: BaseItem): ErrorMessage[] {
  return feedData.isDeleted
    ? []
    : ([
        validateDateRange(feedData, 'start_timestamp', 'end_timestamp'),
        validateDateRange(feedData, 'start_time', 'end_timestamp'),
        validateIfEmpty(feedData, 'league_id'),
        validateIfEmpty(feedData, 'sport_type'),
        validateIfEmpty(feedData, 'delivery_type_id'),
        validateIfEmpty(feedData, 'title'),
      ] as ErrorMessage[]);
}

export function transformFeedDelta(delta: any, feed: any) {
  if (delta?.template_two) {
    const template = (staticService as any).data.feedTemplates.byId[
      delta.template_two
    ]?.data;
    const templateDelta = { ...template };

    if (templateDelta?.league_id) {
      templateDelta.sport_type = (staticService as any).data.leagues.byId[
        template.league_id
      ]?.sportType;
    }

    return {
      ...templateDelta,
      ...delta,
    };
  }

  return delta;
}
