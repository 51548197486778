import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { useState } from 'react';
import { dateInputErrorText } from '../style';

const CalendarDateInput = ({ rightValue, handleValue, error, field }: any) => {
  const [selectedDate, setSelectedDate] = useState(rightValue);

  const handleChange = (value: any) => {
    const date = new Date(value).toLocaleDateString('sv-SE');
    setSelectedDate(date);
    handleValue(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        format="yyyy-MM-dd"
        value={parseISO(selectedDate)}
        minDate={new Date()}
        onChange={(newValue: any) => handleChange(newValue)}
        slotProps={{
          textField: {
            variant: 'standard',
          },
          openPickerButton: {
            'aria-label': `Choose ${field}`,
          },
          day: {
            'aria-label': selectedDate,
          },
        }}
      />
      {error && <p style={dateInputErrorText}>{error}</p>}
    </LocalizationProvider>
  );
};

export default CalendarDateInput;
