import { Box } from '@mui/system';
import { useState } from 'react';
import Roles from '../../../models/Roles';
import { createNewFeed } from '../../../models/feeds';
import { Actions } from '../../components/Actions/Actions';
import { CreateAction } from '../../components/Actions/CreateAction';
import { SaveAction } from '../../components/Actions/SaveAction';
import { UndoAction } from '../../components/Actions/UndoAction';
import { FilterToolbar } from '../../components/Filters/FilterToolbar';
import { useFilters } from '../../components/Filters/hooks';
import { Toasts } from '../../components/Toasts';
import { WithStaticData } from '../../components/WithStaticData';
import {
  applyFilter,
  clearList,
  commit,
  create,
  getSlice,
  rollback,
} from '../../reducers/feedsSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import RenderOnRole from '../Auth/RenderOnRole';
import { onSave } from './ActionsHandler';
import { FeedsTableNew } from './FeedsTable/FeedsTable';
import { DownloadAction } from '../../components/Actions/DownloadAction';
import { DataType } from '../../reducers/dataSlice';
import { downloadLists } from '../../api/dataDownloader';
import { AlertDialog } from '../../components/AlertModal/AlertDialog';

export function FeedPage() {
  useFilters(DataType.Feed, applyFilter);

  const { creates, changes, errors } = useAppSelector(getSlice);
  const filter = useAppSelector(state => state.filter);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const changeCount = Object.keys(changes).length + Object.keys(creates).length;
  const hasErrors =
    Object.keys(errors).length > 0 &&
    Object.values(errors).some((itemErrors: any) => itemErrors.length > 0);

  const createAction = (date: any) => {
    const newFeed = createNewFeed(date);
    dispatch(create(newFeed));
  };

  return (
    <WithStaticData realms={['public', 'private']}>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FilterToolbar presetType="feed" />
        <FeedsTableNew />
        <Actions>
          <RenderOnRole
            roles={[
              Roles.ADMIN,
              Roles.FEED_CREATOR,
              Roles.FEED_EDITOR,
              Roles.TRAFFIC,
            ]}
          >
            {changeCount > 0 && (
              <UndoAction onUndo={() => dispatch(rollback())} />
            )}
            {changeCount > 0 && (
              <SaveAction
                onSave={async () => {
                  setLoading(true);
                  const commits: any = await onSave(creates, changes, dispatch);
                  setLoading(false);
                  dispatch(commit(commits));
                  dispatch(clearList(false));
                }}
                changeCount={changeCount}
                hasErrors={hasErrors}
                disabled={loading}
              />
            )}

            <RenderOnRole roles={[Roles.ADMIN, Roles.FEED_CREATOR]}>
              <CreateAction onSave={createAction} type="feed" />
            </RenderOnRole>
          </RenderOnRole>
          <RenderOnRole
            roles={[
              Roles.ADMIN,
              Roles.FEED_CREATOR,
              Roles.FEED_EDITOR,
              Roles.FEED_VIEWER,
            ]}
          >
            <DownloadAction
              onDownload={() =>
                downloadLists({ filter, type: DataType.Feed, dispatch })
              }
            />
          </RenderOnRole>
        </Actions>
        <Toasts />
        <AlertDialog undo={() => dispatch(rollback())} />
      </Box>
    </WithStaticData>
  );
}
