export const tableSettingPaper = {
  borderRadius: '10px 0px 0px 10px',
  width: 600,
  right: '0px',
  height: '100%',
};

export const tableSettingHeader = {
  display: 'flex',
  flexDirection: 'row',
  gap: 25,
  width: '100%',
  padding: 2,
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '5%',
  backgroundColor: '#212D33',
  color: 'white',
};

export const tableSettingTitle = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
};

export const columnSettingContent = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90%',
  overflowY: 'auto',
  gap: 4,
};

export const settingContent = {
  display: 'flex',
  flexDirection: 'column',
  padding: 3,
  gap: 5,
};

export const settingHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 3,
};

export const tableSettingFooter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '5%',
  backgroundColor: '#212D33',
};

export const toggleButtonContent = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',
  justifyContent: 'center',
};
