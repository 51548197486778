import { Undo } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { BaseItem } from '../../reducers/dataSlice';
import { useAppDispatch } from '../../reducers/hooks';
import { TableContext } from '../InfiniteTable/InfiniteTable';

interface UndoIconProps {
  item: BaseItem;
  isNew?: boolean;
}

export default function UndoIcon({ item, isNew }: UndoIconProps) {
  const dispatch = useAppDispatch();
  const { undoCreate, undoChange } = useContext(TableContext);

  const payload = { type: item.type, _id: item._id };

  return (
    <IconButton
      aria-label={`undo-${item.type}-changes`}
      color="default"
      size="small"
      onClick={() =>
        dispatch(isNew ? undoCreate(payload) : undoChange(payload))
      }
    >
      <Undo />
    </IconButton>
  );
}
