import { Icon, ToggleButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DataType } from '../../../reducers/dataSlice';
import { useAppDispatch } from '../../../reducers/hooks';
import { TableType } from '../../../reducers/settingSlice';
import { toggleButtonContent } from './style';

export interface ColumnToggleProps {
  column: any;
  color: any;
  handleToggleChange(data: any): void;
}

export function ColumnToggleButton({
  column,
  color,
  handleToggleChange,
}: ColumnToggleProps) {
  const dispatch = useAppDispatch();

  const getStyle = () => {
    if (!column.required) {
      return { border: '1px solid rgba(155, 193, 160)' };
    }
  };

  return (
    <ToggleButton
      value={column.id}
      color={color}
      disabled={column.required}
      selected={!column.hidden && !column.required}
      onChange={() =>
        handleToggleChange({
          id: column.id,
          hidden: !column.hidden,
        })
      }
      sx={getStyle()}
    >
      <Stack sx={toggleButtonContent}>
        <Typography>{column.header}</Typography>
        <Box>
          {column?.src && (
            <img loading="lazy" alt="" width="15" src={column.src} />
          )}
        </Box>
        <Box>{column?.icon && <Icon>{column.icon}</Icon>}</Box>
      </Stack>
    </ToggleButton>
  );
}
