import { Box, Stack } from '@mui/system';
import { WithStaticData } from '../../components/WithStaticData';
import { AuditTable } from './AuditTable';
import { historyPageContainer, historyTableContainer } from './style';
import { AuditFilter } from './AuditFilter';

export function HistoryPage() {
  return (
    <WithStaticData realms={['public', 'private']}>
      <Box sx={historyPageContainer}>
        <AuditFilter />
        <Stack sx={historyTableContainer}>
          <AuditTable />
        </Stack>
      </Box>
    </WithStaticData>
  );
}
