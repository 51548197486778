import clsx from 'clsx';

import { TooltipBox } from '../../Tooltip/Tooltip';

export interface ValueCellProps {
  value: any;
  style?: any;
  align?: string;
  pass?: any;
  property: any;
  refreshed: [];
}
export function ValueCell({
  value,
  style,
  align = 'center',
  pass,
  property,
  refreshed,
}: ValueCellProps) {
  return (
    <div
      className={property in refreshed ? `${style} blinking` : style}
      {...pass}
    >
      <TooltipBox value={value} className={clsx('-value', `-${align}`)} />
    </div>
  );
}
