export function getAudioList(audio: any) {
  if (!audio || Object.keys(audio).length === 0) {
    return [];
  }
  const audios: any = [743, 743, 743, 743];

  if (audio['Ch 1']) {
    audios[0] = audio['Ch 1'];
  }
  if (audio['Ch 3']) {
    audios[1] = audio['Ch 3'];
  }

  if (audio['Ch 5']) {
    audios[2] = audio['Ch 5'];
  }

  if (audio['Ch 7']) {
    audios[3] = audio['Ch 7'];
  }
  return audios;
}
