import { useState } from 'react';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Toasts } from '../../components/Toasts';
import { WithStaticData } from '../../components/WithStaticData';
import { LeagueDetail } from './LeagueDetail/LeagueDetail';
import { SearchLeague } from './Filter/SearchLeague';
import { leagePageHeader, leaguePageBox } from './style';
import { LeaguesTable } from './LeaguesTable/LeaguesTable';
import { deleteLeague, getLeagueById } from '../../api/leagues';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import { Actions } from '../../components/Actions/Actions';
import RenderOnRole from '../Auth/RenderOnRole';
import Roles from '../../../models/Roles';
import { CreateAction } from '../../components/Actions/CreateAction';
import {
  addItems,
  clearDetails,
  clearList,
  getSlice,
  setDetails,
} from '../../reducers/leaguesSlice';
import { createNewLeague } from '../../../models/leagues';
import { SaveAction } from '../../components/Actions/SaveAction';
import { handleAddLeague } from './ActionHandler';
import { CloseAction } from '../../components/Actions/CloseAction';
import { isNewId } from '../../../models/records';
import { DeleteAction } from '../../components/Actions/DeleteAction';
import { staticService } from '../../../services/StaticService';

export function LeaguePage() {
  const dispatch = useAppDispatch();
  const { details, items } = useAppSelector(getSlice);
  const [loading, setLoading] = useState<boolean>(false);
  const changeCount = details?.changes
    ? Object.keys(details.changes).length
    : 0;

  const hasErrors = details?.changes && Object.keys(details.errors).length > 0;

  const handleClickedRow = async (id: any) => {
    await getLeagueById({ id, dispatch });
  };

  const onCreate = () => {
    const newLeague: any = createNewLeague();
    dispatch(setDetails(newLeague));
  };

  const handleClose = () => {
    dispatch(clearDetails());
  };

  const handleDelete = async () => {
    const id = details.data._id;
    setLoading(true);
    const response: any = await deleteLeague(id, dispatch);
    if (!response?.hasErrors) {
      await staticService.updatePublic();
      await staticService.updatePrivate();
      dispatch(clearList(false));
    }
    setLoading(false);
  };

  return (
    <WithStaticData realms={['public', 'private']}>
      <Box sx={leaguePageBox}>
        {!details.open && (
          <Stack sx={leagePageHeader}>
            <h2>Leagues Admin</h2>
            <SearchLeague />
          </Stack>
        )}
        {!details.open && <LeaguesTable handleClickedRow={handleClickedRow} />}
        {details.open && <LeagueDetail />}
        <Actions>
          <RenderOnRole roles={[Roles.ADMIN, Roles.LEAGUE_CREATOR]}>
            {!details.open && <CreateAction onSave={onCreate} type="league" />}
          </RenderOnRole>
          {details.open && (
            <>
              {!isNewId(details.data._id) && (
                <RenderOnRole roles={[Roles.ADMIN, Roles.LEAGUE_CREATOR]}>
                  <DeleteAction
                    handleDelete={handleDelete}
                    disabled={loading}
                  />
                </RenderOnRole>
              )}
              <CloseAction disabled={loading} onClose={handleClose} />
              <RenderOnRole
                roles={[Roles.ADMIN, Roles.LEAGUE_CREATOR, Roles.LEAGUE_EDITOR]}
              >
                {changeCount > 0 && (
                  <SaveAction
                    changeCount={0}
                    disabled={loading}
                    hasErrors={hasErrors}
                    onSave={async () => {
                      setLoading(true);
                      const response: any = await handleAddLeague(
                        details,
                        dispatch,
                      );
                      if (!response?.hasErrors) {
                        await staticService.updatePublic();
                        await staticService.updatePrivate();
                        dispatch(clearList(false));
                      }
                      setLoading(false);
                    }}
                  />
                )}
              </RenderOnRole>
            </>
          )}
        </Actions>
        <Toasts />
      </Box>
    </WithStaticData>
  );
}
