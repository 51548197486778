import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AccountProps {
  id?: string;
  propertyName?: string;
  label?: string;
  icon?: string;
  disabled?: boolean;
  value?: string | string[];
}
interface Account {
  username?: AccountProps;
  email?: AccountProps;
  firstname?: AccountProps;
  lastname?: AccountProps;
  phone?: AccountProps;
  countries?: AccountProps;
  userGroups?: AccountProps;
}

interface userType {
  [key: string]: any;
}

const initialState: userType = {
  account: {
    username: {
      id: 'username',
      propertyName: 'user_name',
      label: 'Username',
      icon: 'AccountCircle',
      disabled: true,
      value: '',
    },
    email: {
      id: 'email',
      propertyName: 'email',
      label: 'Email',
      icon: 'Mail',
      disabled: false,
      value: '',
    },
    firstname: {
      id: 'firstname',
      propertyName: 'first_name',
      label: 'Firstname',
      icon: 'Person',
      disabled: false,
      value: '',
    },
    lastname: {
      id: 'lastname',
      propertyName: 'last_name',
      label: 'Lastname',
      icon: 'Person',
      disabled: false,
      value: '',
    },
    phone: {
      id: 'phone',
      propertyName: 'phone',
      label: 'Phone',
      icon: 'PhoneIphone',
      disabled: false,
      value: '',
    },
    countries: {
      id: 'countries',
      propertyName: 'countries',
      label: 'Countries',
      icon: 'Public',
      disabled: true,
      value: [],
    },
    userGroups: {
      id: 'groups',
      propertyName: 'groups',
      label: 'Groups',
      icon: 'Group',
      disabled: false,
      value: [],
    },
  },
  initials: '',
  fullname: '',
  changes: {
    account: {} as Account,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserInfo: (state, action: PayloadAction<any>) => {
      return {
        account: {
          username: {
            ...state.account.username,
            value: action.payload.account.username,
          },
          email: {
            ...state.account.email,
            value: action.payload.account.email,
          },
          firstname: {
            ...state.account.firstname,
            value: action.payload.account.first_name,
          },
          lastname: {
            ...state.account.lastname,
            value: action.payload.account.last_name,
          },
          phone: {
            ...state.account.phone,
            value: action.payload.account.phone,
          },
          countries: {
            ...state.account.countries,
            value: [...(action.payload.account.countries || '')],
          },
          userGroups: {
            ...state.account.userGroups,
            value: [
              ...action.payload.account.user_groups.map(
                (userGroup: any) => userGroup.name,
              ),
            ],
          },
        },
        country_code: action.payload.account.country_code || '',
        defaultCountry: action.payload.account?.countries
          ? action.payload.account?.countries[0]
          : '',
        initials: getInitials(action.payload.account.fullname),
        userGroupIds: [
          ...(action.payload.account.user_groups.map(
            (userGroup: any) => userGroup.id,
          ) || ''),
        ],
        fullname: action.payload.account.fullname,
        creates: { ...state.creates },
        changes: { ...state.changes },
        errors: { ...state.errors },
      };
    },

    updateAccount: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        account: {
          username: {
            ...state.account.username,
            value: action.payload.username,
          },
          email: {
            ...state.account.email,
            value: action.payload.email,
          },
          firstname: {
            ...state.account.firstname,
            value: action.payload.first_name,
          },
          lastname: {
            ...state.account.lastname,
            value: action.payload.last_name,
          },
          phone: {
            ...state.account.phone,
            value: action.payload.phone,
          },
          countries: {
            ...state.account.countries,
            value: [...(action.payload.countries || '')],
          },
          userGroups: {
            ...state.account.userGroups,
            value: [
              ...(action.payload.user_groups.map(
                (userGroup: any) => userGroup.name,
              ) || ''),
            ],
          },
        },
        initials: getInitials(action.payload.fullname),
        country_code: action.payload?.country_code || '',
        userGroupIds: [
          ...(action.payload.user_groups.map(
            (userGroup: any) => userGroup.id,
          ) || ''),
        ],
      };
    },

    update: (state, { payload }: PayloadAction<any>) => {
      const { type, id, delta } = payload;
      const oldChanges = { ...state.changes[type] };

      if (type === 'account') {
        return {
          ...state,
          changes: {
            ...state.changes,
            [type]: {
              ...oldChanges,
              [id]: { ...state[type][id], value: delta },
            },
          },
        };
      } else {
        return {
          ...state,
          changes: {
            ...state.changes,
            [type]: delta,
          },
        };
      }
    },
    clear: state => ({
      ...state,
      creates: { ...initialState.creates },
      changes: { ...initialState.changes },
    }),
  },
});

const getInitials = (fullname: string) =>
  fullname
    .split(' ')
    .map((n: any) => n[0])
    .join('')
    .substring(0, 2)
    .toUpperCase();

export const { addUserInfo, updateAccount, update, clear } = userSlice.actions;

export const { reducer } = userSlice;
