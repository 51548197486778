import {
  RequestMessage,
  ResponseMessage,
  Transport,
  TransportCallback,
  TransportStatus,
} from './Transport';

/**
 * Component to handle the websocket communication and send message according to websocket API defined on service
 * sports-websocket-stream-service.
 * The component will deliver the messages to websocket layer according to Transport Interface and will pass on the responses to a
 * TransportCallback component which will be in charge to interpret the different responses coming in.
 */

export default class WebsocketTransport implements Transport {
  ws: WebSocket;

  queue: any[];

  connected: boolean = false;

  lastActivity: number = Date.now();

  constructor(url: string, token: string, callback: TransportCallback) {
    this.ws = new WebSocket(`${url}`, ['access_token', token]);
    this.queue = [];
    // this.connected = false;
    // Connection opened
    this.ws.addEventListener('open', event => {
      const d = JSON.stringify(event);
      console.log(`Connected ${d}`);
      this.connected = true;
      callback.onStatus(TransportStatus.online);
      this.sendQueue();
    });
    this.ws.addEventListener('message', event => {
      this.lastActivity = Date.now();
      const response: ResponseMessage = JSON.parse(event.data);

      callback.onResponse(response);
    });
    this.ws.addEventListener('close', event => {
      console.log(`Closing ws. code:${event.code} reason ${event.reason}`);
      this.connected = false;
      callback.onStatus(TransportStatus.offline);
    });
    this.ws.addEventListener('error', event => {
      const data = JSON.stringify(event);
      console.error(`Error on ws ${data}`);
    });
  }

  subscribe = (channelId: string) => {
    const sub = {
      sub: {
        channel_id: channelId,
        origin: 'Calendar',
      },
    };
    this.sendMessage(sub);
  };

  unsubscribe = (channelId: string) => {
    const unsub = {
      unsub: {
        channel_id: channelId,
      },
    };
    this.sendMessage(unsub);
  };

  sendRequest = (request: RequestMessage): void => {
    this.sendMessage(request);
  };

  private sendMessage(message: any) {
    const jsonRequest = JSON.stringify(message);
    this.queue.push(jsonRequest);
    // console.log(`enqueue ${jsonRequest}`);
    if (this.connected) {
      this.sendQueue();
    }
  }

  private sendQueue = () => {
    if (this.connected) {
      while (this.queue.length > 0) {
        const message = this.queue.shift();
        this.ws.send(message);
      }
    }
  };
}
