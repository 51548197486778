import React, { forwardRef, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { AllAssociations } from './AllAssociations';
import { loadExistingAssociations } from './hooks';

interface ReadOnlyAssociationsProps {
  data: any;
  onCancel(): any;
}

export const ReadOnlyAssociations = forwardRef(
  ({ data, onCancel }: ReadOnlyAssociationsProps, ref:any) => {
    // Records, mapped by type and ID, e.g.:
    // assocItems.events[12345] = { id: 12345, event: 'Event Name', ...etc }
    // assocItems.feeds[67890] = { feed_id: 67890, title: 'Feed Name', ...etc }
    const [assocItems, setAssocItems] = useState({
      events: {},
      feeds: {},
      productions: {},
    });

    useEffect(() => {
      loadExistingAssociations(data.associations, setAssocItems);
    }, [data]);

    return (
      <Stack ref={ref} spacing={1} sx={{ padding: 1 }}>
        <AllAssociations
          editAssoc={data.associations}
          assocItems={assocItems}
        />

        <Stack direction="row" justifyContent="flex-end">
          <Button startIcon={<Close />} onClick={onCancel}>
            Close
          </Button>
        </Stack>
      </Stack>
    );
  },
);
