import { DataType } from '../../../reducers/dataSlice';

interface RowSizeProps {
  index: number;
  rows: any;
  creates: any;
  subItems: any;
}

export function getRowSize({ index, rows, creates, subItems }: RowSizeProps) {
  if (index >= rows.length) return 0;

  const eventData = rows[index];
  const eventId = eventData?._id;
  const eventDetails = subItems[eventId];
  if (!eventDetails || !eventDetails.open || !eventDetails.data) {
    return 35;
  }

  const oldProds = eventDetails.data.productions?.length || 0;
  const oldFeeds = eventDetails.data.feeds?.length || 0;

  const newProds = Object.values(creates).filter(
    (create: any) =>
      create.type === DataType.Production &&
      create.associations?.events?.includes(eventId),
  ).length;
  const newFeeds = Object.values(creates).filter(
    (create: any) =>
      create.type === DataType.Feed &&
      create.associations?.events?.includes(eventId),
  ).length;

  return (
    35 *
    (1 +
      2 +
      Math.max(1, oldProds + newProds) +
      Math.max(1, oldFeeds + newFeeds))
  );
}
