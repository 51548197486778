import { Info } from '@mui/icons-material';
import { useContext } from 'react';
import { BaseItem } from '../../reducers/dataSlice';
import { useAppDispatch } from '../../reducers/hooks';
import { EditNotes } from '../EditCell';
import { TableContext } from '../InfiniteTable/InfiniteTable';
import { PopoutIcon } from '../Popout';

interface HistoryIconProps {
  item: BaseItem;
  readOnly?: boolean;
}

export default function HistoryIcon({ item, readOnly }: HistoryIconProps) {
  const dispatch = useAppDispatch();
  const { update } = useContext(TableContext);

  const hasComments = item.comments && Object.keys(item.comments).length > 0;

  return (
    <PopoutIcon
      icon={<Info />}
      aria-label="history"
      color={hasComments ? 'primary' : 'default'}
    >
      {(close: any) => (
        <EditNotes
          idField="id"
          data={item}
          readOnly={readOnly}
          onCommit={(comments: any) => {
            dispatch(
              update({
                type: item.type,
                _id: item._id,
                delta: { comments },
              }),
            );
            close();
          }}
        />
      )}
    </PopoutIcon>
  );
}
