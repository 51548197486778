import { Box, Stack } from '@mui/system';
import { nanoid } from '@reduxjs/toolkit';
import { useContext, useMemo } from 'react';
import { TableContext } from '../../components/InfiniteTable/InfiniteTable';
import { TableHeader } from '../../components/InfiniteTable/TableHeader';
import { TableRow } from '../../components/InfiniteTable/TableRow';
import { PlaceholderRow } from '../Workspace/EventsTable/PlaceholderRow';
import { useHistoryChangeColumnSetting } from './HistoryChangeColumnSetting';
import { auditSubRowStyle, expandRowStyle } from './style';

export function useSubRow(item: any) {
  const { subItems } = useContext(TableContext);

  const columnSetting: any = useHistoryChangeColumnSetting();
  const columnHeader = columnSetting;
  const columnTable = columnSetting;

  const { auditChanges } = useMemo(() => {
    if (!subItems[item._id]?.data) return { auditChanges: [] };
    const { id } = item;
    return {
      auditChanges: subItems[id].data,
    };
  }, [subItems, item]);
  return (
    <Box
      sx={{
        ...expandRowStyle,
        flex: 1,
        marginLeft: '120px',
        overflowY: 'scroll hidden',
      }}
    >
      {subItems[item._id]?.open && (
        <Stack className="_subs">
          <TableHeader
            key={nanoid()}
            columns={columnTable}
            sub
            rowStyle={auditSubRowStyle}
            item={item}
            badgeName={`Version:${item.entityChange.versionNumber}`}
            canCreate
            minWidth="500px"
          />
          {!auditChanges.length && <PlaceholderRow type="audit" />}
          {auditChanges.map((data: any) => (
            <TableRow
              key={nanoid()}
              item={data}
              readOnly
              columns={columnTable}
              tableRowStyle={auditSubRowStyle}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
