import { Close, EditNoteSharp } from '@mui/icons-material';
import {
  ClickAwayListener,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { updateSettings } from '../../../api/user';
import { DataType } from '../../../reducers/dataSlice';
import { useAppSelector } from '../../../reducers/hooks';
import { TableType } from '../../../reducers/settingSlice';
import { getDefaultSetting, getMergeSettings } from './ColumnPreference';
import { TableColumns } from './TableColumns';
import {
  columnSettingContent,
  tableSettingFooter,
  tableSettingHeader,
  tableSettingPaper,
  tableSettingTitle,
} from './style';

interface TableSettingProps {
  open: boolean;
  setOpen(x: boolean): any;
  dataType: DataType;
}
export function TableSettings({ open, setOpen, dataType }: TableSettingProps) {
  const { tableSettings, changes }: any = useAppSelector(
    state => state.settings,
  );

  const defaultSetting = getDefaultSetting(true);

  const { table, columns, subTables, usingDefaultTableSettings } =
    getMergeSettings(tableSettings, dataType, defaultSetting);

  const handleClickAway = () => {
    if (Object.keys(changes).length > 0 && changes?.tableSettings) {
      const changedTableSettings = changes?.tableSettings;
      updateSettings(changedTableSettings);
    }
    setOpen(false);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      sx={{ '.MuiDrawer-paper': { overflow: 'hidden' } }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper sx={tableSettingPaper}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '95%',
            }}
          >
            <Stack sx={tableSettingHeader}>
              <Stack sx={tableSettingTitle}>
                <Typography sx={{ fontSize: '1rem' }}>
                  Table Column Setting
                </Typography>
                <EditNoteSharp />
              </Stack>
              <IconButton id="close-column-setting" onClick={handleClickAway}>
                <Close sx={{ color: 'white', paddingRight: '20px' }} />
              </IconButton>
            </Stack>
            <Divider />

            <Stack sx={columnSettingContent}>
              <TableColumns
                tableType={TableType.Main}
                mainTableDataType={table}
                columns={columns}
                usingDefaultSettings={usingDefaultTableSettings}
              />
              {subTables.length > 0 &&
                subTables.map((subTable: any) => (
                  <TableColumns
                    key={subTable.table}
                    tableType={TableType.Sub}
                    mainTableDataType={table}
                    subTableDataType={subTable.table}
                    columns={subTable.columns}
                    usingDefaultSettings={subTable.usingDefaultTableSettings}
                  />
                ))}
            </Stack>
          </Stack>
          <Divider />
          <Stack sx={tableSettingFooter} />
        </Paper>
      </ClickAwayListener>
    </Drawer>
  );
}
