import axios from 'axios';

class AuditService {
  axiosInstance: any;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_AUDIT_URL}`,
    });
  }

  /**
   * Get request to the given url. Returns the data response
   * @param {Get} url
   * @returns
   */
  get = async (
    url: string,
    controller?: { signal: any } | null | undefined,
  ) => {
    try {
      const config = controller ? { signal: controller.signal } : {};

      const response = await this.axiosInstance.get(url, config);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

export default new AuditService();
