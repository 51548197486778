import { nanoid } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { addToast } from '../reducers/appSlice';
import api from '../../services/APIService';
import { setDetails, setLoading } from '../reducers/leaguesSlice';
import { DEFAULT_START_OFFSET } from '../../models/productions';
import { store } from '../reducers/store';

export function retrofitLeagues(leagues: any) {
  return leagues.map((league: any) => ({
    ...league,
    _id: league.id,
    isDisabled: league.disabled,
  }));
}

export async function saveLeague(league: any, dispatch: any) {
  const headers = {
    'content-type': 'application/json',
  };
  let response = {};
  try {
    dispatch(setLoading(true));
    const { data } = await api.post(
      '/leagues',
      JSON.stringify(league),
      headers,
    );
    dispatch(setLoading(false));
    dispatch(
      addToast({
        id: 'create-league',
        message: `League ${data.id} created`,
        severity: 'success',
      }),
    );
  } catch (e) {
    console.warn(e);
    dispatch(setLoading(false));
    dispatch(
      addToast({
        id: 'create-league',
        message: e,
        severity: 'error',
      }),
    );
    response = { hasErrors: true };
  }
  return response;
}

export async function deleteLeague(id: any, dispatch: any) {
  let response = {};
  try {
    await api.delete(`/leagues/${id}`);
    dispatch(
      addToast({
        id: 'delete-league',
        message: `League ${id} deleted`,
        severity: 'success',
      }),
    );
  } catch (e: any) {
    const message =
      e?.response?.data?.errorMessage ||
      `Not able to update league ${id} , try again`;
    dispatch(
      addToast({
        id: 'delete-league',
        message,
        severity: 'error',
      }),
    );
    response = { hasErrors: true };
  }
  return response;
}

export async function updateLeague(id: any, updates: any, dispatch: any) {
  let response = {};
  try {
    dispatch(setLoading(true));
    const response = await api.put(`/leagues/${id}`, JSON.stringify(updates));
    dispatch(setLoading(false));
    dispatch(
      addToast({
        id: 'league-updated',
        message: `League ${id} updated`,
        severity: 'success',
      }),
    );
  } catch (e: any) {
    const messages = [`Not able to update league ${id} , try again`];
    const errorMessage = e?.response?.data?.errorMessage;
    if (errorMessage) {
      messages.push(errorMessage);
    }
    const errors = e?.response?.data?.errors?.traces;
    if (errors?.length > 0) {
      errors.forEach((error: any) => {
        messages.push(error.trace);
      });
    }
    messages.forEach((message: any) => {
      dispatch(
        addToast({
          id: 'update-league',
          message,
          severity: 'error',
        }),
      );
    });
    dispatch(setLoading(false));
    response = { hasErrors: true };
    return response;
  }
  return response;
}

export async function getLeagueById({ id, dispatch }: any) {
  try {
    const league = await api.get(`/leagues/${id}`);
    if (league) {
      const sortedRights = sortBy(league?.rights, 'country');
      const rights = sortedRights?.map((right: any) => {
        return {
          id: right.id,
          country: right.country,
          rightStart: right.rightStart,
          rightEnd: right.rightEnd,
          type: right.type,
          level: right.level,
        };
      });

      let hasMapping = false;
      if (league.mapping?.provider_id && !league?.mapping?.disabled) {
        hasMapping = true;
      }

      const sortedSeason = sortBy(league?.seasons, 'startDate');
      const seasons = sortedSeason?.map((season: any) => {
        return {
          id: season.id,
          name: season.name,
          sourceId: season.sourceId,
          startDate: season.startDate,
          endDate: season.endDate,
          updated_time: season.updated_time,
          manuallyChanged: season.manuallyChanged,
          dateChanged: season.manuallyChanged,
        };
      });

      const audio = league.audio?.map((leagueAudio: any) => {
        return {
          language: leagueAudio.language,
          available: leagueAudio.available,
        };
      });

      const displayLeague: any = {
        _id: league.id,
        update: true,
        name: league.name,
        shortName: league.shortName,
        abbreviationName: league.abbreviationName,
        sportType: league.sportType,
        ruleSet: league.ruleSet || '',
        mapping: {
          id: league.mapping?.id,
          providerId: league.mapping?.provider_id,
          providerLeagueId: league.mapping?.provider_league_id,
        },
        rights,
        seasons,
        disabled: league?.disabled ? league.disabled : false,
        hasMapping,
        deletedSeasons: [],
        manuallySeasonChecked: league?.seasons?.length > 0 && !hasMapping,
        providerChecked: hasMapping,
        audio,
        ...(league?.default_duration && {
          default_duration: league?.default_duration,
        }),
        default_start_offset:
          'default_start_offset' in league
            ? league.default_start_offset
            : DEFAULT_START_OFFSET,
      };
      dispatch(setDetails(displayLeague));
    }
  } catch (e) {
    console.warn(e);
    dispatch(
      addToast({
        id: 'get-league-by-id',
        message: e,
        severity: 'error',
      }),
    );
  }
}

export async function deleteSeasonById(id: any, dispatch: any) {
  try {
    await api.delete(`/seasons/${id}`);
  } catch (e: any) {
    const message =
      e?.response?.data?.errorMessage ||
      `Not able to delete league ${id} , try again`;
    dispatch(
      addToast({
        id: 'delete_season_by_id',
        message,
        severity: 'error',
      }),
    );
  }
}

export async function getSeasonByLeagueIdAndDate(leagueId: any, date: any) {
  if (leagueId === 0) {
    return [];
  }
  try {
    const url = `/leagues/${leagueId}/seasons?date=${date}`;
    const seasons = await api.get(url);
    if (seasons.length === 0) {
      store.dispatch(
        addToast({
          id: 'league-season',
          message: `No active season at ${date}, select another date or add season`,
          severity: 'warning',
        }),
      );
    }
    return seasons;
  } catch (e) {
    return [];
  }
}
