import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { copyEvent } from '../../../models/events';
import { BaseItem } from '../../reducers/dataSlice';
import { useAppDispatch } from '../../reducers/hooks';
import { TableContext } from '../InfiniteTable/InfiniteTable';

interface CopyIconProps {
  item: BaseItem;
  createCopy(x: any, y: any): any;
}

export default function CopyIcon({ item, createCopy }: CopyIconProps) {
  const dispatch = useAppDispatch();
  const { create } = useContext(TableContext);

  async function handleCopy() {
    const copy = await createCopy(item, dispatch);
    dispatch(create(copy));
  }

  return (
    <IconButton
      aria-label={`copy-${item.type}`}
      color="default"
      size="small"
      onClick={() => handleCopy()}
    >
      <ContentCopy />
    </IconButton>
  );
}
