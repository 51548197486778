import React, { useState } from 'react';
import {
  Stack,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { staticService } from '../../../../services/StaticService';
import { trapEvent } from '../../../components/hooks';

export function EditAudio({ data, onCommit }: any) {
  const [audio, setAudio] = useState(data.audio_layout || {});
  const [mode, setMode] = useState(isStereo(audio) ? 'stereo' : 'mono');
  const layouts = staticService.data.audioLayouts.all;

  return (
    <Stack
      gap={2}
      sx={{
        padding: 2,
        width: '280px',
      }}
      onClick={trapEvent}
      onKeyDown={trapEvent}
    >
      {mode === 'stereo' && renderStereo(layouts, audio, setAudio)}
      {mode === 'mono' && renderMono(layouts, audio, setAudio)}

      <Stack direction="row" justifyContent="flex-end">
        <RadioGroup
          row
          defaultValue={mode}
          onChange={e => setMode(e.target.value)}
        >
          <FormControlLabel value="stereo" label="stereo" control={<Radio />} />
          <FormControlLabel value="mono" label="mono" control={<Radio />} />
        </RadioGroup>

        <Button startIcon={<Check />} onClick={() => onCommit(audio)}>
          Done
        </Button>
      </Stack>
    </Stack>
  );
}

function isStereo(audio: any) {
  for (let c = 1; c <= 15; c += 2) {
    if (audio[`Ch ${c}`] !== audio[`Ch ${c + 1}`]) return false;
  }
  return true;
}

function renderStereo(layouts: any, audio: any, setAudio: any) {
  return (
    <>
      {renderStereoChannel(1, layouts, audio, setAudio)}
      {renderStereoChannel(3, layouts, audio, setAudio)}
      {renderStereoChannel(5, layouts, audio, setAudio)}
      {renderStereoChannel(7, layouts, audio, setAudio)}
    </>
  );
}

function renderStereoChannel(
  pair: any,
  layouts: any,
  audio: any,
  setAudio: any,
) {
  const key1 = `Ch ${pair}`;
  const key2 = `Ch ${pair + 1}`;

  return (
    <Stack
      key={pair}
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        span: { flex: '1', whiteSpace: 'nowrap' },
        select: { flex: '1', width: '0' },
      }}
    >
      <span>
        Channel {pair}
        {' & '}
        {pair + 1}
      </span>
      <select
        value={audio[key1] || ''}
        onChange={e =>
          setAudio({
            ...audio,
            [key1]: Number(e.target.value) || undefined,
            [key2]: Number(e.target.value) || undefined,
          })
        }
      >
        <option value="">-</option>
        {layouts.map((layout: any) => (
          <option key={layout.id} value={layout.id}>
            {layout.name}
          </option>
        ))}
      </select>
    </Stack>
  );
}

function renderMono(layouts: any, audio: any, setAudio: any) {
  return (
    <>
      {renderMonoChannel(1, layouts, audio, setAudio)}
      {renderMonoChannel(2, layouts, audio, setAudio)}
      {renderMonoChannel(3, layouts, audio, setAudio)}
      {renderMonoChannel(4, layouts, audio, setAudio)}
      {renderMonoChannel(5, layouts, audio, setAudio)}
      {renderMonoChannel(6, layouts, audio, setAudio)}
      {renderMonoChannel(7, layouts, audio, setAudio)}
      {renderMonoChannel(8, layouts, audio, setAudio)}
    </>
  );
}

function renderMonoChannel(
  channel: any,
  layouts: any,
  audio: any,
  setAudio: any,
) {
  const key = `Ch ${channel}`;

  return (
    <Stack
      key={channel}
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        span: { flex: '1', whiteSpace: 'nowrap' },
        select: { flex: '1', width: '0' },
      }}
    >
      <span>Channel {channel}</span>
      <select
        value={audio[key] || ''}
        onChange={e =>
          setAudio({
            ...audio,
            [key]: Number(e.target.value) || undefined,
          })
        }
      >
        <option value="">-</option>
        {layouts.map((layout: any) => (
          <option key={layout.id} value={layout.id}>
            {layout.name}
          </option>
        ))}
      </select>
    </Stack>
  );
}
