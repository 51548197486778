import { useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Popout } from './Popout';

interface PopoutIconProps {
  icon: any;
  noClickAway?: any;
  children: any;
  color?: any;
  iconProps?: any;
}

export function PopoutIcon({
  icon,
  noClickAway,
  children,
  ...iconProps
}: PopoutIconProps) {
  const ref = useRef<any>();
  const [isOpen, setOpen] = useState(false);

  return (
    <span ref={ref}>
      <IconButton {...iconProps} size="small" onClick={() => setOpen(!isOpen)}>
        {isOpen ? <Close /> : icon}
      </IconButton>
      <Popout
        anchor={ref}
        open={isOpen}
        placement="bottom-end"
        transformOrigin="right top"
      >
        {children(() => setOpen(false))}
      </Popout>
    </span>
  );
}
