import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { fetchEventWithAssociations } from '../../../api/events';
import {
  closeSubItems,
  opensubItems,
  setSubItems,
} from '../../../reducers/workspaceSlice';

interface ToggleRowProps {
  subItems: any;
}

export async function loadAndSet(eventId: any, dispatch: any) {
  const eventDetails = await fetchEventWithAssociations(eventId);
  dispatch(setSubItems({ id: eventId, data: eventDetails }));
}

export async function openAndLoad(eventId: any, subItems: any, dispatch: any) {
  dispatch(opensubItems({ id: eventId }));

  if (!subItems?.data) {
    loadAndSet(eventId, dispatch);
  }
}

export function useToggleRow({ subItems }: ToggleRowProps) {
  const dispatch = useDispatch();

  const toggleRow = useCallback(
    async (item: any) => {
      const id = item._id;
      const { open } = subItems[id] || {};
      if (open) {
        dispatch(closeSubItems({ id }));
        return;
      }

      openAndLoad(id, subItems[id], dispatch);
    },
    [subItems, dispatch],
  );

  return toggleRow;
}
