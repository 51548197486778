import { Box } from '@mui/system';
import { useContext } from 'react';
import { RowProps, ColumnType, ColumnSetting } from './index';

import { getKeyFromId, isTempId } from '../../../models/records';
import { BaseItem, ItemAction } from '../../reducers/dataSlice';
import { TableContext } from './InfiniteTable';
import {
  AdminCell,
  DateCell,
  DropdownCell,
  CustomCell,
  ExpandCell,
  TextCell,
  TextStatusCell,
  TimeCell,
  ToggleCell,
} from './cells';

export function TableRow<T extends BaseItem>(rowProps: RowProps<T>) {
  const { item, columns, readOnly, style, tableRowStyle, subRows } = rowProps;
  const { changes } = useContext(TableContext);
  const id = item._id;
  const key = getKeyFromId(item.type, id);
  const rowData = { ...item, ...changes[key] };
  const isNew = isTempId(id);

  const getStyle = () => {
    if (isNew) {
      return '-new';
    } else if (item.isDeleted) {
      return '-deleted';
    } else if (item.isDisabled) {
      return '-disabled';
    } else if (item.action && item.action === ItemAction.REFRESHED) {
      return '-blinking-row';
    } else {
      return '';
    }
  };

  return (
    <Box
      key={id}
      data-id={id}
      onClick={() => rowProps?.onRowClick && rowProps.onRowClick(id)}
      style={style}
    >
      <Box sx={tableRowStyle} className={getStyle()}>
        {columns.map((column: ColumnSetting) => {
          if (!column) return;
          const containsSpecifikProp = column.checkEdit
            ? column.checkEdit(item)
            : false;
          const canEdit =
            (column.editable &&
              !readOnly &&
              !rowData.isDeleted &&
              !containsSpecifikProp) ||
            (column?.editRole && column.editRole && !rowData.isDeleted);

          switch (column.datatype) {
            case ColumnType.Expand:
              return (
                <ExpandCell
                  key={`${id}-${column.key}-${column.header}`}
                  item={rowData}
                />
              );
            case ColumnType.Text:
              return (
                <TextCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                  render={(value: any) =>
                    column.render ? column.render(column, item) : value
                  }
                />
              );
            case ColumnType.TextStatus:
              return (
                <TextStatusCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                />
              );
            case ColumnType.Date:
              return (
                <DateCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                />
              );
            case ColumnType.Time:
              return (
                <TimeCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                />
              );
            case ColumnType.Dropdown:
              return (
                <DropdownCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                />
              );
            case ColumnType.Toggle:
              return (
                <ToggleCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                />
              );
            case ColumnType.Admin:
              return (
                <AdminCell
                  key={`${id}-${column.key}-${column.header}`}
                  item={rowData}
                  column={column}
                  displayIcon={
                    column.onGetIconToDisplay &&
                    column.onGetIconToDisplay(rowData, changes, item.type)
                  }
                  className={column.style}
                  canEdit={canEdit}
                  readOnly={readOnly}
                  isNew={isNew}
                />
              );
            default:
              return (
                <CustomCell
                  key={`${id}-${column.key}-${column.header}`}
                  column={column}
                  item={rowData}
                  editable={canEdit}
                />
              );
          }
        })}
      </Box>
      {subRows && subRows(rowData)}
    </Box>
  );
}
