import IconButton from '@mui/material/IconButton';

import { Stack } from '@mui/material';
import logo from '../../assets/images/Viaplay_solo.png';
import classes from './Logo.module.css';

const NentIcon = () => (
  <>
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="open drawer"
      sx={{ mr: 2, padding: '6px' }}
    >
      <div className={classes.Logo}>
        <img src={logo} alt="Nent logo" />
      </div>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{
          padding: '0',
          width: '123px',
        }}
      >
        <p className={classes.Main}>viaplay</p>
        <p className={classes.SubMain}>sports calendar</p>
      </Stack>
    </IconButton>
  </>
);

export default NentIcon;
