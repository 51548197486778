import { createTableStyle } from '../../../components/InfiniteTable/createTableStyle';

export const { rowStyle, columns } = createTableStyle({
  subCount: {
    width: 55,
    border: '0px solid',
    alignItems: 'center',
  },

  id: 60,
  week: 40,
  day: 40,
  date: 80,
  start: 48,
  sport: {
    width: 120,
    button: {
      textAlign: 'center',
    },
  },
  league: {
    minWidth: 80,
    flex: '0.6 1 100px',
    overflow: 'hidden',
    button: {
      textAlign: 'left',
    },
  },
  audio: {
    width: 60,
    alignItems: 'center',
  },
  event: {
    margin: 0,
    flex: '2 1 100px',
    overflow: 'hidden',
    minWidth: 120,
    button: {
      textAlign: 'left',
      minWidth: 100,
    },
  },
  round: {
    width: 40,
    button: {
      display: 'flex',
      minWidth: 40,
      margin: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  stage: {
    width: 90,
  },
  rights: {
    width: 50,
    button: {
      display: 'flex',
      margin: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  admin: 150,
});

export const eventRowStyle = {
  ...rowStyle,

  '._status': {
    marginLeft: '8px',
    padding: '0 4px',
    borderRadius: '4px',
    color: '#fff',

    '&.-new': {
      backgroundColor: '#175882',
    },
    '&.-updated': {
      backgroundColor: '#ff6833',
    },
    '&.-deleted': {
      color: '#f88',
      textDecoration: 'line-through',
    },
  },
};

export const rightsPop = {
  width: '200px',
  backgroundColor: 'white',
};

export const rightsStyle = {
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  overflow: 'hidden',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  color: '#FDFEFE',
  fontWeight: 600,

  svg: {
    width: '12px',
    height: '12px',
  },

  '&.-level-0': {
    backgroundColor: '#ccc',
  },
  '&.-level-1': {
    backgroundColor: '#EFC311',
  },
  '&.-level-2': {
    backgroundColor: '#2F8A28',
  },
  '&.-level-3': {
    backgroundColor: '#0D559C',
  },
  '&.-level-4': {
    backgroundColor: '#AC5DBD',
  },
  '&.-level-none': {
    backgroundColor: '#AEB6BF',
  },
  '&.-level-100': {
    backgroundColor: '#FC9403',
    width: '26px',
    height: '26px',
    clipPath:
      'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
  },
  '&.-deleted': {
    color: '#f88',
    textDecoration: 'line-through',
  },
  '&.-display': {
    backgroundColor: '#CC0099',
  },
};

export const importanceStyle = {
  '&.-level-1': {
    color: '#EFC311',
  },
  '&.-level-2': {
    color: '#2F8A28',
  },
  '&.-level-3': {
    color: '#0D559C',
  },
  '&.-level-4': {
    color: '#AC5DBD',
  },
};
