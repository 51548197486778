export const fileSize = (size: any) => {
  if (size === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / k ** i).toFixed(2)) + sizes[i];
};

export const VALID_TYPES = {
  xlsx: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
  },
};

export function timeout(delay: any) {
  return new Promise(res => setTimeout(res, delay));
}
