import { createDataSlice } from './dataSlice';
import { transformFeedDelta, validateFeed } from '../../models/feeds';

const feedsSlice = createDataSlice({
  sliceName: 'feeds',
  validator: validateFeed, // function to validate created rows
  deltaTransformer: transformFeedDelta, // function to transform the data to fit ui model
});

export const { reducer } = feedsSlice;
export const {
  create,
  commit,
  applyFilter,
  setRequestedTo,
  addItems,
  setFinished,
  update,
  rollback,
  clearList,
  undoCreate,
  undoChange,
  clearCreates,
  setLoading,
  refreshItem,
  resetItemAction,
} = feedsSlice.actions;

export const actions = feedsSlice.actions;

export const getSlice = (state: any) => state.feeds;
