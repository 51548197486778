import { addMinutes } from 'date-fns';
import { DataType, ErrorMessage, ItemAction } from '../app/reducers/dataSlice';
import { staticService } from '../services/StaticService';
import {
  JSDateToUTC,
  calculateDuration,
  localizedCleanToUTC,
  nowToLocalJSDate,
  timeToMinutes,
  utcTimestampToJSDate,
} from './dates';
import {
  createCopyIdNew,
  createNewId,
  validateDateRange,
  validateIfEmpty,
} from './records';

export const isProduction = (record: { production_id: any }) =>
  !!record.production_id;

export const DEFAULT_DURATION: number = 120;
export const DEFAULT_START_OFFSET: number = -5;

export const BLANK_PRODUCTION = {
  channel_id: 0,
  sport_type: '',
  league_id: 0,
  title: '',
  run_type_id: 0,
  template_two: 0,
  stream_equipment: 0,
  production_leader_id: 0,
  created_by: 'a@b',
  updated_by: 'a@b',
  commentators: [],
  takers: [],
  audio_layout: {},
  associations: {},
  comments: {},
};

export const retrofitProduction = (production: any) => {
  const { leagues } = (staticService as any).data;

  return {
    ...BLANK_PRODUCTION,
    ...production,
    _id: production.production_id,
    type: DataType.Production,
    start_timestamp: localizedCleanToUTC(production.production_start),
    start_time: localizedCleanToUTC(production.production_start),
    end_timestamp: localizedCleanToUTC(production.production_end),
    duration: calculateDuration(
      localizedCleanToUTC(production.production_start),
      localizedCleanToUTC(production.production_end),
    ),
    isDeleted: !!production.delete_datetime,
    country: production?.country || '',
    sport_type: leagues.byId[production.league_id]?.sportType,
    template: {
      one: production?.template_one || 0,
      two: production?.template_two || 0,
    },
  };
};

export function retrofitProductions(productions = []) {
  return productions.map((production: any) => retrofitProduction(production));
}

export function createNewProduction({
  fromDate = nowToLocalJSDate(),
  country,
}: any) {
  const id = createNewId();
  const start = fromDate;
  start.setMinutes(0);

  const end = addMinutes(new Date(start), DEFAULT_DURATION);

  const localStartTimestamp = JSDateToUTC(start);
  const localEndTimeStamp = JSDateToUTC(end);

  return {
    ...BLANK_PRODUCTION,
    country,
    _id: id,
    type: DataType.Production,
    start_timestamp: localStartTimestamp,
    start_time: localStartTimestamp,
    end_timestamp: localEndTimeStamp,
    duration: calculateDuration(localStartTimestamp, localEndTimeStamp),
    itemAction: ItemAction.NEW,
  };
}

export function createSubProduction(item: any, country?: string | undefined) {
  const eventStartAt = utcTimestampToJSDate(item.start_timestamp);

  const league = staticService.data.leagues.all.find(
    (league: any) => league.id === item.league_id,
  );
  const defaultDuration = league?.default_duration
    ? timeToMinutes(league?.default_duration)
    : DEFAULT_DURATION;

  const defaultStartOffset =
    'default_start_offset' in league
      ? league.default_start_offset
      : DEFAULT_START_OFFSET;

  const startDate = addMinutes(eventStartAt, defaultStartOffset);
  const endDate = addMinutes(startDate, defaultDuration);
  if (!item.start_timestamp) {
    startDate.setMinutes(0);
    startDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setHours(0);
  }

  const sport = staticService.data.sports.all.find(
    (s: any) => s.internalName === item.sport,
  );

  return {
    ...createNewProduction({ eventStartAt, country }),
    associations: { events: [item.id] },
    sport_type: sport.internalName,
    league_id: item.league_id,
    title: item.event,
    start_timestamp: JSDateToUTC(startDate),
    start_time: JSDateToUTC(startDate),
    end_timestamp: JSDateToUTC(endDate),
    duration: calculateDuration(JSDateToUTC(startDate), JSDateToUTC(endDate)),
  };
}

export function copyProduction(production: any) {
  const id = createCopyIdNew();
  return {
    ...production,
    delete_datetime: undefined,
    _id: id,
    copy_id: production.production_id,
    production_id: id,
    itemAction: ItemAction.COPY,
    commentators: production.commentators.map(
      (c: { id: any; skill: any; location: any }) => {
        return { id: c.id, skill: c.skill, location: c.location };
      },
    ),
    audio_layout: { ...production.audio_layout },
    start_timestamp: localizedCleanToUTC(production.production_start),
    start_time: localizedCleanToUTC(production.production_start),
    end_timestamp: localizedCleanToUTC(production.production_end),
  };
}

/**
 * Return the key element for the fields not valid.
 * @param prodData
 * @returns {*[]|*[]}
 */
export function validateProduction(prodData: {
  [x: string]: any;
  delete_datetime?: any;
}): ErrorMessage[] {
  return prodData.isDeleted
    ? []
    : ([
        validateIfEmpty(prodData, 'league_id'),
        validateIfEmpty(prodData, 'sport_type'),
        validateIfEmpty(prodData, 'channel_id'),
        validateIfEmpty(prodData, 'run_type_id'),
        validateIfEmpty(prodData, 'production_leader_id'),
        validateIfEmpty(prodData, 'title'),
        validateIfEmpty(prodData, 'country'),
        validateDateRange(prodData, 'start_timestamp', 'end_timestamp'),
        validateDateRange(prodData, 'start_time', 'end_timestamp'),
      ] as ErrorMessage[]);
}

export function validateCountry(prodData: any, field: string) {
  const result = validateIfEmpty(prodData, field);
  return result;
}

export function transformProductionDelta(delta: any, production: any) {
  // If a template is specified in the delta,
  // apply the values defined in the template
  if (delta.template_two) {
    const template = (staticService as any).data.prodTemplates.byId[
      delta.template_two
    ]?.data;
    const templateDelta = { ...template };

    if (templateDelta?.league_id) {
      templateDelta.sport_type = (staticService as any).data.leagues.byId[
        template.league_id
      ]?.sportType;
    }

    return {
      ...templateDelta,
      ...delta,
    };
  }

  return delta;
}
