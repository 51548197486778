import { color } from '@mui/system';

export const day_button = {
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: '#eee',
  border: '2px solid transparent',
  fontSize: '0.75rem',
  fontWeight: 400,
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#a6087f',
  },
};
