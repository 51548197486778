import { Stack } from '@mui/system';
import { ReactNode } from 'react';

interface ActionProps {
  children: ReactNode;
}
export function Actions({ children }: ActionProps) {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ position: 'fixed', bottom: 16, right: 48 }}
    >
      {children}
    </Stack>
  );
}
