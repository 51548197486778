import { formatDate } from './dates';

export const getId = (itemId: string): string => {
  let id = '';
  if (itemId.includes('#')) {
    const splitedId = itemId.split('#');
    id = splitedId[1];
  }
  return id;
};

export const retroFitChanges = (changes: any) => {
  const fitedChanges = changes.map((change: any) => {
    return {
      ...change,
      id: getId(change.id),
    };
  });
  return fitedChanges;
};
export const retroFitAudit = (audit: any) => {
  const changes = audit?.entityChange
    ? retroFitChanges(audit.entityChange.changes)
    : [];

  return {
    _id: audit.id,
    ...audit,
    ...(audit?.entityChange && {
      entityChange: {
        ...audit.entityChange,
        changes,
      },
    }),
  };
};

export function retroFitAudits(audits = []) {
  return audits.map((audit: any) => retroFitAudit(audit));
}

export const filterToQuery = (currentFilter: any, type: string, offset = 0) => {
  const filter = {
    ...currentFilter[type],
  };

  if (filter.fromDate && filter.toDate) {
    const from = formatDate(filter.fromDate);
    const to = formatDate(filter.toDate);
    delete filter.fromDate;
    delete filter.toDate;
    return new URLSearchParams({
      from,
      to,
      filter: JSON.stringify(filter),
      next: offset,
    } as any);
  }

  return new URLSearchParams({
    filter: JSON.stringify(filter),
    next: offset,
  } as any);
};

export const TYPED_FILTERS: any = [
  { value: 0, label: 'All' },
  {
    value: 'Event',
    label: 'Event',
  },
  {
    value: 'Feed',
    label: 'Feed',
  },
  {
    value: 'Production',
    label: 'Production',
  },
  {
    value: 'League',
    label: 'League',
  },
];

export const ACTIVITY_FILTERS: any = [
  { value: 0, label: 'All' },
  {
    value: 'Update',
    label: 'Update',
  },
  {
    value: 'Create',
    label: 'Create',
  },
  {
    value: 'Delete',
    label: 'Delete',
  },
  {
    value: 'SearchEvents',
    label: 'Search:Events',
  },
];
