import { Clear, Search } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { TYPED_FILTERS } from '../../../models/audits';
import { staticService } from '../../../services/StaticService';
import { DateRangePicker } from '../../components/DateRangePicker';
import { applyFilter } from '../../reducers/auditSlice';
import { setFilter } from '../../reducers/filterSlice';
import { useAppDispatch } from '../../reducers/hooks';
import { historyFilterContainer, historyFilterOptionsContainer } from './style';

export const getFilters = (currentFilter: any) => {
  const requestFilters = currentFilter;
  for (const field in currentFilter) {
    if (currentFilter[field] === 0 || currentFilter[field] === '') {
      delete currentFilter[field];
    }
  }

  return requestFilters;
};

export function AuditFilter() {
  const dispatch = useAppDispatch();

  const activityOptions: any = staticService.getActivities();

  const [filters, setFilters] = useState<any>({
    fromDate: new Date(),
    toDate: new Date(),
    entity: 0,
    activity: 0,
  });

  const handleSearch = async () => {
    const requestFilters = getFilters(filters);
    dispatch(setFilter({ audit: requestFilters }));
    dispatch(applyFilter());
  };

  const handleClear = () => {
    const newFilter = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    };
    setFilters({
      ...newFilter,
      entity: 0,
      activity: 0,
    });
    dispatch(setFilter({ audit: newFilter }));
    dispatch(applyFilter());
  };
  return (
    <Stack sx={historyFilterContainer}>
      <Typography variant="h1" sx={{ fontSize: 20 }}>
        History
      </Typography>
      <Stack sx={historyFilterOptionsContainer}>
        <DateRangePicker
          range={[filters.fromDate, filters.toDate]}
          onChange={(newRange: any[]) => {
            setFilters({
              ...filters,
              fromDate: newRange[0],
              toDate: newRange[1],
            });
          }}
          maxDate={new Date()}
        />
        <FormControl size="small">
          <InputLabel id="type">Entity</InputLabel>
          <Select
            id="type"
            label="type"
            sx={{ width: 150 }}
            value={filters?.entity}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                entity: e.target.value,
              });
            }}
            name="entityType"
          >
            {TYPED_FILTERS.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ height: 35 }}
          value={filters.entity_id || ''}
          size="small"
          label="Id"
          id="entity_id"
          onChange={(e: any) => {
            setFilters({
              ...filters,
              entity_id: Number(e.target.value),
            });
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <FormControl size="small">
          <InputLabel>Activity</InputLabel>
          <Select
            label="Activity"
            sx={{ width: 150 }}
            value={filters?.activity}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                activity: e.target.value,
              });
            }}
            id="activity"
            name="activity"
          >
            {activityOptions &&
              activityOptions.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          size="small"
          value={filters?.author || ''}
          id="updated_by"
          label="Updated By..."
          onChange={(e: any) => {
            console.log(e);
            setFilters({
              ...filters,
              author: e.target.value,
            });
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <Button variant="contained" endIcon={<Search />} onClick={handleSearch}>
          Search
        </Button>
        <Button variant="outlined" endIcon={<Clear />} onClick={handleClear}>
          Clear Filter
        </Button>
      </Stack>
    </Stack>
  );
}
