import { Add } from '@mui/icons-material';
import { Fab, Tooltip } from '@mui/material';
import { useAppSelector } from '../../reducers/hooks';

interface CreateActionProps {
  onSave(x: any): any;
  type: string;
}

export function CreateAction({ onSave, type }: CreateActionProps) {
  const filter = useAppSelector(state => state.filter);

  const onclick = () => {
    onSave(filter.fromDate);
    document
      ?.querySelector('.infinite-table')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Tooltip title={`Add ${type}`}>
      <Fab
        id={`add-${type}s`}
        color="secondary"
        aria-label="create new"
        onClick={onclick}
      >
        <Add />
      </Fab>
    </Tooltip>
  );
}
