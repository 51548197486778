import { useState } from 'react';
import {
  Looks3,
  Looks3Outlined,
  Looks4,
  Looks4Outlined,
  LooksOne,
  LooksOneOutlined,
  LooksTwo,
  LooksTwoOutlined,
} from '@mui/icons-material';
import { Checkbox, TableCell } from '@mui/material';
import { unionBy } from 'lodash';
import { tableRow } from '../style';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { getSlice, updateDetails } from '../../../../reducers/leaguesSlice';

const LeagueImportance = ({ id, level, disabled }: any) => {
  const { details } = useAppSelector(getSlice);
  const dispatch = useAppDispatch();
  const checkArray = [false, false, false, false];
  checkArray[level - 1] = true;
  const [checked, setChecked] = useState(checkArray);

  const handleClick = (event: any) => {
    const newArray = [false, false, false, false];
    newArray[event.target.id - 1] = true;
    const currentChanges = details.changes.rights || [];
    const existedRights = details.data.rights || [];
    const currentRights = unionBy(currentChanges, existedRights, 'id');
    const newRights = currentRights.map((right: any) => {
      if (right.id === id) {
        return {
          ...right,
          level: event.target.id,
        };
      }
      return right;
    });
    setChecked(newArray);
    dispatch(updateDetails({ rights: newRights }));
  };
  return (
    <TableCell id="importance" sx={tableRow}>
      <Checkbox
        id="1"
        icon={<LooksOneOutlined />}
        checkedIcon={<LooksOne htmlColor="#EFC311" />}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 25, paddingLeft: 1 } }}
        checked={checked[0]}
        onClick={handleClick}
        disabled={disabled}
      />

      <Checkbox
        id="2"
        icon={<LooksTwoOutlined />}
        checkedIcon={<LooksTwo htmlColor="#2F8A28" />}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 25, paddingLeft: 1 } }}
        checked={checked[1]}
        onClick={handleClick}
        disabled={disabled}
      />
      <Checkbox
        id="3"
        icon={<Looks3Outlined />}
        checkedIcon={<Looks3 htmlColor="#0D559C" />}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 25, paddingLeft: 1 } }}
        checked={checked[2]}
        onClick={handleClick}
        disabled={disabled}
      />
      <Checkbox
        id="4"
        icon={<Looks4Outlined />}
        checkedIcon={<Looks4 htmlColor="#AC5DBD" />}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 25, paddingLeft: 1 } }}
        checked={checked[3]}
        onClick={handleClick}
        disabled={disabled}
      />
    </TableCell>
  );
};

export default LeagueImportance;
