import { createDataSlice } from './dataSlice';
import { validateEvent } from '../../models/events';

const eventsSlice = createDataSlice({
  sliceName: 'event',
  validator: validateEvent,
});

export const { reducer } = eventsSlice;
export const {
  create,
  commit,
  applyFilter,
  setRequestedTo,
  addItems,
  setFinished,
  update,
  rollback,
  clearList,
  undoCreate,
  undoChange,
  setUploading,
  clearUploading,
  setLoading,
  refreshItem,
  resetItemAction,
} = eventsSlice.actions;

export const actions = eventsSlice.actions;

export const getSlice = (state: any) => state.events;
