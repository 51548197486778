import { createTableStyle } from '../InfiniteTable/createTableStyle';

export const { rowStyle, columns }: any = createTableStyle({
  id: 150,
  property: 150,
  type: 80,
  old: 200,
  new: 200,
});

export const historyHeadbRowStyle = {
  ...rowStyle,
  '._badge': {
    backgroundColor: '#b17e9b',
  },
  '._new': { backgroundColor: 'green' },
  backgroundColor: '#FBFCFC',
};

export const auditSubRowStyle = {
  ...rowStyle,
  backgroundColor: '#FBFCFC',
};

export const expandRowStyle = {
  border: '0px solid',
  borderBottom: '0px solid',
  '._subs': {
    backgroundColor: '#f4f3f4',
    width: 750,
    margin: '20px',
  },
};

export const infoHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: '0px',
  paddingBottom: '5px',
  backgroundColor: 'white',
  borderBottom: '1px solid #ffd9d9',
};
