import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';
import {
  diffTimesRoundToHour,
  nowIsoDate,
  nowIsoDateTime,
} from '../../models/dates';

export enum NOTIFICATION_STATUS {
  UNREAD = 0,
  READ = 1,
  IMPORTANT = 2,
}

export interface NotificationItem {
  id: string;
  message_type?: string;
  message: string;
  url?: string;
  status: number;
  created_at: string;
  action: string;
  data: any;
}

const initialState: {
  notifications: NotificationItem[];
  serviceOnline: boolean;
} = {
  notifications: [],
  serviceOnline: false,
};

const exists = (item: NotificationItem, list: NotificationItem[]) => {
  return list.findIndex(current => current.id === item.id) !== -1;
};

const inAppNotificationSlice = createSlice({
  name: 'inAppNotifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationItem[]>) => {
      const allIncomingNotifications: NotificationItem[] = action.payload;
      const currents: NotificationItem[] = [...state.notifications];
      const newOnes: NotificationItem[] = allIncomingNotifications.filter(
        item => !exists(item, currents),
      );
      const local = JSON.parse(localStorage.getItem('notifications') || '[]');

      //Filter out the ones already in state, then adding the status based on localstore.
      newOnes.map((item: NotificationItem) => {
        const inLocalStorage = local.find(
          (notification: NotificationItem) => notification.id === item.id,
        );
        item.status = inLocalStorage ? inLocalStorage.status : 0;
        return item;
      });

      state.notifications = [...state.notifications, ...newOnes];
      state.notifications.sort((a, b) =>
        a.created_at > b.created_at ? -1 : 1,
      );
      //We keep in local store 48h of notification, in case the notification are reload and we want to keep the previous status.
      const now: string = nowIsoDateTime();
      const toLocalStore = unionBy(local, state.notifications, 'id').filter(
        item => {
          const since = diffTimesRoundToHour(item.created_at, now);
          return since === undefined ? false : since <= 48;
        },
      );
      //clean locale store older 48 hours
      localStorage.setItem('notifications', JSON.stringify(toLocalStore));
    },
    removeNotification: (state, action: PayloadAction<string[]>) => {
      const deletedIds: string[] = action.payload;

      console.log(
        `Before removing  ${state.notifications.length} deleted coming in ${deletedIds.length}`,
      );
      state.notifications = state.notifications.filter(
        item => !deletedIds.includes(item.id),
      );

      console.log(
        `After filtering ${state.notifications.length} deleted coming in ${deletedIds.length}`,
      );
    },
    setServiceStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        serviceOnline: action.payload,
      };
    },
    readNotification: (state, action: PayloadAction<any>) => {
      const { notification, status } = action.payload;
      const index = state.notifications.findIndex(
        (item: NotificationItem) => item.id === notification.id,
      );

      if (index !== -1 && !status) {
        if (notification.status === NOTIFICATION_STATUS.IMPORTANT) {
          state.notifications[index].status = NOTIFICATION_STATUS.UNREAD;
        } else {
          state.notifications[index].status = notification.status + 1;
        }
      }

      if (status && notification.status === NOTIFICATION_STATUS.UNREAD) {
        state.notifications[index].status = status;
      }
      localStorage.setItem(
        'notifications',
        JSON.stringify(state.notifications),
      );
    },
  },

  // 24b88464e800946ce22b0de8cb38c79b
});

export const {
  addNotification,
  readNotification,
  setServiceStatus,
  removeNotification,
} = inAppNotificationSlice.actions;

export const { reducer } = inAppNotificationSlice;

export const getSlice = (state: any) => state.inAppNotifications;
