import { useState } from 'react';
import { unionBy } from 'lodash';
import CalendarDateInput from './CalendarDateInput';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import {
  getSlice,
  setDetails,
  updateDetails,
} from '../../../../reducers/leaguesSlice';

export function RightStartDateInput({ id, rightStartDate }: any) {
  const { details } = useAppSelector(getSlice);
  const dispatch = useAppDispatch();

  const handleChange = (value: any) => {
    const currentChanges = details.changes.rights || [];
    const existedRights = details.data.rights || [];
    const currentRights = unionBy(currentChanges, existedRights, 'id');
    let date = new Date(value).toLocaleDateString('sv-SE');
    const today = new Date().toLocaleDateString('sv-SE');
    if (date < today) {
      date = '';
    }
    const newRights = currentRights.map((right: any) => {
      if (right.id === id) {
        return {
          ...right,
          rightStart: date,
        };
      }
      return right;
    });
    dispatch(updateDetails({ rights: newRights }));
  };

  return (
    <CalendarDateInput
      id={id}
      rightValue={rightStartDate}
      handleValue={handleChange}
      field="rights-start-date"
    />
  );
}

export function RightEndDateInput({ id, rightEndDate }: any) {
  const { details } = useAppSelector(getSlice);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  const handleChange = (value: any) => {
    const currentChanges = details.changes.rights || [];
    const existedRights = details.data.rights || [];
    const currentRights = unionBy(currentChanges, existedRights, 'id');
    let date = new Date(value).toLocaleDateString('sv-SE');
    const today = new Date().toLocaleDateString('sv-SE');
    if (date < today) {
      date = '';
    }
    setError('');
    const newRights = currentRights.map((right: any) => {
      if (right.id === id) {
        if (date === right.rightStart) {
          setError('Not allowed to choose the same date as start date!');
          return {
            ...right,
            rightEnd: '',
          };
        }
        if (date < right.rightStart) {
          setError('Not allowed to choose a date before the start date!');
          return {
            ...right,
            rightEnd: '',
          };
        }
        return {
          ...right,
          rightEnd: date,
        };
      }
      return right;
    });
    dispatch(updateDetails({ rights: newRights }));
  };

  return (
    <>
      <CalendarDateInput
        id={id}
        rightValue={rightEndDate}
        handleValue={handleChange}
        error={error}
        field="rights-end-date"
      />
    </>
  );
}
