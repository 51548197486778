import { DateTime, DurationObjectUnits } from 'luxon';

export const DAY_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const LOCALE_ESTABLISHED = 'sv-SE';
export const TIMEZONE_ESTABLISHED = 'Europe/Stockholm';

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATE_TIME_FORMAT_TIMEZONE = "yyyy-MM-dd'T'HH:mm'Z'";
export const DATE_TIME_FORMAT_SECONDS = 'yyyy-MM-dd HH:mm:ss';

const MIN_MS = 1000 * 60;
const HOUR_MS = 1000 * 60 * 60;

export const nowToLocalJSDate = (): Date => {
  const datetime = DateTime.now();
  datetime.setZone(TIMEZONE_ESTABLISHED);
  return datetime.toJSDate();
};

export const nowIsoDate = (): string => {
  const datetime = DateTime.now();
  datetime.setZone(TIMEZONE_ESTABLISHED);
  return datetime.toISODate() || '';
};

export const nowIsoDateTime = (): string => {
  const datetime = DateTime.now();
  datetime.setZone(TIMEZONE_ESTABLISHED);
  return datetime.toISO() || '';
};

export const timeToMinutes = (stringTime: string): number => {
  if (!stringTime) return 0;
  const timePart = stringTime.split(':'); // split it at the colons
  if (timePart.length === 1) {
    return +timePart[0] * 60;
  }
  return +timePart[0] * 60 + +timePart[1];
};

export const minutesToTime = (minutes: number): string => {
  if (!minutes || minutes === 0) return '00:00';
  const hour = minutes / 60;
  const min = minutes % 60;

  const hoursSt = hour.toString().padStart(2, '0');
  const minutesSt = min.toString().padStart(2, '0');

  return `${hoursSt}:${minutesSt}`;
};

const getUTCDateTime = (timestamp: string): DateTime => {
  return DateTime.fromISO(timestamp, {
    zone: 'UTC',
  });
};

/**
 * Get Date object (browser localized) from date and time in UTC
 * @param date
 * @param time
 */
export const utcToJSDate = (date: string, time: string): Date => {
  if (!time) return DateTime.fromISO(date).toJSDate();

  const timezone = time.includes('Z') ? '' : 'Z';
  const datetime = getUTCDateTime(`${date}T${time}${timezone}`);
  return datetime.toJSDate();
};

/**
 * Get Date object from timestamp string in UTC
 * eg.
 * @param timestamp format as   yyyy-mm-ddThh:mm:ssZ
 */
export const utcTimestampToJSDate = (timestamp: string): Date => {
  const datetime = getUTCDateTime(timestamp);
  return datetime.toJSDate();
};

/**
 * Get localized time from a given UTC date and time
 * @param date
 * @param time
 * @param useSeconds
 * @return yyyy-MM-dd
 */
export const utcToLocalizedTime = (
  date: string,
  time: string,
  useSeconds?: boolean,
): string => {
  return utcTimestampToLocalizedTime(`${date}T${time}`);
};
export const utcTimestampToLocalizedTime = (
  timestamp: string,
  useSeconds?: boolean,
): string => {
  if (!extractTime(timestamp)) return '';
  const datetime = getUTCDateTime(timestamp);
  return (
    datetime
      .setZone(TIMEZONE_ESTABLISHED)
      .toISOTime({ suppressSeconds: !useSeconds, includeOffset: false }) || ''
  );
};

export const utcTimestampToLocalizedDate = (timestamp: string): string => {
  const datetime = getUTCDateTime(timestamp);
  return datetime.setZone(TIMEZONE_ESTABLISHED).toISODate() || '';
};

export const utcTimestampToLocalizedJSDate = (timestamp: string): Date => {
  const datetime = getUTCDateTime(timestamp);
  return datetime.setZone(TIMEZONE_ESTABLISHED).toJSDate() || '';
};

/**
 * Get localized timestamp from date and time in UTC.
 *
 * @param date
 * @param time
 * @return yyyy-MM-ddThh:mm
 */
export const utcToLocalizedISO = (date: string, time: string): string => {
  if (!time) return date;
  return utcTimestampToLocalized(`${date}T${time}`);
};

/**
 * Return the given utc timestamp with the local time using format yyyy-MM-dd HH-mm
 *
 * @param timestamp
 * @param useSeconds
 */
export const utcTimestampToLocalizedClean = (
  timestamp: string,
  useSeconds = false,
) => {
  return utcTimestampToLocalized(timestamp, useSeconds, false);
};
/**
 * Return the given utc timestamp with the local time using iso format by default
 * @param timestamp
 * @param useSeconds add seconds
 * @param iso defines format, iso (YYYY-MM-ddTHH:mm+02:00) or (YYYY-MM-dd HH:mm)
 */
export const utcTimestampToLocalized = (
  timestamp: string,
  useSeconds: boolean = false,
  iso = true,
): string => {
  const datetime: DateTime = getUTCDateTime(timestamp);
  if (!datetime?.isValid) return timestamp;
  const format =
    !iso && useSeconds ? DATE_TIME_FORMAT_SECONDS : DATE_TIME_FORMAT;
  const localDate = iso
    ? datetime
        .setZone(TIMEZONE_ESTABLISHED)
        .toISO({ suppressSeconds: !useSeconds })
    : datetime.setZone(TIMEZONE_ESTABLISHED).toFormat(format);

  return localDate || '';
};

/**
 * Convert the input date and time express in Europe/Stockholm (timezone established).
 * If the browser is another timezone, we need to create the date on timezone established before converting to UTC.
 * The user intention is to enter the time on Europe/Stockholm timezone regarless where thet are located.
 *
 * eg. entering 15:00 in Finland means 15:00 in Sweden, so the UTC time should be 13:00.
 * @param date
 * @param time
 */
export const localizedToUTC = (date: string, time: string): string => {
  if (!time) return date;
  return localizedTimestampToUTC(`${date}T${time}`);
};

export const localizedTimestampToUTC = (timestamp: string): string => {
  if (!extractTime(timestamp)) return timestamp;
  const datetimeLocal = DateTime.fromISO(timestamp, {
    zone: TIMEZONE_ESTABLISHED,
  });
  if (!datetimeLocal.isValid) return timestamp;
  return datetimeLocal.toUTC().toISO({ suppressSeconds: true }) || '';
};

export const localizedCleanToUTC = (datetimeLocal: string): string => {
  return localizedToUTC(extractDate(datetimeLocal), extractTime(datetimeLocal));
};

export const formatDate = (date: Date): string => {
  const a = date.toLocaleString(LOCALE_ESTABLISHED, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: TIMEZONE_ESTABLISHED,
  });
  return a;
};

export const buildTimestamp = (date: string, time: string) => {
  if (!time) return date;
  const datetime = getUTCDateTime(`${date}T${time}`);
  return datetime.toISO({ suppressSeconds: true });
};

export const buildLocalJSDate = (date: string, time: string) => {
  if (!time) return date;

  return new Date(`${date}T${time}`);
};

export const JSDateToLocal = (date: Date): string => {
  const datetimeLocal = DateTime.fromJSDate(date, {
    zone: TIMEZONE_ESTABLISHED,
  });
  return datetimeLocal.toISO({ suppressSeconds: true }) || '';
};

export const JSDateToUTC = (date: Date): string => {
  const datetime = DateTime.fromJSDate(date, {
    zone: 'UTC',
  });
  return datetime.toFormat(DATE_TIME_FORMAT_TIMEZONE) || '';
};

export const JSDateToUTCDate = (date: Date): string => {
  const datetime = DateTime.fromJSDate(date, {
    zone: 'UTC',
  });
  return datetime.toISODate() || '';
};

export const isAroundSameTime = (date1: Date, date2: Date): boolean => {
  const diffMinutes = 1;
  const temp = new Date(date1);
  temp.setMinutes(date1.getMinutes() + diffMinutes);
  return temp > date2;
};

export function compareDate(
  earlier: {
    getFullYear: () => number;
    getMonth: () => number;
    getDate: () => number;
  },
  later: {
    getFullYear: () => number;
    getMonth: () => number;
    getDate: () => number;
  },
) {
  return (
    earlier.getFullYear() - later.getFullYear() ||
    earlier.getMonth() - later.getMonth() ||
    earlier.getDate() - later.getDate()
  );
}

export function extractDate(datetimeString = '') {
  const space = datetimeString.includes('T')
    ? datetimeString.indexOf('T')
    : datetimeString.indexOf(' ');
  return space > 0 ? datetimeString.substring(0, space) : datetimeString;
}

export function extractTime(datetimeString = '') {
  const space = datetimeString.includes('T')
    ? datetimeString.indexOf('T')
    : datetimeString.indexOf(' ');
  return space > 0 ? datetimeString.substring(space + 1) : '';
}

export function getLocalizedDay(date: any) {
  return DAY_OF_WEEK[date.getDay()];
}

export function getInterval(start: string, end: string) {
  return (
    utcTimestampToJSDate(end).getTime() - utcTimestampToJSDate(start).getTime()
  );
}

export function parseDateInput(input: string, basis: string | number | Date) {
  const digits = input.replace(/[^0-9]/g, '');
  const date = new Date(basis);

  if (digits.length <= 0) {
    return basis;
  }

  const dayIndex = Math.max(0, digits.length - 2);
  const day = Number(digits.substring(dayIndex));
  date.setDate(day);

  const monthIndex = Math.max(0, digits.length - 4);
  if (monthIndex < dayIndex) {
    const month = Number(digits.substring(monthIndex, dayIndex)) - 1;
    date.setMonth(month);
  }
  if (monthIndex > 0) {
    const year = Number(digits.substring(0, monthIndex));
    const fullYear =
      (year < 10 && 2020 + year) || (year < 100 && 2000 + year) || year;
    date.setFullYear(fullYear);
  }

  return formatDate(date);
}

export function parseTimeInput(input: string) {
  if (input === '-' || input === '') return '';
  const digits = input.replace(/[^0-9]/g, '');
  if (digits.length <= 2) {
    const hours = digits.padStart(2, '0');
    const parsedNumber = parseInt(hours, 10);
    if (parsedNumber === 24) {
      return '00:00';
    }
    if (parsedNumber > 23) {
      return 'Invalid';
    }

    return `${hours}:00`;
  } else {
    const minuteIndex = digits.length - 2;
    const hours = digits.substring(0, minuteIndex).padStart(2, '0');
    const minutes = digits.substring(minuteIndex);
    const parsedNumber = parseInt(hours, 10);
    const parsedMinutes = parseInt(minutes, 10);
    if (parsedNumber === 24) {
      return `00:${minutes}`;
    }
    if (parsedNumber > 23 || parsedMinutes > 59) {
      return 'Invalid';
    }

    return `${hours}:${minutes}`;
  }
}

/**
 * Adjust the end date according to the previous duration.
 * takes the original interval and calculate the new To date based on the new start date.
 * new To date is newFromDate+original interval,
 *
 * If start date is not valid, then returnt the origial To date.
 * @param originalFrom string iso format yyyy-mm-dd hh:mm
 * @param originalTo string iso format yyyy-mm-dd hh:mm
 * @param newStartDate Date object.
 * @returns {Date|*}
 */
export function skewDateRange(
  originalFrom: any,
  originalTo: any,
  newFromDate: Date,
) {
  if (!Number.isNaN(newFromDate.getTime())) {
    const interval = getInterval(originalFrom, originalTo);
    return new Date(newFromDate.getTime() + interval);
  }
  return utcTimestampToJSDate(originalTo);
}

/**
 * Calculate the duration in hour in between the given from and to timestamp
 * @param from string iso format yyyy-mm-dd hh:mm
 * @param to string iso format yyyy-mm-dd hh:mm
 */
export function calculateDuration(from: string, to: string) {
  let duration = '-';
  const fromDate = utcTimestampToJSDate(from);
  const toDate = utcTimestampToJSDate(to);

  if (!Number.isNaN(fromDate.getTime()) && !Number.isNaN(toDate.getTime())) {
    const interval = getInterval(from, to);
    const hours = String(Math.abs(Math.trunc(interval / HOUR_MS)));
    const minutes = String(Math.abs(Math.trunc((interval % HOUR_MS) / MIN_MS)));
    duration = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }
  return duration;
}

export const diffTimesHour = (
  from: string,
  to: string,
): DurationObjectUnits => {
  const fromDateTime: DateTime = getUTCDateTime(from);
  const toDateTime: DateTime = getUTCDateTime(to);
  return toDateTime.diff(fromDateTime, ['hours', 'minutes']);
};

export const diffTimesRoundToHour = (
  from: string,
  to: string,
): number | undefined => {
  const diff: DurationObjectUnits = diffTimesHour(from, to);
  return diff.hours;
};

/**
 *
 * @param newDateTime
 * @param oldDateTime
 * @returns new enddate in local date time CET
 */
export function getCalculatedEndDate(
  startDateTime: string,
  endDateTime: string,
) {
  const newDate: Date = utcTimestampToJSDate(startDateTime);
  const oldDate: Date = utcTimestampToJSDate(endDateTime);
  if (Number.isNaN(oldDate.getTime()) || Number.isNaN(newDate.getTime()))
    return endDateTime;

  const diff_hour = (oldDate.getTime() - newDate.getTime()) / 1000 / (60 * 60);

  if (diff_hour >= 24) {
    oldDate.setDate(newDate.getDate());
    oldDate.setMonth(newDate.getMonth());
    oldDate.setFullYear(newDate.getFullYear());
  }

  if (diff_hour < 1) {
    while (oldDate.getTime() < newDate.getTime()) {
      oldDate.setDate(oldDate.getDate() + 1);
    }
  }
  return JSDateToUTC(oldDate);
}

export function checkTimes(newtime: any) {
  const date = new Date(newtime);
  const noValid = Number.isNaN(date.getTime());
  if (noValid) {
    return {
      start_date: extractDate(newtime),
      start_time: `${extractDate(newtime)}TInvalid`,
    };
  }
  return { start_date: newtime, start_time: newtime };
}
