import { Redo } from '@mui/icons-material';
import { getISOWeek } from 'date-fns';
import { get } from 'lodash';
import Roles from '../../../../models/Roles';
import {
  JSDateToUTC,
  calculateDuration,
  checkTimes,
  extractDate,
  getCalculatedEndDate,
  getLocalizedDay,
  localizedTimestampToUTC,
  skewDateRange,
  utcTimestampToJSDate,
  utcTimestampToLocalized,
  utcTimestampToLocalizedDate,
  utcTimestampToLocalizedJSDate,
} from '../../../../models/dates';
import { copyProduction } from '../../../../models/productions';
import { isTempId } from '../../../../models/records';
import { abbreviateName } from '../../../../models/strings';
import { authService } from '../../../../services/AuthService';
import { staticService } from '../../../../services/StaticService';
import { ColumnSetting, ColumnType } from '../../../components/InfiniteTable';
import { AdminIcons } from '../../../components/InfiniteTable/cells/AdminCell';
import { getSettingsByType } from '../../../components/Settings/table/ColumnPreference';
import { SettingsIcon } from '../../../components/Settings/table/SettingsIcon';
import { addDialogMessage } from '../../../reducers/appSlice';
import { BaseItem, DataType } from '../../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { ProductionAudio, ProductionCommentators } from './CustomRows';
import { columns } from './style';

export const useProductionColumnSettings = (
  type: DataType,
  subType?: DataType,
) => {
  const dispatch = useAppDispatch();
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.PRODUCTION_EDITOR]);
  const { tableSettings }: any = useAppSelector(state => state.settings);
  const columnSettings = getSettingsByType({
    table: type,
    subTable: subType,
    userSettings: tableSettings,
  });
  const allColumns: any = {
    id: {
      key: '_id',
      header: 'ID',
      datatype: ColumnType.Text,
      style: columns.id,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        const isNew = isTempId(item._id);
        if (item.isDeleted) {
          return item._id;
        } else {
          return (
            <>
              {!isNew && (
                <a
                  href={`${process.env.REACT_APP_BOOKING_URL}/production/${item._id}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {item._id}
                </a>
              )}
            </>
          );
        }
      },
    },
    week: {
      key: 'start_timestamp',
      header: 'Wk',
      datatype: ColumnType.Text,
      style: columns.week,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        const cetJSDate = utcTimestampToLocalizedDate(item.start_timestamp);
        return getISOWeek(new Date(cetJSDate));
      },
    },
    day: {
      key: 'start_timestamp',
      header: '',
      datatype: ColumnType.Text,
      style: columns.day,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        const cetJSDate = utcTimestampToLocalizedDate(item.start_timestamp);
        return getLocalizedDay(new Date(cetJSDate));
      },
    },
    date: {
      key: 'start_timestamp',
      header: 'Date',
      datatype: ColumnType.Date,
      editable: true,
      style: columns.date,
      onBeforeChange(newTimestamp: any, item: BaseItem) {
        const utcNewTimestamp = localizedTimestampToUTC(newTimestamp);
        if (utcNewTimestamp === item.start_timestamp) return;

        const newEndDate = skewDateRange(
          item.start_timestamp,
          item.end_timestamp,
          utcTimestampToJSDate(utcNewTimestamp),
        );
        const endTimeStamp = JSDateToUTC(newEndDate);

        return {
          start_timestamp: utcNewTimestamp,
          start_time: utcNewTimestamp,
          end_timestamp: endTimeStamp,
        };
      },
    },
    start: {
      key: 'start_time',
      header: 'Start',
      datatype: ColumnType.Time,
      editable: true,
      style: columns.start,
      onBeforeChange(newStartTime: string, item: BaseItem) {
        const utcNewTimestamp = localizedTimestampToUTC(newStartTime);
        if (utcNewTimestamp === item.start_time) return;
        const newEndDate = getCalculatedEndDate(
          utcNewTimestamp,
          item.end_timestamp,
        );

        const { start_date, start_time } = checkTimes(utcNewTimestamp);
        return {
          start_timestamp: start_date,
          start_time,
          end_timestamp: newEndDate,
          duration: calculateDuration(start_date, newEndDate),
        };
      },
    },
    end: {
      key: 'end_timestamp',
      header: 'End',
      datatype: ColumnType.Time,
      editable: true,
      style: columns.end,
      onGetRenderValue(column: ColumnSetting, item: BaseItem, value: any) {
        const startDate = extractDate(
          utcTimestampToLocalized(item.start_timestamp),
        );
        const endDate = extractDate(
          utcTimestampToLocalized(item.end_timestamp),
        );
        const isSameDate = startDate.startsWith(endDate);
        if (isSameDate) return value;
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 8,
            }}
          >
            <span>{value}</span>
            <span>
              <Redo sx={{ fontSize: '10px' }} />
            </span>
          </div>
        );
      },
      onBeforeChange(newEnd: any, item: BaseItem) {
        const utcNewTimestamp = localizedTimestampToUTC(newEnd);
        if (utcNewTimestamp === item.end_timestamp) return;

        const newEndDate = getCalculatedEndDate(
          item.start_timestamp,
          utcNewTimestamp,
        );

        return {
          end_timestamp: newEndDate,
          duration: calculateDuration(item.start_timestamp, newEndDate),
        };
      },
    },
    duration: {
      key: 'duration',
      header: 'Duration',
      datatype: ColumnType.Text,
      editable: false,
      style: columns.duration,
    },
    country: {
      key: 'country',
      header: 'Country',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        return staticService.getCountries();
      },
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.country,
      onGetDisplayValue: (options: any, value: any) => {
        return options[value];
      },
      onBeforeChange(newCountry: any, item: BaseItem) {
        const newValues = staticService.validateCountryChange(newCountry, item);
        return newValues;
      },
    },
    run_type: {
      key: 'run_type_id',
      header: 'Run Type',
      datatype: ColumnType.Dropdown,
      editable: true,
      style: columns.runtype,
      onGettingOptions: (item: BaseItem) => {
        const country = get(item, 'country', '');
        return country
          ? staticService.getRunTypesByCountry(country)
          : staticService.getRunTypes();
      },
      onGetDisplayValue: (options: any, value: any) => {
        return options[value];
      },
    },
    template: {
      key: 'template',
      header: 'Template',
      datatype: ColumnType.Dropdown,
      editable: true,
      style: columns.template_two,
      onGettingOptions: (item: BaseItem) => {
        const country = get(item, 'country', '');
        return country
          ? staticService.getProductionTeemplatesByCountry(country)
          : staticService.getProductionTemplates();
      },
      onGetDisplayValue: (options: any, template: any, style: any) => {
        let currentTemplate =
          template?.two &&
          Object.values(options).find(
            (option: any) => option.value === template.two,
          );
        const template_one: any =
          template?.one &&
          Object.values(options).find(
            (option: any) => option.value === template.one,
          );
        if (!currentTemplate && template_one) {
          currentTemplate = {
            ...template_one,
            label: template_one.label,
            style: `${style} -strikethrough`,
          };
        }
        return currentTemplate;
      },
      onBeforeChange: (newTemplate: any, item: BaseItem) => {
        if (item.template_two !== 0 && newTemplate !== item.template_two) {
          dispatch(
            addDialogMessage({
              title: 'Be aware!',
              message:
                'Changing template can lead to loss of data as Crew Members, Equipments etc. Do you want to proceed with the change?',
            }),
          );
        }

        return {
          template: {
            two: newTemplate,
          },
          template_two: newTemplate,
        };
      },
    },
    sport: {
      key: 'sport_type',
      header: 'Sport',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        return staticService.getSports();
      },
      onGetDisplayValue: (options: any, value: any) => {
        return options[value];
      },
      onBeforeChange: (sportUpdated: any, item: BaseItem) => {
        return {
          sport_type: sportUpdated,
          league_id: 0,
        };
      },
      checkEdit: (item: BaseItem) => !!item?.source,
      editable: true,
      style: columns.sport,
    },
    league: {
      key: 'league_id',
      header: 'League',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        const sport = get(item, 'sport_type', '');
        return sport ? staticService.getLeaguesBySports(sport) : [];
      },
      onGetDisplayValue: (options: any, value: any) => {
        return Object.values(options).find(
          (option: any) => option.value === value,
        );
      },
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.league,
      textAlign: 'left',
    },
    title: {
      key: 'title',
      header: 'Title',
      datatype: ColumnType.TextStatus,
      editable: true,
      style: columns.title,
      onBeforeChange(newTitle: any, item: BaseItem) {
        return { title: newTitle };
      },
    },
    channel: {
      key: 'channel_id',
      header: 'Channel',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        const country = get(item, 'country', '');
        return country
          ? staticService.getChannelsByCountry(country)
          : staticService.getChannels();
      },
      onGetDisplayValue: (options: any, value: any) => {
        return Object.values(options).find(
          (option: any) => option.value === value,
        );
      },
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.channel,
      textAlign: 'left',
    },
    stream: {
      key: 'stream_equipment',
      header: 'Viaplay STR',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        return staticService.getStreams();
      },
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.channel,
      textAlign: 'left',
      onGetDisplayValue: (options: any, value: any) => {
        return Object.values(options).find(
          (option: any) => option.value === value,
        );
      },
    },
    audio: {
      key: 'audio',
      header: 'Audio',
      datatype: ColumnType.Custom,
      editable: true,
      style: columns.audio,
      render: (x: any, y: any) => ProductionAudio({ column: x, item: y }),
    },
    takers: {
      key: 'takers',
      header: 'WSC',
      datatype: ColumnType.Toggle,
      toggleStyleActive: '-rec-active',
      editable: true,
      style: columns.takers,
      onGettingOptions() {
        return staticService.data.countries.all.map(
          (country: any) => country.code,
        );
      },
    },
    production_leader: {
      key: 'production_leader_id',
      header: 'Prod.Leader',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        const country = get(item, 'country', '');
        return country
          ? staticService.getProductionLeaderByCountry(country)
          : staticService.getProductionLeader();
      },
      onGetDisplayValue: (options: any, value: any) => {
        const leader: any = Object.values(options).find(
          (option: any) => option.value === value,
        );
        return { value: leader?.value, label: abbreviateName(leader?.label) };
      },
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.leader,
    },
    editorial: {
      key: 'commentator',
      header: 'Editorial',
      datatype: ColumnType.Custom,
      editable: true,
      style: columns.commentator,
      render: (x: any, y: any) =>
        ProductionCommentators({ column: x, item: y }),
    },
    admin: {
      key: 'admin',
      header: <SettingsIcon dataType={DataType.Production} subType={subType} />,
      datatype: ColumnType.Admin,
      style: columns.admin,
      editable: true,
      onGetIconToDisplay(item: BaseItem, changes: any, type: string) {
        const isNew = isTempId(item._id);
        const hasChanges = !!changes[`${type}:${item._id}`];
        return {
          history: !isNew,
          copy: !isNew && !item.isDeleted && !readOnly,
          associations: true,
          delete: !isNew && !item.isDeleted && !readOnly,
          undo: isNew || hasChanges,
        } as AdminIcons;
      },
      onCopy: (item: any, dispatch: any) => copyProduction(item),
    },
  };
  const settings: any = [];
  columnSettings.forEach((column: any) => {
    if (!column.hidden) {
      settings.push(allColumns[column.id]);
    }
  });
  return settings;
};
