import { useContext } from 'react';
import { InsertLink } from '@mui/icons-material';
import { useAppDispatch } from '../../reducers/hooks';
import { TableContext } from '../InfiniteTable/InfiniteTable';
import { BaseItem } from '../../reducers/dataSlice';
import { PopoutIcon } from '../Popout';
import { ReadOnlyAssociations } from '../Associations/ReadOnlyAssociations';
import { EditAssociations } from '../Associations/EditAssociations';

interface AssociationIconProps {
  isNew: any;
  item: BaseItem;
  readOnly?: boolean;
}

export function AssociationIcon({
  isNew,
  item,
  readOnly,
}: AssociationIconProps) {
  const dispatch = useAppDispatch();
  const { update } = useContext(TableContext);

  const onCommit = (associations: any) => {
    dispatch(
      update({
        type: item.type,
        _id: item._id,
        delta: { associations },
      }),
    );
  };

  const hasAssoc =
    item.associations?.events?.length ||
    item.associations?.productions?.length ||
    item.associations?.feeds?.length;
  const hasNewAssoc =
    item.associations?.events?.length ||
    item.associations?.productions?.length ||
    item.associations?.feeds?.length;

  return (
    <PopoutIcon
      noClickAway
      icon={<InsertLink />}
      aria-label="associations"
      color={
        (hasNewAssoc && 'secondary') || (hasAssoc && 'primary') || 'default'
      }
    >
      {(close: () => void) =>
        readOnly ? (
          <ReadOnlyAssociations data={item} onCancel={close} />
        ) : (
          <EditAssociations
            data={item}
            isNew={isNew}
            onCancel={close}
            onCommit={(result: any) => {
              onCommit(result);
              close();
            }}
          />
        )
      }
    </PopoutIcon>
  );
}
