import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { authService } from '../../../services/AuthService';
import { useAppSelector } from '../../reducers/hooks';
import UserProfileMenu from './ProfileMenu';

export default function UserAuth() {
  const { initials } = useAppSelector(state => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (!authService.isLoggedIn()) {
    return (
      <Button
        variant="contained"
        size="small"
        style={{
          maxHeight: '32px',
          minHeight: '32px',
          margin: 'auto',
          background: '#CC0099',
        }}
        endIcon={<LoginIcon />}
        onClick={() => authService.doLogin()}
      >
        Log in
      </Button>
    );
  }

  return (
    <>
      <Tooltip title="User profile">
        <IconButton
          aria-controls={open ? 'user-profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="inherit"
          aria-label="login"
          size="medium"
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
        >
          <Avatar
            sx={{
              bgcolor: '#CC0099',
              width: 32,
              height: 32,
              fontSize: 16,
            }}
          >
            {initials}
          </Avatar>
        </IconButton>
      </Tooltip>

      <UserProfileMenu
        anchor={anchorEl}
        open={open}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
}
