import { configureStore } from '@reduxjs/toolkit';
import { reducer as user } from './userSlice';
import { reducer as app } from './appSlice';
import { reducer as productions } from './productionsSlice';
import { reducer as leagues } from './leaguesSlice';
import { reducer as events } from './eventsSlice';
import { reducer as feeds } from './feedsSlice';
import { reducer as workspace } from './workspaceSlice';
import { reducer as audit } from './auditSlice';
import { reducer as notifications } from './notificationSlice';
import { reducer as filter } from './filterSlice';
import { reducer as settings } from './settingSlice';
import { reducer as inAppNotifications } from './inAppNotificationSlice';

export const store = configureStore({
  devTools: true,
  reducer: {
    user,
    notifications,
    filter,
    settings,
    events,
    app,
    productions,
    leagues,
    feeds,
    workspace,
    audit,
    inAppNotifications,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Effectively disable serialization checking
      serializableCheck: {
        isSerializable: () => true,
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
