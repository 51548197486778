export const alertStyle = {
  padding: '0 10px 0 10px',
};

export const alertContent = {
  display: 'flex',
  flexDirection: 'row',
  height: 5,
  justifyContent: 'center',
};

export const statusBox = {
  display: 'flex',
};

export const statusButton = {
  padding: '10px',
};

export const circularProgress = {
  color: '#79F908',
  position: 'absolute',
  top: -10,
  left: -40,
};

export const paper = {
  height: 'fit-content',
  width: 'fit-content',
  padding: 3,
  fontSize: 12,
};

export const paperContent = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 2,
  width: 'fit-content',
  height: 'fit-content',
};

export const feedBackContent = {
  border: '0.1px solid black',
  padding: 2,
};

export const actionButton = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const dialogContent = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};

export const uploadStackHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const uploadStackContent = {
  height: 'fit-content',
  display: 'flex',
  direction: 'column',
  gap: 3,
  width: 700,
};

export const fileInfo = {
  width: 700,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 2,
};

export const uploadAction = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const uploadButton = {
  width: 'fit-content',
  height: 'fit-content',
};
