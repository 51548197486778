import { IconButton, InputAdornment, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import { setFilter } from '../../../reducers/filterSlice';
import { applyFilter } from '../../../reducers/leaguesSlice';

export function SearchLeague() {
  const dispatch = useAppDispatch();
  const { league }: any = useAppSelector(state => state.filter);

  return (
    <TextField
      id="league-search"
      sx={{ width: 300 }}
      label="Search"
      type="search"
      value={
        Object.keys(league).length > 0 &&
        Object.values(league.filter).length > 0
          ? league.filter
          : ''
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={e => {
        dispatch(
          setFilter({
            league: { filter: e.target.value },
          }),
        );
        dispatch(applyFilter());
      }}
    />
  );
}
