import PropTypes from 'prop-types';
import { authService } from '../../../services/AuthService';

interface RenderOnRoleProps {
  roles: any;
  children: any;
}

const RenderOnRole = ({ roles, children }: RenderOnRoleProps) =>
  authService.hasRole(roles) ? children : null;

RenderOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RenderOnRole;
