import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICONS,
  handleAction,
  handleLeague,
  handleMessageType,
  handleNotificationHref,
  handleNotificationTitle,
  handleStartInfo,
  handleUpdateTime,
} from '../../../../components/Notifications/helper';
import {
  NOTIFICATION_STATUS,
  NotificationItem,
  getSlice,
  readNotification,
} from '../../../../reducers/inAppNotificationSlice';
import {
  actionContent,
  bodyContent,
  dateIconContent,
  headerContent,
  inboxContent,
  inboxStatusContent,
  titleContent,
} from './styles';
import { utcTimestampToLocalizedClean } from '../../../../../models/dates';

interface NotificationInboxProps {
  fontSizeStatus: number;
  gridMinWidth: number | string;
  timestampSize: number;
  changeContentSize: number;
  statusLabelFontSize: number;
}

export function NotificationsInbox({
  fontSizeStatus,
  gridMinWidth,
  timestampSize,
  changeContentSize,
  statusLabelFontSize,
}: NotificationInboxProps) {
  const notifications: NotificationItem[] = useSelector(getSlice).notifications;

  const dispatch = useDispatch();

  return (
    <Box>
      <Box sx={inboxContent}>
        {notifications.map((notification: NotificationItem) => (
          <Grid
            key={notification.id}
            sx={{
              display: 'flex',
              width: '99%',
              borderBottom: '1px solid #e0e0e0',
              minWidth: gridMinWidth,
              backgroundColor: `${
                (notification.status === NOTIFICATION_STATUS.UNREAD &&
                  '#F6FAFF') ||
                (notification.status === NOTIFICATION_STATUS.IMPORTANT &&
                  '#FFF9F8')
              }`,
            }}
          >
            <Stack sx={actionContent}>
              {handleAction(notification, statusLabelFontSize)}
            </Stack>
            <CardActionArea
              href={handleNotificationHref(notification)}
              target="_blank"
              onClick={() =>
                dispatch(
                  readNotification({
                    notification,
                    status: NOTIFICATION_STATUS.READ,
                  }),
                )
              }
            >
              <CardContent>
                <Stack sx={headerContent}>
                  <Stack sx={titleContent}>
                    <Typography
                      sx={{
                        fontSize: fontSizeStatus,
                        fontWeight: `${
                          notification.status === NOTIFICATION_STATUS.UNREAD &&
                          'bold'
                        }`,
                      }}
                    >
                      {handleMessageType(notification)} -
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fontSizeStatus,
                        fontWeight: `${
                          notification.status === NOTIFICATION_STATUS.UNREAD &&
                          'bold'
                        }`,
                      }}
                    >
                      {handleNotificationTitle(notification)}
                    </Typography>
                  </Stack>
                  <Stack sx={dateIconContent}>
                    <Typography
                      sx={{ fontSize: timestampSize }}
                      color="text.secondary"
                    >
                      {handleUpdateTime(notification)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={bodyContent}>
                  <Stack sx={titleContent}>
                    <Typography
                      sx={{ fontSize: changeContentSize, whiteSpace: 'pre' }}
                    >
                      {handleStartInfo(notification)}
                    </Typography>
                  </Stack>
                  {notification.message && (
                    <Stack
                      sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}
                    >
                      <Typography
                        sx={{ fontSize: changeContentSize, color: '#D12306' }}
                      >
                        Change:
                      </Typography>
                      <Typography
                        sx={{ fontSize: changeContentSize, whiteSpace: 'pre' }}
                      >
                        {notification.message}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </CardActionArea>
            <CardActions sx={inboxStatusContent}>
              <IconButton
                id="notification-status"
                size="large"
                onClick={() => dispatch(readNotification({ notification }))}
              >
                <Icon>{ICONS[notification.status]}</Icon>
              </IconButton>
            </CardActions>
          </Grid>
        ))}
      </Box>
    </Box>
  );
}
