import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getDefaultSetting,
  getMergeSettings,
} from '../components/Settings/table/ColumnPreference';
import { DataType } from './dataSlice';

export enum TableType {
  Main = 'main',
  Sub = 'sub',
}

export interface Column {
  id: string;
  hidden: boolean;
}

export interface ChangeProps {
  mainTableDataType: DataType;
  subTableDataType?: DataType;
  tableType: TableType;
  data?: Column;
}

const initialState = {
  tableSettings: getDefaultSetting(),
  changes: {},
};

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addColumnSettings: (state, action: PayloadAction<any>) => {
      const { tableSettings } = action.payload;
      const newTableSettings: any = [];
      if (tableSettings) {
        tableSettings.forEach((setting: any) => {
          const defaultSetting = getDefaultSetting();
          const mergedTableSettings = getMergeSettings(
            tableSettings,
            setting.table,
            defaultSetting,
          );
          newTableSettings.push(mergedTableSettings);
        });
      }
      return {
        ...state,
        tableSettings: newTableSettings,
      };
    },
    updateColumnSettings: (state: any, action: PayloadAction<ChangeProps>) => {
      const { mainTableDataType, subTableDataType, tableType, data } =
        action.payload;
      const newTableSettings = updateCurrentState(
        state,
        mainTableDataType,
        tableType,
        subTableDataType,
        data,
      );

      return {
        ...state,
        tableSettings: newTableSettings,
        changes: {
          ...state.changes,
          tableSettings: newTableSettings,
        },
      };
    },
  },
});

export function updateCurrentState(
  state: any,
  mainTableDataType: string,
  tableType: string,
  subTableDataType?: string,
  data?: any,
) {
  const currentSettingsState = [...state.tableSettings];
  const mainIndex = currentSettingsState.findIndex(
    (setting: any) => setting.table === mainTableDataType,
  );

  const mainSettings: any = { ...currentSettingsState[mainIndex] };
  const defaultSetting = getDefaultSetting();
  const defaultMainSettings: any = {
    ...defaultSetting[mainIndex],
  };

  let newMainSettings = {};

  if (tableType === TableType.Main) {
    newMainSettings = handleMainTable(mainSettings, defaultMainSettings, data);
  }

  if (tableType === TableType.Sub) {
    newMainSettings = handleSubTable(
      mainSettings.subTables,
      defaultMainSettings.subTables,
      subTableDataType,
      mainSettings,
      data,
    );
  }

  currentSettingsState[mainIndex] = newMainSettings;

  return currentSettingsState;
}

export function handleMainTable(
  stateMainSettings: any,
  defaultMainSettings: any,
  data?: any,
) {
  let newMainColumns: any[] = [];
  let usingDefault = false;
  if (data) {
    const mainColumns: any[] = [...stateMainSettings.columns];
    const defaultMainColumns: any[] = [...defaultMainSettings.columns];
    const { usingDefaultTableSettings, newColumns } = replaceColumn(
      data,
      mainColumns,
      defaultMainColumns,
    );
    newMainColumns = newColumns;
    usingDefault = usingDefaultTableSettings;
  } else {
    const defaultMainColumns: any[] = [...defaultMainSettings.columns];
    newMainColumns = defaultMainColumns;
    usingDefault = true;
  }

  return {
    ...stateMainSettings,
    usingDefaultTableSettings: usingDefault,
    columns: newMainColumns,
  };
}

export function handleSubTable(
  subTables: any,
  defaultSubTables: any,
  subTableDataType: any,
  stateMainSettings: any,
  data?: any,
) {
  const subTable = subTables.find(
    (subTable: any) => subTable.table === subTableDataType,
  );
  const defaultSubTable = defaultSubTables.find(
    (subTable: any) => subTable.table === subTableDataType,
  );
  let newSubColumns: any[] = [];
  let usingDefault = false;
  if (data) {
    const { usingDefaultTableSettings, newColumns } = replaceColumn(
      data,
      subTable.columns,
      defaultSubTable.columns,
    );
    newSubColumns = newColumns;
    usingDefault = usingDefaultTableSettings;
  } else {
    newSubColumns = defaultSubTable.columns;
    usingDefault = true;
  }
  const newSubTable = {
    ...subTable,
    usingDefaultTableSettings: usingDefault,
    columns: newSubColumns,
  };
  const newSubTables = [...stateMainSettings.subTables];
  const index = newSubTables.findIndex(
    (subTable: any) => subTable.table === subTableDataType,
  );
  newSubTables[index] = newSubTable;
  return {
    ...stateMainSettings,
    subTables: newSubTables,
  };
}

export function replaceColumn(
  data: any,
  columns: any[],
  defaultColumns?: any[],
) {
  const newColumns: any = [];
  const usingDefaultValus: boolean[] = [];
  columns.forEach((column: any) => {
    if (column.id === data.id) {
      newColumns.push(data);
    } else {
      newColumns.push(column);
    }
  });

  newColumns.forEach((newColumn: any) => {
    const defaultColumn = defaultColumns?.find(
      (defaultColumn: any) => defaultColumn.id === newColumn.id,
    );
    if (defaultColumn.hidden === newColumn.hidden) {
      usingDefaultValus.push(true);
    } else {
      usingDefaultValus.push(false);
    }
  });
  return {
    usingDefaultTableSettings: !usingDefaultValus.includes(false),
    newColumns,
  };
}

export const { reducer } = settingSlice;

export const { addColumnSettings, updateColumnSettings } = settingSlice.actions;
