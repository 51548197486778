import React, { createRef, useState } from 'react';
import { FilterList, Person } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Notifications from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { authService } from '../../../services/AuthService';
import { AccountPage } from '../../container/Account/AccountPage';
import { useAppSelector } from '../../reducers/hooks';

interface UserProfileMenuProps {
  anchor: any;
  open: boolean;
  setAnchorEl(x: any): any;
}

export default function UserProfileMenu({
  anchor,
  open,
  setAnchorEl,
}: UserProfileMenuProps) {
  const { initials, fullname, account } = useAppSelector(state => state.user);
  const { email } = account;
  const [openPop, setOpenPop] = useState(false);
  const [type, setType] = useState('');

  const logout = () => {
    localStorage.removeItem('filter');
    sessionStorage.clear();
    authService.doLogout();
  };

  const ref = createRef<any>();

  const onClick = (input: React.SetStateAction<string>) => {
    setType(input);
    setOpenPop(!openPop);
  };

  return (
    <div>
      <Menu
        anchorEl={anchor}
        id="user-profile-menu"
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setAnchorEl(null)}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: '#CC0099' }}>{initials}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={fullname} secondary={email.value} />
        </MenuItem>
        <Divider />
        <div>
          <MenuItem ref={ref} onClick={() => onClick('Profile')}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            Account
          </MenuItem>
          <MenuItem ref={ref} onClick={() => onClick('Filter')}>
            <ListItemIcon>
              <FilterList />
            </ListItemIcon>
            Filter
          </MenuItem>
          <MenuItem ref={ref} onClick={() => onClick('Notification')}>
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            Notification
          </MenuItem>
          <MenuItem onClick={() => logout()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Logout
          </MenuItem>
        </div>
      </Menu>
      {openPop && (
        <AccountPage open={openPop} setOpen={setOpenPop} type={type} />
      )}
    </div>
  );
}
