export function validFilters(
  currentFilters: { field: string; value: any }[],
  allowedFilters: { [x: string]: any },
  presetType: any,
) {
  const filters = [];
  for (const current in currentFilters) {
    for (const allowed in allowedFilters) {
      if (currentFilters[current].field === allowedFilters[allowed]) {
        filters.push(currentFilters[current]);
      }
    }
    if (
      currentFilters[current].field === 'ids' &&
      currentFilters[current].value['type'] === presetType
    ) {
      filters.push({
        field: 'ids',
        value: currentFilters[current].value?.id,
      });
    }
  }
  return filters;
}
