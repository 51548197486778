import { Undo } from '@mui/icons-material';
import { Fab } from '@mui/material';

interface UndoActionProps {
  onUndo(): any;
}

export function UndoAction({ onUndo }: UndoActionProps) {
  return (
    <Fab
      id="undo-changes"
      color="default"
      aria-label="undo changes"
      onClick={onUndo}
    >
      <Undo />
    </Fab>
  );
}
