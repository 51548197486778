import { useCallback } from 'react';
import { Stack } from '@mui/material';
import { UploadProgress } from '../../container/Calendar/Upload/UploadProgress';
import { useAppDispatch, useAppSelector } from '../../reducers/hooks';
import { DateFilter } from './DateFilter';
import { FilterButton } from './FilterButton';
import { LeagueFilter } from './LeagueFilter';
import { SelectedFilters } from './SelectedFilters';
import { useFilterCallbacks } from './hooks';
import { FilterCriterias, FilterState, PresetType, TypeFilters } from './index';
import { useStoredFilters } from '../../../models/filters';
import { populateFilterStorage } from '../../../models/storage';
import { setFilterDates } from '../../reducers/filterSlice';

export interface FilterToolbarProps {
  presetType: PresetType;
}

export function FilterToolbar({ presetType }: FilterToolbarProps) {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(state => state.filter);
  const { customFilter, filterPresets } = useStoredFilters();

  const allowFields = TypeFilters[presetType].concat(TypeFilters['global']);

  const { onChangeFilter, onSaveFilter } = useFilterCallbacks({
    customFilter,
    filterPresets,
  });

  const onChangeDates = (newRange: any[]) => {
    dispatch(
      setFilterDates({
        fromDate: newRange[0],
        toDate: newRange[1],
      }),
    );

    const dates = {
      fromDate: {
        value: newRange[0],
        date: new Date(),
      },
      toDate: {
        value: newRange[1],
        date: new Date(),
      },
    };
    populateFilterStorage(dates);
  };

  const onTodayClick = useCallback(() => {
    dispatch(
      setFilterDates({
        fromDate: new Date(),
        toDate: new Date(),
      }),
    );

    // Add the dates to the local storage too keep filers when refreshing page
    const dates = {
      fromDate: {
        value: new Date(),
        date: new Date(),
      },
      toDate: {
        value: new Date(),
        date: new Date(),
      },
    };
    populateFilterStorage(dates);
  }, [dispatch]);

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={{ padding: 1 }}>
      <LeagueFilter
        idField="id"
        presetType={presetType}
        filter={customFilter}
        onChange={(newCustomFilter: FilterCriterias) =>
          onChangeFilter({
            customFilter: newCustomFilter,
            filterPresets,
            currentCustomFilters: customFilter,
          })
        }
      />
      <FilterButton
        presetType={presetType}
        filter={customFilter}
        presets={filterPresets}
        allowFields={allowFields}
        onCommit={onChangeFilter}
      />
      <Stack direction="row" alignItems="center" gap={1} sx={{ flex: '1' }}>
        <SelectedFilters
          customFilter={customFilter}
          filterPresets={filterPresets}
          presetType={presetType}
          onChangeFilter={onChangeFilter}
          onSave={onSaveFilter}
        />
      </Stack>
      <UploadProgress />
      <DateFilter
        filter={filter}
        onChangeRange={onChangeDates}
        onTodayClick={onTodayClick}
      />
    </Stack>
  );
}
