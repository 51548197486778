import axios from 'axios';
import { authService } from './AuthService';

class AuditService {
  axiosInstance: any;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_CREW_URL}`,
    });
    this.configure();
  }

  configure = () => {
    this.axiosInstance.interceptors.request.use(
      async (config: {
        headers: { Authorization: string };
        responseType: string;
      }) => {
        if (authService.isTokenExpired()) {
          await authService.refreshToken();
        }

        config.headers.Authorization = `Bearer ${authService.getToken()}`;
        return config;
      },
    );
  };

  /**
   * Get request to the given url. Returns the data response
   * @param {Get} url
   * @returns
   */
  get = async (
    url: string,
    controller?: { signal: any } | null | undefined,
  ) => {
    try {
      const config = controller ? { signal: controller.signal } : {};

      const response = await this.axiosInstance.get(url, config);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Post request to given url.
   * @param {*} url
   * @param {*} payload
   * @returns
   */
  post = async (
    url: string,
    payload?: any,
    headers?: any,
    responseType?: any,
  ) => {
    try {
      const response = await this.axiosInstance.post(url, payload, {
        headers,
        responseType,
      });

      return response;
    } catch (error: any) {
      //  handleError(error);
      console.log(error);
      return error;
    }
  };

  /**
   * Put request
   * @param {*} url
   * @param {*} payload
   * @returns
   */
  put = async (url: string, payload: any) => {
    try {
      const response = await this.axiosInstance.put(url, payload, {
        headers: {
          'content-type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      //  handleError(error);
      console.log(error);
      throw error;
    }
  };

  /**
   * Put request
   * @param {*} url
   * @param {*} payload
   * @returns
   */
  delete = async (url: string) => {
    try {
      const response = await this.axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      //  handleError(error);
      console.log(error);
      throw error;
    }
  };
}

export default new AuditService();
