import { useState } from 'react';
import { Check } from '@mui/icons-material';
import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { importance, rightStatus } from '../../../../models/rights';
import { trapEvent } from '../../../components/hooks';
import { rightsStyle } from './style';
import { staticService } from '../../../../services/StaticService';

const style = {
  width: '330px',
  padding: 3,
  flexDirection: 'column',
};

interface EditRightsProps {
  eventData: any;
  country: any;
  onCommit(x: any): any;
}

export function EditRights({ eventData, country, onCommit }: EditRightsProps) {
  const currentRight =
    eventData && eventData.find((r: any) => r.country === country);
  const [right, setRight] = useState(currentRight);

  function onSave() {
    const index = eventData.findIndex((r: any) => r.country === country);
    const rights = [...eventData];
    rights[index] = { ...right };
    onCommit(rights);
  }

  function handleChange(e: any, field: any) {
    setRight({
      ...right,
      [field]: e.target.value,
    });
  }

  const className = '-display';

  const countryLabel =
    staticService.data.countries.all.find((c: any) => c.code === country)
      ?.label || country;

  return (
    <>
      {currentRight && (
        <Stack sx={style} onClick={trapEvent} onKeyDown={trapEvent}>
          <Stack
            direction="row"
            gap={5}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="button">{countryLabel}</Typography>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${
                country.toLowerCase() === 'uk' ? 'gb' : country.toLowerCase()
              }.png`}
              srcSet={`https://flagcdn.com/w40/${
                country.toLowerCase() === 'uk' ? 'gb' : country.toLowerCase()
              }.png 2x`}
              alt=""
            />
          </Stack>
          <Stack direction="column" gap={1}>
            <FormLabel id="status-buttons">Rights</FormLabel>
            <RadioGroup
              aria-labelledby="status-buttons"
              sx={{
                flexDirection: 'column',
                border: '1px solid #EBEEF0',
                padding: 3,
                width: 'fit-content',
              }}
              value={right.type}
              onChange={e => handleChange(e, 'type')}
            >
              {rightStatus.map(({ value, label, icon }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={
                    <Stack
                      direction="row"
                      width={225}
                      gap={3}
                      justifyContent="space-between"
                    >
                      <Typography>{label}</Typography>
                      <Box className={className} sx={rightsStyle}>
                        {icon}
                      </Box>
                    </Stack>
                  }
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
            <FormLabel id="importance-buttons">Importance</FormLabel>
            <RadioGroup
              aria-labelledby="importance-buttons"
              row
              sx={{ border: '1px solid #EBEEF0 ', padding: 2 }}
              value={right.level || 0}
              onChange={e => handleChange(e, 'level')}
            >
              {importance.map(({ value, icon }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={icon}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button startIcon={<Check />} onClick={() => onSave()}>
              Done
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
