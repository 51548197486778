export const accountDialogPaper = {
  position: 'fixed',
  top: '30%',
  left: 200,
  m: 0,
};

export const accountHeader = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 2,
};

export const accountContent = {
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
};

export const dialogAvatar = {
  bgcolor: '#CC0099',
};

export const dialogBox = {
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '10px',
};

export const cardContent = {
  padding: '10px',
  margin: '20px',
  backgroundColor: '#FAFAFA',
};

export const profileContent = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 5,
  padding: 3,
  backgroundColor: 'white',
};

export const actionButtons = {
  display: 'flex',
  flexDirection: 'row-reverse',
  columnGap: 3,
};

export const filterHeader = {
  '.css-1qvr50w-MuiTypography-root ': {
    fontSize: '0.7rem',
  },
  '.css-1uaxcxb-MuiCardHeader-root': {
    padding: '8px',
  },
  padding: 1,
  margin: 0,
  backgroundColor: '#F1F1F1',
  color: 'black',
};

export const filterCardContent = {
  padding: 2,
  paddingBottom: 0,
};

export const filtetListHeader = {
  flexDirection: 'row-reverse',
  columnGap: 1,
  backgroundColor: '#EAEDED',
  fontSize: 'small',
  height: 'fit-content',
  minHeight: 0,
  pointerEvents: 'none',
  margin: 0,
};

export const expandMore = {
  pointerEvents: 'auto',
};

export const filterAccordianDetails = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: 2,
  rowGap: 2,
  maxWidth: '300px',
};

export const filterList = {
  display: 'flex',
  flexFlow: 'row wrap',
  maxWidth: '900px',
  rowGap: 2,
  columnGap: 2,
};

export const filterListItem = {
  width: 'auto',
};

export const filterCards = {
  flexDirection: 'column',
  rowGap: 5,
  width: '800px',
};

export const acccountCountriesInput = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: 2,
  paddingTop: 2,
  paddingBottom: 3,
};

export const accountCountryFields = {
  display: 'flex',
  flexDirection: 'row',
  position: 'start',
  columnGap: 2,
  padding: 1,
};

export const inputPropsStack = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: 3,
  paddingBottom: 3,
  paddingTop: 3,
};

export const phoneFormControl = {
  m: 1,
  minWidth: 70,
  paddingBottom: 2,
};

export const filterCardHeader = {
  fontSize: 15,
  marginLeft: 1,
};

export const accordionHeader = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  backgroundColor: '#EAEDED',
};
