import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
  buildFilterFromCriteria,
  filterToCriteria,
} from '../../../../../models/filters';
import { TypeFilters } from '../../../../components/Filters';
import { FilterCriteria } from '../../../../components/Filters/FilterCriteria';
import { useAppDispatch, useAppSelector } from '../../../../reducers/hooks';
import { updateNotifications } from '../../../../reducers/notificationSlice';
import { notificationCriteriaBox, whenBox, whenBoxLabel } from '../style';
import { ProductionNotification } from '../NotificationSetting';

export function ProductionCritera() {
  const dispatch = useAppDispatch();
  const { changes, errors } = useAppSelector(state => state.notifications);
  const allowFields = TypeFilters['production'].concat(TypeFilters['global']);
  const criteria =
    changes?.filter && Object.keys(changes?.filter).length !== 0
      ? filterToCriteria(changes?.filter, allowFields)
      : [];

  const [filter, setFilter] = useState(criteria);

  const handleChange = (newChange: any) => {
    dispatch(updateNotifications(newChange));
  };

  return (
    <Box sx={whenBox}>
      <Box sx={whenBoxLabel}>
        <FormLabel>When do I want to be notified?</FormLabel>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormLabel>Productions that matches the following criterias:</FormLabel>
        <Box sx={notificationCriteriaBox}>
          <RadioGroup>
            <FormControlLabel
              id="all-productions"
              control={<Radio id="all-productions" />}
              value="all"
              label="All Productions"
              checked={changes.all}
              onClick={(e: any) => {
                handleChange({
                  all: true,
                  filter: {},
                });
              }}
            />
            <Stack
              display="flex"
              flexDirection="row"
              gap={2}
              alignItems="center"
            >
              <FormControlLabel
                id="select-filters"
                control={<Radio id="select-filters" />}
                value="filter"
                label="Select filters"
                checked={!changes.all}
                onClick={(e: any) => {
                  handleChange({
                    all: false,
                    filter: {},
                  });
                }}
              />
              {errors?.filter && (
                <Typography color="red">{errors.filter}</Typography>
              )}
            </Stack>
          </RadioGroup>
          {!changes.all && (
            <FilterCriteria
              criteria={filter}
              allowFields={allowFields}
              presetType="production"
              onChange={criterias => {
                setFilter(criterias);
                handleChange({
                  filter:
                    criterias.length > 0
                      ? buildFilterFromCriteria(criterias, allowFields)
                      : {},
                  all: criterias.length === 0,
                });
              }}
            />
          )}
        </Box>
        {/*<Stack display="flex" flexDirection="row" gap={2}>
          <FormLabel>Action made on production is:</FormLabel>
          {errors?.productionCriteria && (
            <Typography color="red">{errors.productionCriteria}</Typography>
          )}
        </Stack>*/}
      </Box>
    </Box>
  );
}
