import { useState } from 'react';
import { useAppSelector } from '../../reducers/hooks';
import { PresetChip } from './PresetChip';

export const useSelectedPresets = (
  presetIds: any,
  presetType: string,
  onChange: any,
) => {
  const { savedFilters } = useAppSelector(state => state.filter);
  const [openId, setOpenId] = useState(0);

  const typedPresetsById = Object.entries(savedFilters)
    .map(([type, presets]) =>
      presets.map((preset: any) => ({ type, ...preset })),
    )
    .flat()
    .filter(preset => presetIds.includes(preset.id))
    .sort((one, other) => one.name.localeCompare(other.name));

  const typedPresets =
    presetType === 'workspace'
      ? typedPresetsById
      : typedPresetsById.filter(
          preset => preset.type === presetType || preset.type === 'global',
        );

  const presets = typedPresets.map(typedPreset => {
    return (
      <PresetChip
        key={typedPreset.id}
        tab={presetType}
        isOpen={openId === typedPreset.id}
        setOpen={setOpenId}
        preset={typedPreset}
        onDelete={() =>
          onChange(presetIds.filter((id: any) => id !== typedPreset.id))
        }
      />
    );
  });

  const readOnlyPresets = typedPresets.map(typedPreset => {
    return (
      <PresetChip
        key={typedPreset.id}
        tab={presetType}
        preset={typedPreset}
        onDelete={() =>
          onChange(presetIds.filter((id: any) => id !== typedPreset.id))
        }
      />
    );
  });

  return { presets, readOnlyPresets };
};
